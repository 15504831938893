export const cardStyles = {
  selected: {
    backgroundColor: 'var(--ql-card-background)',
    border: 'var(--ql-card-border-selected)',
    borderRadius: 'var(--card-radius)'
  },
  default: {
    border: 'var(--ql-card-border-default)',
    borderRadius: 'var(--card-radius)'
  }
}
