import { Tag } from 'antd'
import styled from 'styled-components'

const TagListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 12px;
`
const StyledTag = styled(Tag)`
  margin-right: 0;
`

export { TagListWrapper, StyledTag }
