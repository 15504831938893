import { useGetUser, useUserBaseActions } from 'domains/User/hooks'

import { Link } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { Tooltip } from 'antd'
import { createFullUserName } from 'helpers'

const FullNameLink = (props) => {
  const { text, userId, ellipsis, maxWidth } = props
  const { onOpen } = useUserBaseActions({ userId })

  /* Get user's document */
  const [user, loading] = useGetUser(!text && userId)

  /* Get user's full name */
  const fullName = text || createFullUserName(user?.firstName, user?.lastName)

  return (
    !loading && (
      <Tooltip title={ellipsis ? fullName : null}>
        <Link onClick={onOpen} ellipsis={ellipsis} maxWidth={maxWidth}>
          {fullName}
        </Link>
      </Tooltip>
    )
  )
}
FullNameLink.propTypes = {
  text: PropTypes.string,
  userId: PropTypes.string,
  ellipsis: PropTypes.bool,
  maxWidth: PropTypes.string
}

export default FullNameLink
