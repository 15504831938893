import { COLLECTIONS, STATUSES } from '__constants__'

import { useGetQuery } from 'hooks'
import { useMemo } from 'react'
import { collection, orderBy, query, where } from 'firebase/firestore'
import firestore from 'services/firebase/firestore'
import { useCollectionData } from 'react-firebase-hooks/firestore'

/**
 * It returns an array of values that are used to render the users
 * @param props - An object that contains the query parameters.
 * @returns An array of values.
 */

const useGetUsers = (props) => {
  const generatedQueryParams = useGetQuery({
    collectionName: COLLECTIONS.USERS,
    where: props?.where,
    orderBy: props?.orderBy,
    statuses: STATUSES.USER_STATUSES,
    fieldName: 'newspaperAssociated'
  })

  const buildQuery = useMemo(() => {
    return query(
      collection(firestore, generatedQueryParams?.ref),
      ...(generatedQueryParams?.where?.map?.((rule) => where(...rule)) || []),
      ...(generatedQueryParams?.orderBy?.map?.((rule) => orderBy(...rule)) ||
        [])
    )
  }, [generatedQueryParams])

  const [value, loading, error] = useCollectionData(buildQuery)

  const usersMapped = useMemo(() => {
    return value?.filter(({ _id }) => _id)
  }, [value])

  return [usersMapped, loading, error]
}

export default useGetUsers
