import { Col, PageWrapper, Row } from '@qonsoll/react-design'
import { HeaderBreadcrumbs, PageActions, Spinner } from 'components'
import { useParams } from 'react-router-dom'

import { EditorAdvancedView } from 'domains/Editor/components'
import { useTranslations } from 'contexts/Translation'
import {
  useGetEditorInitialValues,
  useEditorBaseActions
} from 'domains/Editor/hooks'

const EditorShowPage = () => {
  /* Getting translations instance */
  const { t } = useTranslations()

  /* Getting params from router */
  const params = useParams()

  /* Getting id from params */
  const { editorId } = params

  /* Get base actions for editor */
  const { onBack, onEdit } = useEditorBaseActions({ editorId })

  /* Get editor's data */
  const [profile, loading] = useGetEditorInitialValues(editorId)

  /* Configuring page header */
  const headingProps = {
    title: profile?.editor?.fullName || t('Editor Details'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  /* Configuring page actions */
  const actions = { edit: true }

  return (
    <PageWrapper
      action={<PageActions actions={actions} onEdit={onEdit} />}
      onBack={onBack}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}>
      <Row noGutters>
        {loading ? (
          <Col>
            <Spinner height="70vh" text={t('Editor loading')} />
          </Col>
        ) : (
          <Col cw={12}>
            <EditorAdvancedView {...profile} />
          </Col>
        )}
      </Row>
    </PageWrapper>
  )
}

export default EditorShowPage
