import { COLLECTIONS, KEYS, STATUSES } from '__constants__'
import { getUserCustomClaimsAndUid, uploadImage } from 'helpers'
import {
  createDocument,
  getId,
  updateDocument,
  updateParent
} from 'services/api/firebase'
import { useUser } from 'domains/User/context'

import { Editor } from 'models'
import { message } from 'antd'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const useActionsEditorAdvancedForm = ({ initialData, form } = {}) => {
  /* State for loading */
  const [loading, setLoading] = useState(false)
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()
  /* Get user's token */
  const { token } = useUser()

  /* Getting history state */
  const historyState = history.location.state

  /* Function for preparing values for saving */
  const prepareValues = async (values = {}, additionalValues = {}) => {
    /* If there is user for current email in auth record - get its uid */
    const uid = values?.uid

    /* Getting id */
    const editorId =
      initialData?.['editor']?._id || uid || getId(COLLECTIONS.EDITORS)

    /* Preparing editor values */
    const preparedValues = {
      _id: editorId,
      ...additionalValues,
      firstName: values?.['editor']?.['firstName'] ?? null,
      lastName: values?.['editor']?.['lastName'] ?? null,
      email: values?.['editor']?.['email'] ?? null,
      phoneNumber: values?.['editor']?.['phoneNumber'] ?? null,
      avatarUrl: (await uploadImage(values?.['editor']?.['avatarUrl'])) ?? null,
      newspaperId: values['newspaper']?._id ?? null,
      isBlocked: values?.['editor']?.['isBlocked'] ?? false,
      // isTemporaryPasswordResolved will be moved to customClaims
      isTemporaryPasswordResolved:
        values?.['editor']?.['isTemporaryPasswordResolved'] ?? false,
      status: STATUSES.EDITOR_STATUSES.INVITED,
      totalTips: 0,
      sno: null
    }
    return preparedValues
  }

  /* Saving form data */
  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)

      // Updating parent references
      if (historyState?.parent)
        await updateParent(historyState.parent, data._id)

      // Save data
      if (initialData?.editor) {
        await updateDocument(COLLECTIONS.EDITORS, initialData.editor?._id, data)

        /* Update newspaperId if it was changed during editing */
        if (initialData.editor?.newspaperId !== data?.newspaperId) {
          await updateDocument(
            COLLECTIONS.EMAILS,
            initialData.editor?.emailId,
            {
              newspaperId: data?.newspaperId
            }
          )
        }
        message.success(t('Editor successfully updated'))
      } else {
        await createDocument(COLLECTIONS.EDITORS, data, data._id)
        message.success(t('Editor successfully created'))
      }
      // Final callback
      callback?.()
    } catch (error) {
      throw new Error(t('Something went wrong during data save'))
    }
  }

  /* Function for validating form */
  const validateForm = (values) => {
    try {
      // Prepare data to be validated
      const validationData = {
        firstName: values?.['editor']?.['firstName'],
        lastName: values?.['editor']?.['lastName'],
        email: values?.['editor']?.['email'],
        phoneNumber: values?.['editor']?.['phoneNumber'],
        avatarUrl: values?.['editor']?.['avatarUrl']
      }
      Editor.validationSchema.validateSync(validationData)
    } catch (error) {
      throw new Error(t('Editor validation error: ') + t(error.message))
    }
  }

  /* On finish callback */
  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()
      // Validate fields
      validateForm(formValues)
      // Final callback
      const callback = () => history.goBack()

      // Check of user exist in system like editor
      const { uid, customClaims = {} } = await getUserCustomClaimsAndUid(
        formValues?.editor?.email,
        token
      )
      const isEditor = customClaims?.editor

      if (isEditor && !initialData) {
        message.error(t('This editor already exists, please enter another!'))
        return
      }
      // Save data
      await saveForm({ ...formValues, uid }, callback)
    } catch (error) {
      message.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  /* On cancel callback */
  const onReset = () => {
    form.resetFields()
    history.goBack()
  }

  /* Handling enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      form.submit()
    }
  }

  return {
    onFinish,
    onReset,
    loading,
    saveForm,
    validateForm,
    prepareValues,
    handleKeyPress
  }
}

export default useActionsEditorAdvancedForm
