import { Col, Container, Row, Text } from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'
import { capitalizeFirstLetter } from 'utils'

export default function RewardRewardType(props) {
  const { reward } = props

  /* Getting translation instance */
  const { t } = useTranslations()

  const rewardTypeTextComputed = capitalizeFirstLetter(
    reward?.type?.toLocaleLowerCase() || ''
  )

  return (
    <Container>
      <Row noGutters>
        <Col>
          {reward?.type ? (
            <Text>{rewardTypeTextComputed}</Text>
          ) : (
            <Text type="secondary" variant="overline">
              {t('No reward types have been added yet')}
            </Text>
          )}
        </Col>
      </Row>
    </Container>
  )
}

RewardRewardType.propTypes = {
  reward: PropTypes.object.isRequired
}
