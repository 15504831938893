import { Card, Tabs } from 'antd'
import { Col, Container, Img, Row, Text, Title } from '@qonsoll/react-design'
import { StoreNewspaper, StoreStoreStatus } from 'domains/Store/components'
import { useHistory, useParams } from 'react-router-dom'

import PropTypes from 'prop-types'
import { StickyContainer } from 'react-sticky'
import styles from './StoreAdvancedView.styles'
import { useStateWithStorage } from 'hooks'
import { useTranslations } from 'contexts/Translation'

const StoreAdvancedView = (props) => {
  const { store } = props

  /* State for active tab in AdvancedView with saving it to storage */
  const [activeTab, setActiveTab] = useStateWithStorage(
    null,
    'store-advanced-view-active-tab'
  )

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Getting history instance from useHistory hook for redirecting */
  const history = useHistory()

  /* Getting params from useParams hook for getting url params */
  const params = useParams()

  /* Getting id from params */
  const { storeId } = params

  /* Getting TabPane component from Tabs */
  const { TabPane } = Tabs

  /* Handling change tab */
  const changeTab = (key) => {
    if (params && storeId) {
      if (key.includes(`stores/${storeId}`)) {
        history.push(key)
      }
      setActiveTab(key)
    }
  }

  return (
    <Container>
      <Row mx="var(--ql-grid-gutter-negative)">
        <Col cw={4} mb={3}>
          <Card>
            <Row noGutters mb={2} v="center">
              <Col h="left">
                <Img style={styles.img} src={store?.logoUrl} />
              </Col>
            </Row>
            <Row noGutters mb={2} v="center">
              <Col h="left">
                <Title level={5}>{store?.name}</Title>
              </Col>
            </Row>

            <Row noGutters v="center" mb={2}>
              <Col h="left">
                <Text variant="overline" type="secondary">
                  {t('Sno')}:
                </Text>
              </Col>
              <Col h="right">
                <Text>{store?.sno}</Text>
              </Col>
            </Row>
            <Row noGutters v="center" mb={2}>
              <Col h="left">
                <Text variant="overline" type="secondary">
                  {t('Status')}:
                </Text>
              </Col>
              <Col cw="auto" h="right">
                <StoreStoreStatus status={store?.status} />
              </Col>
            </Row>
            <Row noGutters v="center" mb={2}>
              <Col h="left">
                <Text variant="overline" type="secondary">
                  {t('Total coupons')}:
                </Text>
              </Col>
              <Col h="right">
                <Text>{store?.totalCoupons}</Text>
              </Col>
            </Row>
            <Row noGutters v="center" mb={2}>
              <Col h="left">
                <Text variant="overline" type="secondary">
                  {t('Redeemed')}:
                </Text>
              </Col>
              <Col h="right">
                <Text>{store?.redeemed}</Text>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col cw={12}>
          <StickyContainer>
            <Tabs defaultActiveKey={activeTab} onChange={changeTab}>
              <TabPane tab={t('Newspaper')} key="#newspaper">
                <StoreNewspaper store={store} />
              </TabPane>
            </Tabs>
          </StickyContainer>
        </Col>
      </Row>
    </Container>
  )
}

StoreAdvancedView.propTypes = {
  store: PropTypes.object
}

export default StoreAdvancedView
