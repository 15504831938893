import { Form } from 'antd'
import { KEYS } from '__constants__'

const useSettingsSimpleForm = ({
  form,
  initialValues,
  onCancel,
  setPageConfiguration
} = {}) => {
  /* Getting new form instance from Form.useForm hook */
  const [baseForm] = Form.useForm()

  /* Getting used form from props or using base form */
  const usedForm = form || baseForm

  /* Resetting form on cancel */
  const handleCancel = () => {
    usedForm.resetFields()
    onCancel?.()
  }
  /* Submitting form on enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      e.stopPropagation()
      usedForm?.submit()
    }
  }

  const updateFormData = () => {
    setPageConfiguration(usedForm?.getFieldsValue())
  }

  const validationRules = {}

  return {
    usedForm,
    validationRules,
    handleKeyPress,
    updateFormData,
    handleCancel
  }
}

export default useSettingsSimpleForm
