import {
  DEFAULT_LANGUAGE,
  ENGLISH_SHORT_CODE_LANGUAGE
} from '../../__constants__'

import { Select } from 'antd'
import moment from 'moment'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

const LanguageSelect = () => {
  const { setCurrentLanguage, language, languages, loading } = useTranslations()
  const handleChange = ({ value }) => {
    setCurrentLanguage(value)
    moment.locale(value === DEFAULT_LANGUAGE ? 'nb' : value)
  }

  const options = useMemo(
    () =>
      languages
        ?.filter(({ value }) => value !== language)
        ?.map(({ name, value, label }) => ({ label, name, value })),
    [languages, language]
  )

  const defaultLanguage = useMemo(() => {
    const currentLanguage = languages?.find(({ value }) => value === language)

    //In case when language was removed but in the same time was selected
    if (!currentLanguage) {
      const defaultLanguage = languages?.find(
        ({ value }) => value === ENGLISH_SHORT_CODE_LANGUAGE
      )

      handleChange(defaultLanguage)
      return {
        name: defaultLanguage?.name,
        value: defaultLanguage?.value,
        label: defaultLanguage?.label
      }
    }

    return {
      name: currentLanguage?.name,
      value: currentLanguage?.value,
      label: currentLanguage?.label
    }
  }, [language, languages])

  return (
    <Select
      labelInValue
      onSelect={handleChange}
      value={defaultLanguage}
      options={options}
      loading={loading}
    />
  )
}

export default LanguageSelect
