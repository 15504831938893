import { Button, Col, Row, Text, Title } from '@qonsoll/react-design'
import { Checkbox, Form, Input, InputNumber } from 'antd'
import { KEYS, MAX_LENGTH_FIELD } from '__constants__'

import { ImageUploader } from 'components'
import PropTypes from 'prop-types'
import { StyledFormItem } from './RewardSimpleForm.styles'
import { useTranslations } from 'contexts/Translation'

const RewardSimpleForm = (props) => {
  const { initialValues, onSubmit, onCancel, showTitle, form, group } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting new form instance from Form.useForm hook */
  const [baseForm] = Form.useForm()

  /* Getting used form from props or using base form */
  const usedForm = form || baseForm

  /* Resetting form on cancel */
  const handleCancel = () => {
    usedForm.resetFields()
    onCancel?.()
  }
  /* Submitting form on enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      e.stopPropagation()
      usedForm?.submit()
    }
  }

  return group ? (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Reward')}
        </Title>
      )}
      <Form.Item label={t('Image')} name={[...group, 'imageUrl']}>
        <ImageUploader />
      </Form.Item>
      <Form.Item
        label={t('Reward name')}
        name={[...group, 'name']}
        rules={[
          {
            required: true,
            message: t('Please enter reward name!')
          },
          { max: MAX_LENGTH_FIELD, message: t('reward name is too long!') }
        ]}>
        <Input placeholder={t('Please, enter reward name')} />
      </Form.Item>

      <Row noOuterGutters>
        <Col>
          <Form.Item
            label={t('Points')}
            name={[...group, 'points']}
            rules={[
              {
                required: true,
                message: t('Please enter reward points!')
              }
            ]}>
            <InputNumber placeholder={t('Please, enter reward points')} />
          </Form.Item>
        </Col>

        <Col justifyContent="center">
          <Row noOuterGutters>
            <Col cw="auto">
              <StyledFormItem
                valuePropName="checked"
                name={[...group, 'isVisible']}>
                <Checkbox>
                  <Text wordBreak="break-all">{t('Is visible')}</Text>
                </Checkbox>
              </StyledFormItem>
            </Col>
            <Col>
              <StyledFormItem
                valuePropName="checked"
                name={[...group, 'isTotalUsesEnabled']}>
                <Checkbox>
                  <Text wordBreak="break-all">{t('Show total uses')}</Text>
                </Checkbox>
              </StyledFormItem>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  ) : (
    <Form
      form={usedForm}
      initialValues={initialValues}
      onFinish={onSubmit}
      layout="vertical"
      onKeyPress={handleKeyPress}
      autoComplete="off">
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Reward')}
        </Title>
      )}
      <Form.Item
        label={t('Image')}
        name="imageUrl"
        rules={[
          {
            required: false,
            message: t('Please upload reward image!')
          }
        ]}>
        <ImageUploader />
      </Form.Item>
      <Form.Item
        label={t('Name')}
        name="name"
        rules={[
          {
            required: false,
            message: t('Please enter your reward name!')
          },
          { max: MAX_LENGTH_FIELD, message: t('reward name is too long!') }
        ]}>
        <Input placeholder={t('Please enter your reward name')} />
      </Form.Item>
      <Form.Item
        label={t('Sno')}
        name="sno"
        rules={[
          {
            required: false,
            message: t('Please enter your reward sno!')
          }
        ]}>
        <InputNumber placeholder={t('Please enter your reward sno')} />
      </Form.Item>
      <Form.Item
        label={t('Store')}
        name="store"
        rules={[
          {
            required: false,
            message: t('Please enter your reward store!')
          },
          { max: MAX_LENGTH_FIELD, message: t('reward store is too long!') }
        ]}>
        <Input placeholder={t('Please enter your reward store')} />
      </Form.Item>
      <Form.Item
        label={t('Redeemed')}
        name="redeemed"
        valuePropName="checked"
        initialValue={false} // TODO fix initial value
        rules={[
          {
            required: false,
            message: t('Please enter your reward redeemed!')
          }
        ]}>
        <Checkbox placeholder={t('Please enter your reward redeemed')} />
      </Form.Item>
      <Form.Item
        label={t('Reward')}
        name="reward"
        rules={[
          {
            required: false,
            message: t('Please enter your reward reward!')
          },
          { max: MAX_LENGTH_FIELD, message: t('reward reward is too long!') }
        ]}>
        <Input placeholder={t('Please enter your reward reward')} />
      </Form.Item>
      <Form.Item
        label={t('Points')}
        name="points"
        rules={[
          {
            required: false,
            message: t('Please enter your reward points!')
          }
        ]}>
        <InputNumber placeholder={t('Please enter your reward points')} />
      </Form.Item>
      {!form && (
        <>
          <Button mr={2} htmlType="button" onClick={handleCancel}>
            {t('Cancel')}
          </Button>
          <Button type="primary" onClick={() => usedForm.submit()}>
            {t('Save')}
          </Button>
        </>
      )}
    </Form>
  )
}

RewardSimpleForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  showTitle: PropTypes.bool,
  form: PropTypes.any,
  group: PropTypes.array
}

export default RewardSimpleForm
