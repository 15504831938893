import { Col, Container, Row } from '@qonsoll/react-design'
import { FooterStyled, StyledTextWrapper } from './Footer.styled'

import PropTypes from 'prop-types'
import React from 'react'
import { convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'

const Footer = (props) => {
  const { pageConfiguration } = props

  const { backgroundColor, textColor, mainText } =
    pageConfiguration?.footer || {}
  const footerText =
    mainText && draftToHtml(convertToRaw(mainText?.getCurrentContent()))

  return (
    <FooterStyled bg={backgroundColor} textColor={textColor}>
      <Container bounded>
        <Row noGutters>
          <Col cw={12}>
            <StyledTextWrapper
              dangerouslySetInnerHTML={{ __html: footerText }}
            />
          </Col>
        </Row>
      </Container>
    </FooterStyled>
  )
}
Footer.propTypes = {
  pageConfiguration: PropTypes.object
}
export default Footer
