import {
  Col,
  Container,
  Option,
  Row,
  Select,
  Title
} from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import { capitalize } from 'helpers'
import { useGetStores } from 'domains/Store/hooks/get'
import { useTranslations } from 'contexts/Translation'

const StoreSelect = (props) => {
  const {
    value,
    onChange,
    showTitle = true,
    allowClear = true,
    mode,
    disabled,
    ...rest
  } = props
  const { t } = useTranslations()

  const [storesAll, loading] = useGetStores()

  const handleChange = (value) => {
    const _option = storesAll?.find(({ _id: id }) => id === value)
    onChange?.(_option || null, value)
  }

  return (
    <Container {...rest}>
      {showTitle && (
        <Title mb={2} level={4}>
          {t('Store')}
        </Title>
      )}
      <Row noGutters>
        <Col>
          <Select
            disabled={disabled}
            mode={mode}
            allowClear={allowClear}
            loading={loading}
            placeholder={t('Please, select store')}
            value={value?._id}
            onChange={handleChange}>
            {storesAll?.map((store) => (
              <Option key={store?._id} value={store?._id}>
                {capitalize(store?.name)}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
    </Container>
  )
}

StoreSelect.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  showTitle: PropTypes.bool,
  allowClear: PropTypes.bool,
  mode: PropTypes.string,
  disabled: PropTypes.bool
}
export default StoreSelect
