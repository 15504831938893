import { Form, Tabs } from 'antd'
import { Col, Input, Row, Text } from '@qonsoll/react-design'
import { NewspaperExpirationMessageInput } from 'domains/Newspaper/components'
import { onTextAreaEnterPress } from 'helpers'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useGetNewspaperExpirationValidationRules } from 'domains/Newspaper/hooks'
import { useTranslations } from '@qonsoll/translation'
import PropTypes from 'prop-types'

const NewspaperExpirationMessageForm = (props) => {
  const {
    language,
    group,
    initialValues,
    arePointsExpiring,
    setActiveLanguage
  } = props

  const { expirationMessageRule, expirationTitleRule } =
    useGetNewspaperExpirationValidationRules({ setActiveLanguage })
  const { t } = useTranslations()

  return (
    <>
      <Form.Item
        validateTrigger={['onSubmit']}
        initialValue={
          initialValues?.expirationMessageTitle?.[language?.shortCode] ||
          'Notification'
        }
        label={`${t('Push')} / ${t('in app notification title')}`}
        name={[...group, 'expirationMessageTitle', language?.shortCode]}
        rules={[expirationTitleRule(language?.shortCode)]}>
        <Input
          placeholder={`${t(
            "Please enter the title that will appear in the tipper's notifications"
          )}.`}
          disabled={!arePointsExpiring}
        />
      </Form.Item>
      <Form.Item
        validateTrigger={['onSubmit']}
        label={`${t('Push')} / ${t('in app notification text')}`}
        name={[...group, 'expirationMessage', language?.shortCode]}
        rules={[expirationMessageRule(language?.shortCode)]}>
        <NewspaperExpirationMessageInput
          onKeyPress={onTextAreaEnterPress}
          disabled={!arePointsExpiring}
        />
      </Form.Item>
      <Row noGutters v="center">
        <Col h="center" cw={1}>
          <InfoCircleOutlined size="24px" />
        </Col>
        <Col cw={11}>
          <Text>
            {`${t('You can use the text of the Push')} / ${t(
              'in-app notification as a template for reminders that will be sent to the user'
            )}. ${t('When writing the template')}, ${t(
              'you can add dynamic variables using the symbol'
            )} "{". ${t('Once you click on it')}, ${t(
              'you will receive prompts from the list of variables that can be used'
            )}.`}
          </Text>
        </Col>
      </Row>
    </>
  )
}

NewspaperExpirationMessageForm.propTypes = {
  language: PropTypes.object,
  group: PropTypes.array,
  initialValues: PropTypes.object,
  arePointsExpiring: PropTypes.bool,
  setActiveLanguage: PropTypes.func
}

export default NewspaperExpirationMessageForm
