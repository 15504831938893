import { Checkbox } from 'antd'
import PropTypes from 'prop-types'
import { STATUSES } from '__constants__'
import { Text } from '@qonsoll/react-design'
import { useSubfilterActions } from 'hooks'
import { useTranslations } from 'contexts/Translation'
const EditorStatusFilter = ({ filterData, setFilterData }) => {
  const { t } = useTranslations()

  const { onToggleDetails, onChange, checkIsEnabled } = useSubfilterActions({
    filterData,
    setFilterData,
    fieldName: 'status',
    operand: '==',
    type: 'editorStatus'
  })

  const editorStatuses = Object.values(STATUSES.EDITOR_STATUSES)?.map(
    (editorStatus) => ({ value: editorStatus, name: editorStatus })
  )

  return (
    <details
      open={
        localStorage.getItem(`editorStatus-details-visibility`) === 'opened'
      }>
      <summary onClick={onToggleDetails}>{t('Editor status')}</summary>
      {editorStatuses?.map((editorStatus, index) => (
        <Checkbox
          key={`status-${index}`}
          checked={checkIsEnabled(editorStatus)}
          onChange={() => onChange(editorStatus)}>
          <Text wordBreak="break-all">
            {editorStatus?.name || t('Unnamed filter')}
          </Text>
        </Checkbox>
      ))}
    </details>
  )
}

EditorStatusFilter.propTypes = {
  filterData: PropTypes.object,
  setFilterData: PropTypes.func
}

export default EditorStatusFilter
