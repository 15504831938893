import { doc } from 'firebase/firestore'
import { firestore } from 'services/firebase'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { COLLECTIONS } from '__constants__'

const useGetUser = (userId) => {
  const [user, loading] = useDocumentData(
    userId && doc(firestore, COLLECTIONS.USERS, userId)
  )
  return [user, loading]
}

export default useGetUser
