import { useEffect, useMemo, useState } from 'react'

import _ from 'lodash'

const useCheckChangeFormValue = (form) => {
  const [initialFieldsValue, setInitialFieldsValue] = useState()
  const [isEqualForms, setIsEqualForms] = useState(true)

  useEffect(() => {
    form && setInitialFieldsValue(form)
  }, [form, form?.newspaper])

  // Convert emoji to unicode
  const convertEmojiToCode = (objectKeys, value) => {
    if (value && objectKeys.at(-1) === 'emoji') {
      return value?.codePointAt(0)?.toString(16)
    }
  }

  // This function checks if the value is object
  const checkIsObject = (object) =>
    typeof object === 'object' && !Array?.isArray(object) && object !== null

  // The function returns an array of keys to the deep value of the object
  const getObjectKeys = (object) => {
    const objectKeys = []

    // Recursion that gets keys to deep object value
    const checkDeepObject = (object) => {
      const isObject = checkIsObject(object)
      const isDocument = Object?.keys?.(object)?.includes('_id')

      if (isObject && !isDocument)
        return Object?.entries(object)?.forEach((item) => {
          const [key, value] = item

          if (!objectKeys?.includes?.(key)) objectKeys?.push?.(key)

          if (isObject) return checkDeepObject(value)
        })
    }

    checkDeepObject(object)
    return objectKeys
  }

  // Gets the value of the object by the array of keys that we pull from the getObjectKeys() function
  const getObjectValue = (objectKeys, object, checkIsEmoji) => {
    let value = {}

    objectKeys?.forEach((item, i) => {
      if (i === 0) return (value = object?.[item])

      //If value is null or undefined then return value ''
      if (value?.[item] === null || value?.[item] === undefined)
        return (value = '')

      const emojiValue = convertEmojiToCode?.(objectKeys, value?.[item])
      if (emojiValue) return (value = emojiValue)

      value = value?.[item]
    })

    return value
  }

  const values = {}

  const triggerValueChange = useMemo(
    () => (e) => {
      // Get keys from object
      const objectKeys = getObjectKeys(e)

      // Get value from initial object
      const initialValue = getObjectValue(objectKeys, initialFieldsValue)

      // Get value from event object
      const eventValue = getObjectValue(objectKeys, e, convertEmojiToCode)

      // Check if event value and initial value is equal
      const equalValue =
        checkIsObject(initialValue) && checkIsObject(eventValue)
          ? _.isEqual(initialValue, eventValue)
          : initialValue === eventValue

      // Get the name of the form field from object keys
      // If the last element is '_id' then take the previous
      const fieldName =
        objectKeys.at(-1) !== '_id' ? objectKeys.at(-1) : objectKeys.at(-2)

      fieldName && (values[fieldName] = equalValue)

      //If false is found in the array, then set isEqualForms = false
      const isEqualForms =
        Object?.values(values)?.indexOf(false) === -1 ? true : false

      setIsEqualForms(isEqualForms)
    },
    [initialFieldsValue]
  )

  return { triggerValueChange, isEqualForms }
}

export default useCheckChangeFormValue
