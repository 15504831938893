import { Link, Text } from '@qonsoll/react-design'
import { MOMENT_FORMATS, STATUSES, TABLE_PATHS } from '__constants__'
import { PhoneNumber, TableActions } from 'components'
import { cloneElement, useMemo } from 'react'
import { generatePath, useHistory } from 'react-router-dom'

import { EditorEditorStatus } from 'domains/Editor/components'
import moment from 'moment'
import { sortByAlphabet } from 'helpers'
import { useEditorBaseActions } from 'domains/Editor/hooks'
import { useGetColumnSearchProps } from 'hooks'
import { useTranslations } from '@qonsoll/translation'

const EditorTableActions = ({ status, editorId, isBlocked }) => {
  const actions = useEditorBaseActions({ editorId, isBlocked })

  const { USER_STATUSES } = STATUSES

  const isInvitedUser = status === USER_STATUSES.INVITED

  return cloneElement(<TableActions />, {
    actions,
    isBlocked,
    name: 'editor',
    isInvitedUser
  })
}

const useEditorTableColumns = (currentNewspaperEditorsOnly) => {
  const { t } = useTranslations()

  const history = useHistory()

  const getColumnSearchProps = useGetColumnSearchProps()

  return useMemo(() => {
    const handleOpen = (path, params) => () => {
      history.push(generatePath(path, params))
    }
    let finalArr = [
      {
        title: <Text fontWeight="600">{t('Sno')}</Text>,
        width: 'var(--table-number-column-width)',
        dataIndex: 'sno',
        key: 'sno',
        align: 'center',
        sorter: (a, b) => a?.sno - b?.sno,
        ...getColumnSearchProps('sno', 'sno')
      },
      {
        title: <Text fontWeight="600">{t('Name')}</Text>,
        dataIndex: 'name',
        key: 'name',
        align: 'center',
        sorter: (a, b) => sortByAlphabet(a?.fullName, b?.fullName),
        ...getColumnSearchProps('fullName', 'name'),
        render: (_, record) => (
          <Link
            onClick={handleOpen(TABLE_PATHS.EDITOR_SHOW, {
              editorId: record?._id
            })}>
            {record?.fullName}
          </Link>
        )
      },
      {
        title: <Text fontWeight="600">{t('Added on')}</Text>,
        width: 'var(--table-date-column-width)',
        dataIndex: '_createdAt',
        key: '_createdAt',
        align: 'center',
        sorter: (a, b) => a?._createdAt?.seconds - b?._createdAt?.seconds,
        render: (date) => (
          <Text>
            {moment
              .unix(date?.seconds)
              .format(MOMENT_FORMATS.MONTH_DAYS_COMMA_YEAR)}
          </Text>
        )
      },
      {
        title: <Text fontWeight="600">{t('Email Address')}</Text>,
        dataIndex: 'email',
        key: 'email',
        align: 'center',
        sorter: (a, b) => sortByAlphabet(a?.email, b?.email),
        ...getColumnSearchProps('email')
      },
      {
        title: <Text fontWeight="600">{t('Newspaper')}</Text>,

        dataIndex: 'newspaper',
        key: 'newspaper',
        align: 'center',
        sorter: (a, b) =>
          sortByAlphabet(a?.newspaper?.name, b?.newspaper?.name),
        ...getColumnSearchProps(['newspaper', 'name']),
        render: (value) => (
          <Link
            onClick={handleOpen(TABLE_PATHS.NEWSPAPER_SHOW, {
              newspaperId: value?._id
            })}>
            {value?.name}
          </Link>
        )
      },
      {
        title: <Text fontWeight="600">{t('Total tips')}</Text>,
        dataIndex: 'totalTips',
        key: 'totalTips',
        align: 'center',
        sorter: (a, b) => a?.totalTips - b?.totalTips
      },

      {
        title: <Text fontWeight="600">{t('Mobile Number')}</Text>,
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
        align: 'center',
        sorter: (a, b) => sortByAlphabet(a?.phoneNumber, b?.phoneNumber),
        ...getColumnSearchProps('phoneNumber', 'phone'),
        render: (_, record) => {
          const computedPhoneNumber = record?.phoneNumber || record?.phone
          return <PhoneNumber phoneNumber={computedPhoneNumber} />
        }
      },

      {
        title: <Text fontWeight="600">{t('Status')}</Text>,
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        sorter: (a, b) => sortByAlphabet(a?.status, b?.status),
        render: (_, record) => <EditorEditorStatus editor={record} />
      },
      {
        title: <Text fontWeight="600">{t('Actions')}</Text>,
        width: 'var(--table-actions-column-width)',
        dataIndex: 'actions',
        key: 'actions',
        align: 'center',
        render: (_, record) => (
          <EditorTableActions
            isBlocked={record?.isBlocked}
            editorId={record?._id}
            status={record?.status}
          />
        )
      }
    ]

    if (currentNewspaperEditorsOnly) {
      finalArr = finalArr.filter(
        ({ dataIndex }) =>
          !['newspaper', 'totalTips', 'actions'].includes(dataIndex)
      )
    } else {
      finalArr = finalArr.filter(
        ({ dataIndex }) => !['phoneNumber'].includes(dataIndex)
      )
    }
    return finalArr
  }, [t, getColumnSearchProps, currentNewspaperEditorsOnly, history])
}

export default useEditorTableColumns
