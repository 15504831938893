import { useCallback, useEffect, useState } from 'react'

import { useTranslations } from 'contexts/Translation'

const useGetLanguages = (countryLanguages) => {
  const [languages, setLanguages] = useState([])
  const [loading, setLoading] = useState(true)

  const { languages: initialLanguages, loaded } = useTranslations()

  const transformLanguages = useCallback(
    (countryLanguages, languages) => {
      try {
        setLoading(true)
        if (!languages?.length || !countryLanguages?.length)
          return setLanguages(() => [])

        const transformedLanguages = countryLanguages
          ?.map((key) => {
            const data = languages?.find((item) => item?.shortCode === key)
            return data ? [key, data] : null
          })
          ?.filter(Boolean)

        if (transformedLanguages?.length) {
          setLanguages(() => Object.fromEntries(transformedLanguages))
        }

        return null
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    },
    [countryLanguages, initialLanguages]
  )

  useEffect(() => {
    let isMounted = true
    isMounted &&
      loaded &&
      transformLanguages(countryLanguages, initialLanguages)
    return () => (isMounted = false)
  }, [countryLanguages, initialLanguages])

  return [languages, loading]
}

export default useGetLanguages
