import { COLLECTIONS, KEYS, STATUSES } from '__constants__'
import {
  createDocument,
  getId,
  updateDocument,
  updateParent
} from 'services/api/firebase'

import { Subadmin } from 'models'
import { getUserCustomClaimsAndUid } from 'helpers'
import { message } from 'antd'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

const useActionsSubadminAdvancedForm = ({
  isSuperAdmin = false,
  initialData,
  form
} = {}) => {
  /* State for loading */
  const [loading, setLoading] = useState(false)
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()
  /* Getting history state */
  const historyState = history.location.state
  /* Get user's token */
  const { token } = useUser()

  const entityNameComputed = isSuperAdmin ? t('Super admin') : t('Subadmin')
  const entityFieldNameComputed = isSuperAdmin ? 'superadmin' : 'subadmin'
  const collectionNameComputed = isSuperAdmin
    ? COLLECTIONS.SUPER_ADMINS
    : COLLECTIONS.SUBADMINS

  /* Check if email exist in system */

  /* Function for preparing values for saving */
  const prepareValues = async (values = {}, additionalValues = {}) => {
    /* If there is user for current email in auth record - get its uid */
    const uid = values?.uid

    /* Getting id */
    const entityIdComputed =
      initialData?.[entityFieldNameComputed]?._id ||
      uid ||
      getId(collectionNameComputed)

    /* Preparing entity values */
    const preparedValues = {
      _id: entityIdComputed,
      ...additionalValues,
      firstName: values?.[entityFieldNameComputed]?.['firstName'] ?? null,
      lastName: values?.[entityFieldNameComputed]?.['lastName'] ?? null,
      email: values?.[entityFieldNameComputed]?.['email'] ?? null,
      isBlocked: values?.[entityFieldNameComputed]?.['isBlocked'] ?? false,
      avatarUrl: values?.[entityFieldNameComputed]?.['avatarUrl'] ?? null,
      phoneNumber: values?.[entityFieldNameComputed]?.['phoneNumber'] ?? null,
      // isTemporaryPasswordResolved will be moved to customClaims
      isTemporaryPasswordResolved:
        values?.[entityFieldNameComputed]?.['isTemporaryPasswordResolved'] ??
        false
    }

    /* Checking if the user is an admin or not. If the user is not an admin, then it will add the newspaper
to the preparedValues. */
    if (!isSuperAdmin) {
      preparedValues.newspaperId = values['newspaper']?._id ?? null
    }

    return preparedValues
  }

  /* Saving form data */
  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)

      if (historyState?.parent)
        await updateParent(historyState.parent, data._id)

      // Save data
      if (initialData) {
        await updateDocument(
          collectionNameComputed,
          initialData.subadmin?._id,
          data
        )
        message.success(`${entityNameComputed} ${t('successfully updated')}`)
      } else {
        const documentCreationExtraFields = {
          sno: null,
          isBlocked: false,
          isEmailVerified: false,
          isAgreementAccepted: false,
          isGdprAccepted: false,
          isInvitationSent: false,
          status: STATUSES.USER_STATUSES.INVITED
        }
        const documentCreationFields = {
          ...documentCreationExtraFields,
          ...data
        }

        await createDocument(
          collectionNameComputed,
          documentCreationFields,
          data._id
        )
        message.success(`${entityNameComputed} ${t('successfully created')}`)
      }
      // Final callback
      callback?.()
    } catch (error) {
      throw new Error(t('Something went wrong during data save'))
    }
  }

  /* Function for validating form */
  const validateForm = (values) => {
    try {
      // Prepare data to be validated
      const validationData = {
        firstName: values?.[entityFieldNameComputed]?.['firstName'] ?? null,
        lastName: values?.[entityFieldNameComputed]?.['lastName'] ?? null,
        email: values?.[entityFieldNameComputed]?.['email']
      }
      Subadmin.validationSchema.validateSync(validationData)
    } catch (error) {
      throw new Error(
        `${entityNameComputed} ${t('validation error: ')} ${t(error.message)}`
      )
    }
  }

  /* On finish callback */
  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()
      // Validate fields
      validateForm(formValues)
      // Final callback
      const callback = () => history.goBack()

      // Check of user exist in system like editor
      const { uid, customClaims = {} } = await getUserCustomClaimsAndUid(
        formValues?.[entityFieldNameComputed]?.email,
        token
      )
      const isAdmin = customClaims?.admin

      if (isAdmin && !initialData) {
        message.error(t('This admin already exists, please enter another!'))
        return
      }
      // Save data
      await saveForm({ ...formValues, uid }, callback)
    } catch (error) {
      message.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  /* On cancel callback */
  const onReset = () => {
    form.resetFields()
    history.goBack()
  }

  /* Handling enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      form.submit()
    }
  }

  return {
    onFinish,
    onReset,
    loading,
    saveForm,
    validateForm,
    prepareValues,
    handleKeyPress
  }
}

export default useActionsSubadminAdvancedForm
