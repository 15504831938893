import { Col, PageWrapper, Row } from '@qonsoll/react-design'
import { HeaderBreadcrumbs, Spinner } from 'components'
import { useHistory, useParams } from 'react-router-dom'

import { EmailAdvancedForm } from 'domains/Email/components'
import { useGetEmailInitialValues } from 'domains/Email/hooks'
import { useTranslations } from 'contexts/Translation'

const EmailEdit = () => {
  /* Getting translations instance */
  const { t } = useTranslations()

  /* Getting history instance */
  const history = useHistory()

  /* Getting params from router */
  const params = useParams()

  /* Getting id from params */
  const { emailId } = params

  /* Fetching email initial values */
  const [initialValues, loading] = useGetEmailInitialValues(emailId)

  /* Configuring page header */
  const headingProps = {
    title: t('Email edit'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  /* Function to handle back click */
  const handleBack = () => history.goBack()

  return (
    <PageWrapper
      onBack={handleBack}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}>
      <Row noGutters>
        {loading ? (
          <Col>
            <Spinner height="70vh" text={t('Email loading')} />
          </Col>
        ) : (
          <Col cw={[12, 12, 10, 8, 7]}>
            <EmailAdvancedForm initialData={initialValues} />
          </Col>
        )}
      </Row>
    </PageWrapper>
  )
}

export default EmailEdit
