import { Col, Row } from '@qonsoll/react-design'
import {
  HeaderBreadcrumbs,
  PageActions,
  PageWrapperActionsUnderTitle,
  Spinner
} from 'components'
import { SubadminFilter, SubadminTable } from 'domains/Subadmin/components'
import { useFilter, useGetCustomDataSource } from 'hooks'
import { useGetSubadmins, useSubadminBaseActions } from 'domains/Subadmin/hooks'
import { useMemo, useState } from 'react'

import PATHS from 'pages/paths'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const SubadminsAllPage = (props) => {
  const { withHeader = true } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  const [collection, setCollection] = useState()

  /* Getting filter params */
  const filterParams = useFilter('subadmin')

  /* Destructuring filter params */
  const { filterVisibility, filterData, setFilterVisibility } = filterParams

  /* Building filter query */
  const ref = useMemo(() => filterData, [filterData])

  /* Fetching subadmins */
  const [subadmins, loading] = useGetSubadmins(ref)

  const [customDataSource, customDataSourceLoading] =
    useGetCustomDataSource(subadmins)

  /* Getting base actions */
  const listActions = useSubadminBaseActions()

  /* Configuring page header */
  const headingProps = {
    title: t('Subadmins'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  /* Configuring page actions */
  const actions = {
    filter: true,
    create: Boolean(
      PATHS.AUTHENTICATED.SUBADMIN_CREATE || PATHS.AUTHENTICATED.SUBADMIN_WIZARD
    ),
    search: true
  }

  /* Extracting base actions */
  const { onBack, onCreate } = listActions

  return (
    <PageWrapperActionsUnderTitle
      action={
        withHeader ? (
          <PageActions
            {...filterParams}
            onCreate={onCreate}
            actions={actions}
            circle
            filterMenu={<SubadminFilter {...filterParams} />}
            filterVisibility={filterVisibility}
            setFilterVisibility={setFilterVisibility}
            // search props
            collection={customDataSource}
            setCollection={setCollection}
            searchField={['fullName', 'email', ['newspaper', 'name']]}
            searchPlaceholder={t('Search by Name, email, Newspaper name')}
          />
        ) : null
      }
      onBack={withHeader ? onBack : null}
      breadcrumbs={withHeader ? <HeaderBreadcrumbs /> : null}
      headingProps={withHeader ? headingProps : null}>
      <Row noGutters>
        {loading || customDataSourceLoading ? (
          <Col>
            <Spinner height="70vh" text={t('Subadmin loading')} />
          </Col>
        ) : (
          <Col cw={12}>
            <SubadminTable dataSource={collection ?? customDataSource} />
          </Col>
        )}
      </Row>
    </PageWrapperActionsUnderTitle>
  )
}

SubadminsAllPage.propTypes = {
  isListWithCreate: PropTypes.bool,
  withHeader: PropTypes.bool
}

export default SubadminsAllPage
