import { Button, Title } from '@qonsoll/react-design'
import {
  useActionsSubadminAdvancedForm,
  useInitializeSubadminAdvancedForm
} from './hooks'

import { Form } from 'antd'
import { NewspaperSelect } from 'domains/Newspaper/components'
import PropTypes from 'prop-types'
import { SubadminSimpleForm } from 'domains/Subadmin/components'
import { useCheckChangeFormValue } from 'hooks'
import { useTranslations } from 'contexts/Translation'

const SubadminAdvancedForm = (props) => {
  const { isSuperAdmin, initialData, group, showTitle, isEdit } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting form instance from hook */
  const { form } = useInitializeSubadminAdvancedForm(initialData)

  /* Getting loading state and actions from hook */
  const { loading, onFinish, onReset, handleKeyPress } =
    useActionsSubadminAdvancedForm({
      isSuperAdmin,
      initialData,
      form,
      isEdit
    })

  const { triggerValueChange, isEqualForms } =
    useCheckChangeFormValue(initialData)
  const entityFieldNameComputed = isSuperAdmin ? 'superadmin' : 'subadmin'
  const titleComputed = isSuperAdmin ? t('Super admin') : t('Subadmin')
  return group ? (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {titleComputed}
        </Title>
      )}
      <SubadminSimpleForm
        isSuperAdmin={isSuperAdmin}
        showTitle={false}
        group={[...group, entityFieldNameComputed]}
        isEdit={isEdit}
      />
      {!isSuperAdmin && (
        <Form.Item
          name={[group, 'newspaper']}
          rules={[{ required: true, message: t('Please choose a newspaper') }]}>
          <NewspaperSelect />
        </Form.Item>
      )}
    </>
  ) : (
    <Form
      onKeyPress={handleKeyPress}
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
      onValuesChange={triggerValueChange}
      form={form}>
      {showTitle && (
        <Title level={4} mb={2}>
          {titleComputed}
        </Title>
      )}
      <SubadminSimpleForm
        form={form}
        group={[entityFieldNameComputed]}
        initialValues={initialData}
        isSuperAdmin={isSuperAdmin}
        isEdit={isEdit}
      />
      {!isSuperAdmin && (
        <Form.Item
          name={['newspaper']}
          rules={[{ required: true, message: t('Please choose a newspaper') }]}>
          <NewspaperSelect />
        </Form.Item>
      )}
      <Button mr={2} htmlType="button" onClick={onReset}>
        {t('Cancel')}
      </Button>
      <Button
        type="primary"
        disabled={isEqualForms}
        loading={loading}
        onClick={() => form.submit()}>
        {t('Add')}
      </Button>
    </Form>
  )
}

SubadminAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  group: PropTypes.array,
  showTitle: PropTypes.bool,
  isSuperAdmin: PropTypes.bool,
  isEdit: PropTypes.bool
}

export default SubadminAdvancedForm
