import { COLLECTIONS, ENDPOINTS, STATUSES } from '__constants__'
import { generatePath, useHistory } from 'react-router-dom'

import PATHS from 'pages/paths'
import { message } from 'antd'
import { updateDocument } from 'services/api/firebase'
import { useEvent } from 'hooks'
import { useMemo, useState } from 'react'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

/* Path's */
const createPath =
  PATHS.AUTHENTICATED.USER_CREATE || PATHS.AUTHENTICATED.USER_WIZARD
const editPath = PATHS.AUTHENTICATED.USER_EDIT
const showPath = PATHS.AUTHENTICATED.USER_SHOW

const FIREBASE_FUNCTIONS_API_URL =
  process.env.REACT_APP_FIREBASE_FUNCTIONS_API_URL

const useUserBaseActions = ({ userId, isBlocked, actions } = {}) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()

  // Getting user data
  const user = useUser()

  const [deleteLoading, setDeleteLoading] = useState(false)

  /* Function to handle back click */
  const handleBack = useEvent(() => history.goBack())

  /* Handling opening of the create page */
  const handleCreate = useEvent(() => {
    history.push(createPath)
  })

  /* Handling opening of the show page */
  const handleOpen = useEvent(() => {
    if (userId) {
      const pathParams = { userId }
      const path = generatePath(showPath, pathParams)
      history.push(path)
    }
  })
  const handleEdit = useEvent(() => {
    const pathParams = { userId }
    const path = generatePath(editPath, pathParams)
    history.push(path)
  })

  const handleDelete = useEvent(async () => {
    try {
      setDeleteLoading(true)
      const response = await fetch(
        FIREBASE_FUNCTIONS_API_URL + ENDPOINTS.DELETE_USER,
        {
          method: 'POST',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${user?.token}`
          },
          body: JSON.stringify({
            _id: userId
          })
        }
      )

      if (Number(response.status) === 200)
        return message.success(t('User successfully deleted'))

      throw new Error(t('Error deleting user'))
    } catch (error) {
      message.error(error.message)
    } finally {
      setDeleteLoading(false)
    }
  })

  const handleBlockedOrUnblockedUser = useEvent(async () => {
    try {
      await updateDocument(COLLECTIONS.USERS, userId, {
        isBlocked: !isBlocked,
        status: isBlocked
          ? STATUSES.USER_STATUSES.ACTIVE
          : STATUSES.USER_STATUSES.BLOCKED
      })
      message.success(
        t(`User successfully ${isBlocked ? 'unblocked' : 'blocked'}`)
      )
    } catch (error) {
      message.error(error.message)
    }
  })

  return useMemo(
    () => ({
      onBack: handleBack,
      onCreate: handleCreate,
      onOpen: handleOpen,
      onEdit: handleEdit,
      onDelete: handleDelete,
      onBlock: handleBlockedOrUnblockedUser,
      ...(actions || {}),
      createPath,
      editPath,
      showPath,
      deleteLoading
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      actions?.onBack,
      actions?.onCreate,
      actions?.onDelete,
      actions?.onEdit,
      actions?.onOpen,
      handleBack,
      handleCreate,
      handleDelete,
      handleEdit,
      handleOpen,
      deleteLoading
    ]
  )
}

export default useUserBaseActions
