import { Form } from 'antd'
import { useEffect } from 'react'

const useInitializeNotificationAdvancedForm = (initialData) => {
  /* Getting form instance */
  const [form] = Form.useForm()

  /* Setting initial data */
  useEffect(() => {
    if (initialData) {
      /* Getting initial values */
      const {
        notification,
        isCurrentUserLocalAdmin,
        currentUserNewspaperId,
        currentUserReceiversType
      } = initialData

      const textEnComputed = notification?.text?.EN ?? null
      const textNoComputed = notification?.text?.NO ?? null
      let receiversTypeComputed = notification?.receiversType ?? null
      let newspaperIdComputed = notification?.newspaperId ?? null

      if (isCurrentUserLocalAdmin) {
        newspaperIdComputed = currentUserNewspaperId
        receiversTypeComputed = currentUserReceiversType
      }

      /* Formatting date fields */
      const formValues = {
        notification: {
          textEn: textEnComputed,
          textNo: textNoComputed,
          receiversType: receiversTypeComputed
        },
        receiversNewspaper: { _id: newspaperIdComputed }
      }

      /* Setting initial values to form instance */
      form.setFieldsValue(formValues)
    }
  }, [initialData, form])

  return { form }
}

export default useInitializeNotificationAdvancedForm
