import 'moment/locale/nb'

import { DEFAULT_LANGUAGE } from 'domains/Translation/__constants__'
import { GDPRPopup } from './domains/App/components'
import { LS } from '__constants__'
import Navigator from './pages/Navigator'
import moment from 'moment'

//It's placed here because when application is initialized,
// need to check last selected language for avoiding mistakes of showing data,
// which are related to moment library (for example date of creation)

// eslint-disable-next-line prettier/prettier
(() => {
  const LCLanguage = localStorage.getItem(LS.LANGUAGE)
  const language = LCLanguage === DEFAULT_LANGUAGE ? 'nb' : LCLanguage
  moment.locale(language)
})()

/**
 * It returns a React component that renders a TranslationsProvider and a Navigator.
 * @returns The <TranslationsProvider> component.
 */
function App() {
  return (
    <>
      <Navigator />
      <GDPRPopup />
    </>
  )
}

export default App
