import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  BoldOutlined,
  ItalicOutlined,
  RedoOutlined,
  StrikethroughOutlined,
  UnderlineOutlined,
  UndoOutlined,
  LinkOutlined,
  DisconnectOutlined
} from '@ant-design/icons'

const INLINE_OPTIONS_CONFIG = {
  bold: <BoldOutlined />,
  italic: <ItalicOutlined />,
  underline: <UnderlineOutlined />,
  strikethrough: <StrikethroughOutlined />
}
const TEXT_ALIGN_OPTIONS_CONFIG = {
  left: <AlignLeftOutlined />,
  center: <AlignCenterOutlined />,
  right: <AlignRightOutlined />
}
const HISTORY_OPTIONS_CONFIG = {
  undo: <UndoOutlined />,
  redo: <RedoOutlined />
}

const LINK_OPTIONS_CONFIG = {
  link: <LinkOutlined />,
  unlink: <DisconnectOutlined />
}

const LINK_CONFIG_NAMES = {
  LINK: 'link',
  UNLINK: 'unlink'
}

export {
  INLINE_OPTIONS_CONFIG,
  TEXT_ALIGN_OPTIONS_CONFIG,
  HISTORY_OPTIONS_CONFIG,
  LINK_OPTIONS_CONFIG,
  LINK_CONFIG_NAMES
}
