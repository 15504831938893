import PropTypes from 'prop-types'
import { StyledTable } from 'components'
import { useGetNewsTipTableColumns } from 'domains/NewsTip/hooks'
import { useEffect, useState } from 'react'

const NewsTipTable = (props) => {
  const { dataSource, currentNewspaperTipsOnly } = props

  /* Pagination params for table */
  const [tableParams, setTableParams] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showQuickJumper: true,
    showSizeChanger: true
  })

  /* Getting table columns */
  const columns = useGetNewsTipTableColumns(currentNewspaperTipsOnly)

  /* Handle changes for table */
  const handleTableChange = (pagination) => setTableParams(pagination)

  /* Update total value for tableParams */
  useEffect(() => {
    dataSource?.length &&
      setTableParams((prev) => ({
        ...prev,
        total: dataSource?.length
      }))
  }, [dataSource])

  return (
    <StyledTable
      {...props}
      rowKey="_id"
      columns={columns}
      pagination={tableParams}
      onChange={handleTableChange}
    />
  )
}

NewsTipTable.propTypes = {
  currentNewspaperTipsOnly: PropTypes.bool,
  dataSource: PropTypes.array
}

export default NewsTipTable
