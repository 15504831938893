import { useCallback, useEffect, useState } from 'react'

import { COLLECTIONS } from '__constants__'
import { createFullUserName } from 'helpers'
import { getDocument } from 'services/api/firebase'

const useGetCustomDataSource = (dataSource, collectionData) => {
  const [customDataSource, setCustomDataSource] = useState()
  const [loading, setLoading] = useState(false)

  const getQueryData = (collectionName, documentId) => {
    return { collectionName, documentId }
  }

  const getQueryConfig = useCallback((data) => {
    return {
      newspaper:
        (data?.newspaperId || data?.newspaper) &&
        getQueryData(
          COLLECTIONS.NEWSPAPERS,
          data?.newspaperId || data?.newspaper
        ),

      store: data?.storeId && getQueryData(COLLECTIONS.STORES, data?.storeId),

      rewardType:
        data?.rewardTypeId &&
        getQueryData(COLLECTIONS.REWARD_TYPES, data?.rewardTypeId),

      status:
        data?.statusId && getQueryData(collectionData?.status, data?.statusId),

      user:
        collectionData?.fetchUserData &&
        data?._createdBy &&
        getQueryData(COLLECTIONS.USERS, data?._createdBy)
    }
  }, [])

  const getCustomDataSource = useCallback(async () => {
    try {
      setLoading(true)
      const customDataSource = dataSource?.map(async (data, index) => {
        const additionalData = {}
        const queryConfig = getQueryConfig(data)

        if (data?.firstName || data?.lastName) {
          additionalData.fullName = createFullUserName(
            data?.firstName,
            data?.lastName
          )
        }

        try {
          const additionalDataPromise = Object.entries(queryConfig)?.map(
            async (queryValues) => {
              const [field, query] = queryValues
              if (field && query)
                additionalData[field] = await getDocument(
                  query?.collectionName,
                  query?.documentId
                )
            }
          )
          await Promise.all(additionalDataPromise)

          if (additionalData?.user?.firstName || additionalData?.user?.lastName)
            additionalData.user.fullName = createFullUserName(
              additionalData?.user?.firstName,
              additionalData?.user?.lastName
            )
        } catch (e) {
          console.error(e)
        }

        return { ...data, ...additionalData }
      })

      const customDataSourcePromise = await Promise.all(customDataSource)
      setCustomDataSource(customDataSourcePromise)
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }, [dataSource, getQueryConfig])

  useEffect(() => {
    dataSource && getCustomDataSource()
  }, [dataSource, getCustomDataSource])

  return [customDataSource, loading]
}
export default useGetCustomDataSource
