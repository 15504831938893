import PATHS from 'pages/paths'
const { AUTHENTICATED } = PATHS

const PATHNAME_TO_SELECTED_MENU_ITEM = {
  [AUTHENTICATED.DASHBOARD]: AUTHENTICATED.DASHBOARD,

  [AUTHENTICATED.USERS_ALL]: AUTHENTICATED.USERS_ALL,
  [AUTHENTICATED.USER_CREATE]: AUTHENTICATED.USERS_ALL,
  [AUTHENTICATED.USER_SHOW]: AUTHENTICATED.USERS_ALL,
  [AUTHENTICATED.USER_EDIT]: AUTHENTICATED.USERS_ALL,

  [AUTHENTICATED.NEWSPAPERS_ALL]: AUTHENTICATED.NEWSPAPERS_ALL,
  [AUTHENTICATED.NEWSPAPER_CREATE]: AUTHENTICATED.NEWSPAPERS_ALL,
  [AUTHENTICATED.NEWSPAPER_SHOW]: AUTHENTICATED.NEWSPAPERS_ALL,
  [AUTHENTICATED.NEWSPAPER_EDIT]: AUTHENTICATED.NEWSPAPERS_ALL,

  [AUTHENTICATED.WEB_TIPPS_CONFIGURATIONS_ALL]:
    AUTHENTICATED.WEB_TIPPS_CONFIGURATIONS_ALL,
  [AUTHENTICATED.WEB_TIPPS_CONFIGURATION_EDIT]:
    AUTHENTICATED.WEB_TIPPS_CONFIGURATION_EDIT,

  [AUTHENTICATED.NEWS_TIP_ALL]: AUTHENTICATED.NEWS_TIP_ALL,
  [AUTHENTICATED.NEWS_TIP_EDIT]: AUTHENTICATED.NEWS_TIP_ALL,
  [AUTHENTICATED.NEWS_TIP_SHOW]: AUTHENTICATED.NEWS_TIP_ALL,

  [AUTHENTICATED.STORES_ALL]: AUTHENTICATED.STORES_ALL,
  [AUTHENTICATED.STORE_CREATE]: AUTHENTICATED.STORES_ALL,
  [AUTHENTICATED.STORE_EDIT]: AUTHENTICATED.STORES_ALL,
  [AUTHENTICATED.STORE_SHOW]: AUTHENTICATED.STORES_ALL,

  [AUTHENTICATED.REWARDS_ALL]: AUTHENTICATED.REWARDS_ALL,
  [AUTHENTICATED.REWARD_CREATE]: AUTHENTICATED.REWARDS_ALL,
  [AUTHENTICATED.REWARD_EDIT]: AUTHENTICATED.REWARDS_ALL,
  [AUTHENTICATED.REWARD_SHOW]: AUTHENTICATED.REWARDS_ALL,

  [AUTHENTICATED.REWARD_OVERVIEW_ALL]: AUTHENTICATED.REWARD_OVERVIEW_ALL,

  [AUTHENTICATED.NOTIFICATIONS_ALL]: AUTHENTICATED.NOTIFICATIONS_ALL,
  [AUTHENTICATED.NOTIFICATION_CREATE]: AUTHENTICATED.NOTIFICATIONS_ALL,
  [AUTHENTICATED.NOTIFICATION_EDIT]: AUTHENTICATED.NOTIFICATIONS_ALL,
  [AUTHENTICATED.NOTIFICATION_SHOW]: AUTHENTICATED.NOTIFICATIONS_ALL,

  [AUTHENTICATED.CONTENT]: AUTHENTICATED.CONTENT,

  [AUTHENTICATED.REJECT_REASONS_ALL]: AUTHENTICATED.REJECT_REASONS_ALL,
  [AUTHENTICATED.REJECT_REASON_CREATE]: AUTHENTICATED.REJECT_REASONS_ALL,
  [AUTHENTICATED.REJECT_REASON_EDIT]: AUTHENTICATED.REJECT_REASONS_ALL,
  [AUTHENTICATED.REJECT_REASON_SHOW]: AUTHENTICATED.REJECT_REASONS_ALL,

  [AUTHENTICATED.EDITORS_ALL]: AUTHENTICATED.EDITORS_ALL,
  [AUTHENTICATED.EDITOR_CREATE]: AUTHENTICATED.EDITORS_ALL,
  [AUTHENTICATED.EDITOR_EDIT]: AUTHENTICATED.EDITORS_ALL,
  [AUTHENTICATED.EDITOR_SHOW]: AUTHENTICATED.EDITORS_ALL,

  [AUTHENTICATED.EDITORS_ALL]: AUTHENTICATED.EDITORS_ALL,
  [AUTHENTICATED.EDITOR_CREATE]: AUTHENTICATED.EDITORS_ALL,
  [AUTHENTICATED.EDITOR_EDIT]: AUTHENTICATED.EDITORS_ALL,
  [AUTHENTICATED.EDITOR_SHOW]: AUTHENTICATED.EDITORS_ALL,

  [AUTHENTICATED.EMAILS_ALL]: AUTHENTICATED.EMAILS_ALL,
  [AUTHENTICATED.EMAIL_CREATE]: AUTHENTICATED.EMAILS_ALL,
  [AUTHENTICATED.EMAIL_EDIT]: AUTHENTICATED.EMAILS_ALL,

  [AUTHENTICATED.SUBADMINS_ALL]: AUTHENTICATED.SUBADMINS_ALL,
  [AUTHENTICATED.SUBADMIN_CREATE]: AUTHENTICATED.SUBADMINS_ALL,
  [AUTHENTICATED.SUBADMIN_EDIT]: AUTHENTICATED.SUBADMINS_ALL,
  [AUTHENTICATED.SUBADMIN_SHOW]: AUTHENTICATED.SUBADMINS_ALL,

  [AUTHENTICATED.ADMINS_ALL]: AUTHENTICATED.ADMINS_ALL,
  [AUTHENTICATED.ADMIN_CREATE]: AUTHENTICATED.ADMINS_ALL,
  [AUTHENTICATED.ADMIN_EDIT]: AUTHENTICATED.ADMINS_ALL,
  [AUTHENTICATED.ADMIN_SHOW]: AUTHENTICATED.ADMINS_ALL,

  [AUTHENTICATED.TRANSLATIONS]: AUTHENTICATED.TRANSLATIONS
}

export { PATHNAME_TO_SELECTED_MENU_ITEM }
