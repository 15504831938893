import { Form } from 'antd'
import { useEffect } from 'react'

const useInitializeUserAdvancedForm = (initialData) => {
  /* Getting form instance */
  const [form] = Form.useForm()

  /* Setting initial data */
  useEffect(() => {
    if (initialData) {
      /* Getting initial values */
      const { user, newspaperAssociated, newsTips } = initialData

      /* Formatting date fields */
      const formValues = {
        user: {
          firstName: user?.['firstName'] ?? null,
          lastName: user?.['lastName'] ?? null,
          email: user?.['email'] ?? null,
          phoneNumber: user?.['phoneNumber'] ?? null,
          avatarUrl: user?.['avatarUrl'] ?? null
        },
        newspaperAssociated: newspaperAssociated ?? null,
        newsTips: newsTips?.map((e) => e?._id).filter((e) => e) ?? []
      }

      /* Setting initial values to form instance */
      form.setFieldsValue(formValues)
    }
  }, [initialData, form])

  return { form }
}

export default useInitializeUserAdvancedForm
