import { Progress } from 'antd'
import styled from 'styled-components'
const ProgressStyled = styled(Progress)`
  .ant-progress-outer {
    margin: 0;
    padding: 0;
  }
  .ant-progress-text {
    display: none;
  }
`

export default ProgressStyled
