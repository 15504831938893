import { COLLECTIONS, STATUSES } from '__constants__'

import { message } from 'antd'
import { serverTimestamp } from 'firebase/firestore'
import { updateDocument } from 'services/api/firebase'
import { useEvent } from 'hooks'
import { useHistory } from 'react-router-dom'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

const useRewardOverviewBaseActions = ({ rewardOverviewId, actions } = {}) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()

  /* Function to handle back click */
  const handleBack = useEvent(() => history.goBack())

  /* Handling sending */
  const handleSend = useEvent(async () => {
    try {
      await updateDocument(COLLECTIONS.REWARD_OVERVIEW, rewardOverviewId, {
        // TODO here should be status update
        _sentAt: serverTimestamp(),
        isSent: true,
        status: STATUSES.REWARD_OVERVIEW_STATUSES.SENT
      })
      message.success(t('Successfully sent'))
    } catch (error) {
      message.error(t('Error during sending'))
    }
  })

  return useMemo(
    () => ({
      onSent: handleSend,
      ...(actions || {})
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [actions?.onSent, handleSend, handleBack]
  )
}

export default useRewardOverviewBaseActions
