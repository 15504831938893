import { Checkbox } from 'antd'
import PropTypes from 'prop-types'
import { STATUSES } from '__constants__'
import { Text } from '@qonsoll/react-design'
import { useSubfilterActions } from 'hooks'
import { useTranslations } from 'contexts/Translation'

const UserStatusFilter = ({ filterData, setFilterData }) => {
  const { t } = useTranslations()

  const { onToggleDetails, onChange, checkIsEnabled } = useSubfilterActions({
    filterData,
    setFilterData,
    fieldName: 'isBlocked',
    operand: '==',
    type: 'status'
  })

  const userStatuses = [
    { value: true, name: STATUSES.USER_STATUSES.BLOCKED },
    { value: false, name: STATUSES.USER_STATUSES.ACTIVE }
  ]

  return (
    <details
      open={localStorage.getItem(`status-details-visibility`) === 'opened'}>
      <summary onClick={onToggleDetails}>{t('User status')}</summary>
      {userStatuses?.map((userStatus, index) => (
        <Checkbox
          key={`status-${index}`}
          checked={checkIsEnabled(userStatus)}
          onChange={() => onChange(userStatus)}>
          <Text wordBreak="break-all">
            {userStatus?.name || t('Unnamed filter')}
          </Text>
        </Checkbox>
      ))}
    </details>
  )
}

UserStatusFilter.propTypes = {
  filterData: PropTypes.object,
  setFilterData: PropTypes.func
}

export default UserStatusFilter
