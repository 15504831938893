import { useEffect } from 'react'
import { Form } from 'antd'

const useInitializeSubadminAdvancedForm = (initialData) => {
  /* Getting form instance */
  const [form] = Form.useForm()

  /* Setting initial data */
  useEffect(() => {
    if (initialData) {
      /* Getting initial values */
      const { subadmin, newspaper, status } = initialData

      /* Formatting date fields */
      const formValues = {
        subadmin: {
          sno: subadmin?.['sno'] ?? null,
          name: subadmin?.['name'] ?? null,
          email: subadmin?.['email'] ?? null
        },
        newspaper: newspaper ?? null,
        status: status ?? null
      }

      /* Setting initial values to form instance */
      form.setFieldsValue(formValues)
    }
  }, [initialData, form])

  return { form }
}

export default useInitializeSubadminAdvancedForm
