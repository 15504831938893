import { Form } from 'antd'
import { Button } from '@qonsoll/react-design'
import draftToHtml from 'draftjs-to-html'
import { ContentState, convertToRaw, EditorState } from 'draft-js'
import { MAX_LENGTH_DESCRIPTION } from '__constants__'
import { ContentTextEditor } from 'domains/Content/components'
import { useTranslations } from 'contexts'
import htmlToDraft from 'html-to-draftjs'
import { useMemo } from 'react'
import PropTypes from 'prop-types'

const PublicWizardConfiguration = (props) => {
  const {
    initialValues,
    onSubmit,
    onCancel,
    form,
    setPublicWizardConfiguration
  } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting new form instance from Form.useForm hook */
  const [baseForm] = Form.useForm()

  /* Getting used form from props or using base form */
  const usedForm = form || baseForm

  /* Resetting form on cancel */
  const handleCancel = () => {
    usedForm?.resetFields()
    onCancel?.()
  }
  const editorInitialState = useMemo(() => {
    let content = htmlToDraft(
      initialValues?.publicWizardConfiguration?.publicWizardText
    )
    content = ContentState.createFromBlockArray(
      content.contentBlocks,
      content.entityMap
    )

    return EditorState.createWithContent(content)
  }, [initialValues?.publicWizardConfiguration?.publicWizardText])

  const updateFormData = () => {
    setPublicWizardConfiguration?.(usedForm?.getFieldsValue())
  }

  return (
    <Form
      onFieldsChange={updateFormData}
      form={usedForm}
      initialValues={initialValues}
      onFinish={onSubmit}
      layout="vertical"
      autoComplete="off">
      <Form.Item
        label={t('Public wizard text')}
        name={['publicWizardConfiguration', 'publicWizardText']}
        rules={[
          {
            required: true,
            message: t('Please enter public wizard text!')
          },
          () => ({
            validator(_, value) {
              if (
                draftToHtml(convertToRaw(value?.getCurrentContent()))?.length <
                MAX_LENGTH_DESCRIPTION
              ) {
                return Promise.resolve()
              } else {
                return Promise.reject(
                  new Error(t('Public wizard text is too long'))
                )
              }
            }
          })
        ]}>
        <ContentTextEditor initState={editorInitialState} />
      </Form.Item>
      {!form && (
        <>
          <Button mr={2} htmlType="button" onClick={handleCancel}>
            {t('Cancel')}
          </Button>
          <Button type="primary" onClick={() => usedForm.submit()}>
            {t('Save')}
          </Button>
        </>
      )}
    </Form>
  )
}

PublicWizardConfiguration.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  form: PropTypes.any,
  group: PropTypes.array,
  setPublicWizardConfiguration: PropTypes.func
}

export default PublicWizardConfiguration
