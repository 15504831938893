import { collection, query, where, orderBy } from 'firebase/firestore'

import { COLLECTIONS } from '__constants__'
import firestore from 'services/firebase/firestore'
import { useCollectionData } from 'react-firebase-hooks/firestore'

const useGetNewsTipComments = (newsTipId) => {
  const queryItems =
    newsTipId &&
    query(
      collection(firestore, COLLECTIONS.TIPS_COMMENTS),
      where('tipsId', '==', newsTipId),
      orderBy('_createdAt', 'desc')
    )

  const [newsTipComments, loading, error] = useCollectionData(queryItems)

  return [newsTipComments && [...newsTipComments], loading, error]
}

export default useGetNewsTipComments
