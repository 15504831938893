import { useHandleError, useQueryParams } from 'hooks'

import { LoginForm } from 'domains/Session/components'
import PATHS from 'pages/paths'
import { PageWrapper } from '@qonsoll/react-design'
import { useHistory, useLocation } from 'react-router-dom'
import { useSessionActions } from 'domains/Session/hooks'
import { useTranslations } from 'contexts/Translation'
import { useEffect, useState } from 'react'
import { urlParamsStringIntoParamsObject } from 'helpers'

const LoginWithEmail = () => {
  const { t } = useTranslations()
  // [ADDITIONAL_HOOKS]
  const history = useHistory()
  const location = useLocation()
  const onError = useHandleError()
  const { login } = useSessionActions()
  const { getLastSessionProvider } = useSessionActions()

  // [CLEAN_FUNCTIONS]
  const redirectToForgotPassword = () =>
    history.push(PATHS.UNAUTHENTICATED.FORGOT_PASSWORD)
  const isEmailExists = useQueryParams('email')

  const sessionProvider = getLastSessionProvider()

  // [COMPONENT STATE HOOKS]
  const [authCreds, setAuthCreds] = useState(null)

  // [COMPUTED PROPERTIES]
  const urlParamsObject = urlParamsStringIntoParamsObject(location.search)

  // [USE_EFFECTS]
  useEffect(() => {
    if (!authCreds && urlParamsObject?.email && urlParamsObject?.password) {
      setAuthCreds({
        email: decodeURIComponent(urlParamsObject?.email),
        password: decodeURIComponent(urlParamsObject?.password)
      })
    }
  }, [authCreds, urlParamsObject, history])

  return (
    <PageWrapper
      alignMiddle
      height="100%"
      headingProps={{
        title: t('Login with email'),
        subTitle: t('Please, enter your email and password'),
        marginBottom: 40
      }}
      contentWidth={['100%', '100%', 400]}
      firstLevelHidden={isEmailExists && sessionProvider === 'email'}>
      <LoginForm
        initialValues={authCreds}
        sumbitImmediately
        login={login}
        onError={onError}
        onForgotPasswordClick={redirectToForgotPassword}
      />
    </PageWrapper>
  )
}

export default LoginWithEmail
