import { Modal } from 'antd'
import styled from 'styled-components'

const ModalStyled = styled(Modal)`
  width: ${({ breakpoints }) => {
    const { xs, sm, lg } = breakpoints

    return (lg && '900px') || (sm && '90%') || (xs && '100%')
  }} !important;
`

export { ModalStyled }
