import { useEffect, useMemo, useState } from 'react'

import { KEYS } from '__constants__'

const useSearchFilter = (props) => {
  const { collection, setCollection, searchField } = props
  const [searchValue, setSearchValue] = useState()

  const multipleSearch = useMemo(
    () => (value) =>
      collection
        ?.map((document) => {
          const findDocument = searchField?.find((item) => {
            const [field, deepField] = item

            const computedDocumentField =
              item?.length === 2
                ? document?.[field]?.[deepField]
                : document?.[item]

            return (
              computedDocumentField &&
              computedDocumentField?.toLowerCase()?.indexOf(value) !== -1
            )
          })
          return findDocument ? document : null
        })
        ?.filter(Boolean),
    [collection, searchField]
  )

  const singleSearch = useMemo(
    () => (value) =>
      collection?.filter(
        (document) =>
          document?.[searchField]?.toLowerCase()?.indexOf(value) !== -1,
        []
      ),
    [collection, searchField]
  )

  const updateCollection = (value) => {
    if (!value) {
      return setCollection(collection)
    } else {
      const filteredCollection =
        Array.isArray(searchField) && searchField?.length > 0
          ? multipleSearch(value)
          : singleSearch(value)

      setCollection(filteredCollection)
    }
  }

  const handleSearch = useMemo(
    () => (e) => {
      const value = e.target.value.toLowerCase()

      if (!value || e.key === KEYS.ENTER) {
        setSearchValue(value)
        updateCollection(value)
      }
    },
    [searchField, setCollection, multipleSearch]
  )

  useEffect(() => {
    collection && updateCollection(searchValue)
  }, [collection])

  return [handleSearch]
}

export default useSearchFilter
