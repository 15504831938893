import * as yup from 'yup'

import { attr, hasOne, model } from 'services/model'

const validationSchema = yup.object().shape({
  sno: yup
    .number()
    .typeError('Field sno has wrong type')
    .default(null)
    .nullable(),
  name: yup
    .string()
    .typeError('Field name has wrong type')
    .default(null)
    .nullable(),
  store: yup
    .string()
    .typeError('Field store has wrong type')
    .default(null)
    .nullable(),
  newspaper: yup
    .string()
    .typeError('Field newspaper has wrong type')
    .default(null)
    .nullable(),
  redeemed: yup
    .boolean()
    .typeError('Field redeemed has wrong type')
    .default(null)
    .nullable(),
  rewardType: yup
    .string()
    .typeError('Field rewardType has wrong type')
    .default(null)
    .nullable(),
  status: yup
    .string()
    .typeError('Field status has wrong type')
    .default(null)
    .nullable(),
  reward: yup
    .string()
    .typeError('Field reward has wrong type')
    .default(null)
    .nullable(),
  points: yup
    .number()
    .typeError('Field points has wrong type')
    .default(null)
    .nullable(),
  emoji: yup
    .string()
    .typeError('Field emoji has wrong type')
    .default(null)
    .required()
})

const Reward = model(
  'reward',
  {
    sno: attr('number'),
    name: attr('string'),
    store: attr('string'),
    newspaper: hasOne('newspaper'),
    redeemed: attr('boolean'),
    rewardType: hasOne('rewardType'),
    status: hasOne('rewardStatus'),
    reward: attr('string'),
    points: attr('number'),
    emoji: attr('string')
  },
  validationSchema
)

export default Reward
