import { useHistory, generatePath } from 'react-router-dom'
import PATHS from 'pages/paths'
import { COLLECTIONS, STATUSES } from '__constants__'
import { useTranslations } from 'contexts/Translation'
import { deleteDocument, updateDocument } from 'services/api/firebase'
import { message } from 'antd'
import { useEvent } from 'hooks'
import { useMemo } from 'react'

/* Path's */
const allPath = PATHS.AUTHENTICATED.REJECT_REASONS_ALL
const createPath = PATHS.AUTHENTICATED.REJECT_REASON_CREATE
const editPath = PATHS.AUTHENTICATED.REJECT_REASON_EDIT
const showPath = PATHS.AUTHENTICATED.REJECT_REASON_SHOW

const useRejectReasonBaseActions = ({
  rejectReasonId,
  actions,
  isBlocked
} = {}) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()

  /* Function to handle back click */
  const handleBack = useEvent(() => history.goBack())

  /* Handling opening of the create page */
  const handleCreate = useEvent(() => {
    history.push(createPath)
  })

  /* Handling opening of the show page */
  const handleOpen = useEvent(() => {
    const pathParams = { rejectReasonId }
    const path = generatePath(showPath, pathParams)
    history.push(path)
  })

  /* Handling opening of the edit page or change view to edit mode */
  const handleEdit = useEvent(() => {
    const pathParams = { rejectReasonId }
    const path = generatePath(editPath, pathParams)
    history.push(path)
  })

  const handleDelete = useEvent(async () => {
    try {
      await deleteDocument(COLLECTIONS.REJECT_REASONS, rejectReasonId)
      message.success(t('Reject reason successfully deleted'))
      history.push(allPath)
    } catch (error) {
      message.error(error.message)
    }
  })

  const handleBlock = useEvent(async () => {
    const statusActive = STATUSES.REJECT_REASON_STATUSES.ACTIVE
    const statusBlocked = STATUSES.REJECT_REASON_STATUSES.BLOCKED

    const computedStatus = isBlocked ? statusActive : statusBlocked

    try {
      await updateDocument(COLLECTIONS.REJECT_REASONS, rejectReasonId, {
        status: computedStatus,
        isBlocked: !isBlocked
      })

      message.success(
        t(`Reject reason's status successfully updated to ${computedStatus}`)
      )
    } catch (error) {
      message.error(
        t(`Error during updating reject reason's status to ${computedStatus}`)
      )
    }
  })

  return useMemo(
    () => ({
      onBack: handleBack,
      onCreate: handleCreate,
      onOpen: handleOpen,
      onEdit: handleEdit,
      onDelete: handleDelete,
      onBlock: handleBlock,
      ...(actions || {}),
      createPath,
      editPath,
      showPath
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      actions?.onBack,
      actions?.onCreate,
      actions?.onDelete,
      actions?.onEdit,
      actions?.onOpen,
      actions?.onBlock,
      handleBack,
      handleCreate,
      handleDelete,
      handleEdit,
      handleOpen,
      handleBlock
    ]
  )
}

export default useRejectReasonBaseActions
