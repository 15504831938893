import { Col, Container, Row, Text } from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import { capitalize } from 'helpers'
import { useTranslations } from 'contexts/Translation'

const NewspaperNewspaperStatus = (props) => {
  const { newspaper } = props

  /* Getting translation instance */
  const { t } = useTranslations()
  const computedStatus = newspaper?.isBlocked
    ? t('Blocked')
    : newspaper?.status && t(capitalize(newspaper?.status))

  return (
    <Container>
      <Row noGutters>
        <Col>
          {newspaper?.status ? (
            <Text>{computedStatus}</Text>
          ) : (
            <Text type="secondary" variant="overline">
              {t('No newspaper statuses have been added yet')}
            </Text>
          )}
        </Col>
      </Row>
    </Container>
  )
}

NewspaperNewspaperStatus.propTypes = {
  newspaper: PropTypes.object.isRequired
}

export default NewspaperNewspaperStatus
