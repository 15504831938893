import { Form } from 'antd'
import { useEffect } from 'react'

const useInitializeEmailAdvancedForm = (initialData) => {
  /* Getting form instance */
  const [form] = Form.useForm()

  /* Setting initial data */
  useEffect(() => {
    if (initialData) {
      /* Getting initial values */
      const { email, newspaper } = initialData

      /* Formatting date fields */
      const formValues = {
        email: {
          email: email?.email ?? null
        },

        newspaper: newspaper ?? null
      }

      /* Setting initial values to form instance */
      form.setFieldsValue(formValues)
    }
  }, [initialData, form])

  return { form }
}

export default useInitializeEmailAdvancedForm
