import { Grid, Tooltip } from 'antd'
import {
  useGetNewsTipInitialValues,
  useNewsTipBaseActions
} from 'domains/NewsTip/hooks'

import PropTypes from 'prop-types'
import StyledLink from './NewsTipLink.styles'

const { useBreakpoint } = Grid

const NewsTipLink = ({ text, newsTipId }) => {
  const { onOpen } = useNewsTipBaseActions({ newsTipId })

  /* Get newspaper's document */
  const [newsTipInitialValue, loading] = useGetNewsTipInitialValues(
    !text && newsTipId
  )
  /* Get name from newspaper's document */
  const newspaperName = text || newsTipInitialValue?.newsTip?.name

  const breakpoints = useBreakpoint()
  return (
    !loading && (
      <Tooltip title={text}>
        <StyledLink breakpoints={breakpoints} ellipsis onClick={onOpen}>
          {newspaperName}
        </StyledLink>
      </Tooltip>
    )
  )
}
NewsTipLink.propTypes = {
  newsTipId: PropTypes.string,
  text: PropTypes.string
}

export default NewsTipLink
