import { KEYS } from '__constants__'

/* Handling text area enter key press and prevent form submit with ability to move to another line*/
const onTextAreaEnterPress = (e) => {
  if (e.key === KEYS.ENTER) {
    e.target.value += '\n'
    e.preventDefault()
  }
}

export default onTextAreaEnterPress
