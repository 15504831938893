import { USER_ROLES } from '__constants__'
import { useMemo } from 'react'
import { useUser } from 'domains/User/context'
import { useGetNewspaperWebConfig } from 'domains/Newspaper/hooks'

const useGetSubadminData = (fieldName) => {
  const { role, newspaperId } = useUser()

  const isLocalAdmin = role === USER_ROLES.LOCAL_ADMIN_ROLE

  const computedFieldName = fieldName || 'newspaperId'
  const computedQueryValue = newspaperId || null

  const subadminQuery = useMemo(
    () => (isLocalAdmin ? [[computedFieldName, '==', computedQueryValue]] : []),
    [isLocalAdmin, computedQueryValue, computedFieldName]
  )

  const [newspaperWebConfig] = useGetNewspaperWebConfig(newspaperId)

  return {
    subadminQuery,
    isSubadmin: isLocalAdmin,
    newspaperId,
    newspaperWebConfig
  }
}

export default useGetSubadminData
