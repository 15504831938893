import { COLLECTIONS } from '__constants__'
import { useDocument } from 'services/api/firebase'
import { useGetStoreNewspaper } from 'domains/Store/hooks'
import { useLoading } from 'hooks'
import { useMemo } from 'react'

const useGetStoreInitialValues = (id) => {
  /* Fetching data using useDocument hook */
  const [store, storeLoading] = useDocument({
    ref: id ? COLLECTIONS.STORES + '/' + id : null
  })

  /* Fetching newspaper data */
  const [newspaper, newspaperLoading] = useGetStoreNewspaper(store)

  /* Merge all fetched data */
  const initialValues = useMemo(
    () => ({
      store,
      newspaper
    }),
    [store, newspaper]
  )

  /* Merge all loading states */
  const loadings = useMemo(
    () => (id ? [!store, storeLoading, newspaperLoading] : []),
    [id, store, storeLoading, newspaperLoading]
  )

  /* Getting loading state using merged loadings */
  const loading = useLoading(loadings)

  return [initialValues, loading]
}

export default useGetStoreInitialValues
