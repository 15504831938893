import PropTypes from 'prop-types'
import { Row, Col, Text } from '@qonsoll/react-design'
import { Image, VideoPlayerWrapper } from './NewsTipMediaList.styles'
import { VideoPlayer } from 'components'
import { Card } from 'antd'
import { useTranslations } from 'contexts/Translation'

const NewsTipMediaList = (props) => {
  const { photoUrls, videoUrl } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  return (
    <Card>
      <Row noOuterGutters>
        <Col cw={12} mb={2}>
          <Text variant="overline" type="secondary">
            {t('Media')}
          </Text>
        </Col>
      </Row>
      <Row noOuterGutters>
        {photoUrls?.length || videoUrl ? (
          <>
            {photoUrls?.map((url) => (
              <Col cw={[12, 6, 6, 4, 3]} key={url}>
                <Image src={url} />
              </Col>
            ))}
            {videoUrl && (
              <Col cw={[12, 6, 6, 4, 3]}>
                <VideoPlayerWrapper>
                  <VideoPlayer src={videoUrl} />
                </VideoPlayerWrapper>
              </Col>
            )}
          </>
        ) : (
          <Col cw={12}>
            <Text type="secondary">{t('There is no media')}</Text>
          </Col>
        )}
      </Row>
    </Card>
  )
}

NewsTipMediaList.propTypes = {
  photoUrls: PropTypes.array,
  videoUrl: PropTypes.string
}
export default NewsTipMediaList
