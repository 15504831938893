import styled from 'styled-components'

const FooterStyled = styled.footer`
  background-color: ${({ backgroundColor }) =>
    backgroundColor || 'var(--ql-footer-bg)'};
  width: 100%;
  padding: 24px 0;
`
const StyledTextWrapper = styled.div`
  & > p {
    margin: 0;
  }
`
export { FooterStyled, StyledTextWrapper }
