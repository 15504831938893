import { Body, Footer, Header } from './components'

import { Box } from '@qonsoll/react-design'
import PropTypes from 'prop-types'

const WebTippsPagePreview = (props) => {
  const {
    pageConfiguration,
    initialValues,
    countTipps,
    customBodyContent,
    children,
    customTitle
  } = props
  const { newspaperId } = initialValues
  return (
    <Box display="flex" flex="1" flexDirection="column" position="relative">
      <Header
        pageConfiguration={pageConfiguration}
        initialValues={initialValues}
      />
      <Body
        customBodyContent={customBodyContent}
        pageConfiguration={pageConfiguration}
        newspaperId={newspaperId}
        countTipps={countTipps}
        customTitle={customTitle}
      />
      <Footer pageConfiguration={pageConfiguration} />
      {children}
    </Box>
  )
}
WebTippsPagePreview.propTypes = {
  initialValues: PropTypes.object,
  pageConfiguration: PropTypes.object,
  countTipps: PropTypes.number,
  children: PropTypes.node,
  customBodyContent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  customTitle: PropTypes.string
}
export default WebTippsPagePreview
