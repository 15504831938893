import { deleteObject, ref } from 'firebase/storage'

import { storage } from 'services/firebase'

const deleteStorageFile = async (url) => {
  const reference = ref(storage, url)
  await deleteObject(reference)
}

export default deleteStorageFile
