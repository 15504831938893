import { Box, Col, Row, Text, Title } from '@qonsoll/react-design'
import { useTranslations } from 'contexts/Translation'
import { Button, Input } from 'antd'
import PropTypes from 'prop-types'
import draftToHtml from 'draftjs-to-html'
import { convertToRaw } from 'draft-js'
import { StyledTextWrapper } from '../WebTippsPagePreview/components/Footer/Footer.styled'

const CustomBodyPublicWizard = (props) => {
  const { initialValues, publicWizardConfiguration } = props

  const { t } = useTranslations()
  const computedTitle = initialValues?.title || t('Please, add title')
  const computedDescription =
    initialValues?.description ||
    t('It is mandatory to add a heading and title')

  const text =
    publicWizardConfiguration?.publicWizardText &&
    draftToHtml(
      convertToRaw(
        publicWizardConfiguration?.publicWizardText?.getCurrentContent()
      )
    )

  return (
    <Row h="center" noGutters height="35vh" v="center">
      <Col cw={[12, 12, 10, 8, 7]}>
        <Title textAlign="center" variant="h4" mb={1}>
          {computedTitle}
        </Title>
        <Box textAlign="center" mb={3}>
          <Text>{computedDescription}</Text>
        </Box>
        <Input placeholder={t('Please enter news tip title')} />
        <Box
          width="100%"
          mt="16px"
          display="flex"
          alignItems="end"
          justifyContent="end">
          <Button>{t('Next step')}</Button>
        </Box>
        <div style={{ marginTop: '16px' }}>
          <StyledTextWrapper
            dangerouslySetInnerHTML={{
              __html: text
            }}
          />
        </div>
      </Col>
    </Row>
  )
}

CustomBodyPublicWizard.propTypes = {
  initialValues: PropTypes.object.isRequired,
  publicWizardConfiguration: PropTypes.object.isRequired
}

export default CustomBodyPublicWizard
