import { Form } from 'antd'
import { useEffect } from 'react'

const useInitializeCountryAdvancedForm = (initialData) => {
  /* Getting form instance */
  const [form] = Form.useForm()

  /* Setting initial data */
  useEffect(() => {
    if (initialData) {
      /* Getting initial values */
      const { country, languages } = initialData

      /* Formatting date fields */
      const formValues = {
        country: {
          internationalName: country?.internationalName ?? null
        },
        languages: languages ?? null
      }

      /* Setting initial values to form instance */
      form.setFieldsValue(formValues)
    }
  }, [initialData, form])

  return { form }
}

export default useInitializeCountryAdvancedForm
