import {
  Box,
  Button,
  Col,
  Container,
  Img,
  Row,
  Text
} from '@qonsoll/react-design'
import { Card, Tabs, Tooltip } from 'antd'
import {
  NewspaperEditors,
  NewspaperNewsTips
} from 'domains/Newspaper/components'
import { generatePath, useHistory, useParams } from 'react-router-dom'

import PATHS from 'pages/paths'
import PropTypes from 'prop-types'
import { SettingOutlined } from '@ant-design/icons'
import { useGetSubadminData } from 'hooks'
import { useStateWithStorage } from 'hooks'
import { useTranslations } from 'contexts/Translation'

const NewspaperAdvancedView = (props) => {
  const { newspaper } = props

  /* State for active tab in AdvancedView with saving it to storage */
  const [activeTab, setActiveTab] = useStateWithStorage(
    null,
    'newspaper-advanced-view-active-tab'
  )

  const { isSubadmin } = useGetSubadminData()
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance from useHistory hook for redirecting */
  const history = useHistory()
  /* Getting params from useParams hook for getting url params */
  const params = useParams()
  /* Getting id from params */
  const { newspaperId } = params

  /* Getting TabPane component from Tabs */
  const { TabPane } = Tabs

  /* Handling change tab */
  const changeTab = (key) => {
    if (params && newspaperId) {
      if (key.includes(`newspapers/${newspaperId}`)) {
        history.push(key)
      }
      setActiveTab(key)
    }
  }

  const computedDescription = newspaper?.description || 'N/A'

  const handleOpenAdvancedSettings = () => {
    if (newspaperId) {
      const params = { newspaperId }
      const path = PATHS.AUTHENTICATED.NEWSPAPER_ADVANCED_SETTING_EDIT
      const generatedPath = generatePath(path, params)
      history.push(generatedPath)
    }
  }
  return (
    <Container position="relative">
      <Row mx="var(--ql-grid-gutter-negative)">
        <Col cw={12} mb={3}>
          <Card position="relative">
            {/* Advanced setting */}
            {!isSubadmin && (
              <Box position="absolute" right="0" top="0">
                <Tooltip title={t('Advanced settings')}>
                  <Button type="link" onClick={handleOpenAdvancedSettings}>
                    <SettingOutlined size={16} />
                  </Button>
                </Tooltip>
              </Box>
            )}

            <Row
              noGutters
              mb={2}
              v="center"
              h="between"
              style={{ gap: '48px', alignItems: 'flex-start' }}>
              <Col h="left" cw="auto" minWidth="200px" minHeight="200px">
                <Img
                  style={{
                    maxHeight: '200px',
                    maxWidth: '200px',
                    objectFit: 'contain',
                    borderRadius: 'var(--card-radius)'
                  }}
                  src={newspaper?.logoUrl}
                  alt={t('Newspaper logo')}
                />
              </Col>
              <Col h="left">
                <Text mb={1} type="secondary">
                  {t('Newspaper Name')}:
                </Text>
                <Text mb={4}>{newspaper?.name}</Text>
                <Text mb={1} type="secondary">
                  {t('Points Criteria')}:
                </Text>
                <Text>{computedDescription}</Text>
              </Col>
              <Col h="left" cw={3}>
                <Text type="secondary">{t('Added On')}:</Text>

                <Text>{newspaper?.dateAndTime}</Text>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col cw={12}>
          <Card>
            <Tabs defaultActiveKey={activeTab} onChange={changeTab}>
              <TabPane tab={t('All Editors')} key="#editors">
                <NewspaperEditors />
              </TabPane>
              <TabPane tab={t('All Tips')} key="#tips">
                <NewspaperNewsTips />
              </TabPane>
            </Tabs>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

NewspaperAdvancedView.propTypes = {
  newspaper: PropTypes.object
}

export default NewspaperAdvancedView
