import { COLLECTIONS } from '__constants__'
import { doc } from 'firebase/firestore'
import firestore from 'services/firebase/firestore'
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore'
import { useMemo } from 'react'

const useGetCountry = (countryId) => {
  const ref = useMemo(
    () => (countryId ? doc(firestore, COLLECTIONS.COUNTRIES, countryId) : null),
    [countryId]
  )

  return useDocumentDataOnce(ref)
}
export default useGetCountry
