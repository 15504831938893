import { useTranslations } from 'contexts/Translation'
import { HeaderBreadcrumbs, Spinner } from 'components'
import { PageWrapper, Row, Col } from '@qonsoll/react-design'
import { useParams } from 'react-router-dom'
import { UserAdvancedForm } from 'domains/User/components'
import { useGetUserInitialValues, useUserBaseActions } from 'domains/User/hooks'

const UserEditPage = (props) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting params from router */
  const { userId } = useParams()

  /* Fetching user initial values */
  const [initialValues, loading] = useGetUserInitialValues(userId)
  /* Getting base actions */
  const { onBack } = useUserBaseActions()

  /* Configuring page header */
  const headingProps = {
    title: t('User edit'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  return (
    <PageWrapper
      onBack={onBack}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}>
      <Row noGutters>
        {loading ? (
          <Col>
            <Spinner height="70vh" text={t('User loading')} />
          </Col>
        ) : (
          <Col cw={[12, 12, 10, 8, 7]}>
            <UserAdvancedForm initialData={initialValues} />
          </Col>
        )}
      </Row>
    </PageWrapper>
  )
}

export default UserEditPage
