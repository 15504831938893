const styles = {
  iconProps: {
    alignItems: 'center',
    zIndex: 1,
    name: 'DraggerOutlined',
    size: 16,
    fill: 'var(--ql-typography-text-color-secondary)'
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  rowProps: {
    h: 'center',
    v: 'center',
    noOuterGutters: true,
    mb: 3
  },

  deleteIconProps: {
    size: 16,
    name: 'Trash1Outlined',
    fill: 'var(--ql-color-danger)'
  }
}

export { styles }
