import { convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import { message } from 'antd'
import { useTranslations } from '@qonsoll/translation'
import { useEvent } from 'hooks'
import { createDocument, updateDocument } from 'services/api/firebase'
import { useEffect, useMemo, useState } from 'react'
import { COLLECTIONS } from '__constants__'
import { useGetContent } from 'domains/Content/hooks'
import { ContentState, EditorState } from 'draft-js'
import htmlToDraft from 'html-to-draftjs'

const useSaveContent = (contentType) => {
  const { t } = useTranslations()
  const [template, setTemplate] = useState({})
  const [contents, contentLoading] = useGetContent()

  const contentByContentType = useMemo(
    () =>
      contents.find(
        ({ contentType: contentTypeFromDB }) =>
          contentType === contentTypeFromDB
      ) || null,
    [contents, contentType]
  )

  const prepareStateForDatabase = () => ({
    content: draftToHtml(convertToRaw(template?.state.getCurrentContent())),
    contentType
  })

  const saveContentHandler = useEvent(async (dataToSave) => {
    try {
      const preparedData = prepareStateForDatabase(dataToSave)
      if (!contentByContentType) {
        await createDocument(COLLECTIONS.CONTENT, preparedData)
        message.success(t('Content was created successfully'))
        return
      }

      await updateDocument(
        COLLECTIONS.CONTENT,
        contentByContentType?._id,
        preparedData
      )

      message.success(t('Content was saved successfully'))
    } catch (error) {
      message.error(t('Error saving content'))
    }
  })

  const onTextEditorChange = useEvent((editorState) =>
    setTemplate({ ...template, state: editorState })
  )

  const handleReset = useEvent(() =>
    setTemplate({
      state: EditorState.createWithText(
        `Default text for ${contentType.split('_').join(' ').toLowerCase()}`
      )
    })
  )

  useEffect(() => {
    if (contentByContentType !== null && !contentLoading) {
      let content = htmlToDraft(contentByContentType.content)
      content = ContentState.createFromBlockArray(
        content.contentBlocks,
        content.entityMap
      )

      setTemplate({
        state: EditorState.createWithContent(content)
      })
    }
  }, [contentByContentType, contentLoading])

  return {
    saveContentHandler,
    template,
    onTextEditorChange,
    contentLoading,
    handleReset
  }
}

export default useSaveContent
