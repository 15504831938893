import { Box, Button, Input, Text } from '@qonsoll/react-design'
import {
  LINK_CONFIG_NAMES,
  LINK_OPTIONS_CONFIG
} from 'domains/Content/constants'
import { Form, message, Popconfirm, Checkbox } from 'antd'
import { useTranslations } from '@qonsoll/translation'
import PropTypes from 'prop-types'
import { useState } from 'react'

const TextEditLinkBlock = (props) => {
  const { config, onChange, currentState } = props

  const { t } = useTranslations()

  const [isAddLink, setIsAddLink] = useState(null)
  const [isChecked, setIsChecked] = useState(false)

  /* Getting new form instance from Form.useForm hook */
  const [form] = Form.useForm()
  const onFinish = (values) => {
    const { url } = values

    if (!url?.trim()) {
      message.warn(t('Url is required'))
      return
    }
    if (isAddLink) {
      onChange(
        LINK_CONFIG_NAMES.LINK,
        currentState?.selectionText,
        url,
        isChecked ? '_blank' : '_self'
      )

      message.success(t('Link has been added'))
    } else {
      onChange(LINK_CONFIG_NAMES.UNLINK)
      message.success(t('Link has been removed'))
    }

    form.resetFields()
    setIsAddLink(null)
    setIsChecked(false)
  }

  const confirm = () => form.submit()
  const handleClickOption = (option) => {
    const isAdd = option === LINK_CONFIG_NAMES.LINK

    setIsAddLink(isAdd)
    !isAdd && setIsChecked(currentState?.link?.targetOption === '_blank')

    form.setFieldsValue({
      selectedText: currentState?.selectionText,
      url: isAdd ? '' : currentState?.link?.target
    })
  }

  const onChangeCheckBox = (e) => setIsChecked(e.target.checked)

  return (
    <Box mx={-1} pl={2}>
      {config?.options?.map((option, index) => (
        <Popconfirm
          title={
            <>
              {option === LINK_CONFIG_NAMES.UNLINK && (
                <Box mb="8px">
                  <Text strong>{t('Do you want to remove link')}?</Text>
                </Box>
              )}
              <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item label={t('Selected text')} name="selectedText">
                  <Input disabled />
                </Form.Item>
                <Form.Item label={t('Url')} name="url" required>
                  <Input
                    placeholder="google.com"
                    disabled={option === LINK_CONFIG_NAMES.UNLINK}
                  />
                </Form.Item>
                <Form.Item name="isOpenUrlInNewWindow">
                  <Checkbox
                    checked={isChecked}
                    onChange={onChangeCheckBox}
                    disabled={option === LINK_CONFIG_NAMES.UNLINK}>
                    {t('Open url in new window')}
                  </Checkbox>
                </Form.Item>
              </Form>
            </>
          }
          icon={null}
          onConfirm={confirm}
          okText={option === LINK_CONFIG_NAMES.UNLINK ? t('Remove') : t('Add')}
          cancelText={t('Cancel')}>
          <Button
            disabled={
              option === LINK_CONFIG_NAMES.UNLINK
                ? !currentState?.link
                : currentState?.selectionText?.length === 0
            }
            key={`TextEditLinkBlock-${index}`}
            mx={1}
            size="small"
            type="text"
            icon={LINK_OPTIONS_CONFIG[option]}
            onClick={() => handleClickOption(option)}
          />
        </Popconfirm>
      ))}
    </Box>
  )
}

TextEditLinkBlock.propTypes = {
  config: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  currentState: PropTypes.object.isRequired
}
export default TextEditLinkBlock
