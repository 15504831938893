import { COLLECTIONS, STATUSES } from '__constants__'
import { generatePath, useHistory } from 'react-router-dom'

import PATHS from 'pages/paths'
import { message } from 'antd'
import { updateDocument } from 'services/api/firebase'
import { useEvent } from 'hooks'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

/* Path's */
const editPath = PATHS.AUTHENTICATED.COUNTRY_EDIT

const useCountryBaseActions = ({
  countryId,
  isBlocked,
  status,
  actions
} = {}) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()

  /* Function to handle back click */
  const handleBack = useEvent(() => history.goBack())

  /* Handling opening of the edit page or change view to edit mode */
  const handleEdit = useEvent(() => {
    const pathParams = { countryId }
    const path = generatePath(editPath, pathParams)
    history.push(path)
  })

  const handleBlock = useEvent(async () => {
    const computedStatus = isBlocked ? 'unblocked' : 'blocked'
    try {
      await updateDocument(COLLECTIONS.COUNTRIES, countryId, {
        isBlocked: !isBlocked,
        status: isBlocked
          ? STATUSES.COUNTRY_STATUSES.ACTIVE
          : STATUSES.COUNTRY_STATUSES.BLOCKED
      })
      message.success(t(`Country successfully ${computedStatus}`))
    } catch (error) {
      message.error(t(`Country ${computedStatus} status ` + error.message))
    }
  })

  return useMemo(
    () => ({
      onBack: handleBack,
      onEdit: countryId && handleEdit,
      onBlock: handleBlock,
      ...(actions || {}),
      editPath
    }),
    [
      actions?.onBack,
      actions?.onCreate,
      actions?.onDelete,
      actions?.onEdit,
      handleBack,
      handleEdit,
      handleBlock
    ]
  )
}

export default useCountryBaseActions
