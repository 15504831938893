import { COLLECTIONS } from '__constants__'
import { useCollection } from 'services/api/firebase'
import { useMemo } from 'react'

const useGetUserNewsTips = (props) => {
  /* Query for getting collection reference */
  const query = useMemo(
    () => ({
      ref: COLLECTIONS.NEWS_TIPS,
      orderBy: [['_createdAt', 'desc']],
      ...props
    }),
    [props]
  )

  /* Getting collection data */
  const [value, loading, error, next, loadingMore, loadMoreAvailable] =
    useCollection(query)

  return [value, loading, error, next, loadingMore, loadMoreAvailable]
}

export default useGetUserNewsTips
