import { Col, Container, Row, Text } from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import { STATUSES } from '__constants__'
import { useTranslations } from 'contexts/Translation'

const EmailStatus = (props) => {
  const { email } = props

  /* Getting translation instance */
  const { t } = useTranslations()

  const { BLOCKED, ACTIVE } = STATUSES.EMAIL_STATUSES
  const computedText = email?.isBlocked ? BLOCKED : ACTIVE
  return (
    <Container>
      <Row noGutters>
        <Col>
          {computedText ? (
            <Text>{t(computedText)}</Text>
          ) : (
            <Text type="secondary" variant="overline">
              {t('No email statuses have been added yet')}
            </Text>
          )}
        </Col>
      </Row>
    </Container>
  )
}

EmailStatus.propTypes = {
  email: PropTypes.object.isRequired
}

export default EmailStatus
