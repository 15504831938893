import { useTranslations } from 'contexts/Translation'
import { HeaderBreadcrumbs, Spinner } from 'components'
import { PageWrapper, Row, Col } from '@qonsoll/react-design'
import { useParams } from 'react-router-dom'
import { StoreAdvancedForm } from 'domains/Store/components'
import {
  useGetStoreInitialValues,
  useStoreBaseActions
} from 'domains/Store/hooks'

const StoreEditPage = (props) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting params from router */
  const { storeId } = useParams()

  /* Fetching store initial values */
  const [initialValues, loading] = useGetStoreInitialValues(storeId)
  /* Getting base actions */
  const { onBack } = useStoreBaseActions()

  /* Configuring page header */
  const headingProps = {
    title: t('Store edit'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  return (
    <PageWrapper
      onBack={onBack}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}>
      <Row noGutters>
        {loading ? (
          <Col>
            <Spinner height="70vh" text={t('Store loading')} />
          </Col>
        ) : (
          <Col cw={[12, 12, 10, 8, 7]}>
            <StoreAdvancedForm initialData={initialValues} />
          </Col>
        )}
      </Row>
    </PageWrapper>
  )
}

export default StoreEditPage
