import { Checkbox } from 'antd'
import PropTypes from 'prop-types'
import { STATUSES } from '__constants__'
import { Text } from '@qonsoll/react-design'
import { useSubfilterActions } from 'hooks'
import { useTranslations } from 'contexts/Translation'

const SubadminStatusFilter = ({ filterData, setFilterData, isSuperAdmin }) => {
  const { t } = useTranslations()

  const computedAdminRole = !isSuperAdmin ? 'subadmin' : 'superAdmin'

  const computedTitle = !isSuperAdmin ? 'Subadmin' : 'Super admin'

  const { onToggleDetails, onChange, checkIsEnabled } = useSubfilterActions({
    filterData,
    setFilterData,
    fieldName: 'status',
    operand: '==',
    type: `${computedAdminRole}Status`
  })

  const adminStatuses = Object.values(STATUSES.USER_STATUSES)?.map(
    (adminStatus) => ({ value: adminStatus, name: adminStatus })
  )

  return (
    <details
      open={
        localStorage.getItem(
          `${computedAdminRole}-status-details-visibility`
        ) === 'opened'
      }>
      <summary onClick={onToggleDetails}>
        {t(`${computedTitle} status`)}
      </summary>
      {adminStatuses?.map((adminStatus, index) => (
        <Checkbox
          key={`status-${index}`}
          checked={checkIsEnabled(adminStatus)}
          onChange={() => onChange(adminStatus)}>
          <Text wordBreak="break-all">
            {adminStatus?.name || t('Unnamed filter')}
          </Text>
        </Checkbox>
      ))}
    </details>
  )
}

SubadminStatusFilter.propTypes = {
  filterData: PropTypes.object,
  setFilterData: PropTypes.func,
  isSuperAdmin: PropTypes.bool
}

export default SubadminStatusFilter
