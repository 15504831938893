import { Col, PageWrapper, Row } from '@qonsoll/react-design'
import { HeaderBreadcrumbs, PageActions, Spinner } from 'components'
import {
  useGetNewspaperInitialValues,
  useNewspaperBaseActions
} from 'domains/Newspaper/hooks'

import { NewspaperAdvancedView } from 'domains/Newspaper/components'
import { useGetSubadminData } from 'hooks'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'

const NewspaperShowPage = () => {
  /* Getting translations instance */
  const { t } = useTranslations()

  /* Getting params from router */
  const params = useParams()

  /* Getting user role */
  const { isSubadmin } = useGetSubadminData()

  /* Getting id from params */
  const { newspaperId } = params

  /* Fetching document data from useDocument hook */
  const [newspaperInitialValues, loading] =
    useGetNewspaperInitialValues(newspaperId)

  /* Configuring page header */
  const headingProps = {
    title: t('Newspaper Detail'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  /* Configuring page actions */
  const actions = {
    edit: true,
    block: !isSubadmin,
    delete: !isSubadmin,
    enable: !isSubadmin
  }

  const isBlocked = useMemo(
    () => newspaperInitialValues?.newspaper?.isBlocked,
    [newspaperInitialValues?.newspaper]
  )

  /* Get value or web tips is enabled */
  const isWebTippsEnabled =
    newspaperInitialValues?.webTippsConfiguration?.isWebTippsEnabled
  /* Get id for web tipps  */
  const webTippsConfigurationId =
    newspaperInitialValues?.newspaper?.webTippsConfigurationId

  /* Get text for enable web tipps button  */
  const enableButtonText = `${
    isWebTippsEnabled ? 'Disable' : 'Enable'
  } web Tipps`

  const { onDelete, onBack, onBlock, onEdit, onEnableWebTipps } =
    useNewspaperBaseActions({
      newspaperId,
      isBlocked,
      isWebTippsEnabled,
      webTippsConfigurationId
    })

  return (
    <PageWrapper
      action={
        <PageActions
          actions={actions}
          onBlock={onBlock}
          onEdit={onEdit}
          isBlocked={isBlocked}
          onDelete={onDelete}
          onEnable={onEnableWebTipps}
          enableButtonText={enableButtonText}
        />
      }
      onBack={onBack}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}>
      <Row noGutters>
        {loading ? (
          <Col>
            <Spinner height="70vh" text={t('Newspaper loading')} />
          </Col>
        ) : (
          <Col cw={12}>
            <NewspaperAdvancedView
              newspaper={newspaperInitialValues?.newspaper}
            />
          </Col>
        )}
      </Row>
    </PageWrapper>
  )
}

export default NewspaperShowPage
