import { Button, Title } from '@qonsoll/react-design'
import { LOCAL_ADMIN_ROLE, RECEIVERS_TYPES_OBJ } from '__constants__'
import {
  useActionsNotificationAdvancedForm,
  useInitializeNotificationAdvancedForm
} from './hooks'

import { Form } from 'antd'
import { KEYS } from '__constants__'
import { NewspaperSelect } from 'domains/Newspaper/components'
import { NotificationSimpleForm } from 'domains/Notification/components'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

/**
 * The `NotificationAdvancedForm` component is a form that allows users to send notifications, with
 * options for selecting receivers and additional settings.
 * Extra logic for component: local admin has always 1 "Receivers group" and this is "Newspaper users"
 * so this is predefined in the form for the users with role Local admin.
 * So select is disabled for them and newspaper is predefined and disabled correspondingly.
 * @param props - The `props` parameter is an object that contains the following properties:
 * @returns The NotificationAdvancedForm component is being returned.
 */
const NotificationAdvancedForm = (props) => {
  const { initialData, group, showTitle } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  const { role, newspaperId } = useUser()

  const isCurrentUserLocalAdmin = role === LOCAL_ADMIN_ROLE

  const initialDataComputed = useMemo(
    () => ({
      ...initialData,
      isCurrentUserLocalAdmin,
      currentUserNewspaperId: newspaperId,
      currentUserReceiversType:
        RECEIVERS_TYPES_OBJ.RECEIVERS_NEWSPAPER_USERS_TYPE
    }),
    [initialData, isCurrentUserLocalAdmin, newspaperId]
  )
  /* Getting form instance from hook */
  const { form } = useInitializeNotificationAdvancedForm(initialDataComputed)

  /* Handling enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      form.submit()
    }
  }

  /* Getting loading state and actions from hook */
  const {
    isEqualForms,
    isNewspaperUsersReceive,
    loading,
    onFormValuesChange,
    onFinish,
    onReset
  } = useActionsNotificationAdvancedForm({
    initialData,
    isCurrentUserLocalAdmin,
    form
  })

  return group ? (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Notification')}
        </Title>
      )}
      <NotificationSimpleForm
        showTitle={false}
        group={[...group, 'notification']}
      />
      <Form.Item name={[group, 'receiversNewspaper']}>
        <NewspaperSelect />
      </Form.Item>
    </>
  ) : (
    <Form
      onKeyPress={handleKeyPress}
      onFinish={onFinish}
      onValuesChange={onFormValuesChange}
      layout="vertical"
      autoComplete="off"
      form={form}>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Notification')}
        </Title>
      )}
      <NotificationSimpleForm
        group={['notification']}
        isCurrentUserLocalAdmin={isCurrentUserLocalAdmin}
      />
      {isNewspaperUsersReceive && (
        <Form.Item name={['receiversNewspaper']}>
          <NewspaperSelect disabled={isCurrentUserLocalAdmin} />
        </Form.Item>
      )}
      <Button mr={2} htmlType="button" onClick={onReset}>
        {t('Cancel')}
      </Button>
      <Button
        type="primary"
        disabled={isEqualForms}
        loading={loading}
        onClick={() => form.submit()}>
        {t('Send')}
      </Button>
    </Form>
  )
}

NotificationAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  group: PropTypes.array,
  showTitle: PropTypes.bool
}

export default NotificationAdvancedForm
