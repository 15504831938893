import { useGetRewardNewspaper, useGetRewardStore } from 'domains/Reward/hooks'

import { COLLECTIONS } from '__constants__'
import { useDocument } from 'services/api/firebase'
import { useLoading } from 'hooks'
import { useMemo } from 'react'

const useGetRewardInitialValues = (id) => {
  /* Fetching data using useDocument hook */
  const [reward, rewardLoading] = useDocument({
    ref: id ? COLLECTIONS.REWARDS + '/' + id : null
  })

  /* Fetching newspaper data */
  const [newspaper, newspaperLoading] = useGetRewardNewspaper(reward)

  const [store, storeLoading] = useGetRewardStore(reward)

  /* Merge all fetched data */
  const initialValues = useMemo(
    () => ({
      reward,
      newspaper,
      store
    }),
    [reward, newspaper, store]
  )

  /* Merge all loading states */
  const loadings = useMemo(
    () => (id ? [!reward, rewardLoading, newspaperLoading, storeLoading] : []),
    [id, reward, rewardLoading, newspaperLoading, storeLoading]
  )

  /* Getting loading state using merged loadings */
  const loading = useLoading(loadings)

  return [initialValues, loading]
}

export default useGetRewardInitialValues
