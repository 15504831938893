import { NOTIFICATION_RECEIVERS_OPTIONS } from '__constants__'
import PropTypes from 'prop-types'
import { Text } from '@qonsoll/react-design'
import { useTranslations } from 'contexts/Translation'

const NotificationReceiversType = ({ receiversType }) => {
  const { t } = useTranslations()

  /* Get notification receiver type name */
  const displayingReceiversType = NOTIFICATION_RECEIVERS_OPTIONS?.find(
    (receiverOption) => receiverOption?.value === receiversType
  )

  return displayingReceiversType ? (
    <Text>{t(displayingReceiversType?.label)}</Text>
  ) : (
    <Text>{t(`No group (single user)`)}</Text>
  )
}
NotificationReceiversType.propTypes = {
  receiversType: PropTypes.string.isRequired
}

export default NotificationReceiversType
