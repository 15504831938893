import { NewsTipStatusFilter, NewspaperFilter } from './components'

import PropTypes from 'prop-types'
import { useGetSubadminData } from 'hooks'

const NewsTipFilter = (props) => {
  const { filterVisibility, filterData, setFilterData, hiddenNewspaperFilter } =
    props

  const { isSubadmin } = useGetSubadminData()

  return filterVisibility ? (
    <>
      <NewsTipStatusFilter
        filterData={filterData}
        setFilterData={setFilterData}
      />
      {!isSubadmin && !hiddenNewspaperFilter && (
        <NewspaperFilter
          filterData={filterData}
          setFilterData={setFilterData}
        />
      )}
    </>
  ) : null
}

NewsTipFilter.propTypes = {
  filterVisibility: PropTypes.bool,
  hiddenNewspaperFilter: PropTypes.bool,
  filterData: PropTypes.object,
  setFilterData: PropTypes.func
}

export default NewsTipFilter
