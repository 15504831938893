import { useWebTippsConfigurationBaseActions } from 'domains/WebTippsConfiguration/hooks'

import { Link } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const WebTippsConfigurationDomainNameLink = ({
  text,
  webTippsConfigurationId
}) => {
  const { t } = useTranslations()

  const { onOpen } = useWebTippsConfigurationBaseActions({
    webTippsConfigurationId
  })

  const computedText = text || 'N/A'

  return <Link onClick={onOpen}>{computedText}</Link>
}

WebTippsConfigurationDomainNameLink.propTypes = {
  text: PropTypes.string,
  webTippsConfigurationId: PropTypes.string
}

export default WebTippsConfigurationDomainNameLink
