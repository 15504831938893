import { ENDPOINTS } from '__constants__'

const FIREBASE_FUNCTIONS_API_URL =
  process.env.REACT_APP_FIREBASE_FUNCTIONS_API_URL

/**
 * Function is user for getting user custom claims and uid
 * @param   {string}   email  user's email
 * @returns {object}   user custom claims and uid
 */
const getUserCustomClaimsAndUid = async (email, currentAdminToken) => {
  try {
    // Check if user exists in auth records
    const response = await fetch(
      FIREBASE_FUNCTIONS_API_URL + ENDPOINTS.GET_USER_CUSTOM_CLAIMS_AND_UID,
      {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${currentAdminToken}`
        },
        body: JSON.stringify({ email })
      }
    )

    const responseData = await response.json()

    // If user exists return user custom claims and uid
    return responseData?.data || null
  } catch (error) {
    throw new Error('Error during getting user custom claims and uid')
  }
}

export default getUserCustomClaimsAndUid
