import { ADMIN_ROUTES, MAIN_ROUTES } from './routes'
import { Redirect, Route, Switch } from 'react-router-dom'

import { BoilerplateLayout } from 'components'
import PATHS from '../paths'
import { USER_ROLES } from '__constants__'
import { useMemo } from 'react'
import { useUser } from 'domains/User/context'

const App = () => {
  const { role } = useUser()

  const computedRoutes = useMemo(
    () => (role === USER_ROLES.LOCAL_ADMIN_ROLE ? MAIN_ROUTES : ADMIN_ROUTES),
    [role]
  )

  return (
    <BoilerplateLayout>
      {role && (
        <Switch>
          {computedRoutes?.map((routeProps) => (
            <Route key={routeProps.key} {...routeProps} />
          ))}
          <Redirect to={PATHS.SERVICE.NOT_FOUND} />
        </Switch>
      )}
    </BoilerplateLayout>
  )
}

export default App
