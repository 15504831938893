import { Col, Container, Row, Text } from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import { Spinner } from 'components'
import { StoreSimpleView } from 'domains/Store/components'
import { useGetRewardStore } from 'domains/Reward/hooks'
import { useTranslations } from 'contexts/Translation'

const RewardStore = (props) => {
  const { reward } = props

  /* Getting required data */
  const [store, loading] = useGetRewardStore(reward)

  /* Getting translation instance */
  const { t } = useTranslations()

  return (
    <Container>
      <Row noGutters>
        <Col>
          {loading ? (
            <Spinner flex={1} />
          ) : store ? (
            <StoreSimpleView {...props} store={store} />
          ) : (
            <Text type="secondary" variant="overline">
              {t('No store have been added yet')}
            </Text>
          )}
        </Col>
      </Row>
    </Container>
  )
}

RewardStore.propTypes = {
  reward: PropTypes.object.isRequired
}

export default RewardStore
