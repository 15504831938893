import { cloneElement, useMemo } from 'react'

import { EditorEmailSimpleView } from 'domains/Editor/components'
import { EmailStatus } from 'domains/Email/components'
import MOMENT_FORMATS from '__constants__/momentFormats'
import { TableActions } from 'components'
import { Text } from '@qonsoll/react-design'
import moment from 'moment'
import { sortByAlphabet } from 'helpers'
import { useEmailBaseActions } from 'domains/Email/hooks'
import { useGetColumnSearchProps } from 'hooks'
import { useTranslations } from '@qonsoll/translation'

const EmailTableActions = ({ emailId, isBlocked, isEditor, status }) => {
  const actions = useEmailBaseActions({ emailId, isBlocked, isEditor, status })

  return cloneElement(<TableActions />, {
    actions,
    isBlocked,
    name: 'email'
  })
}

const useEmailTableColumns = () => {
  const { t } = useTranslations()

  const getColumnSearchProps = useGetColumnSearchProps()

  return useMemo(() => {
    return [
      {
        title: <Text fontWeight="600">{t('Sno')}</Text>,
        dataIndex: 'sno',
        width: 'var(--table-number-column-width)',
        key: 'sno',
        align: 'center',
        sorter: (a, b) => a?.sno - b?.sno,
        ...getColumnSearchProps('sno'),
        render: (_, record) => <Text>{record?.sno}</Text>
      },
      {
        title: <Text fontWeight="600">{t('Email address')}</Text>,
        dataIndex: 'email',
        key: 'email',
        align: 'center',
        sorter: (a, b) => sortByAlphabet(a?.email, b?.email),
        ...getColumnSearchProps('email'),
        render: (_, record) =>
          record?.isEditor ? (
            <EditorEmailSimpleView email={record?.email} />
          ) : (
            <Text>{record?.email}</Text>
          )
      },

      {
        title: <Text fontWeight="600">{t('Added on')}</Text>,
        dataIndex: '_createdAt',
        key: '_createdAt',
        align: 'center',
        sorter: (a, b) => a?._createdAt?.seconds - b?._createdAt?.seconds,
        render: (date) => (
          <Text>
            {moment
              ?.unix(date?.seconds)
              ?.format(MOMENT_FORMATS.MONTH_DAYS_COMMA_YEAR)}
          </Text>
        )
      },

      {
        title: <Text fontWeight="600">{t('Status')}</Text>,
        dataIndex: 'status',
        width: 'var(--table-status-column-width)',
        key: 'status',
        align: 'center',
        sorter: (a, b) => a?.isBlocked - b?.isBlocked,
        render: (_, record) => <EmailStatus email={record} />
      },
      {
        title: <Text fontWeight="600">{t('Actions')}</Text>,
        dataIndex: 'actions',
        width: 'var(--table-actions-column-width)',
        key: 'actions',
        align: 'center',
        render: (_, record) => (
          <EmailTableActions
            emailId={record?._id}
            isBlocked={record?.isBlocked}
            isEditor={record?.isEditor}
            status={record?.status}
          />
        )
      }
    ]
  }, [t, getColumnSearchProps])
}

export default useEmailTableColumns
