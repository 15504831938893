import { Button, Title } from '@qonsoll/react-design'
import { Form, Input, InputNumber } from 'antd'
import { KEYS, MAX_LENGTH_FIELD } from '__constants__'

import { ImageUploader } from 'components'
import { PhoneInput } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const UserSimpleForm = (props) => {
  const { initialValues, onSubmit, onCancel, showTitle, form, group } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting new form instance from Form.useForm hook */
  const [baseForm] = Form.useForm()

  /* Getting used form from props or using base form */
  const usedForm = form || baseForm

  /* Resetting form on cancel */
  const handleCancel = () => {
    usedForm.resetFields()
    onCancel?.()
  }
  /* Submitting form on enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      e.stopPropagation()
      usedForm?.submit()
    }
  }

  return group ? (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('User')}
        </Title>
      )}
      <Form.Item
        label={t('Avatar')}
        name={[...group, 'avatarUrl']}
        rules={[
          {
            required: false,
            message: t('Please upload your avatar!')
          }
        ]}>
        <ImageUploader />
      </Form.Item>
      <Form.Item
        label={t('First name')}
        name={[...group, 'firstName']}
        rules={[
          {
            required: true,
            message: t('Please enter your first name!')
          },
          { max: MAX_LENGTH_FIELD, message: t('user name is too long!') }
        ]}>
        <Input placeholder={t('Please enter your user name')} />
      </Form.Item>
      <Form.Item
        label={t('Last name')}
        name={[...group, 'lastName']}
        rules={[
          {
            required: true,
            message: t('Please enter your last name!')
          },
          { max: MAX_LENGTH_FIELD, message: t('user name is too long!') }
        ]}>
        <Input placeholder={t('Please enter your user name')} />
      </Form.Item>
      <Form.Item
        label={t('Email')}
        name={[...group, 'email']}
        rules={[
          {
            required: true,
            message: t('Please enter your user email!')
          },
          { max: MAX_LENGTH_FIELD, message: t('user email is too long!') }
        ]}>
        <Input placeholder={t('Please enter your user email')} />
      </Form.Item>
      <Form.Item
        label={t('Phone number')}
        name={[...group, 'phoneNumber']}
        rules={[
          {
            required: false,
            message: t('Please enter your user phone number!')
          }
        ]}>
        <PhoneInput
          country="no"
          preferredCountries={['no']}
          specialLabel=""
          placeholder={t('Please enter your user phone number')}
        />
      </Form.Item>
    </>
  ) : (
    <Form
      form={usedForm}
      initialValues={initialValues}
      onFinish={onSubmit}
      layout="vertical"
      onKeyPress={handleKeyPress}
      autoComplete="off">
      {showTitle && (
        <Title level={4} mb={2}>
          {t('User')}
        </Title>
      )}
      <Form.Item
        label={t('Avatar')}
        name="avatarUrl"
        rules={[
          {
            required: false,
            message: t('Please upload your avatar!')
          }
        ]}>
        <ImageUploader placeholder={t('Please enter your user avatar url')} />
      </Form.Item>
      <Form.Item
        label={t('First name')}
        name={[...group, 'firstName']}
        rules={[
          {
            required: false,
            message: t('Please enter your user name!')
          },
          { max: MAX_LENGTH_FIELD, message: t('user name is too long!') }
        ]}>
        <Input placeholder={t('Please enter your user name')} />
      </Form.Item>
      <Form.Item
        label={t('Last name')}
        name={[...group, 'lastName']}
        rules={[
          {
            required: false,
            message: t('Please enter your user name!')
          },
          { max: MAX_LENGTH_FIELD, message: t('user name is too long!') }
        ]}>
        <Input placeholder={t('Please enter your user name')} />
      </Form.Item>
      <Form.Item
        label={t('Sno')}
        name="sno"
        rules={[
          {
            required: false,
            message: t('Please enter your user sno!')
          }
        ]}>
        <InputNumber placeholder={t('Please enter your user sno')} />
      </Form.Item>
      <Form.Item
        label={t('Email')}
        name="email"
        rules={[
          {
            required: false,
            message: t('Please enter your user email!')
          },
          { max: MAX_LENGTH_FIELD, message: t('user email is too long!') }
        ]}>
        <Input placeholder={t('Please enter your user email')} />
      </Form.Item>
      <Form.Item
        label={t('Phone number')}
        name="phoneNumber"
        rules={[
          {
            required: false,
            message: t('Please enter your user phone number!')
          }
        ]}>
        <PhoneInput
          country="no"
          preferredCountries={['no']}
          specialLabel=""
          placeholder={t('Please enter your user phone number')}
        />
      </Form.Item>
      <Form.Item
        label={t('Points given')}
        name="pointsGiven"
        rules={[
          {
            required: false,
            message: t('Please enter your user points given!')
          }
        ]}>
        <InputNumber placeholder={t('Please enter your user points given')} />
      </Form.Item>
      <Form.Item
        label={t('Points used')}
        name="pointsUsed"
        rules={[
          {
            required: false,
            message: t('Please enter your user points used!')
          }
        ]}>
        <InputNumber placeholder={t('Please enter your user points used')} />
      </Form.Item>
      {!form && (
        <>
          <Button mr={2} htmlType="button" onClick={handleCancel}>
            {t('Cancel')}
          </Button>
          <Button type="primary" onClick={() => usedForm.submit()}>
            {t('Save')}
          </Button>
        </>
      )}
    </Form>
  )
}

UserSimpleForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  showTitle: PropTypes.bool,
  form: PropTypes.any,
  group: PropTypes.array
}

export default UserSimpleForm
