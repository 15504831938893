import { Button, Input } from '@qonsoll/react-design'
import { Form } from 'antd'
import { useCallback, useState } from 'react'

import PropTypes from 'prop-types'
import { StrengthIndicator } from 'components'
import { useTranslations } from 'contexts/Translation'
import zxcvbn from 'zxcvbn'
import { useUser } from 'domains/User/context'

const ResetPasswordForm = () => {
  const { t } = useTranslations()

  // [ADDITIONAL_HOOKS]
  const { completeNewPassword } = useUser()

  // [COMPONENT_STATE]
  const [loading, setLoading] = useState(false)
  const [level, setLevel] = useState(-1)
  const [password, setPassword] = useState()

  // Handlers
  const handleChange = (e) => {
    const value = e.target.value
    const score = value?.length ? zxcvbn(value).score : -1

    setLevel(score)
    setPassword(value)
  }

  // [CLEAN_FUNCTIONS]
  const validateMatchPasswords = useCallback(
    (_, value, cb) => (value === password ? cb() : cb(true)),
    [password]
  )

  const onFinish = async ({ password }) => {
    setLoading(true)
    await completeNewPassword(password)
    setLoading(false)
  }

  return (
    <Form size="large" onFinish={onFinish} layout="vertical">
      <Form.Item
        label={t('New password')}
        name="password"
        rules={[
          {
            required: true,
            message: t('Please enter your new password!')
          }
        ]}>
        <Input.Password
          autoFocus
          placeholder={t('Enter your new password')}
          onChange={handleChange}
          textAlign="center"
        />
      </Form.Item>
      <Form.Item
        name="confirmPassword"
        label={t('Confirm new password')}
        rules={[
          {
            required: true,
            message: t('Please confirm your password')
          },
          {
            validator: validateMatchPasswords,
            message: t('Password do not match')
          }
        ]}>
        <Input.Password
          autoFocus
          placeholder={t('Confirm your password')}
          textAlign="center"
        />
      </Form.Item>

      <StrengthIndicator level={level} />

      <Button type="primary" block htmlType="submit" loading={loading}>
        {t('Reset Password')}
      </Button>
    </Form>
  )
}

ResetPasswordForm.propTypes = {
  form: PropTypes.object,
  onError: PropTypes.any.isRequired,
  onFinish: PropTypes.func,
  onFinishFailed: PropTypes.func,
  resetPassword: PropTypes.func.isRequired
}
export default ResetPasswordForm
