import { Button, Title } from '@qonsoll/react-design'
import {
  useActionsUserAdvancedForm,
  useInitializeUserAdvancedForm
} from './hooks'

import { Form } from 'antd'
import { KEYS } from '__constants__'
import { NewspaperSelect } from 'domains/Newspaper/components'
import PropTypes from 'prop-types'
import { UserSimpleForm } from 'domains/User/components'
import { useCheckChangeFormValue } from 'hooks'
import { useTranslations } from 'contexts/Translation'

const UserAdvancedForm = (props) => {
  const { initialData, group, showTitle } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting form instance from hook */
  const { form } = useInitializeUserAdvancedForm(initialData)

  const { triggerValueChange, isEqualForms } = useCheckChangeFormValue(form)

  /* Handling enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      form.submit()
    }
  }

  /* Getting loading state and actions from hook */
  const { loading, onFinish, onReset } = useActionsUserAdvancedForm({
    initialData,
    form
  })

  return group ? (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('User')}
        </Title>
      )}
      <UserSimpleForm showTitle={false} group={[...group, 'user']} />
      <Form.Item name={[group, 'newspaperAssociated']}>
        <NewspaperSelect />
      </Form.Item>
    </>
  ) : (
    <Form
      onKeyPress={handleKeyPress}
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
      onValuesChange={triggerValueChange}
      form={form}>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('User')}
        </Title>
      )}
      <UserSimpleForm group={['user']} />
      <Form.Item name={['newspaperAssociated']}>
        <NewspaperSelect />
      </Form.Item>
      <Button mr={2} htmlType="button" onClick={onReset}>
        {t('Cancel')}
      </Button>
      <Button
        type="primary"
        loading={loading}
        disabled={isEqualForms}
        onClick={() => form.submit()}>
        {t('Save')}
      </Button>
    </Form>
  )
}

UserAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  group: PropTypes.array,
  showTitle: PropTypes.bool
}

export default UserAdvancedForm
