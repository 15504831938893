import { AdminCreate, AdminsAll } from './Admin'
import { CountriesAll, CountryEdit } from './Country'
import { EditorCreate, EditorEdit, EditorShow, EditorsAll } from './Editor'
import { EmailCreate, EmailEdit, EmailsAll } from './Email'
import { NewsTipAll, NewsTipShow } from './NewsTip'
import {
  NewspaperAdvancedSettingEdit,
  NewspaperCreate,
  NewspaperEdit,
  NewspaperShow,
  NewspapersAll
} from './Newspaper'
import { NotificationCreate, NotificationsAll } from './Notification'
import { ProfileEdit, ProfileShow } from './Profile'
import {
  RejectReasonAll,
  RejectReasonCreate,
  RejectReasonEdit,
  RejectReasonShow
} from './RejectReason'
import { RewardCreate, RewardEdit, RewardShow, RewardsAll } from './Reward'
import { StoreCreate, StoreEdit, StoreShow, StoresAll } from './Store'
import { SubadminCreate, SubadminsAll } from './Subadmin'
import { UserEdit, UsersAll } from './User'
import {
  WebTippsConfigurationEdit,
  WebTippsConfigurationsAll
} from './WebTippsConfiguration'

import { ContentAll } from './Content'
import Dashboard from './Dashboard/Dashboard'
import PATHS from '../paths'
import { ResetPassword } from './ResetPassword'
import { RewardOverviewAll } from './RewardOverview'
import { Statistics } from './Statistics'
import Translations from './Translations'
import { UserShow } from './User'

const {
  RESET_PASSWORD,
  DASHBOARD,
  CONTENT,
  TRANSLATIONS,
  STATISTICS,

  USERS_ALL,
  USER_SHOW,
  USER_EDIT,

  NEWSPAPER_SHOW,
  NEWSPAPER_EDIT,
  NEWSPAPERS_ALL,
  NEWSPAPER_CREATE,

  NEWSPAPER_ADVANCED_SETTING_EDIT,

  WEB_TIPPS_CONFIGURATIONS_ALL,
  WEB_TIPPS_CONFIGURATION_EDIT,

  EDITOR_SHOW,
  EDITOR_EDIT,
  EDITORS_ALL,
  EDITOR_CREATE,

  NEWS_TIP_ALL,
  NEWS_TIP_SHOW,

  STORES_ALL,
  STORE_SHOW,
  STORE_CREATE,
  STORE_EDIT,

  REWARDS_ALL,
  REWARD_SHOW,
  REWARD_CREATE,
  REWARD_EDIT,

  NOTIFICATIONS_ALL,
  NOTIFICATION_CREATE,

  SUBADMINS_ALL,
  SUBADMIN_CREATE,

  ADMINS_ALL,
  ADMIN_CREATE,

  PROFILE_SHOW,
  PROFILE_EDIT,

  EMAILS_ALL,
  EMAIL_EDIT,
  EMAIL_CREATE,

  COUNTRIES_ALL,
  COUNTRY_EDIT,

  REJECT_REASONS_ALL,
  REJECT_REASON_CREATE,
  REJECT_REASON_EDIT,
  REJECT_REASON_SHOW,

  REWARD_OVERVIEW_ALL
} = PATHS.AUTHENTICATED

const MAIN_ROUTES = [
  { key: 'DASHBOARD', path: DASHBOARD, component: Dashboard, exact: true },
  {
    key: 'PROFILE_SHOW',
    path: PROFILE_SHOW,
    component: ProfileShow,
    exact: true
  },
  {
    key: 'PROFILE_EDIT',
    path: PROFILE_EDIT,
    component: ProfileEdit,
    exact: true
  },

  {
    key: 'RESET_PASSWORD',
    path: RESET_PASSWORD,
    component: ResetPassword,
    exact: true
  },
  {
    key: 'TRANSLATIONS',
    path: TRANSLATIONS,
    component: Translations,
    exact: true
  },
  {
    key: 'STATISTICS',
    path: STATISTICS,
    component: Statistics,
    exact: false
  },

  { key: 'USERS_ALL', path: USERS_ALL, component: UsersAll, exact: true },
  { key: 'USER_SHOW', path: USER_SHOW, component: UserShow, exact: true },
  { key: 'USER_EDIT', path: USER_EDIT, component: UserEdit, exact: true },

  {
    key: 'NEWSPAPER_SHOW',
    path: NEWSPAPER_SHOW,
    component: NewspaperShow,
    exact: true
  },

  {
    key: 'NEWSPAPER_EDIT',
    path: NEWSPAPER_EDIT,
    component: NewspaperEdit,
    exact: true
  },
  {
    key: 'NEWSPAPERS_ALL',
    path: NEWSPAPERS_ALL,
    component: NewspapersAll,
    exact: true
  },
  {
    key: 'NEWSPAPER_CREATE',
    path: NEWSPAPER_CREATE,
    component: NewspaperCreate,
    exact: true
  },

  {
    key: 'NEWSPAPER_ADVANCED_SETTING_EDIT',
    path: NEWSPAPER_ADVANCED_SETTING_EDIT,
    component: NewspaperAdvancedSettingEdit,
    exact: true
  },

  {
    key: 'WEB_TIPPS_CONFIGURATIONS_ALL',
    path: WEB_TIPPS_CONFIGURATIONS_ALL,
    component: WebTippsConfigurationsAll,
    exact: true
  },
  {
    key: 'WEB_TIPPS_CONFIGURATION_EDIT',
    path: WEB_TIPPS_CONFIGURATION_EDIT,
    component: WebTippsConfigurationEdit,
    exact: true
  },
  {
    key: 'NEWS_TIP_ALL',
    path: NEWS_TIP_ALL,
    component: NewsTipAll,
    exact: true
  },
  {
    key: 'NEWS_TIP_SHOW',
    path: NEWS_TIP_SHOW,
    component: NewsTipShow,
    exact: true
  },

  { key: 'STORE_EDIT', path: STORE_EDIT, component: StoreEdit, exact: true },
  { key: 'STORES_ALL', path: STORES_ALL, component: StoresAll, exact: true },
  { key: 'STORE_SHOW', path: STORE_SHOW, component: StoreShow, exact: true },
  {
    key: 'STORE_CREATE',
    path: STORE_CREATE,
    component: StoreCreate,
    exact: true
  },

  { key: 'REWARD_EDIT', path: REWARD_EDIT, component: RewardEdit, exact: true },
  { key: 'REWARDS_ALL', path: REWARDS_ALL, component: RewardsAll, exact: true },
  { key: 'REWARD_SHOW', path: REWARD_SHOW, component: RewardShow, exact: true },
  {
    key: 'REWARD_CREATE',
    path: REWARD_CREATE,
    component: RewardCreate,
    exact: true
  },

  {
    key: 'REWARD_OVERVIEW_ALL',
    path: REWARD_OVERVIEW_ALL,
    component: () => <RewardOverviewAll />,
    exact: true
  },

  {
    key: 'NOTIFICATIONS_ALL',
    path: NOTIFICATIONS_ALL,
    component: NotificationsAll,
    exact: true
  },
  {
    key: 'NOTIFICATION_CREATE',
    path: NOTIFICATION_CREATE,
    component: NotificationCreate,
    exact: true
  },

  {
    key: 'REJECT_REASONS_ALL',
    path: REJECT_REASONS_ALL,
    component: RejectReasonAll,
    exact: true
  },
  {
    key: 'REJECT_REASON_CREATE',
    path: REJECT_REASON_CREATE,
    component: RejectReasonCreate,
    exact: true
  },
  {
    key: 'REJECT_REASON_EDIT',
    path: REJECT_REASON_EDIT,
    component: RejectReasonEdit,
    exact: true
  },
  {
    key: 'REJECT_REASON_SHOW',
    path: REJECT_REASON_SHOW,
    component: RejectReasonShow,
    exact: true
  },

  { key: 'EDITOR_SHOW', path: EDITOR_SHOW, component: EditorShow, exact: true },
  { key: 'EDITOR_EDIT', path: EDITOR_EDIT, component: EditorEdit, exact: true },
  { key: 'EDITORS_ALL', path: EDITORS_ALL, component: EditorsAll, exact: true },
  {
    key: 'EDITOR_CREATE',
    path: EDITOR_CREATE,
    component: EditorCreate,
    exact: true
  },

  {
    key: 'COUNTRIES_ALL',
    path: COUNTRIES_ALL,
    component: CountriesAll,
    exact: true
  },
  {
    key: 'COUNTRY_EDIT',
    path: COUNTRY_EDIT,
    component: CountryEdit,
    exact: true
  },

  {
    key: 'EMAIL_EDIT',
    path: EMAIL_EDIT,
    component: EmailEdit,
    exact: true
  },
  {
    key: 'EMAILS_ALL',
    path: EMAILS_ALL,
    component: EmailsAll,
    exact: true
  },
  {
    key: 'EMAIL_CREATE',
    path: EMAIL_CREATE,
    component: EmailCreate,
    exact: true
  }
]

const ADMIN_ROUTES = [
  ...MAIN_ROUTES,

  { key: 'CONTENT', path: CONTENT, component: ContentAll, exact: true },

  {
    key: 'SUBADMINS_ALL',
    path: SUBADMINS_ALL,
    component: SubadminsAll,
    exact: true
  },
  {
    key: 'SUBADMIN_CREATE',
    path: SUBADMIN_CREATE,
    component: SubadminCreate,
    exact: true
  },

  {
    key: 'ADMINS_ALL',
    path: ADMINS_ALL,
    component: AdminsAll,
    exact: true
  },

  {
    key: 'ADMIN_CREATE',
    path: ADMIN_CREATE,
    component: AdminCreate,
    exact: true
  }
]

export { MAIN_ROUTES, ADMIN_ROUTES }
export default MAIN_ROUTES
