import { COLLECTIONS, STATUSES } from '__constants__'

import { useCollection } from 'services/api/firebase'
import { useGetQuery } from 'hooks'
/**
 * It returns an array of values that are used to render the emails
 * @param props - An object that contains the query parameters.
 * @returns An array of values.
 */
const useGetEmails = (props) => {
  /* Query for getting collection reference */
  const query = useGetQuery({
    collectionName: COLLECTIONS.EMAILS,
    where: props?.where,
    orderBy: props?.orderBy,
    statuses: STATUSES.EMAIL_STATUSES
  })
  /* Getting collection data */

  return useCollection(query)
}

export default useGetEmails
