import { Col, Row, Text } from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import { Spinner } from 'components'
import { useGetLocation } from 'domains/Location/hooks'

export default function LocationView(props) {
  const { location, ...rest } = props

  /* Getting required data */
  const [newsTipsLocation, loading] = useGetLocation(location)

  /* Getting translate for location according to selected language */
  const computedLocation =
    newsTipsLocation?.formattedAddress || location || 'N/A'

  return (
    <Row noGutters>
      <Col>
        {loading ? (
          <Spinner flex={1} />
        ) : computedLocation ? (
          <Text {...rest}>{computedLocation}</Text>
        ) : null}
      </Col>
    </Row>
  )
}

LocationView.propTypes = {
  location: PropTypes.object.isRequired,
  text: PropTypes.string
}
