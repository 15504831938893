import { Link } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import {
  useStoreBaseActions,
  useGetStoreInitialValues
} from 'domains/Store/hooks'

const StoreLink = ({ storeId, text }) => {
  const { onOpen } = useStoreBaseActions({ storeId })

  /* Get store's document */
  const [storeInitialValues, loading] = useGetStoreInitialValues(
    !text && storeId
  )

  /* Get name from store's document */
  const storeName = text || storeInitialValues?.store?.name

  return !loading && <Link onClick={onOpen}>{storeName}</Link>
}
StoreLink.propTypes = {
  storeId: PropTypes.string,
  text: PropTypes.string
}

export default StoreLink
