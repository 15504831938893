import { PageActions, PageWrapperActionsUnderTitle } from 'components'
import { useMemo, useState } from 'react'

import { NewsTipFilter } from 'domains/NewsTip/components'
import PropTypes from 'prop-types'
import { UserNewsTipsTable } from 'domains/User/components'
import { useFilter } from 'hooks'
import { useGetUserNewsTips } from '../../hooks'
import { useTranslations } from 'contexts/Translation'

const UserNewsTips = (props) => {
  const { user } = props

  const { t } = useTranslations()

  const [filteredNewsTipsCollection, setFilteredNewsTipsCollection] = useState()

  /* Getting filter params */
  const filterParams = useFilter('userNewsTips')

  /* Destructuring filter params */
  const { filterVisibility, filterData, setFilterVisibility } = filterParams

  const ref = useMemo(
    () => ({
      where: [['_createdBy', '==', user?._id], ...(filterData?.where || [])]
    }),
    [user, filterData]
  )

  /* Getting relationship data */
  const [newsTips, loading] = useGetUserNewsTips(ref)

  const headingProps = {
    title: t('News Tips'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }
  /* Configuring page actions */
  const actions = {
    search: true,
    filter: true
  }

  return (
    <PageWrapperActionsUnderTitle
      action={
        <PageActions
          {...filterParams}
          actions={actions}
          circle
          searchPlaceholder="Search by Title"
          searchField="title"
          collection={newsTips}
          setCollection={setFilteredNewsTipsCollection}
          filterMenu={<NewsTipFilter {...filterParams} />}
          filterVisibility={filterVisibility}
          setFilterVisibility={setFilterVisibility}
        />
      }
      headingProps={headingProps}>
      <UserNewsTipsTable dataSource={filteredNewsTipsCollection ?? newsTips} />
    </PageWrapperActionsUnderTitle>
  )
}

UserNewsTips.propTypes = {
  user: PropTypes.object.isRequired,
  actions: PropTypes.object
}

export default UserNewsTips
