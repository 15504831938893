import { cloneElement, useMemo } from 'react'
import { LocationView } from 'domains/Location/components'
import { FullNameLink } from 'domains/User/components'
import { MOMENT_FORMATS } from '__constants__'
import { REWARD_OVERVIEW_TYPES } from 'domains/RewardOverview/constants'
import { RewardLink } from 'domains/Reward/components'
import { RewardOverviewStatus } from 'domains/RewardOverview/components'
import { StoreLink } from 'domains/Store/components'
import { TableActions } from 'components'
import { Text } from '@qonsoll/react-design'
import moment from 'moment'
import { sortByAlphabet } from 'helpers'
import { useGetColumnSearchProps } from 'hooks'
import { useRewardOverviewBaseActions } from 'domains/RewardOverview/hooks/actions'
import { useTranslations } from '@qonsoll/translation'

const RewardOverviewTableActions = ({ rewardOverviewId, isSent, _sentAt }) => {
  const actions = useRewardOverviewBaseActions({
    rewardOverviewId,
    isSent
  })

  return cloneElement(<TableActions />, {
    actions,
    isSent,
    _sentAt,
    isRewardOverview: true
  })
}
const useGetRewardOverviewTableColumns = (lastActiveTab) => {
  const { t } = useTranslations()

  const getColumnSearchProps = useGetColumnSearchProps()

  return useMemo(() => {
    let arrFinal = [
      {
        title: <Text fontWeight="600">{t('Sno')}</Text>,
        dataIndex: 'sno',
        width: 'var(--table-number-column-width)',
        key: 'sno',
        align: 'center',
        sorter: (a, b) => a?.sno - b?.sno,
        render: (_, record) => <Text>{record?.sno}</Text>
      },
      {
        title: <Text fontWeight="600">{t('Date')}</Text>,
        width: 'var(--table-date-column-width)',
        dataIndex: '_createdAt',
        key: '_createdAt',
        align: 'center',
        sorter: (a, b) => a?._createdAt?.seconds - b?._createdAt?.seconds,
        render: (_, record) => (
          <Text>
            {moment
              ?.unix(record?._createdAt?.seconds)
              ?.format(MOMENT_FORMATS.MONTH_DAYS_COMMA_YEAR)}
          </Text>
        )
      },
      {
        title: t('Name'),
        dataIndex: 'user',
        key: 'user',
        align: 'center',
        sorter: (a, b) => sortByAlphabet(a?.fullName, b?.fullName),
        ...getColumnSearchProps('fullName', `${t('Name')}`),
        render: (_, record) => (
          <FullNameLink text={record?.fullName} userId={record?.userId} />
        )
      },
      {
        title: <Text fontWeight="600">{t('Address')}</Text>,
        dataIndex: 'address',
        key: 'address',
        align: 'center',
        sorter: (a, b) => sortByAlphabet(a?.address, b?.address),
        ...getColumnSearchProps('address'),
        render: (_, record) => <LocationView location={record?.address} />
      },

      {
        title: <Text fontWeight="600">{t('Email')}</Text>,
        dataIndex: 'email',
        key: 'email',
        align: 'center',
        sorter: (a, b) => sortByAlphabet(a?.email, b?.email),
        ...getColumnSearchProps('email', 'email'),
        render: (_, record) => <Text>{record?.email}</Text>
      },
      {
        title: <Text fontWeight="600">{t('Reward')}</Text>,
        dataIndex: 'reward',
        key: 'reward',
        align: 'center',
        sorter: (a, b) => sortByAlphabet(a?.rewardName, b?.rewardName),
        ...getColumnSearchProps('rewardName', 'reward name'),
        render: (_, record) => (
          <RewardLink text={record?.rewardName} rewardId={record?.rewardId} />
        )
      },
      {
        title: <Text fontWeight="600">{t('Store')}</Text>,
        dataIndex: 'store',
        key: 'store',
        align: 'center',
        sorter: (a, b) => sortByAlphabet(a?.storeName, b?.storeName),
        ...getColumnSearchProps('storeName', 'store name'),
        render: (_, record) => (
          <StoreLink text={record?.storeName} storeId={record?.storeId} />
        )
      },
      {
        title: <Text fontWeight="600">{t('Points')}</Text>,
        width: 'var(--table-points-column-width)',
        dataIndex: 'redeemPoints',
        key: 'redeemPoints',
        align: 'center',
        sorter: (a, b) => a?.redeemPoints - b?.redeemPoints,
        render: (_, record) => <Text>{record?.redeemPoints}</Text>
      },

      {
        title: <Text fontWeight="600">{t('Status')}</Text>,
        width: 'var(--table-status-column-width)',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        sorter: (a, b) => sortByAlphabet(a?.status, b?.status),
        render: (_, record) => <RewardOverviewStatus rewardOverview={record} />
      },

      {
        title: <Text fontWeight="600">{t('Actions')}</Text>,
        width: 'var(--table-actions-column-width)',
        dataIndex: 'actions',
        key: 'actions',
        align: 'center',
        render: (_, record) => (
          <RewardOverviewTableActions
            rewardOverviewId={record?._id}
            isSent={record?.isSent}
            _sentAt={record?._sentAt}
          />
        )
      }
    ]
    if (lastActiveTab === REWARD_OVERVIEW_TYPES.STORE) {
      arrFinal = arrFinal.filter(
        ({ dataIndex }) => !['address', 'email', 'actions'].includes(dataIndex)
      )
    }
    if (lastActiveTab === REWARD_OVERVIEW_TYPES.DONATION) {
      arrFinal = arrFinal.filter(
        ({ dataIndex }) => !['address', 'email'].includes(dataIndex)
      )
    }
    if (lastActiveTab === REWARD_OVERVIEW_TYPES.MAIL) {
      arrFinal = arrFinal.filter(
        ({ dataIndex }) => !['email'].includes(dataIndex)
      )
    }
    if (lastActiveTab === REWARD_OVERVIEW_TYPES.EMAIL) {
      arrFinal = arrFinal.filter(
        ({ dataIndex }) => !['address'].includes(dataIndex)
      )
    }
    return arrFinal
  }, [t, getColumnSearchProps, lastActiveTab])
}
export default useGetRewardOverviewTableColumns
