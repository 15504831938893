import { COLLECTIONS, STATUSES } from '__constants__'
import {
  createDocument,
  getId,
  updateDocument,
  updateParent
} from 'services/api/firebase'

import { KEYS } from '__constants__'
import PATHS from 'pages/paths'
import { RejectReason } from 'models'
import { message } from 'antd'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const useActionsRejectReasonAdvancedForm = ({ initialData, form } = {}) => {
  /* State for loading */
  const [loading, setLoading] = useState(false)
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()
  /* Getting history state */
  const historyState = history.location.state

  /* Function for preparing values for saving */
  const prepareValues = (values = {}, additionalValues = {}) => {
    /* Getting id */
    const rejectReasonId =
      initialData?.['rejectReason']?._id || getId(COLLECTIONS.REJECT_REASONS)

    /* Preparing newsTipStatus values */
    const preparedValues = {
      _id: rejectReasonId,
      ...additionalValues,
      title: values?.['rejectReason']?.['title'] ?? null,
      text: values?.['rejectReason']?.['text'] ?? null,
      newspaperId: values?.['newspaper']?._id ?? null,
      isBlocked: initialData?.rejectReason?.isBlocked ?? false,
      status:
        initialData?.rejectReason?.status ??
        STATUSES.REJECT_REASON_STATUSES.ACTIVE,
      sno: initialData?.rejectReason?.sno ?? null
    }
    return preparedValues
  }

  /* Saving form data */
  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = prepareValues(values)

      // Updating parent references
      if (historyState?.parent)
        await updateParent(historyState.parent, data._id)

      // Save data
      if (initialData) {
        await updateDocument(
          COLLECTIONS.REJECT_REASONS,
          initialData.rejectReason?._id,
          data
        )
        message.success(t('Reject reason successfully updated'))
      } else {
        await createDocument(COLLECTIONS.REJECT_REASONS, data, data._id)
        message.success(t('Reject reason successfully created'))
      }
      // Final callback
      callback?.()
    } catch (error) {
      throw new Error(t('Something went wrong during data save'))
    }
  }

  /* Function for validating form */
  const validateForm = (values) => {
    try {
      // Prepare data to be validated
      const validationData = {
        title: values?.['rejectReason']?.['title'],
        text: values?.['rejectReason']?.['text'],
        newspaperId: values?.['rejectReason']?.['newspaperId']
      }
      RejectReason.validationSchema.validateSync(validationData)
    } catch (error) {
      throw new Error(t('RejectReason validation error: ') + t(error.message))
    }
  }

  /* On finish callback */
  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()
      // Validate fields
      validateForm(formValues)
      // Final callback
      const callback = () =>
        history.push(PATHS.AUTHENTICATED.REJECT_REASONS_ALL)
      // Save data
      await saveForm(formValues, callback)
    } catch (error) {
      message.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  /* On cancel callback */
  const onReset = () => {
    form.resetFields()
    history.goBack()
  }

  /* Handling enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      form.submit()
    }
  }

  return {
    onFinish,
    onReset,
    loading,
    saveForm,
    validateForm,
    prepareValues,
    handleKeyPress
  }
}

export default useActionsRejectReasonAdvancedForm
