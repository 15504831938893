import firebase from 'firebase/compat/app'

/**
 * Send a password reset email to the user with the given email address.
 * @returns None
 */
const resetPassword = async ({ email, onError, onSuccess, successMessage }) => {
  try {
    await firebase.auth().sendPasswordResetEmail(email)
    onSuccess?.({ message: successMessage })
  } catch (error) {
    onError?.(error)
  }
}

export default resetPassword
