import { getDocument, useCollection } from 'services/api/firebase'
import { useEffect, useState } from 'react'
import { useEvent, useGetQuery } from 'hooks'

import { COLLECTIONS } from '__constants__'
import { createFullUserName } from 'helpers'
import { message } from 'antd'
import { useTranslations } from 'contexts/Translation'

/**
 * It returns an array of values that are used to render the rewards
 * @param props - An object that contains the query parameters.
 * @returns An array of values.
 */
const useGetRewardsOverview = (props) => {
  /* Getting translations instance */
  const { t } = useTranslations()

  /* Query for getting collection reference */
  const query = useGetQuery({
    collectionName: COLLECTIONS.REWARD_OVERVIEW,
    where: props?.where,
    orderBy: props?.orderBy,
    fieldName: 'newspaperId'
  })

  /* Getting collection data */
  const [
    rewardOverviews,
    loadingRewardOverviews,
    error,
    next,
    loadingMore,
    loadMoreAvailable
  ] = useCollection(query)

  /* Loading indicator */
  const [loading, setLoading] = useState(true)
  /* Reward overview expanded data, includes full name, reward name and store name */
  const [rewardOverviewsExpanded, setRewardOverviewsByTypesExpanded] = useState(
    []
  )

  const fetchData = useEvent(async () => {
    try {
      const rewardOverviewPromises = rewardOverviews?.map(async (data) => {
        /* Get value that should be expanded */
        const { userId, storeId, rewardId } = data

        /* Get user, store and reward data */
        const [userData, storeData, rewardData] = await Promise.all([
          getDocument(COLLECTIONS.USERS, userId),
          getDocument(COLLECTIONS.STORES, storeId),
          getDocument(COLLECTIONS.REWARDS, rewardId)
        ])

        /* Set got data to initial data */
        return {
          ...data,
          rewardName: rewardData?.name,
          fullName: createFullUserName(userData?.firstName, userData?.lastName),
          storeName: storeData?.name
        }
      })

      const promisesRewardOverview = await Promise.allSettled(
        rewardOverviewPromises
      )
      const resultRewardOverview = promisesRewardOverview
        ?.map(({ value }) => value)
        ?.filter(Boolean)

      setRewardOverviewsByTypesExpanded(resultRewardOverview)
    } catch (error) {
      message.error(t('Error during get rewards overview'))
    } finally {
      setLoading(false)
    }
  })

  useEffect(() => {
    if (!loadingRewardOverviews) {
      /* Start fetch data if there is no loading and rewardOverviews exists */
      fetchData()
    }
  }, [rewardOverviews, loadingRewardOverviews])

  return [
    rewardOverviewsExpanded,
    loading,
    error,
    next,
    loadingMore,
    loadMoreAvailable
  ]
}

export default useGetRewardsOverview
