const TOOLBAR_STYLES = {
  padding: '16px',
  display: 'flex',
  flexWrap: 'wrap',
  border: '1px solid var(--divider-color)',
  borderRadius:
    'var(--ql-border-radius-default) var(--ql-border-radius-default) 0 0',
  background: 'transparent'
}

const EDITOR_STYLES = {
  marginTop: '-4px',
  padding: '16px',
  border: '1px solid var(--divider-color)',
  borderTop: 'none',
  borderRadiusBottom: '0',
  height: '300px'
}

export { TOOLBAR_STYLES, EDITOR_STYLES }
