import { ContentState, EditorState, convertToRaw } from 'draft-js'

import { Form } from 'antd'
import { MAX_LENGTH_DESCRIPTION } from '__constants__'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

const useGeneralConfigurationSimpleForm = ({
  form,
  initialValues,
  onCancel,
  setPageConfiguration
} = {}) => {
  /* Getting translations instance */
  const { t } = useTranslations()

  /* Getting new form instance from Form.useForm hook */
  const [baseForm] = Form.useForm()

  /* Getting used form from props or using base form */
  const usedForm = form || baseForm

  /* Resetting form on cancel */
  const handleCancel = () => {
    usedForm.resetFields()
    onCancel?.()
  }
  /* Submitting form on enter key press */
  // this submit for now is useless because we don't have any inputs etc in form soo whe don't have from where to submit form
  // const handleKeyPress = (e) => {
  //   if (e.key === KEYS.ENTER) {
  //     e.preventDefault()
  //     e.stopPropagation()
  //     usedForm?.submit()
  //   }
  // }

  const editorInitialState = useMemo(() => {
    let content = htmlToDraft(initialValues?.footer?.mainText)
    content = ContentState.createFromBlockArray(
      content.contentBlocks,
      content.entityMap
    )

    return EditorState.createWithContent(content)
  }, [initialValues?.footer?.mainText])

  const updateFormData = () => {
    setPageConfiguration(usedForm?.getFieldsValue())
  }

  const validationRules = {
    logo: [
      {
        required: true,
        message: t('Please upload your newspaper logo!')
      }
    ],
    footer: [
      {
        required: true,
        message: t('Please enter footer text!')
      },
      () => ({
        validator(_, value) {
          if (
            draftToHtml(convertToRaw(value?.getCurrentContent())).length <
            MAX_LENGTH_DESCRIPTION
          ) {
            return Promise.resolve()
          } else {
            return Promise.reject(new Error(t('Footer text is too long')))
          }
        }
      })
    ]
  }

  const fieldsLayout = {
    cw: [12, 12, 12, 6, 4, 3]
  }

  return {
    fieldsLayout,
    usedForm,
    validationRules,
    editorInitialState,
    updateFormData,
    handleCancel
  }
}

export default useGeneralConfigurationSimpleForm
