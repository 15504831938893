import PropTypes from 'prop-types'
import { Text } from '@qonsoll/react-design'
import { usePhoneNumber } from 'hooks'

const PhoneNumber = (props) => {
  const { phoneNumber } = props

  const { transformPhoneNumber } = usePhoneNumber()
  const displayPhoneNumber = phoneNumber
    ? transformPhoneNumber(phoneNumber)
    : 'N/A'

  return <Text>{displayPhoneNumber}</Text>
}

PhoneNumber.propTypes = {
  phoneNumber: PropTypes.number
}

export default PhoneNumber
