import { COLLECTIONS } from '__constants__'
import { useTranslations } from '@qonsoll/translation'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { doc } from 'firebase/firestore'
import { firestore } from 'services/firebase'

const useGetLocation = (locationId) => {
  /* Getting translations instance */
  const { language } = useTranslations()

  /* Get location document */
  const [location, locationLoading] = useDocumentData(
    locationId && doc(firestore, COLLECTIONS.LOCATIONS, locationId)
  )

  /* Get object with formatted addresses */
  const languages = location?.languages

  /* Get translated address according to selected language */
  const formattedAddress = languages
    ? languages[language.toUpperCase()] || languages['NO']
    : null

  return [
    {
      ...location,
      formattedAddress
    },
    locationLoading
  ]
}

export default useGetLocation
