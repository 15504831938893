import { deleteDocument, updateDocument } from 'services/api/firebase'

import { COLLECTIONS } from '__constants__'
import PATHS from 'pages/paths'
import { message } from 'antd'
import { useEvent } from 'hooks'
import { useHistory } from 'react-router-dom'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

/* Path's */
const createPath =
  PATHS.AUTHENTICATED.NOTIFICATION_CREATE ||
  PATHS.AUTHENTICATED.NOTIFICATION_WIZARD
const editPath = PATHS.AUTHENTICATED.NOTIFICATION_EDIT
const showPath = PATHS.AUTHENTICATED.NOTIFICATION_SHOW

const useNotificationBaseActions = ({ notificationId, actions } = {}) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()

  /* Function to handle back click */
  const handleBack = useEvent(() => history.goBack())

  /* Handling opening of the create page */
  const handleCreate = useEvent(() => {
    history.push(createPath)
  })

  const handleDelete = useEvent(async () => {
    try {
      await deleteDocument(COLLECTIONS.NOTIFICATIONS, notificationId)
      message.success(t('Notification successfully deleted'))
    } catch (error) {
      message.error(error.message)
    }
  })
  /* Handling opening of the edit page or change view to edit mode */
  const handleSend = useEvent(async () => {
    await updateDocument(COLLECTIONS.NOTIFICATIONS, notificationId, {
      isResend: true
    })

    try {
      message.success(t('Notification successfully sent!'))
    } catch (error) {
      message.error(error.message)
    }
  })

  return useMemo(
    () => ({
      onBack: handleBack,
      onCreate: handleCreate,
      onDelete: handleDelete,
      onSend: handleSend,
      ...(actions || {}),
      createPath,
      editPath,
      showPath
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      actions?.onBack,
      actions?.onCreate,
      actions?.onSend,
      handleBack,
      handleSend
    ]
  )
}

export default useNotificationBaseActions
