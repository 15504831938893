import { Account, Col, Container, Row, Text } from '@qonsoll/react-design'
import { avatarLetterFromNameOrEmail, createFullUserName } from 'helpers'

import { Logo } from '../../../../../../components/BoilerplateLayout/components'
import MainMenu from '../MainMenu'
import PropTypes from 'prop-types'
import { StyledHeader } from './Header.styled'
import { useUser } from 'domains/User/context'

const Header = ({ pageConfiguration, initialValues }) => {
  const user = useUser()
  const { firstName, lastName, email } = user
  const displayName = createFullUserName(firstName, lastName)
  const avatarFirstLetter = avatarLetterFromNameOrEmail(displayName, email)
  const {
    backgroundColor,
    textColor,
    avatarBackgroundColor: abg,
    avatarTextColor
  } = pageConfiguration?.header || {}

  return (
    <StyledHeader bg={backgroundColor} abg={abg}>
      <Container bounded>
        <Row v="center" noGutters px={4}>
          <Col cw="auto" mr="auto">
            <Logo
              isPreview
              logoUrl={pageConfiguration?.app?.logoUrl}
              initialValues={initialValues}
              additionalStyles={{ borderRadius: '10px' }}
            />
          </Col>
          <Col cw="auto" className="accountWrapper">
            <Account
              avatar={user?.avatarUrl}
              avatarText={
                <Text
                  type="secondary"
                  color={avatarTextColor || 'white'}
                  isEllipsis>
                  {avatarFirstLetter}
                </Text>
              }
              title={
                <Text type="secondary" color={textColor || 'white'} isEllipsis>
                  {displayName}
                </Text>
              }
            />
          </Col>
        </Row>
      </Container>

      <MainMenu pageConfiguration={pageConfiguration} />
    </StyledHeader>
  )
}

Header.propTypes = {
  pageConfiguration: PropTypes.object,
  initialValues: PropTypes.object
}

export default Header
