import * as yup from 'yup'

import { attr, hasMany, hasOne, model } from 'services/model'

const validationSchema = yup.object().shape({
  sno: yup
    .number()
    .typeError('Field sno has wrong type')
    .default(null)
    .nullable(),
  name: yup
    .string()
    .typeError('Field name has wrong type')
    .default(null)
    .nullable(),
  totalEditors: yup
    .number()
    .typeError('Field totalEditors has wrong type')
    .default(null)
    .nullable(),
  totalTips: yup
    .number()
    .typeError('Field totalTips has wrong type')
    .default(null)
    .nullable(),
  logo: yup.string().typeError('Field logo has wrong type'),
  status: yup
    .string()
    .typeError('Field status has wrong type')
    .default(null)
    .nullable(),
  editors: yup
    .array()
    .typeError('Field editors has wrong type')
    .default(null)
    .nullable(),
  tips: yup
    .array()
    .typeError('Field tips has wrong type')
    .default(null)
    .nullable()
})

const Newspaper = model(
  'newspaper',
  {
    daysToExpiration: attr('number'),
    notificationRanges: attr('array'),
    sno: attr('number'),
    name: attr('string'),
    totalEditors: attr('number'),
    totalTips: attr('number'),
    logo: attr('string'),
    status: hasOne('newspaperStatus'),
    editors: hasMany('editor'),
    tips: hasMany('tip')
  },
  validationSchema
)

export default Newspaper
