import { useCallback, useEffect, useState } from 'react'

import { COLLECTIONS } from '__constants__'
import { getDocument } from 'services/api/firebase'

/**
 * It fetches a rewardType document from Firestore and returns it
 * @param reward - The reward object that we're getting the rewardType for.
 * @returns An array with four values. The first value is the rewardType object, and the second value is a
 * boolean that indicates whether the rewardType is loading.
 */
const useGetRewardRewardType = (reward) => {
  /* State for data */
  const [rewardType, setRewardType] = useState(null)
  /* State for loading */
  const [loading, setLoading] = useState(!!reward?.rewardTypeId)
  /* State for error */
  const [error, setError] = useState(null)

  /* Fetching data */
  const getRewardType = useCallback(async () => {
    try {
      setLoading(true)
      const document = await getDocument(
        COLLECTIONS.REWARD_TYPES,
        reward.rewardTypeId
      )
      setRewardType(document)
      setLoading(false)
    } catch (error) {
      setError(error)
    }
  }, [reward?.rewardTypeId])

  useEffect(() => {
    reward?.rewardTypeId && getRewardType()
  }, [reward?.rewardTypeId, getRewardType])

  return [rewardType, loading, error, getRewardType]
}

export default useGetRewardRewardType
