import { COLLECTIONS } from '__constants__'
import { useDocument } from 'services/api/firebase'
import { useGetNewspaper } from 'domains/Newspaper/hooks'
import { useLoading } from 'hooks'
import { useMemo } from 'react'
const useGetEmailInitialValues = (id) => {
  /* Fetching data using useDocument hook */
  const [email, emailLoading] = useDocument({
    ref: id ? COLLECTIONS.EMAILS + '/' + id : null
  })

  const [newspaper, newspaperLoading] = useGetNewspaper(email?.newspaperId)
  /* Merge all fetched data */
  const initialValues = useMemo(
    () => ({
      email,
      newspaper
    }),
    [email, newspaper]
  )

  /* Merge all loading states */
  const loadings = useMemo(
    () => (id ? [!email, emailLoading, newspaperLoading] : []),
    [id, email, newspaper, emailLoading, newspaperLoading]
  )

  /* Getting loading state using merged loadings */
  const loading = useLoading(loadings)

  return [initialValues, loading]
}

export default useGetEmailInitialValues
