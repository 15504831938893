import { message } from 'antd'
import { useTranslations } from 'contexts/Translation'

const useGetValidation = () => {
  const { t } = useTranslations()
  const checkMaxExpirationDays = (maxDays) => ({
    validator: async (_, value) => {
      const isBigValue = value > maxDays
      if (isBigValue) {
        const computedMessage = t(`Value is too big!`)
        return Promise.reject(new Error(computedMessage))
      }
    }
  })

  const checkNotificationRangesDays = (daysToExpiration) => ({
    validator: async (_, rangeDays) => {
      const isBigValue = rangeDays?.find((day) => day > daysToExpiration)

      if (isBigValue) {
        const computedMessage = `${t(
          'The expiration date is less than the  "'
        )}${isBigValue} ${t('day" of notification.')}`

        message.error(computedMessage)
        // this field not allow form submit
        return Promise.reject(new Error(computedMessage))
      }
    }
  })

  return { checkNotificationRangesDays, checkMaxExpirationDays }
}

export default useGetValidation
