import { useCollection } from 'services/api/firebase'
import { useMemo } from 'react'
import { COLLECTIONS } from '__constants__'

const useGetNewspaperWebConfig = (newspaperId) => {
  const query = useMemo(
    () =>
      newspaperId && {
        ref: COLLECTIONS.WEB_TIPPS_CONFIGURATIONS,
        where: [['newspaperId', '==', newspaperId]]
      },
    [newspaperId]
  )

  const [data, loading] = useCollection(query)

  return [data?.at(0) || null, loading]
}

export default useGetNewspaperWebConfig
