/**
 * It takes an array, removes an item from it, and then inserts it back into the array at a different
 * index
 * @param list - the array of items
 * @param startIndex - The index of the item to be moved.
 * @param endIndex - The index of the item that was dragged to.
 * @returns The result of the splice method.
 */
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  if (result?.every((item) => typeof item?.order === 'number'))
    return result.map((item, index) => ({ ...item, order: index }))

  return result
}

export default reorder
