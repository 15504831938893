import { COLLECTIONS, STATUSES } from '__constants__'
import { deleteDocument, updateDocument } from 'services/api/firebase'
import { generatePath, useHistory } from 'react-router-dom'

import PATHS from 'pages/paths'
import { message } from 'antd'
import { useEvent } from 'hooks'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

/* Path's */
const editPath = PATHS.AUTHENTICATED.SUBADMIN_EDIT
const showPath = PATHS.AUTHENTICATED.SUBADMIN_SHOW

const useSubadminBaseActions = ({
  isSuperAdmin = false,
  actions,
  isBlocked,
  status,
  ...rest
} = {}) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()

  const { USER_STATUSES } = STATUSES
  /* Computting create path*/
  const createPath = isSuperAdmin
    ? PATHS.AUTHENTICATED.ADMIN_CREATE
    : PATHS.AUTHENTICATED.SUBADMIN_CREATE

  const entityFieldNameComputed = rest['subadminId']

  const entityNameComputed = isSuperAdmin ? t('Super admin') : t('Subadmin')

  const collectionNameComputed = isSuperAdmin
    ? COLLECTIONS.SUPER_ADMINS
    : COLLECTIONS.SUBADMINS
  const pathParams = { [entityFieldNameComputed]: entityFieldNameComputed }

  /* Function to handle back click */
  const handleBack = useEvent(() => history.goBack())

  /* Handling opening of the create page */
  const handleCreate = useEvent(() => {
    history.push(createPath)
  })

  /* Handling opening of the show page */
  const handleOpen = useEvent(() => {
    const path = generatePath(showPath, pathParams)
    history.push(path)
  })

  /* Handling opening of the edit page or change view to edit mode */

  const handleDelete = useEvent(async () => {
    try {
      await deleteDocument(collectionNameComputed, entityFieldNameComputed)

      message.success(`${entityNameComputed} ${t('successfully deleted')}`)
    } catch (error) {
      message.error(error.message)
    }
  })

  // TODO on adding logc - replace this function handleBlock with db instead of constant statuses
  const handleBlock = useEvent(async () => {
    const computedStatus = isBlocked
      ? USER_STATUSES.ACTIVE
      : USER_STATUSES.BLOCKED

    try {
      await updateDocument(collectionNameComputed, entityFieldNameComputed, {
        isBlocked: !isBlocked,
        status: computedStatus
      })

      message.success(t(`${entityNameComputed} successfully ${computedStatus}`))
    } catch (error) {
      message.error(t(`Error ${computedStatus} ${entityNameComputed}`))
    }
  })

  return useMemo(
    () => ({
      onBack: handleBack,
      onCreate: handleCreate,
      onOpen: handleOpen,
      onDelete: handleDelete,
      onBlock: handleBlock,
      ...(actions || {}),
      createPath,
      editPath,
      showPath
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      actions?.onBack,
      actions?.onCreate,
      actions?.onDelete,
      actions?.onOpen,
      actions?.onBlock,
      handleBack,
      handleCreate,
      handleDelete,
      handleOpen,
      handleBlock
    ]
  )
}

export default useSubadminBaseActions
