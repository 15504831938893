import { avatarLetterFromNameOrEmail } from 'helpers'
import moment from 'moment'

const useGetNewsTipCommentSimpleViewData = (comment, author) => {
  /* Getting test that will be displayed instead avatar */
  const avatarText = avatarLetterFromNameOrEmail(
    author?.fullName,
    author?.email
  )

  /* Getting info how long ago the comment was created (19 minutes ago, 2 days ago, etc) */
  const createdAtFormatted = moment(comment?._createdAt.toDate()).fromNow()

  return {
    fullName: author?.fullName,
    avatarUrl: author?.avatarUrl,
    avatarText,
    text: comment?.text,
    createdAtFormatted
  }
}

export default useGetNewsTipCommentSimpleViewData
