import { Form } from 'antd'
import { convertEmoji } from 'domains/Reward/helpers'
import { useEffect } from 'react'

const useInitializeRewardAdvancedForm = (initialData) => {
  /* Getting form instance */
  const [form] = Form.useForm()

  /* Setting initial data */
  useEffect(() => {
    if (initialData) {
      /* Getting initial values */
      const { reward, newspaper, store } = initialData

      // Convert emoji data from unicode string if it exist
      const computedEmoji = convertEmoji(reward)

      /* Formatting date fields */
      const formValues = {
        reward: {
          sno: reward?.['sno'] ?? null,
          name: reward?.['name'] ?? null,
          points: reward?.['points'] ?? null,
          imageUrl: reward?.['imageUrl'] ?? null,
          isTotalUsesEnabled: reward?.['isTotalUsesEnabled'] ?? null,
          isVisible: !reward?.['isBlocked'] ?? null,
          isDeleted: reward?.['isDeleted'] ?? null,
          type: reward?.['type'] ?? null,
          status: reward?.['status'] ?? null,
          description: reward?.['description'] ?? null,
          direction: reward?.['direction'] ?? null,
          emoji: computedEmoji ?? null
        },
        newspaper: newspaper ?? null,
        store: store ?? null
      }

      /* Setting initial values to form instance */
      form.setFieldsValue(formValues)
    }
  }, [initialData, form])

  return { form }
}

export default useInitializeRewardAdvancedForm
