import { Card } from 'antd'
import { Img } from '@qonsoll/react-design'
import styled from 'styled-components'

const ContentCard = styled(Card)`
  height: 100%;
`
const GeneralDetailsCard = styled(Card)`
  margin-bottom: 16px;
`

const CommentsCard = styled(Card)`
  height: 100%;
  overflow: hidden;
  /* It is necessary to limit the height of the card, 360px is enough */
  max-height: 360px;

  .ant-card-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    /* Padding is added so that the scroll is displayed correctly */
    padding-right: 12px;
  }

  .ant-card-body > div:nth-child(2) {
    overflow: auto;
    /* Padding is added so that the scroll is displayed correctly */
    padding-right: 12px;
  }
`
const StyledImg = styled(Img)`
  max-height: 280px;
  object-fit: cover;
  width: 100%;
  border-radius: var(--card-radius);
`

const StyledVideo = styled.video`
  height: 280px;
  object-fit: cover;
  width: 100%;
  border-radius: var(--card-radius);
`

export { ContentCard, GeneralDetailsCard, CommentsCard, StyledImg, StyledVideo }
