import { Col, Row, Text } from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import { capitalize } from 'helpers'
import { useTranslations } from 'contexts/Translation'

const NewsTipNewsTipStatus = (props) => {
  const { isBlocked, status, noDataTextProps } = props

  /* Getting translation instance */
  const { t } = useTranslations()
  const computedStatus = isBlocked
    ? t('Blocked')
    : status && t(capitalize(status))

  return (
    <Row noGutters>
      <Col>
        {status ? (
          <Text>{computedStatus}</Text>
        ) : (
          <Text type="secondary" variant="overline" {...noDataTextProps}>
            {t('No news tip statuses have been added yet')}
          </Text>
        )}
      </Col>
    </Row>
  )
}

NewsTipNewsTipStatus.propTypes = {
  status: PropTypes.string.isRequired,
  isBlocked: PropTypes.bool,
  noDataTextProps: PropTypes.object
}

export default NewsTipNewsTipStatus
