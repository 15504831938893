const STATUSES = {
  NEWSPAPER_STATUSES: {
    ACTIVE: 'Active',
    BLOCKED: 'Blocked'
  },
  USER_STATUSES: {
    INVITED: 'Invited',
    ACTIVE: 'Active',
    BLOCKED: 'Blocked'
  },
  EDITOR_STATUSES: {
    INVITED: 'Invited',
    ACTIVE: 'Active',
    BLOCKED: 'Blocked'
  },
  NEWS_TIP_STATUSES: {
    DRAFT: 'Draft',
    PENDING: 'Pending',
    REJECTED: 'Rejected',
    APPROVED: 'Approved'
  },
  REJECT_REASON_STATUSES: {
    ACTIVE: 'Active',
    BLOCKED: 'Blocked'
  },
  REWARD_STATUSES: {
    ACTIVE: 'Active',
    BLOCKED: 'Blocked'
  },
  STORE_STATUSES: {
    ACTIVE: 'Active',
    BLOCKED: 'Blocked'
  },
  EMAIL_STATUSES: {
    ACTIVE: 'Active',
    BLOCKED: 'Blocked'
  },
  COUNTRY_STATUSES: {
    ACTIVE: 'Active',
    BLOCKED: 'Blocked'
  },

  REWARD_OVERVIEW_STATUSES: {
    REDEEMED: 'Redeemed',
    PENDING: 'Pending',
    SENT: 'Sent'
  }
}
export { STATUSES }
export default STATUSES
