import { Col, PageWrapper, Row } from '@qonsoll/react-design'
import { HeaderBreadcrumbs, PageActions, Spinner } from 'components'
import {
  useGetRejectReasonInitialValues,
  useRejectReasonBaseActions
} from 'domains/RejectReason/hooks'
import { useMemo } from 'react'
import { RejectReasonAdvancedView } from 'domains/RejectReason/components'
import { useParams } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'

const RejectReasonShowPage = () => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting params from router */
  const { rejectReasonId } = useParams()

  /* Fetching news tip data from useGetRejectReasonInitialValues hook */
  const [rejectReasonInitialValues, loading] =
    useGetRejectReasonInitialValues(rejectReasonId)

  const isBlocked = useMemo(
    () => rejectReasonInitialValues?.rejectReason?.isBlocked,
    [rejectReasonInitialValues?.rejectReason]
  )

  /* Get actions for page */
  const actions = { block: true, delete: true }
  const { onBack, onDelete, onBlock } = useRejectReasonBaseActions({
    rejectReasonId,
    actions,
    isBlocked
  })

  /* Configuring page header */
  const headingProps = {
    title: t('Reject reason detail'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  return (
    <PageWrapper
      action={
        <PageActions
          actions={actions}
          onDelete={onDelete}
          onBlock={onBlock}
          isBlocked={isBlocked}
        />
      }
      onBack={onBack}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}>
      <Row noGutters>
        {loading ? (
          <Col>
            <Spinner height="70vh" text={t('Reject reason loading')} />
          </Col>
        ) : (
          <Col cw={12}>
            <RejectReasonAdvancedView {...rejectReasonInitialValues} />
          </Col>
        )}
      </Row>
    </PageWrapper>
  )
}

export default RejectReasonShowPage
