import { NewspaperStatusFilter } from './components'
import PropTypes from 'prop-types'

const NewspaperFilter = (props) => {
  const { filterData, setFilterData } = props

  return (
    <NewspaperStatusFilter
      filterData={filterData}
      setFilterData={setFilterData}
    />
  )
}

NewspaperFilter.propTypes = {
  filterData: PropTypes.object,
  setFilterData: PropTypes.func
}

export default NewspaperFilter
