import { CardDropdown, SelectableCard } from 'components'
import { Col, Img, Row, Text, Title } from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import styles from './NewspaperSimpleView.styles'
import { useNewspaperBaseActions } from 'domains/Newspaper/hooks'
import { useTranslations } from 'contexts/Translation'

const NewspaperSimpleView = (props) => {
  const { newspaper, onSelect, index, isSelected, actions } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting base actions */
  const { onOpen, onEdit, onDelete } = useNewspaperBaseActions({
    newspaperId: newspaper?._id,
    actions
  })

  /* Handling selection of the item */
  const handleSelect = (e) => {
    e.stopPropagation()
    onSelect?.(newspaper?._id)
  }

  return (
    <CardDropdown
      onDelete={onDelete}
      onEdit={onEdit}
      onDoubleClick={onOpen}
      onClick={handleSelect}
      document={newspaper}
      index={index}
      data-testid="newspaperListItem"
      {...actions}>
      <SelectableCard isSelected={isSelected}>
        <Row noGutters {...styles.fieldRowProps}>
          <Col cw={4}>
            <Img style={styles?.imageStyles} src={newspaper?.logoUrl} />
          </Col>
          <Col>
            <Row noGutters {...styles.fieldRowProps}>
              <Col cw={12}>
                <Title level={5} {...styles.fieldTitleProps}>
                  {newspaper?.name}
                </Title>
              </Col>
            </Row>
            <Row noGutters {...styles.fieldRowProps}>
              <Col>
                <Text type="secondary">{t('Sno')}</Text>
              </Col>
              <Col cw="auto">
                <Text {...styles.fieldTitleProps}>{newspaper?.sno}</Text>
              </Col>
            </Row>
            <Row noGutters {...styles.fieldRowProps}>
              <Col>
                <Text type="secondary">{t('Status')}</Text>
              </Col>
              <Col cw="auto">
                <Text {...styles.fieldTitleProps}>{newspaper?.status}</Text>
              </Col>
            </Row>
            <Row noGutters {...styles.fieldRowProps}>
              <Col>
                <Text type="secondary">{t('Total editors')}</Text>
              </Col>
              <Col cw="auto">
                <Text {...styles.fieldTitleProps}>
                  {newspaper?.totalEditors}
                </Text>
              </Col>
            </Row>
            <Row noGutters {...styles.fieldRowProps}>
              <Col>
                <Text type="secondary">{t('Total tips')}</Text>
              </Col>
              <Col cw="auto">
                <Text {...styles.fieldTitleProps}>{newspaper?.totalTips}</Text>
              </Col>
            </Row>
          </Col>
        </Row>
      </SelectableCard>
    </CardDropdown>
  )
}

NewspaperSimpleView.propTypes = {
  newspaper: PropTypes.object,
  onSelect: PropTypes.func,
  index: PropTypes.number,
  isSelected: PropTypes.bool,
  actions: PropTypes.object
}

export default NewspaperSimpleView
