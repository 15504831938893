import { COLLECTIONS } from '__constants__'
import { useCollection } from 'services/api/firebase'
import { useGetQuery } from 'hooks'

/**
 * It returns an array of values that are used to render the newsTips
 * @param props - An object that contains the query parameters.
 * @returns An array of values.
 */
const useGetNewsTips = (props) => {
  const query = useGetQuery({
    collectionName: COLLECTIONS.NEWS_TIPS,
    where: props?.where,
    orderBy: props?.orderBy
  })

  /* Getting collection data */
  return useCollection(query)
}

export default useGetNewsTips
