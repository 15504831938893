import { Button, Title } from '@qonsoll/react-design'
import { Form, Input, message } from 'antd'
import { KEYS, MAX_LENGTH_FIELD } from '__constants__'

import { DOMAIN_NAME_PREFIX } from '../../__constants__'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const DomainConfigurationSimpleForm = (props) => {
  const {
    initialValues,
    onSubmit,
    onCancel,
    showTitle,
    form,
    group,
    handleNextStep
  } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting new form instance from Form.useForm hook */
  const [baseForm] = Form.useForm()

  /* Getting used form from props or using base form */
  const usedForm = form || baseForm

  /* Resetting form on cancel */
  const handleCancel = () => {
    usedForm.resetFields()
    onCancel?.()
  }
  /* Submitting form on enter key press */
  const handleKeyPress = async (e) => {
    try {
      if (e.key === KEYS.ENTER) {
        e.preventDefault()
        e.stopPropagation()
        // Perform validate fields function,
        // it is important to validate form before moving on to the next step (if function exist)
        await usedForm.validateFields()
        await usedForm?.submit()
        handleNextStep?.()
      }
    } catch (error) {
      const specificErrorMessage =
        error?.message || error?.errorFields?.[0]?.errors?.[0]

      message.error(t(specificErrorMessage))
    }
  }

  return group ? (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Domain setup')}
        </Title>
      )}
      <Form.Item
        label={t('Enter the name domain name for newspaper')}
        name={[...group, 'domainName']}
        rules={[
          {
            required: true,
            message: t('Please enter domain name!')
          },
          {
            max: MAX_LENGTH_FIELD,
            message: t('Domain name is too long!')
          }
        ]}>
        <Input
          addonBefore={DOMAIN_NAME_PREFIX}
          placeholder={t('Domain name')}
        />
      </Form.Item>
    </>
  ) : (
    <Form
      form={usedForm}
      initialValues={initialValues}
      onFinish={onSubmit}
      layout="vertical"
      onKeyPress={handleKeyPress}
      autoComplete="off">
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Domain')}
        </Title>
      )}
      <Form.Item
        label={t('Domain')}
        name="domainName"
        rules={[
          {
            required: true,
            message: t('Please enter your domain name!')
          },
          { max: MAX_LENGTH_FIELD, message: t('domain name is too long!') }
        ]}>
        <Input
          addonBefore={DOMAIN_NAME_PREFIX}
          placeholder={t('Please enter your domain name')}
        />
      </Form.Item>
      {!form && (
        <>
          <Button mr={2} htmlType="button" onClick={handleCancel}>
            {t('Cancel')}
          </Button>
          <Button type="primary" onClick={() => usedForm.submit()}>
            {t('Save')}
          </Button>
        </>
      )}
    </Form>
  )
}

DomainConfigurationSimpleForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  showTitle: PropTypes.bool,
  form: PropTypes.any,
  group: PropTypes.array,
  handleNextStep: PropTypes.func
}

export default DomainConfigurationSimpleForm
