import { Box } from '@qonsoll/react-design'
import styled from 'styled-components'

const ColorBoxStyled = styled(Box)`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  width: 4rem;
  height: 2rem;
  border-radius: var(--card-radius) 0 0 var(--card-radius);
  border: var(--ql-border-width-default) var(--ql-border-style-default)
    var(--input-border-color);
  border-right: none;
`

const ChevronStyled = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 0 var(--card-radius) var(--card-radius) 0;
  background-color: ${({ disabled }) =>
    disabled ? 'var(--input-disabled-bg)' : 'var(--ql-color-white-lighten6)'};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border: var(--ql-border-width-default) var(--ql-border-style-default)
    var(--input-border-color);
  border-left: none;

  & svg {
    transition: transform 0.5s;
    transform: ${({ isOpen }) => isOpen && 'rotate(180deg)'};
    fill: var(--ql-typography-text-color-secondary);
  }
`

export { ChevronStyled, ColorBoxStyled }
