import { Form } from 'antd'
import { useEffect } from 'react'

const useInitializeEditorAdvancedForm = (initialData) => {
  /* Getting form instance */
  const [form] = Form.useForm()

  /* Setting initial data */
  useEffect(() => {
    if (initialData) {
      /* Getting initial values */
      const { editor, newspaper } = initialData

      /* Formatting date fields */
      const formValues = {
        editor: {
          firstName: editor?.['firstName'] ?? null,
          lastName: editor?.['lastName'] ?? null,
          email: editor?.['email'] ?? null,
          phoneNumber: editor?.['phoneNumber'] ?? null,
          avatarUrl: editor?.['avatarUrl'] ?? null
        },
        newspaper: newspaper ?? null
      }

      /* Setting initial values to form instance */
      form.setFieldsValue(formValues)
    }
  }, [initialData, form])

  return { form }
}

export default useInitializeEditorAdvancedForm
