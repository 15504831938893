import { Box, Col, Row, Switch, Text } from '@qonsoll/react-design'
import { Collapse, Form, Tabs } from 'antd'

import { useEffect, useState } from 'react'
import {
  NewspaperExpirationMessageForm,
  NewspaperNotificationRangesSelect
} from 'domains/Newspaper/components'
import { NumericInput } from 'components'
import PropTypes from 'prop-types'
import { useGetValidation } from './hooks'
import { useTranslations } from 'contexts/Translation'
import {
  StyledAddLanguageButton,
  StyledSelect
} from './NewspaperExpirationPointForm.styled'

const { Panel } = Collapse

const EXPIRATION_CONFIG = 'expirationConfig'

const NewspaperExpirationPointForm = (props) => {
  const {
    group,
    initialValues,
    activeLanguage,
    setActiveLanguage,
    usedLanguages,
    unusedLanguages,
    onAddUsedLanguage
  } = props

  const { t } = useTranslations()

  const { checkMaxExpirationDays, checkNotificationRangesDays } =
    useGetValidation()

  const [arePointsExpiring, setArePointsExpiring] = useState(
    initialValues?.newspaper?.arePointsExpiring
  )
  const [daysToExpiration, setDaysToExpiration] = useState(
    initialValues?.newspaper?.daysToExpiration
  )
  const [activePanel, setActivePanel] = useState(false)
  const [languageForAdd, setLanguageForAdd] = useState(null)

  const handleChangeInput = (value) => setDaysToExpiration(value)

  const handleClick = (panel) =>
    panel === activePanel ? setActivePanel(false) : setActivePanel(panel)

  const handleChangeSwitch = (value) => {
    setArePointsExpiring(value)
  }

  useEffect(() => {
    arePointsExpiring
      ? setActivePanel(EXPIRATION_CONFIG)
      : setActivePanel(false)
  }, [arePointsExpiring])

  const onChangeActiveLanguage = (key) => {
    setActiveLanguage(key)
  }

  const unusedLanguagesOptions = unusedLanguages?.map((language) => ({
    label: language?.label,
    value: language?.shortCode
  }))

  const onLanguageSelectChange = (value) => {
    setLanguageForAdd(value)
  }

  const isAddLanguageButtonDisabled =
    !languageForAdd || !unusedLanguages?.length

  const isSelectDisabled = !unusedLanguages?.length

  const onAddLanguageButtonClick = () => {
    onAddUsedLanguage(languageForAdd)
    setActiveLanguage(languageForAdd)
  }

  useEffect(() => {
    unusedLanguages?.length &&
      setLanguageForAdd(unusedLanguages?.at(0)?.shortCode)
  }, [unusedLanguages, setLanguageForAdd])

  return (
    <>
      <Form.Item
        label={t('Enable points expiration')}
        name={[...group, 'arePointsExpiring']}>
        <Switch onChange={handleChangeSwitch} checked={arePointsExpiring} />
      </Form.Item>

      <Box mb={4}>
        <Collapse
          expandIconPosition="right"
          activeKey={activePanel}
          onChange={handleClick}
          collapsible>
          <Panel
            forceRender
            header={t('Expiration config')}
            key={EXPIRATION_CONFIG}>
            <Form.Item
              label={t('Enter the expiration days')}
              name={[...group, 'daysToExpiration']}
              rules={[
                {
                  required: true,
                  message: t(
                    'Please enter the expiration date of points in days!'
                  )
                },
                checkMaxExpirationDays(999)
              ]}>
              <NumericInput
                placeholder={t(
                  'Please enter the expiration date of points in days'
                )}
                handleChangeInput={handleChangeInput}
                disabled={!arePointsExpiring}
              />
            </Form.Item>
            <Form.Item
              label={t('Choose the notification ranges')}
              name={[...group, 'notificationRanges']}
              rules={[
                {
                  required: true,
                  message: t(
                    'Please choose days of notification about the expiration of points'
                  )
                },
                checkNotificationRangesDays(daysToExpiration)
              ]}>
              <NewspaperNotificationRangesSelect
                daysToExpiration={daysToExpiration}
                mode="multiple"
                disabled={!arePointsExpiring}
              />
            </Form.Item>
            <Row h="between" v="center" noGutters>
              <Col cw={6}>
                <Text>{t('Select the language you would like to add')}</Text>
                <StyledSelect
                  disabled={isSelectDisabled}
                  value={languageForAdd}
                  onChange={onLanguageSelectChange}
                  options={unusedLanguagesOptions}
                />
              </Col>
              <Col h="right" cw={6}>
                <StyledAddLanguageButton
                  onClick={onAddLanguageButtonClick}
                  disabled={isAddLanguageButtonDisabled}
                  type="primary">
                  {t('Add')}
                </StyledAddLanguageButton>
              </Col>
            </Row>
            <Tabs activeKey={activeLanguage} onChange={onChangeActiveLanguage}>
              {usedLanguages?.map((language) => (
                <Tabs.TabPane tab={language?.name} key={language?.shortCode}>
                  <NewspaperExpirationMessageForm
                    language={language}
                    group={group}
                    arePointsExpiring={arePointsExpiring}
                    key={language?.shortCode}
                    initialValues={initialValues}
                    setActiveLanguage={setActiveLanguage}
                  />
                </Tabs.TabPane>
              ))}
            </Tabs>
          </Panel>
        </Collapse>
      </Box>
    </>
  )
}
NewspaperExpirationPointForm.propTypes = {
  group: PropTypes.array,
  initialValues: PropTypes.object,
  activeLanguage: PropTypes.string,
  setActiveLanguage: PropTypes.func,
  usedLanguages: PropTypes.array,
  unusedLanguages: PropTypes.array,
  onAddUsedLanguage: PropTypes.func
}

export default NewspaperExpirationPointForm
