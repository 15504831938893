import { collection, limit, query, where } from 'firebase/firestore'

import { COLLECTIONS } from '__constants__'
import firestore from 'services/firebase/firestore'
import { orderBy } from 'lodash'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { useMemo } from 'react'

/**
 * It returns an array of values that are used to render the newsTips
 * @param props - An object that contains the query parameters.
 * @returns An array of values.
 */
const useGetNewsTipsToPreview = (newspaperId, tipsLimit) => {
  const queryConfig = useMemo(
    () => [where('newspaperId', '==', newspaperId), limit(tipsLimit)],
    [tipsLimit, newspaperId]
  )

  return useCollectionData(
    query(collection(firestore, COLLECTIONS.NEWS_TIPS), ...queryConfig)
  )
}

export default useGetNewsTipsToPreview
