import { model, attr, hasOne, hasMany } from 'services/model'
import * as yup from 'yup'

const validationSchema = yup.object().shape({
  sno: yup
    .number()
    .typeError('Field sno has wrong type')
    .default(null)
    .nullable(),
  name: yup
    .string()
    .typeError('Field name has wrong type')
    .default(null)
    .nullable(),
  email: yup
    .string()
    .typeError('Field email has wrong type')
    .default(null)
    .nullable(),
  newspaper: yup
    .string()
    .typeError('Field newspaper has wrong type')
    .default(null)
    .nullable(),
  totalTips: yup
    .number()
    .typeError('Field totalTips has wrong type')
    .default(null)
    .nullable(),
  status: yup
    .string()
    .typeError('Field status has wrong type')
    .default(null)
    .nullable(),
  tips: yup
    .array()
    .typeError('Field tips has wrong type')
    .default(null)
    .nullable()
})

const Editor = model(
  'editor',
  {
    sno: attr('number'),
    firstName: attr('string'),
    lastName: attr('string'),
    email: attr('string'),
    phone: attr('string'),
    newspaper: hasOne('newspaper'),
    totalTips: attr('number'),
    status: hasOne('editorStatus'),
    tips: hasMany('tip')
  },
  validationSchema
)

export default Editor
