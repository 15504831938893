import { cloneElement, useMemo } from 'react'

import { MOMENT_FORMATS } from '__constants__'
import { NotificationReceiversType } from 'domains/Notification/components'
import { TableActions } from 'components'
import { Text } from '@qonsoll/react-design'
import moment from 'moment'
import { sortByAlphabet } from 'helpers'
import { useGetColumnSearchProps } from 'hooks'
import { useNotificationBaseActions } from 'domains/Notification/hooks'
import { useTranslations } from '@qonsoll/translation'

const NotificationTableActions = ({ notificationId, isResend }) => {
  const actions = useNotificationBaseActions({ notificationId })

  return cloneElement(<TableActions />, {
    actions,
    isResend,
    name: 'notification'
  })
}

const useNotificationTableColumns = () => {
  const { t } = useTranslations()

  const getColumnSearchProps = useGetColumnSearchProps()

  return useMemo(
    () => [
      {
        title: t('Sno'),
        width: 'var(--table-number-column-width)',
        dataIndex: 'sno',
        key: 'sno',
        align: 'center',
        sorter: (a, b) => a?.sno - b?.sno,
        ...getColumnSearchProps('sno')
      },
      {
        title: t('Title'),
        dataIndex: 'name',
        key: 'name',
        align: 'center',
        sorter: (a, b) => sortByAlphabet(a?.name, b?.name),
        ...getColumnSearchProps('name')
      },
      {
        title: t('Receivers group'),
        width: 'var(--table-date-column-width)',
        dataIndex: 'receiversType',
        key: ' v',
        align: 'center',
        sorter: (a, b) => sortByAlphabet(a?.receiversType, b?.receiversType),
        render: (_, record) => (
          <NotificationReceiversType receiversType={record?.receiversType} />
        )
      },
      {
        title: t('Added on'),
        width: 'var(--table-date-column-width)',
        dataIndex: '_createdAt',
        key: '_createdAt',
        align: 'center',
        sorter: (a, b) => a?._createdAt?.seconds - b?._createdAt?.seconds,
        render: (date) => (
          <Text>
            {moment
              .unix(date?.seconds)
              .format(MOMENT_FORMATS.MONTH_DAYS_COMMA_YEAR)}
          </Text>
        )
      },
      {
        title: t('Actions'),
        width: 'var(--table-actions-column-width)',
        dataIndex: 'actions',
        key: 'actions',
        align: 'center',
        render: (_, record) => (
          <NotificationTableActions
            notificationId={record?._id}
            isResend={record?.isResend}
          />
        )
      }
    ],
    [t, getColumnSearchProps]
  )
}

export default useNotificationTableColumns
