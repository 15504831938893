import { Button, Img, Text } from '@qonsoll/react-design'
import {
  CloseWrapper,
  GDPRPopupWrapper,
  ContentWrapper
} from './GDPRPopup.styles'
import PATHS from 'pages/paths'
import cookie from 'assets/cookie.png'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'
import { Icon } from '@qonsoll/icons'
import { LS } from '__constants__'

const GDPRPopup = () => {
  const { t } = useTranslations()
  const history = useHistory()
  const isAcceptedGDPR = JSON.parse(window?.localStorage.getItem(LS.GDPR))

  const [accepted, setAccepted] = useState(isAcceptedGDPR)
  const [visible, setVisible] = useState(true)

  const redirectToTheCookiesPolicies = () => history.push(PATHS.SERVICE.GDPR)
  const handleAccept = () => {
    localStorage.setItem(LS.GDPR, 'true')
    setAccepted(true)
  }

  const handleClose = () => {
    /* Close popup if user clicks on close button, but show popup again if user refresh page */
    setVisible(false)
  }

  return accepted || !visible ? null : (
    <GDPRPopupWrapper>
      <CloseWrapper onClick={handleClose}>
        <Button
          size="small"
          className="btn-icon-stroke"
          type="text"
          icon={<Icon name="XCloseOutlined" />}
        />
      </CloseWrapper>
      <ContentWrapper>
        <Img src={cookie} width="64" height="64" alt={t('cookie')} mb={16} />
        <Text textAlign="center" mb={24}>
          {t('Application uses cookies to personalize content')}.{' '}
          {t(
            'By continuing of using this site, you agree with our cookie policy'
          )}
          .
        </Text>
        <Button block onClick={redirectToTheCookiesPolicies} mb={16}>
          {t('Cookie policy')}
        </Button>
        <Button type="primary" block onClick={handleAccept}>
          {t('Accept')}
        </Button>
      </ContentWrapper>
    </GDPRPopupWrapper>
  )
}

export default GDPRPopup
