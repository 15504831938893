import { Col, PageWrapper, Row } from '@qonsoll/react-design'
import { HeaderBreadcrumbs, Spinner } from 'components'
import { useParams } from 'react-router-dom'

import { NewspaperAdvancedForm } from 'domains/Newspaper/components'
import {
  useGetNewspaperInitialValues,
  useNewspaperBaseActions
} from 'domains/Newspaper/hooks'
import { useTranslations } from 'contexts/Translation'

const NewspaperEditPage = (props) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting params from router */
  const { newspaperId } = useParams()

  /* Fetching newspaper initial values */
  const [initialValues, loading] = useGetNewspaperInitialValues(newspaperId)
  /* Getting base actions */
  const { onBack } = useNewspaperBaseActions()

  /* Configuring page header */
  const headingProps = {
    title: t('Update newspaper'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  return (
    <PageWrapper
      onBack={onBack}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}>
      <Row noGutters>
        {loading ? (
          <Col>
            <Spinner height="70vh" text={t('Newspaper loading')} />
          </Col>
        ) : (
          <Col cw={[12, 12, 10, 8, 7]}>
            <NewspaperAdvancedForm initialData={initialValues} />
          </Col>
        )}
      </Row>
    </PageWrapper>
  )
}

export default NewspaperEditPage
