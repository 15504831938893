import { Button, Title } from '@qonsoll/react-design'
import {
  useActionsRejectReasonAdvancedForm,
  useInitializeRejectReasonAdvancedForm
} from 'domains/RejectReason/components/RejectReasonAdvancedForm/hooks'

import { Form } from 'antd'
import { NewspaperSelect } from 'domains/Newspaper/components'
import PropTypes from 'prop-types'
import { RejectReasonSimpleForm } from 'domains/RejectReason/components'
import { useCheckChangeFormValue } from 'hooks'
import { useTranslations } from 'contexts/Translation'

const RejectReasonAdvancedForm = (props) => {
  const { initialData, group, showTitle } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting form instance from hook */
  const { form } = useInitializeRejectReasonAdvancedForm(initialData)

  /* Getting loading state and actions from hook */
  const { loading, onFinish, onReset, handleKeyPress } =
    useActionsRejectReasonAdvancedForm({
      initialData,
      form
    })

  const { triggerValueChange, isEqualForms } =
    useCheckChangeFormValue(initialData)

  const isBlockedNewspaper = !!initialData?.newspaper?._id

  return group ? (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Reject reason')}
        </Title>
      )}
      <RejectReasonSimpleForm
        showTitle={false}
        group={[...group, 'rejectReason']}
      />
    </>
  ) : (
    <Form
      onKeyPress={handleKeyPress}
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
      onValuesChange={triggerValueChange}
      form={form}>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Reject reason')}
        </Title>
      )}
      <RejectReasonSimpleForm group={['rejectReason']} />
      <Form.Item
        name={['newspaper']}
        rules={[
          {
            required: true,
            message: t('Please select newspaper associated!')
          }
        ]}>
        <NewspaperSelect disabled={isBlockedNewspaper} />
      </Form.Item>
      <Button mr={2} htmlType="button" onClick={onReset}>
        {t('Cancel')}
      </Button>
      <Button
        type="primary"
        loading={loading}
        onClick={() => form.submit()}
        disabled={isEqualForms}>
        {t('Save')}
      </Button>
    </Form>
  )
}

RejectReasonAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  group: PropTypes.array,
  showTitle: PropTypes.bool
}

export default RejectReasonAdvancedForm
