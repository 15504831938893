import styled from 'styled-components'
import { Modal } from 'antd'

export const StyledModal = styled(Modal).attrs({ width: '420px' })`
  .ant-modal-body {
    height: 50vh;
    display: flex;
    flex-direction: column;
    padding: 24px;
  }

  .ant-modal-header {
    color: var(--ql-color-white);
    padding-left: var(--modal-header-close-size);
    background-color: var(--ql-color-accent1);
  }

  .ant-modal-close {
    left: 0 !important;
    right: unset;
    color: var(--ql-color-white);
  }

  .ant-modal-footer {
    background-color: var(--btn-default-bg);
    color: var(--ql-color-white);
    justify-content: flex-end;
  }

  position: absolute;
  top: 250px;
  right: 64px;
`
export const StyledFilter = styled.div`
  overflow: auto;

  ::-webkit-scrollbar {
    width: hidden;
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    visibility: hidden;
  }
  ::-webkit-scrollbar-thumb {
    visibility: hidden;
  }
  ::-webkit-scrollbar-button {
    visibility: hidden;
  }
  &:hover {
    ::-webkit-scrollbar {
      visibility: visible;
      width: 4px;
      height: 4px;
    }
    ::-webkit-scrollbar-track {
      visibility: visible;
      background-color: transparent;
      border-radius: 100px;
    }
    ::-webkit-scrollbar-thumb {
      visibility: visible;
      border-radius: 100px;
      background-image: linear-gradient(
        to bottom,
        rgba(71, 118, 230, 0.2) 0%,
        rgba(142, 84, 233, 0.2) 51%,
        rgba(71, 118, 230, 0.2) 100%
      );
      box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
    }
    ::-webkit-scrollbar-button {
      visibility: visible;
      background: transparent;
    }
  }

  summary {
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 0;
    margin-bottom: 8px;
  }
  details {
    margin-bottom: 12px;
  }
  .ant-checkbox-wrapper {
    margin-left: 16px;
    display: flex;
    margin-bottom: 2px;
  }
`
export const okButtonStyle = { display: 'none' }
