import { Col, Row } from '@qonsoll/react-design'
import {
  HeaderBreadcrumbs,
  PageActions,
  PageWrapperActionsUnderTitle,
  Spinner
} from 'components'
import { NewsTipFilter, NewsTipTable } from 'domains/NewsTip/components'
import { useFilter, useGetCustomDataSource } from 'hooks'
import { useGetNewsTips, useNewsTipBaseActions } from 'domains/NewsTip/hooks'
import { useMemo, useState } from 'react'

import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const NewsTipAllPage = (props) => {
  const { withHeader = true } = props

  const [collection, setCollection] = useState()
  /* Getting translations instance */
  const { t } = useTranslations()

  /* Getting filter params */
  const filterParams = useFilter('newsTip')

  /* Destructuring filter params */
  const { filterVisibility, filterData, setFilterVisibility } = filterParams

  /* Building filter query */
  const ref = useMemo(() => filterData, [filterData])

  /* Fetching newsTips */
  const [newsTips, loading] = useGetNewsTips(ref)

  const [customDataSource, customDataSourceLoading] = useGetCustomDataSource(
    newsTips,
    {
      fetchUserData: true
    }
  )

  /* Getting base actions */
  const { onBack, onCreate } = useNewsTipBaseActions()
  /* Configuring page header */
  const headingProps = {
    title: t('News Tips'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  /* Configuring page actions */
  const actions = {
    filter: true,
    search: true
  }

  return (
    <PageWrapperActionsUnderTitle
      action={
        withHeader ? (
          <PageActions
            {...filterParams}
            onCreate={onCreate}
            actions={actions}
            filterMenu={<NewsTipFilter {...filterParams} />}
            filterVisibility={filterVisibility}
            setFilterVisibility={setFilterVisibility}
            searchPlaceholder="Search by Title"
            collection={customDataSource}
            setCollection={setCollection}
            searchField="title"
            filterData={filterData}
          />
        ) : null
      }
      onBack={withHeader ? onBack : null}
      breadcrumbs={withHeader ? <HeaderBreadcrumbs /> : null}
      headingProps={withHeader ? headingProps : null}>
      <Row noGutters>
        {loading || customDataSourceLoading ? (
          <Col>
            <Spinner height="60vh" text={t('News tip loading')} />
          </Col>
        ) : (
          <Col cw={12}>
            <NewsTipTable dataSource={collection ?? customDataSource} />
          </Col>
        )}
      </Row>
    </PageWrapperActionsUnderTitle>
  )
}

NewsTipAllPage.propTypes = {
  isListWithCreate: PropTypes.bool,
  withHeader: PropTypes.bool
}

export default NewsTipAllPage
