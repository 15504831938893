import { COLLECTIONS } from '__constants__'
import { createFullUserName } from 'helpers'
import { useCollection } from 'services/api/firebase'
import { useMemo } from 'react'

const useGetNewspaperEditorsAll = (props) => {
  /* Query for getting collection reference */
  const query = useMemo(
    () => ({
      ref: COLLECTIONS.EDITORS,
      orderBy: [['_createdAt', 'desc']],
      ...props
    }),
    [props]
  )

  const [newspaperEditors, loading, error] = useCollection(query)

  const transformedEditorsMap = useMemo(
    () =>
      newspaperEditors?.map(({ firstName, lastName, ...rest }) => ({
        ...rest,
        firstName,
        lastName,
        fullName: createFullUserName(firstName, lastName)
      })),
    [newspaperEditors]
  )

  return [transformedEditorsMap, loading, error]
}

export default useGetNewspaperEditorsAll
