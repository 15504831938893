import { COLLECTIONS, ENDPOINTS, MOMENT_FORMATS } from '__constants__'
import { getBlob, getMetadata, ref } from 'firebase/storage'

import { capitalize } from 'helpers'
import { message } from 'antd'
import moment from 'moment'
import { storage } from 'services/firebase'
import { useEvent } from 'hooks'
import { useState } from 'react'
import { useTranslations } from '@qonsoll/translation'

const FIREBASE_FUNCTIONS_API_URL =
  process.env.REACT_APP_FIREBASE_FUNCTIONS_API_URL

const useExportUsersCSV = () => {
  const { t } = useTranslations()
  const [loading, setLoading] = useState(false)

  const downloadFileToDevice = (blob, fileFormat, title) => {
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.style.display = 'none'

    link.href = url

    link.setAttribute('download', `${title}.${fileFormat}`)

    // Append to html link element page
    document.body.appendChild(link)

    // Start download
    link.click()

    // Clean up and remove the link
    link.parentNode.removeChild(link)
  }

  const handleExportCSV = useEvent(async () => {
    try {
      setLoading(true)
      const response = await fetch(
        FIREBASE_FUNCTIONS_API_URL + ENDPOINTS.GET_USERS_CSV,
        {
          method: 'GET',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      if (response?.ok) {
        const responseJSON = await response.json()

        const downloadUrl = responseJSON?.data?.fileUrl

        const reference = ref(storage, downloadUrl)
        const blob = await getBlob(reference)
        const metadata = await getMetadata(reference)
        const fileFormat = metadata?.contentType?.split('/').at(-1)

        const currentDateAndTime = moment()
          .format(MOMENT_FORMATS.FILE_DATE_FORMATS)
          ?.split(' ')
          ?.join(' at ')

        const fileTitle = [
          capitalize(COLLECTIONS.USERS),
          currentDateAndTime
        ]?.join(' ')

        downloadFileToDevice(blob, fileFormat, fileTitle)
        message.success(t('CSV with users was successfully saved to device'))
      } else {
        message.error(t('Error while creating users CSV file'))
      }
    } catch (e) {
      message.error(t('Error while creating users CSV file'))
    } finally {
      setLoading(false)
    }
  })

  return { handleExportCSV, exportCSVLoading: loading }
}

export default useExportUsersCSV
