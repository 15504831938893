import { message } from 'antd'
import { useTranslations } from '@qonsoll/translation'
import { useState } from 'react'
import { useEvent } from 'hooks'
import { remove, ref } from 'firebase/database'
import { database } from 'services/firebase'
import { RDB_COLLECTIONS } from '__constants__'
import { AVAILABLE_APPS } from 'domains/Translation/__constants__'

const useRemoveLanguage = () => {
  const { t } = useTranslations()

  const [removeLanguageLoading, setRemoveLanguageLoading] = useState(false)

  const handleRemoveLanguage = useEvent(async (language) => {
    try {
      setRemoveLanguageLoading(true)

      const { shortCode } = language

      const removeLanguagePromise = remove(
        ref(database, `${RDB_COLLECTIONS.LANGUAGES}/${shortCode}`)
      )

      const removeTranslationLoadingPromise = remove(
        ref(database, `${RDB_COLLECTIONS.TRANSLATIONS_LOADING}/${shortCode}`)
      )

      const removeTranslationsPromises = Object.values(AVAILABLE_APPS).map(
        (appName) =>
          remove(
            ref(
              database,
              `${RDB_COLLECTIONS.TRANSLATIONS}/${appName}/${shortCode}`
            )
          )
      )

      await Promise.all([
        removeLanguagePromise,
        removeTranslationLoadingPromise,
        ...removeTranslationsPromises
      ])

      message.success(t('Language was successfully deleted'))
    } catch (error) {
      message.error(t('Error removing language'))
    } finally {
      setRemoveLanguageLoading(false)
    }
  })

  return { handleRemoveLanguage, removeLanguageLoading }
}

export default useRemoveLanguage
