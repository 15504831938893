import { Badge } from 'antd'
import { Button } from '@qonsoll/react-design'
import styled from 'styled-components'

const BadgeStyled = styled(Badge)`
  margin-right: 12px;

  .ant-badge-count {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--ql-color-accent2) !important;
    border: 1px solid var(--ql-color-grey);
  }
  button {
    box-shadow: var(--btn-circle-box-shadow);
  }
`

const CreateButtonStyled = styled(Button)`
  box-shadow: var(--btn-circle-box-shadow);
`

export { BadgeStyled, CreateButtonStyled }
