import styled from 'styled-components'

const EditorEmailWrapper = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
`

const iconStyles = { size: 16, fill: 'var(--ql-typography-text-color-primary)' }

export { EditorEmailWrapper, iconStyles }
