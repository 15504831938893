import { COLLECTIONS, STATUSES, STORAGE_PATH } from '__constants__'
import {
  createDocument,
  getId,
  updateDocument,
  updateParent
} from 'services/api/firebase'
import { deleteReplacedFile, uploadImage } from 'helpers'

import { Store } from 'models'
import { message } from 'antd'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const useActionsStoreAdvancedForm = ({ initialData, form } = {}) => {
  /* State for loading */
  const [loading, setLoading] = useState(false)
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()
  /* Getting history state */
  const historyState = history.location.state

  /* Function for preparing values for saving */
  const prepareValues = async (values = {}, additionalValues = {}) => {
    /* Getting id */
    const storeId = initialData?.['store']?._id || getId(COLLECTIONS.STORES)

    const isBlockedStore =
      values?.store?.status === STATUSES.STORE_STATUSES.BLOCKED
    /* Preparing store values */
    const preparedValues = {
      _id: storeId,
      ...additionalValues,
      // try/catch is not necessary here, because we have it in function
      logoUrl: await uploadImage(values?.store?.logoUrl, STORAGE_PATH.STORE),
      name: values?.store?.name ?? null,
      description: values?.store.description ?? null,
      isBlocked: isBlockedStore ?? false,
      redeemed: initialData?.store?.redeemed ?? 0,
      totalCoupons: initialData?.store?.totalCoupons ?? 0,
      newspaperId: values?.newspaper?._id ?? null,
      status: values?.store?.status ?? null,
      sno: initialData?.store?.sno ?? null
    }
    return preparedValues
  }

  /* Saving form data */
  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)

      if (historyState?.parent)
        await updateParent(historyState.parent, data._id)

      // Save data
      if (initialData) {
        await updateDocument(COLLECTIONS.STORES, initialData.store?._id, data)

        await deleteReplacedFile(initialData?.store?.logoUrl, data?.logoUrl)

        message.success(t('Store successfully updated'))
      } else {
        await createDocument(COLLECTIONS.STORES, data, data._id)
        message.success(t('Store successfully created'))
      }
      // Final callback
      callback?.()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Something went wrong during data save'))
    }
  }

  /* Function for validating form */
  const validateForm = (values) => {
    try {
      // Prepare data to be validated
      const validationData = {
        name: values?.['store']?.['name'],
        totalCoupons: values?.['store']?.['totalCoupons']
      }
      Store.validationSchema.validateSync(validationData)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Store validation error: ') + t(error.message))
    }
  }

  /* On finish callback */
  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()
      // Validate fields
      validateForm(formValues)
      // Final callback
      const callback = () => history.goBack()
      // Save data
      await saveForm(formValues, callback)
    } catch (error) {
      setLoading(false)
      message.error(error.message)
    }
  }

  /* On cancel callback */
  const onReset = () => {
    form.resetFields()
    history.goBack()
  }

  return { onFinish, onReset, loading, saveForm, validateForm, prepareValues }
}

export default useActionsStoreAdvancedForm
