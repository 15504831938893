import { Box, Col, Row } from '@qonsoll/react-design'
import { EditorFilter, EditorTable } from 'domains/Editor/components'
import { PageActions, PageWrapperActionsUnderTitle, Spinner } from 'components'
import { useMemo, useState } from 'react'

import PropTypes from 'prop-types'
import { useFilter } from 'hooks'
import { useGetNewspaperEditorsAll } from 'domains/Newspaper/hooks'
import { useParams } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'

const NewspaperEditors = () => {
  const [filteredEditorCollection, setFilteredEditorCollection] = useState()

  const { t } = useTranslations()
  /* Getting filter params */
  const filterParams = useFilter('newspaperEditors')
  const { newspaperId } = useParams()

  /* Destructuring filter params */
  const { filterVisibility, filterData, setFilterVisibility } = filterParams

  const ref = useMemo(
    () => ({
      where: [['newspaperId', '==', newspaperId], ...(filterData?.where || [])]
    }),
    [newspaperId, filterData]
  )
  const [editors, loading] = useGetNewspaperEditorsAll(ref)
  const actions = {
    search: true,
    filter: true
  }
  return (
    <PageWrapperActionsUnderTitle
      action={
        <PageActions
          {...filterParams}
          actions={actions}
          searchPlaceholder="Search by Name"
          searchField="fullName"
          collection={editors}
          setCollection={setFilteredEditorCollection}
          circle
          filterMenu={<EditorFilter {...filterParams} hiddenNewspaperFilter />}
          filterVisibility={filterVisibility}
          setFilterVisibility={setFilterVisibility}
        />
      }>
      <Row noGutters>
        <Col cw={12}>
          {loading ? (
            <Box height="30vh">
              <Spinner flex={1} text={t('Editors loading')} />
            </Box>
          ) : (
            <EditorTable
              currentNewspaperEditorsOnly
              dataSource={filteredEditorCollection ?? editors}
            />
          )}
        </Col>
      </Row>
    </PageWrapperActionsUnderTitle>
  )
}

NewspaperEditors.propTypes = {
  newspaper: PropTypes.object,
  actions: PropTypes.object
}

export default NewspaperEditors
