import { COLLECTIONS, STATUSES } from '__constants__'
import {
  createDocument,
  getId,
  updateDocument,
  updateParent
} from 'services/api/firebase'
import { useMemo, useState } from 'react'

import { message } from 'antd'
import { useGetCountries } from 'domains/Country/hooks'
import { useHistory } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'

const useActionsCountryAdvancedForm = ({
  initialData,
  form,
  isSuperAdmin,
  newspaperId = null
} = {}) => {
  /* Get countries */
  const [countries, countriesLoading] = useGetCountries()

  // Get all exist countries
  const computedCountry = useMemo(
    () => countries?.map(({ internationalName }) => internationalName),
    [countries]
  )

  const checkIsCountryExist = useMemo(
    () => (country) => {
      if (initialData?.country?.internationalName === country) return false

      return computedCountry?.find((item) => item === country)
    },
    [computedCountry]
  )

  /* State for loading */
  const [loading, setLoading] = useState(false)

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Getting history instance */
  const history = useHistory()

  /* Getting history state */
  const historyState = history.location.state

  /* Function for preparing values for saving */
  const prepareValues = async (values = {}, additionalValues = {}) => {
    /* Getting id */
    const countryId = initialData?.country?._id || getId(COLLECTIONS.COUNTRIES)

    const languagesShortCode = Object.values(values?.languages)
      ?.map((item) => item?.shortCode)
      ?.filter(Boolean)

    let languageKeys = languagesShortCode?.map((shortCode) => [shortCode, true])
    languageKeys = Object.fromEntries(languageKeys) ?? {}

    /* Preparing country values */
    const preparedValues = {
      _id: countryId,
      ...additionalValues,
      internationalName: values?.country?.internationalName ?? null,
      sno: initialData?.country?.sno ?? null,
      isBlocked: initialData?.country?.isBlocked ?? false,
      status: initialData?.country?.status ?? STATUSES.COUNTRY_STATUSES.ACTIVE,
      languagesOrdered: languagesShortCode ?? [],
      languageKeys: languageKeys ?? {}
    }
    return preparedValues
  }

  /* Saving form data */
  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)

      if (historyState?.parent)
        await updateParent(historyState.parent, data._id)

      // Save data
      if (initialData) {
        await updateDocument(
          COLLECTIONS.COUNTRIES,
          initialData.country?._id,
          data
        )
        message.success(t('Country successfully updated'))
      } else {
        await createDocument(COLLECTIONS.COUNTRIES, data, data._id)
        message.success(t('Country successfully created'))
      }
      // Final callback
      callback?.()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Something went wrong during data save'))
    }
  }

  /* On finish callback */
  const onFinish = async () => {
    if (loading || countriesLoading) return // Avoid multiple calls

    try {
      setLoading(true)

      // Get form values
      const formValues = form.getFieldsValue()

      // Check of country exist in system
      const isCountryAlreadyExist = checkIsCountryExist(
        formValues?.country?.internationalName
      )

      if (isCountryAlreadyExist) {
        message.error(t('This country already exists, please enter another!'))
        return
      }

      // Final callback
      const callback = () => history.goBack()

      // Save data
      await saveForm(formValues, callback)
    } catch (error) {
      message.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  /* On cancel callback */
  const onReset = () => {
    form.resetFields()
    history.goBack()
  }

  return { onFinish, onReset, loading, saveForm, prepareValues }
}

export default useActionsCountryAdvancedForm
