import { Col, Row, Card } from '@qonsoll/react-design'
import { Tabs } from 'antd'
import { useStateWithStorage } from 'hooks'
import { useTranslations } from '@qonsoll/translation'
import { ContentForm } from 'domains/Content/components'
import { CONTENT_TYPES } from 'domains/Content/constants'
const { TabPane } = Tabs

const ContentAdvancedView = () => {
  /* State for active tab control */
  const [lastActiveTab, setLastActiveTab] = useStateWithStorage(
    CONTENT_TYPES.ABOUT_US,
    `content-last-active-tab`
  )
  /* Getting translations instance */
  const { t } = useTranslations()

  /* Callback function to handle tab change */
  const onChangeTab = (key) => setLastActiveTab(key)

  /* Tab items */
  const tabsData = [
    {
      title: t('About Us'),
      key: CONTENT_TYPES.ABOUT_US,
      component: <ContentForm contentType={CONTENT_TYPES.ABOUT_US} />
    },
    {
      title: t('Terms of Service'),
      key: CONTENT_TYPES.TERMS_OF_SERVICE,
      component: <ContentForm contentType={CONTENT_TYPES.TERMS_OF_SERVICE} />
    },
    {
      title: t('Privacy Policy'),
      key: CONTENT_TYPES.PRIVACY_POLICY,
      component: <ContentForm contentType={CONTENT_TYPES.PRIVACY_POLICY} />
    }
  ]

  return (
    <Row mx="var(--ql-grid-gutter-negative)" mb={2}>
      <Col cw={12}>
        <Card mb={2}>
          <Tabs
            defaultActiveKey={lastActiveTab}
            activeKey={lastActiveTab}
            onChange={onChangeTab}>
            {tabsData.map(({ title, key, component }) => (
              <TabPane tab={title} key={key}>
                {component}
              </TabPane>
            ))}
          </Tabs>
        </Card>
      </Col>
    </Row>
  )
}

export default ContentAdvancedView
