import { COLLECTIONS, STATUSES, STORAGE_PATH } from '__constants__'
import {
  createDocument,
  getId,
  updateDocument,
  updateParent
} from 'services/api/firebase'
import { deleteReplacedFile, uploadImage } from 'helpers'
import { useHistory, useParams } from 'react-router-dom'

import { Newspaper } from 'models'
import { message } from 'antd'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const useActionsNewspaperAdvancedForm = ({ initialData, form } = {}) => {
  /* State for loading */
  const [loading, setLoading] = useState(false)
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()
  /* Getting params instance */
  const params = useParams()
  /* Getting history state */
  const historyState = history.location.state

  /* Function for preparing values for saving */
  const prepareValues = async (values = {}, additionalValues = {}) => {
    /* Getting id */
    const newspaperId =
      initialData?.['newspaper']?._id || getId(COLLECTIONS.NEWSPAPERS)

    const countries = values?.newspaper?.countries

    const computedCountries = countries?.length
      ? Object.fromEntries(
          countries
            ?.map((countryId) => (countryId ? [countryId, true] : null))
            ?.filter(Boolean)
        )
      : {}

    /* Preparing newspaper values */

    const preparedValues = {
      _id: newspaperId,
      ...additionalValues,
      // try/catch is not necessary here, because we have it in function
      logoUrl:
        (await uploadImage(
          values?.newspaper?.logoUrl,
          STORAGE_PATH.NEWSPAPER
        )) ?? null,
      name: values?.newspaper?.name ?? null,
      description: values?.newspaper?.description ?? null,
      isBlocked: initialData?.newspaper?.isBlocked ?? false,
      status:
        initialData?.newspaper?.status ?? STATUSES?.NEWSPAPER_STATUSES?.ACTIVE,
      totalTips: initialData?.newspaper?.totalTips ?? 0,
      totalEditors: initialData?.newspaper?.totalEditors ?? 0,
      sno: initialData?.newspaper?.sno ?? null,
      countries: computedCountries ?? {}
    }
    if (params.editorId) preparedValues['editorId'] = params.editorId
    return preparedValues
  }

  /* Saving form data */
  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)

      // Updating parent references
      // if(params.editorId) await updateParentHasOneRelationship (params.editorId, COLLECTIONS.EDITORS, 'newspaper', data._id)
      if (historyState?.parent)
        await updateParent(historyState.parent, data._id)

      // Save data
      if (initialData) {
        // Delete replaced image
        try {
          await deleteReplacedFile(
            initialData?.newspaper?.logoUrl,
            data?.logoUrl
          )
        } catch (error) {
          console.error(t('Error deleting image'), error)
        }

        await updateDocument(
          COLLECTIONS.NEWSPAPERS,
          initialData.newspaper?._id,
          data
        )

        message.success(t('Newspaper successfully updated'))
      } else {
        await createDocument(COLLECTIONS.NEWSPAPERS, data, data._id)
        message.success(t('Newspaper successfully created'))
      }
      // Final callback
      callback?.()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Something went wrong during data save'))
    }
  }

  /* Function for validating form */
  const validateForm = (values) => {
    try {
      // Prepare data to be validated
      const validationData = {
        name: values?.newspaper?.name,
        description: values?.newspaper?.description,
        logoUrl: values?.newspaper?.logoUrl
      }
      Newspaper.validationSchema.validateSync(validationData)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Newspaper validation error: ') + error.message)
    }
  }

  /* On finish callback */
  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()
      // Validate fields
      validateForm(formValues)
      // Final callback
      const callback = () => history.goBack()
      // Save data
      await saveForm(formValues, callback)
    } catch (error) {
      setLoading(false)
      message.error(error.message)
    }
  }

  /* On cancel callback */
  const onReset = () => {
    form.resetFields()
    history.goBack()
  }

  return { onFinish, onReset, loading, saveForm, validateForm, prepareValues }
}

export default useActionsNewspaperAdvancedForm
