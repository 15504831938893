import { Img } from '@qonsoll/react-design'
import styled from 'styled-components'

const ImageStyled = styled(Img)`
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-radius: var(--card-radius);
`

export { ImageStyled }
