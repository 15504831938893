import { REWARD_OVERVIEW_TYPES } from 'domains/RewardOverview/constants'

/**
 * It returns an array of values that are used to render the rewardTypes
 * @param props - An object that contains the query parameters.
 * @returns An array of values.
 */
const useGetRewardTypes = (props) => {
  // TODO remove this code after 09.01.2023, if there will not
  // be necessity to make dynamic reward types
  // /* Query for getting collection reference */
  // const query = useMemo(
  //   () => ({ ref: COLLECTIONS.REWARD_TYPES, ...props }),
  //   [props]
  // )
  // /* Getting collection data */
  // return useCollection(query)

  return [Object.keys(REWARD_OVERVIEW_TYPES)]
}

export default useGetRewardTypes
