import { MOMENT_FORMATS, STATUSES } from '__constants__'
import { cloneElement, useMemo } from 'react'

import { NewspaperLink } from 'domains/Newspaper/components'
import { TableActions } from 'components'
import { Text } from '@qonsoll/react-design'
import moment from 'moment'
import { sortByAlphabet } from 'helpers'
import { useGetColumnSearchProps } from 'hooks'
import { useSubadminBaseActions } from 'domains/Subadmin/hooks'
import { useTranslations } from '@qonsoll/translation'

const SubadminTableActions = ({
  subadminId,
  isBlocked,
  isSuperAdmin,
  status
}) => {
  const actions = useSubadminBaseActions({
    status,
    subadminId,
    isBlocked,
    isSuperAdmin
  })

  const { USER_STATUSES } = STATUSES

  const isInvitedUser = status === USER_STATUSES.INVITED

  return cloneElement(<TableActions />, {
    actions,
    isBlocked,
    name: isSuperAdmin ? 'admin' : 'subadmin',
    isInvitedUser
  })
}

const useSubadminTableColumns = (isSuperAdmin) => {
  const { t } = useTranslations()

  const getColumnSearchProps = useGetColumnSearchProps()

  const newspaperTableColumn = useMemo(
    () =>
      isSuperAdmin
        ? null
        : {
            title: <Text fontWeight="600px">{t('Newspaper')}</Text>,
            dataIndex: 'newspaper',
            key: 'newspaper',
            align: 'center',
            sorter: (a, b) =>
              sortByAlphabet(a?.newspaper?.name, b?.newspaper?.name),
            ...getColumnSearchProps(['newspaper', 'name'], 'name'),
            render: (_, record) => (
              <NewspaperLink
                text={record?.newspaper?.name}
                newspaperId={record?.newspaper?._id}
              />
            )
          },
    [isSuperAdmin, t, getColumnSearchProps]
  )

  return useMemo(
    () =>
      [
        {
          title: <Text fontWeight="600px">{t('Sno')}</Text>,
          width: 'var(--table-number-column-width)',
          dataIndex: 'sno',
          key: 'sno',
          align: 'center',
          sorter: (a, b) => a?.sno - b?.sno,
          ...getColumnSearchProps('sno')
        },
        {
          title: <Text fontWeight="600px">{t('Name')}</Text>,
          dataIndex: 'fullName',
          key: 'name',
          align: 'center',
          sorter: (a, b) => sortByAlphabet(a?.fullName, b?.fullName),
          ...getColumnSearchProps('fullName', 'name')
        },
        {
          title: <Text fontWeight="600px">{t('Added on')}</Text>,
          dataIndex: '_createdAt',
          key: '_createdAt',
          align: 'center',
          sorter: (a, b) => a?._createdAt?.seconds - b?._createdAt?.seconds,
          render: (date) => (
            <Text>
              {moment
                .unix(date?.seconds)
                .format(MOMENT_FORMATS.MONTH_DAYS_COMMA_YEAR)}
            </Text>
          )
        },
        {
          title: <Text fontWeight="600px">{t('Email address')}</Text>,
          dataIndex: 'email',
          key: 'email',
          align: 'center',
          sorter: (a, b) => sortByAlphabet(a?.email, b?.email),
          ...getColumnSearchProps('email')
        },
        newspaperTableColumn,
        {
          title: <Text fontWeight="600px">{t('Status')}</Text>,
          dataIndex: 'status',
          key: 'status',
          align: 'center',
          sorter: (a, b) => sortByAlphabet(a?.status, b?.status),
          render: (status) => <Text>{t(status)}</Text>
        },
        {
          title: <Text fontWeight="600px">{t('Actions')}</Text>,
          width: 'var(--table-actions-column-width)',
          dataIndex: 'actions',
          key: 'actions',
          align: 'center',
          render: (_, record) => (
            <SubadminTableActions
              isBlocked={record?.isBlocked}
              subadminId={record?._id}
              status={record?.status}
              isSuperAdmin={isSuperAdmin}
            />
          )
        }
      ].filter(Boolean),
    [t, newspaperTableColumn, getColumnSearchProps, isSuperAdmin]
  )
}

export default useSubadminTableColumns
