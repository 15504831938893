import { Button, Empty, Tooltip, message } from 'antd'
import { Col, Container, Row } from '@qonsoll/react-design'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { useCallback, useMemo } from 'react'

import { LanguageDraggableListItem } from 'domains/Language/components'
import PropTypes from 'prop-types'
import { StyledWrapper } from './LanguageDraggableList.styles'
import generateNewLanguage from './helpers/generateNewLanguage'
import { reorder } from 'helpers'
import { useTranslations } from '@qonsoll/translation'

const LanguageDraggableList = (props) => {
  const { value = {}, onChange, form } = props

  const { t, languages } = useTranslations()

  const valueArray = useMemo(() => Object.values(value), [value])

  const computeLanguages = useMemo(() => {
    const usedLanguages = valueArray
      ?.map((item) => item?.shortCode)
      ?.filter(Boolean)

    return languages?.filter(
      ({ shortCode }) => !usedLanguages.includes(shortCode)
    )
  }, [valueArray])

  const onRemoveLanguage = useCallback(
    (uid) => () => {
      const valueCopy = { ...value }
      delete valueCopy[uid]
      onChange(valueCopy)
    },
    [value]
  )

  const checkEmptyItems = useCallback(
    () => valueArray?.find((item) => !item?.label && !item.name),
    [valueArray]
  )

  const onAddLanguage = useCallback(() => {
    if (checkEmptyItems()) {
      message.error(t('Please complete the blank fields'))
      return null
    }

    const newLanguage = generateNewLanguage()
    onChange({ ...value, [newLanguage.uid]: newLanguage })
  }, [valueArray])

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) return

    const items = reorder(
      Object.values(value || {}),
      result.source.index,
      result.destination.index
    )
    const reorderedItems = items?.reduce((acc, item) => {
      // reorder field on create and on edit with its id-s
      acc[item?.uid || item?.shortCode] = item
      return acc
    }, {})

    onChange?.(reorderedItems)
  }

  const isEmpty = useMemo(() => valueArray.length, [valueArray])

  const isDisabledButton = useMemo(
    () => !computeLanguages?.length,
    [computeLanguages?.length]
  )
  const tooltipTitle = useMemo(
    () => (isDisabledButton ? t('No more languages to add') : null),
    [isDisabledButton]
  )

  const emptyData = useMemo(
    () => <Empty description={t('You have not added any languages yet')} />,
    []
  )
  const languagesListItem = useMemo(
    () =>
      valueArray.map((language, index) => (
        <LanguageDraggableListItem
          key={language?.shortCode || language?.uid || index}
          language={language}
          index={index}
          onRemoveLanguage={onRemoveLanguage}
          languages={computeLanguages}
          form={form}
        />
      )),
    [valueArray]
  )

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Container>
        <Row>
          <Col cw={12}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <StyledWrapper
                  {...provided.droppableProps}
                  ref={provided.innerRef}>
                  <Row noGutters>
                    <Col cw={12}>{isEmpty ? languagesListItem : emptyData}</Col>
                    {provided.placeholder}
                  </Row>
                </StyledWrapper>
              )}
            </Droppable>
          </Col>
        </Row>

        <Row mt={3} h="right" noGutters>
          <Col cw="auto">
            <Tooltip placement="bottom" title={tooltipTitle}>
              <Button
                onClick={onAddLanguage}
                size="medium"
                disabled={isDisabledButton}>
                + {t('Add language')}
              </Button>
            </Tooltip>
          </Col>
        </Row>
      </Container>
    </DragDropContext>
  )
}

LanguageDraggableList.propTypes = {
  value: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  form: PropTypes.object
}

export default LanguageDraggableList
