import { useStateWithStorage } from 'hooks'
import { useLayoutEffect, useMemo, useState } from 'react'
import { useTranslations } from '@qonsoll/translation'
import { message } from 'antd'

const useGetNewspaperExpirationLanguageConfig = (newspaper) => {
  const { languages, t } = useTranslations()

  const [activeLanguage, setActiveLanguage] = useStateWithStorage(
    'en',
    'newspaper-expiration-config-active-language'
  )

  const [usedLanguages, setUsedLanguages] = useState([])

  useLayoutEffect(() => {
    const setDefaultLanguage = () => {
      setUsedLanguages([
        languages?.find((language) => language?.shortCode === 'en')
      ])

      setActiveLanguage('en')
    }

    if (typeof newspaper?.expirationMessage === 'object') {
      const newspaperSetupLanguages = Object.keys(newspaper?.expirationMessage)

      newspaperSetupLanguages?.length
        ? setUsedLanguages(
            languages?.filter((language) =>
              newspaperSetupLanguages?.includes(language?.shortCode)
            )
          )
        : setDefaultLanguage()
    } else {
      setDefaultLanguage()
    }
  }, [newspaper, languages])

  const unusedLanguages = useMemo(
    () =>
      languages?.filter(
        (language) =>
          !usedLanguages
            ?.map(({ shortCode }) => shortCode)
            ?.includes(language?.shortCode)
      ),
    [usedLanguages, languages]
  )

  const onAddUsedLanguage = (shortCode) => {
    try {
      if (!shortCode) message.error(t('Short code is required'))

      const languageToAdd = languages?.find(
        (language) => language?.shortCode === shortCode
      )

      setUsedLanguages([...usedLanguages, languageToAdd])
    } catch (error) {
      message.error(t('Something went wrong during adding language'))
    }
  }

  return {
    activeLanguage,
    setActiveLanguage,
    usedLanguages,
    unusedLanguages,
    onAddUsedLanguage
  }
}

export default useGetNewspaperExpirationLanguageConfig
