import { Select } from 'antd'
import { useTranslations } from 'contexts/Translation'
import { useMemo } from 'react'
import LANGUAGES from 'domains/Translation/__constants__/languages'
import PropTypes from 'prop-types'
import { ENGLISH_SHORT_CODE_LANGUAGE } from 'domains/Translation/__constants__'

const AddNewLanguageSelect = (props) => {
  const { withOnlyExistLanguages = false, defaultValue } = props
  const { t, languages } = useTranslations()

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  const transformedLanguages = useMemo(
    () => languages?.map(({ value }) => value),
    [languages]
  )

  const options = useMemo(
    () =>
      LANGUAGES.filter(({ value }) =>
        withOnlyExistLanguages
          ? transformedLanguages?.includes(value) &&
            ENGLISH_SHORT_CODE_LANGUAGE !== value
          : !transformedLanguages?.includes(value)
      ),
    [transformedLanguages, withOnlyExistLanguages]
  )

  return (
    <Select
      labelInValue
      showSearch
      placeholder={t('Select language')}
      optionFilterProp="children"
      filterOption={filterOption}
      options={options}
      value={defaultValue}
      {...props}
    />
  )
}
AddNewLanguageSelect.propTypes = {
  withOnlyExistLanguages: PropTypes.bool,
  defaultValue: PropTypes.object
}

export default AddNewLanguageSelect
