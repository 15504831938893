// It is necessary to create such a constant
// so that it is possible to know the number of steps
const DOMAIN_CONFIGURATION_STEPS = {
  ENTER_DOMAIN: {
    title: 'Enter domain',
    description: 'Type domain name related to the newspaper here',
    okText: 'Continue'
  },
  VERIFY_OWNERSHIP: {
    title: 'Verify ownership',
    description:
      'Add these A records to your domain by visiting your DNS provider or registrar, your site will show a security certificate warning for a few hours, until the certificate has been provisioned',
    okText: 'Verify'
  },
  GO_LIVE: {
    title: 'Go live',
    description: 'Add these TXT records to your DNS provider',
    okText: 'Finish'
  }
}

export { DOMAIN_CONFIGURATION_STEPS }
export default DOMAIN_CONFIGURATION_STEPS
