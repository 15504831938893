import { Col, Row } from '@qonsoll/react-design'
import { EmailFilter, EmailTable } from 'domains/Email/components'
import {
  HeaderBreadcrumbs,
  PageActions,
  PageWrapperActionsUnderTitle,
  Spinner
} from 'components'
import { useEmailBaseActions, useGetEmails } from 'domains/Email/hooks/'
import { useMemo, useState } from 'react'

import PATHS from 'pages/paths'
import PropTypes from 'prop-types'
import { useFilter } from 'hooks'
import { useParams } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'

const EmailsAll = (props) => {
  const { withHeader = true } = props

  const [filteredEmailsCollection, setFilteredEmailsCollection] = useState()

  /* Getting translations instance */
  const { t } = useTranslations()

  const { emailId } = useParams()

  /* Getting filter params */
  const filterParams = useFilter('email')

  /* Destructuring filter params */
  const { filterVisibility, filterData, setFilterVisibility } = filterParams

  /* Building filter query */
  const ref = useMemo(() => filterData, [filterData])

  /* Fetching email */
  const [emails, loading] = useGetEmails(ref)

  /* Getting base actions */
  const listActions = useEmailBaseActions({ emailId })

  /* Configuring page header */
  const headingProps = {
    title: t('Emails'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  /* Configuring page actions */
  const actions = {
    filter: true,
    create: Boolean(PATHS.AUTHENTICATED.EMAIL_EDIT),
    search: true
  }

  /* Extracting base actions */
  const { onBack, onCreate } = listActions

  return (
    <PageWrapperActionsUnderTitle
      action={
        withHeader ? (
          <PageActions
            {...filterParams}
            onCreate={onCreate}
            actions={actions}
            circle
            filterMenu={<EmailFilter {...filterParams} />}
            filterVisibility={filterVisibility}
            setFilterVisibility={setFilterVisibility}
            // search props
            collection={emails}
            setCollection={setFilteredEmailsCollection}
            searchField="email"
            searchPlaceholder="Search by Email"
          />
        ) : null
      }
      onBack={withHeader ? onBack : null}
      breadcrumbs={withHeader ? <HeaderBreadcrumbs /> : null}
      headingProps={withHeader ? headingProps : null}>
      <Row noGutters>
        {loading ? (
          <Col>
            <Spinner height="70vh" text={t('Email loading')} />
          </Col>
        ) : (
          <Col cw={12}>
            <EmailTable dataSource={filteredEmailsCollection ?? emails} />
          </Col>
        )}
      </Row>
    </PageWrapperActionsUnderTitle>
  )
}

EmailsAll.propTypes = {
  isListWithCreate: PropTypes.bool,
  withHeader: PropTypes.bool
}

export default EmailsAll
