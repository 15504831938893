import { Link, Text } from '@qonsoll/react-design'
import { MOMENT_FORMATS, TABLE_PATHS } from '__constants__'
import { RewardRewardStatus, RewardRewardType } from 'domains/Reward/components'
import { cloneElement, useMemo } from 'react'
import { generatePath, useHistory } from 'react-router-dom'

import { TableActions } from 'components'
import moment from 'moment'
import { sortByAlphabet } from 'helpers'
import { useGetColumnSearchProps } from 'hooks'
import { useRewardBaseActions } from 'domains/Reward/hooks'
import { useTranslations } from '@qonsoll/translation'

const RewardTableActions = ({ rewardId, isBlocked, imageUrl }) => {
  const actions = useRewardBaseActions({ rewardId, isBlocked, imageUrl })

  return cloneElement(<TableActions />, {
    actions,
    isBlocked,
    name: 'reward'
  })
}

const useRewardTableColumns = () => {
  const { t } = useTranslations()
  const history = useHistory()

  const getColumnSearchProps = useGetColumnSearchProps()

  return useMemo(() => {
    const handleOpen = (path, params) => () =>
      history.push(generatePath(path, { ...params }))

    return [
      {
        title: <Text fontWeight="600">{t('Sno')}</Text>,
        dataIndex: 'sno',
        width: 'var(--table-number-column-width)',
        key: 'sno',
        align: 'center',
        sorter: (a, b) => a?.sno - b?.sno,
        ...getColumnSearchProps('sno'),
        render: (_, record) => <Text>{record?.sno}</Text>
      },
      {
        title: <Text fontWeight="600">{t('Reward name')}</Text>,
        dataIndex: 'name',
        key: 'name',
        align: 'center',
        sorter: (a, b) => sortByAlphabet(a?.name, b?.name),
        ...getColumnSearchProps('name', 'reward name'),
        render: (_, record) => (
          <Link
            onClick={handleOpen(TABLE_PATHS.REWARD_SHOW, {
              rewardId: record?._id
            })}>
            {record?.name}
          </Link>
        )
      },
      {
        title: <Text fontWeight="600">{t('Store name')}</Text>,
        dataIndex: 'store',
        key: 'store',
        align: 'center',
        sorter: (a, b) => sortByAlphabet(a?.store?.name, b?.store?.name),
        ...getColumnSearchProps(['store', 'name'], 'store name'),
        render: (value) => (
          <Link
            onClick={handleOpen(TABLE_PATHS.STORE_SHOW, {
              storeId: value?._id
            })}>
            {value?.name}
          </Link>
        )
      },
      {
        title: <Text fontWeight="600">{t('Added on')}</Text>,
        dataIndex: '_createdAt',
        key: '_createdAt',
        align: 'center',
        sorter: (a, b) => a?._createdAt?.seconds - b?._createdAt?.seconds,
        render: (date) => (
          <Text>
            {moment
              ?.unix(date?.seconds)
              ?.format(MOMENT_FORMATS.MONTH_DAYS_COMMA_YEAR)}
          </Text>
        )
      },
      {
        title: <Text fontWeight="600">{t('Newspaper')}</Text>,
        dataIndex: 'newspaper',
        key: 'newspaper',
        align: 'center',
        sorter: (a, b) =>
          sortByAlphabet(a?.newspaper?.name, b?.newspaper?.name),
        ...getColumnSearchProps(['newspaper', 'name'], 'Newspaper'),
        render: (value) => (
          <Link
            onClick={handleOpen(TABLE_PATHS.NEWSPAPER_SHOW, {
              newspaperId: value?._id
            })}>
            {value?.name}
          </Link>
        )
      },
      {
        title: <Text fontWeight="600">{t('Redeemed')}</Text>,
        dataIndex: 'redeemed',
        key: 'redeemed',
        align: 'center',
        sorter: (a, b) => a?.redeemed - b?.redeemed,
        render: (_, render) => <Text>{render?.redeemed}</Text>
      },

      {
        title: <Text fontWeight="600">{t('Reward type')}</Text>,
        dataIndex: 'type',
        width: 'var(--table-reward-column-width)',
        key: 'type',
        align: 'center',
        sorter: (a, b) => sortByAlphabet(a?.type, b?.type),
        ...getColumnSearchProps('type', 'reward type'),
        render: (_, record) => <RewardRewardType reward={record} />
      },
      {
        title: <Text fontWeight="600">{t('Status')}</Text>,
        dataIndex: 'status',
        width: 'var(--table-status-column-width)',
        key: 'status',
        align: 'center',
        sorter: (a, b) => sortByAlphabet(a?.status, b?.status),
        render: (_, record) => <RewardRewardStatus reward={record} />
      },
      {
        title: <Text fontWeight="600">{t('Actions')}</Text>,
        dataIndex: 'actions',
        width: 'var(--table-actions-column-width)',
        key: 'actions',
        align: 'center',
        render: (_, record) => (
          <RewardTableActions
            rewardId={record?._id}
            isBlocked={record?.isBlocked}
            imageUrl={record?.imageUrl}
          />
        )
      }
    ]
  }, [t, getColumnSearchProps, history])
}

export default useRewardTableColumns
