import { useDocument } from 'services/api/firebase'
import { COLLECTIONS } from '__constants__'
import { createFullUserName } from 'helpers'

const useGetTitleInsteadId = (documentId, collectionName) => {
  // Get document data by passed collection name and documentId
  const [documentData, documentDataLoading] = useDocument({
    ref: documentId ? collectionName + '/' + documentId : null
  })

  const titleByCollection = {
    [COLLECTIONS.USERS]: createFullUserName(
      documentData?.firstName,
      documentData?.lastName
    ),
    [COLLECTIONS.TIP_STATUSES]: documentData?.name,
    [COLLECTIONS.NEWSPAPERS]: documentData?.name,
    [COLLECTIONS.EDITORS]: createFullUserName(
      documentData?.firstName,
      documentData?.lastName
    ),
    [COLLECTIONS.NEWS_TIPS]: documentData?.title,
    [COLLECTIONS.NEWS_TIP_STATUSES]: documentData?.name,
    [COLLECTIONS.STORES]: documentData?.name,
    [COLLECTIONS.STORE_STATUSES]: documentData?.name,
    [COLLECTIONS.REWARDS]: documentData?.name,
    [COLLECTIONS.REWARD_TYPES]: documentData?.name,
    [COLLECTIONS.REWARD_STATUSES]: documentData?.name,
    [COLLECTIONS.SUBADMINS]: createFullUserName(
      documentData?.firstName,
      documentData?.lastName
    ),
    [COLLECTIONS.SUPER_ADMINS]: createFullUserName(
      documentData?.firstName,
      documentData?.lastName
    ),
    [COLLECTIONS.EMAILS]: documentData?.name,
    [COLLECTIONS.PAGES]: documentData?.title,
    [COLLECTIONS.REJECT_REASONS]: documentData?.title,
    [COLLECTIONS.REJECT_REASON_STATUSES]: documentData?.name,
    [COLLECTIONS.USER_STATUSES]: documentData?.name
  }

  const replacedField = titleByCollection?.[collectionName]

  return [replacedField, documentDataLoading]
}

export default useGetTitleInsteadId
