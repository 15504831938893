import styled from 'styled-components'
import { Select } from 'antd'
import { Button } from '@qonsoll/react-design'

export const StyledSelect = styled(Select)`
  max-width: 148px;
  margin-top: 8px;
`

export const StyledAddLanguageButton = styled(Button)`
  max-width: 64px;
`
