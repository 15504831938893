import { RewardOverviewStatusFilter, StoreFilter } from './components'

import PropTypes from 'prop-types'

const NewspaperFilter = (props) => {
  const { filterData, setFilterData } = props

  return (
    <>
      <RewardOverviewStatusFilter
        filterData={filterData}
        setFilterData={setFilterData}
      />
      <StoreFilter filterData={filterData} setFilterData={setFilterData} />
    </>
  )
}

NewspaperFilter.propTypes = {
  filterData: PropTypes.object,
  setFilterData: PropTypes.func
}

export default NewspaperFilter
