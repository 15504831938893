import { useTranslations } from 'contexts/Translation'
import { Box, Col, Row, Title, Remove } from '@qonsoll/react-design'
import { SelectableCard } from 'components'
import { Icon } from '@qonsoll/icons'
import { useRemoveLanguage } from 'domains/Translation/hooks'
import {
  DEFAULT_LANGUAGE,
  ENGLISH_SHORT_CODE_LANGUAGE
} from 'domains/Translation/__constants__'

const LanguagesList = () => {
  const { t, languages } = useTranslations()

  const { handleRemoveLanguage, removeLanguageLoading } = useRemoveLanguage()

  return (
    <Row noGutters>
      {languages.map((language) => (
        <Col cw={[12, 6, 4, 3]} mb="8px" v="center">
          <Box p="4px">
            <SelectableCard>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center">
                <Title level={5}>{language?.label}</Title>
                <Remove
                  disabled={[
                    ENGLISH_SHORT_CODE_LANGUAGE,
                    DEFAULT_LANGUAGE
                  ].includes(language?.shortCode)}
                  loading={removeLanguageLoading}
                  itemName={`${language?.label} ${t('language')}`}
                  onSubmit={() => handleRemoveLanguage(language)}
                  icon={
                    <Icon name="TrashFilled" size={24} fill={'currentColor'} />
                  }
                />
              </Box>
            </SelectableCard>
          </Box>
        </Col>
      ))}
    </Row>
  )
}

export default LanguagesList
