import { COLLECTIONS, STATUSES } from '__constants__'
import { deleteDocument, updateDocument } from 'services/api/firebase'
import { generatePath, useHistory } from 'react-router-dom'

import PATHS from 'pages/paths'
import { deleteStorageFile } from 'helpers'
import { message } from 'antd'
import { useEvent } from 'hooks'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

/* Path's */
const createPath =
  PATHS.AUTHENTICATED.REWARD_CREATE || PATHS.AUTHENTICATED.REWARD_WIZARD
const editPath = PATHS.AUTHENTICATED.REWARD_EDIT
const showPath = PATHS.AUTHENTICATED.REWARD_SHOW

const useRewardBaseActions = ({
  rewardId,
  actions,
  imageUrl,
  isBlocked
} = {}) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()

  /* Function to handle back click */
  const handleBack = useEvent(() => history.goBack())

  /* Handling opening of the create page */
  const handleCreate = useEvent(() => {
    history.push(createPath)
  })

  /* Handling opening of the create page */
  const handleOpen = useEvent(() => {
    if (rewardId) {
      const genPath = generatePath(showPath, { rewardId })
      history.push(genPath)
    }
  })

  /* Handling opening of the edit page or change view to edit mode */
  const handleEdit = useEvent(() => {
    const pathParams = { rewardId }
    const path = generatePath(editPath, pathParams)
    history.push(path)
  })

  const handleDelete = useEvent(async () => {
    try {
      await deleteDocument(COLLECTIONS.REWARDS, rewardId)
      imageUrl && (await deleteStorageFile(imageUrl))
      message.success(t('Reward successfully deleted'))
    } catch (error) {
      message.error(error.message)
    }
  })

  const handleBlock = useEvent(async () => {
    const computedTextStatusToDisplay = isBlocked ? 'unblocked' : 'blocked'
    const { ACTIVE, BLOCKED } = STATUSES.REWARD_STATUSES

    try {
      await updateDocument(COLLECTIONS.REWARDS, rewardId, {
        isBlocked: !isBlocked,
        status: isBlocked ? ACTIVE : BLOCKED
      })
      message.success(t(`Reward successfully ${computedTextStatusToDisplay}`))
    } catch (error) {
      message.error(
        t(`Error ${computedTextStatusToDisplay} status`, error.message)
      )
    }
  })

  return useMemo(
    () => ({
      onBack: handleBack,
      onCreate: handleCreate,
      onEdit: handleEdit,
      onDelete: handleDelete,
      onBlock: handleBlock,
      onOpen: handleOpen,
      ...(actions || {}),
      createPath,
      editPath,
      showPath
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      actions?.onBack,
      actions?.onCreate,
      actions?.onDelete,
      actions?.onEdit,
      handleBack,
      handleCreate,
      handleDelete,
      handleEdit,
      handleBlock
    ]
  )
}

export default useRewardBaseActions
