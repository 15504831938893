import { useState, useEffect } from 'react'
import { Form } from 'antd'
import { DOMAIN_CONFIGURATION_STEPS } from '../../../__constants__'

const useDomainConfigurationModalActions = () => {
  /* Getting new form instance from Form.useForm hook */
  const [domainConfigurationForm] = Form.useForm()

  const [modalVisible, setModalVisible] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)
  const [submitLoading, setSubmitLoading] = useState(false)

  /* Get number of steps */
  const numberOfSteps = Object.keys(DOMAIN_CONFIGURATION_STEPS)?.length

  /* Function open modal */
  const handleClickAddDomain = () => setModalVisible(true)

  /* Function skips to the next step */
  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1)
  }

  /* Function that will be called when a user clicks mask or Cancel button.
     Close modal and reset step */
  const handleCancel = () => {
    setModalVisible(false)
    setCurrentStep(0)
  }

  /* Set values for form's loading */
  const handleSubmitLoading = (value) => setSubmitLoading(value)

  /* Close modal and reset step if the last step was reached  */
  useEffect(
    () => currentStep + 1 > numberOfSteps && handleCancel?.(),
    [currentStep, numberOfSteps]
  )

  return {
    handleClickAddDomain,
    handleNextStep,
    handleCancel,
    handleSubmitLoading,
    submitLoading,
    modalVisible,
    currentStep,
    domainConfigurationForm
  }
}

export default useDomainConfigurationModalActions
