import {
  Box,
  Card,
  Col,
  Container,
  Row,
  Text,
  Title
} from '@qonsoll/react-design'
import {
  ImgStyled,
  VideoStyled,
  TagStyled,
  styles
} from './NewsTipSimpleView.styles'
import { MOMENT_FORMATS, STATUSES_COLORS } from '__constants__'

import PropTypes from 'prop-types'
import moment from 'moment'
import noImage from 'assets/NoImage.png'

const PreviewTipsSimpleView = (props) => {
  const { tip } = props
  const { status, _createdAt, title, photoUrls, coverPhotoUrl, videoUrl } = tip
  const { DATE_FORMATS } = MOMENT_FORMATS

  const formattedData =
    _createdAt && moment?.unix(_createdAt?.seconds)?.format(DATE_FORMATS)

  const handleErrorLoadImage = ({ currentTarget }) => {
    currentTarget.onerror = null
    currentTarget.src = noImage
  }

  const computedImage = coverPhotoUrl || photoUrls?.[0] || videoUrl || noImage

  const isOnlyVideoExist = !coverPhotoUrl && !photoUrls?.[0] && videoUrl

  return (
    <Card height="100%" bodyStyle={{ height: '100%' }}>
      <Container height="100%">
        <Row noGutters height="100%">
          <Col cw={6}>
            <Box height="100%" mr={4}>
              {isOnlyVideoExist ? (
                //Added video tag without controls for preview image on tip, for poster image enough video metadata
                <VideoStyled src={computedImage} preload="metadata" />
              ) : (
                <ImgStyled src={computedImage} onError={handleErrorLoadImage} />
              )}
            </Box>
          </Col>
          <Col cw={6}>
            <Row noGutters height="100%" flexDirection="column">
              <Col mb={2} cw={12}>
                <Title {...styles?.title}>{title}</Title>
              </Col>
              <Col cw={12} mb={2} style={styles?.column}>
                <Text variant="body2" type="secondary" mb={2}>
                  {formattedData}
                </Text>
                <TagStyled color={STATUSES_COLORS?.[status]}>
                  {status}
                </TagStyled>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Card>
  )
}

PreviewTipsSimpleView.propTypes = {
  tip: PropTypes.object
}

export default PreviewTipsSimpleView
