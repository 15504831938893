import { Account, Box } from '@qonsoll/react-design'
import { LogoutOutlined, ReloadOutlined, UserOutlined } from '@ant-design/icons'

import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { avatarLetterFromNameOrEmail } from 'helpers'
import firebase from 'firebase/compat/app'
import { useMemo } from 'react'
import { useProfileBaseActions } from 'domains/Profile/hooks'
import { useTranslations } from 'contexts/Translation'

const AccountMenu = ({ avatar, displayName, email, menuArr }) => {
  const { t } = useTranslations()
  const { onOpen, onChangePassword } = useProfileBaseActions()

  const avatarFirstLetter = avatarLetterFromNameOrEmail(displayName, email)

  const menu = useMemo(
    () => [
      {
        type: 'item',
        text: t('Profile'),
        icon: <UserOutlined />,
        danger: false,
        disabled: false,
        onClick: onOpen
      },
      {
        type: 'item',
        text: t('Reset password'),
        icon: <ReloadOutlined />,
        danger: false,
        disabled: false,
        onClick: onChangePassword
      },
      {
        type: 'item',
        text: t('Log out'),
        icon: <LogoutOutlined />,
        danger: true,
        disabled: false,
        onClick: () => firebase.auth().signOut()
      }
    ],
    [onChangePassword, onOpen, t]
  )
  return (
    <Box display="flex" v="center">
      <Account
        avatar={avatar}
        avatarText={avatarFirstLetter}
        isEllipsis
        menu={menuArr || menu}
        menuPlacement="topRight"
        menuArrow={true}
        title={
          <Box display="flex">
            {displayName || t('No name')}
            <Icon
              name="ArrowShortDownFilled"
              size={24}
              pl={3}
              display="flex"
              justifyContent="center"
              alignItems="center"
              fill="currentColor"
            />
          </Box>
        }
      />
    </Box>
  )
}

AccountMenu.propTypes = {
  id: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  displayName: PropTypes.string,
  email: PropTypes.string
}

export default AccountMenu
