import {
  useGetNewspaperInitialValues,
  useNewspaperBaseActions
} from 'domains/Newspaper/hooks'

import { Link } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { Tooltip } from 'antd'

const NewspaperLink = (props) => {
  const { text, newspaperId, ellipsis, maxWidth } = props
  const { onOpen } = useNewspaperBaseActions({ newspaperId })

  /* Get newspaper's document */
  const [newspaperInitialValues, loading] = useGetNewspaperInitialValues(
    !text && newspaperId
  )
  /* Get name from newspaper's document */
  const newspaperName = text || newspaperInitialValues?.newspaper?.name

  return (
    !loading && (
      <Tooltip title={ellipsis ? text : null}>
        <Link onClick={onOpen} ellipsis={ellipsis} maxWidth={maxWidth}>
          {newspaperName}
        </Link>
      </Tooltip>
    )
  )
}
NewspaperLink.propTypes = {
  newspaperId: PropTypes.string,
  text: PropTypes.string,
  ellipsis: PropTypes.bool,
  maxWidth: PropTypes.string
}

export default NewspaperLink
