const WIDGET_CONFIG = {
  POSITIONS: [
    { value: 'bottom-left', label: 'Bottom left' },
    { value: 'bottom-right', label: 'Bottom right' },
    { value: 'top-left', label: 'Top left' },
    { value: 'top-right', label: 'Top right' }
  ],
  SIZES: [
    {
      value: 'small',
      label: 'Small'
    },
    {
      value: 'medium',
      label: 'Medium'
    },
    {
      value: 'large',
      label: 'Large'
    }
  ],
  BORDER_RADIUS: [
    { value: '0px', label: '0px' },
    { value: '4px', label: '4px' },
    { value: '8px', label: '8px' },
    { value: '16px', label: '16px' },
    { value: '24px', label: '24px' },
    { value: '50%', label: '50%' }
  ]
}

export default WIDGET_CONFIG
export { WIDGET_CONFIG }
