import { cloneElement, useMemo } from 'react'

import { TableActions } from 'components'
import { Text } from '@qonsoll/react-design'
import { sortByAlphabet } from 'helpers'
import { useCountryBaseActions } from 'domains/Country/hooks'
import { useGetColumnSearchProps } from 'hooks'
import { useTranslations } from '@qonsoll/translation'

const CountryTableActions = ({ countryId, isBlocked, status }) => {
  const actions = useCountryBaseActions({ countryId, isBlocked })

  return cloneElement(<TableActions />, {
    actions,
    isBlocked,
    name: 'country'
  })
}

const useCountryTableColumns = () => {
  const { t } = useTranslations()

  const getColumnSearchProps = useGetColumnSearchProps()

  return useMemo(() => {
    return [
      {
        title: <Text fontWeight="600">{t('Sno')}</Text>,
        dataIndex: 'sno',
        width: 'var(--table-number-column-width)',
        key: 'sno',
        align: 'center',
        sorter: (a, b) => a?.sno - b?.sno,
        ...getColumnSearchProps('sno'),
        render: (_, record) => <Text>{record?.sno}</Text>
      },
      {
        title: <Text fontWeight="600">{t('Country')}</Text>,
        dataIndex: 'internationalName',
        key: 'internationalName',
        align: 'center',
        sorter: (a, b) =>
          sortByAlphabet(a?.internationalName, b?.internationalName),
        ...getColumnSearchProps('internationalName', 'country'),
        render: (internationalName) => <Text>{internationalName}</Text>
      },
      {
        title: <Text fontWeight="600">{t('Status')}</Text>,
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        sorter: (a, b) => sortByAlphabet(a?.status, b?.status),
        ...getColumnSearchProps('status'),
        render: (status) => <Text>{status}</Text>
      },
      {
        title: <Text fontWeight="600">{t('Actions')}</Text>,
        dataIndex: 'actions',
        width: 'var(--table-actions-column-width)',
        key: 'actions',
        align: 'center',
        render: (_, record) => (
          <CountryTableActions
            countryId={record?._id}
            isBlocked={record?.isBlocked}
            status={record?.status}
          />
        )
      }
    ]
  }, [t, getColumnSearchProps])
}

export default useCountryTableColumns
