import { Box, Button } from '@qonsoll/react-design'
import { TEXT_ALIGN_OPTIONS_CONFIG } from 'domains/Content/constants'
import PropTypes from 'prop-types'

const TextEditTextAlignBlock = (props) => {
  const { config, onChange, currentState } = props

  const handleOnClick = (option) => onChange(option)

  return (
    <Box mx={-1} px={2}>
      {config?.options?.map((option, index) => (
        <Button
          key={`TextEditTextAlignBlock-${index}`}
          mx={1}
          size="small"
          type={currentState[option] ? 'primary' : 'text'}
          onClick={() => handleOnClick(option)}
          icon={TEXT_ALIGN_OPTIONS_CONFIG[option]}
        />
      ))}
    </Box>
  )
}
TextEditTextAlignBlock.propTypes = {
  config: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  currentState: PropTypes.object.isRequired
}

export default TextEditTextAlignBlock
