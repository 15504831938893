import { Col, PageWrapper, Row } from '@qonsoll/react-design'

import { HeaderBreadcrumbs } from 'components'
import { SubadminAdvancedForm } from 'domains/Subadmin/components'
import { useSubadminBaseActions } from 'domains/Subadmin/hooks'
import { useTranslations } from 'contexts/Translation'

const SubadminCreatePage = (props) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting base actions for page */
  const { onBack } = useSubadminBaseActions()

  /* Configuring page header */
  const headingProps = {
    title: t('Subadmin create'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  return (
    <PageWrapper
      onBack={onBack}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}>
      <Row noGutters>
        <Col cw={[12, 12, 10, 8, 7]}>
          <SubadminAdvancedForm />
        </Col>
      </Row>
    </PageWrapper>
  )
}

export default SubadminCreatePage
