import { CountryStatusFilter } from './components'
import PropTypes from 'prop-types'

const CountryFilter = (props) => {
  const { filterVisibility, filterData, setFilterData } = props

  return filterVisibility ? (
    <CountryStatusFilter
      filterData={filterData}
      setFilterData={setFilterData}
    />
  ) : null
}

CountryFilter.propTypes = {
  filterVisibility: PropTypes.bool,
  filterData: PropTypes.object,
  setFilterData: PropTypes.func
}

export default CountryFilter
