import { Col, Row, Title } from '@qonsoll/react-design'

import { ENGLISH_SHORT_CODE_LANGUAGE } from '../../__constants__'
import LocalizationItem from '../LocalizationItem'
import PropTypes from 'prop-types'
import { Spinner } from 'components'
import { useGetTranslations } from '../../hooks'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

const LocalizationForm = (props) => {
  const { appName, translateTo, translateToShortCode } = props

  const { t } = useTranslations()
  const [translations, [loading, firstLoading], fetchTranslations] =
    useGetTranslations(appName, translateToShortCode)

  const isEnglish = useMemo(
    () => ENGLISH_SHORT_CODE_LANGUAGE === translateToShortCode,
    [translateToShortCode]
  )

  const titleToTranslate = useMemo(
    () =>
      isEnglish
        ? t('Please select another language for translating')
        : translateTo,
    [isEnglish, translateTo, t]
  )

  return (
    <>
      <Row mb={2} noOuterGutters>
        <Col>
          <Title level={5}>{t('English')}</Title>
        </Col>
        <Col>
          <Title level={5}>{titleToTranslate}</Title>
        </Col>
      </Row>
      <Row mb={2} noOuterGutters v="center">
        <Col>
          <LocalizationItem
            key={`${appName}-00`}
            appName={appName}
            enPlaceholder={t('Enter text for EN translation')}
            noPlaceholder={t(
              `Enter text for ${translateToShortCode?.toUpperCase()} translation`
            )}
            withTooltips
            creationEnabled
            triggerTranslationsRefetch={fetchTranslations}
            translateToShortCode={translateToShortCode}
            isEnglish={isEnglish}
          />
        </Col>
      </Row>

      {!firstLoading ? (
        <Spinner text={t('Translations are loading')} height="100%" />
      ) : (
        <Row
          noOuterGutters
          height={'100%'}
          v={!loading && 'center'}
          position="relative"
          overflowY="auto">
          <Col cw={6}>
            {translations.map((translation, index) => (
              <LocalizationItem
                withRightColumn={false}
                key={`${appName}-${index}`}
                appName={appName}
                data={translation}
                translateToShortCode={translateToShortCode}
                isEnglish={isEnglish}
              />
            ))}
          </Col>
          <Col cw={6}>
            {loading ? (
              <Spinner
                text={t('Translations are loading')}
                position="absolute"
                style={{ left: '75%', top: '60%' }}
              />
            ) : (
              translations.map((translation, index) => (
                <LocalizationItem
                  withLeftColumn={false}
                  key={`${appName}-${index}`}
                  appName={appName}
                  data={translation}
                  translateToShortCode={translateToShortCode}
                  isEnglish={isEnglish}
                />
              ))
            )}
          </Col>
        </Row>
      )}
    </>
  )
}

LocalizationForm.propTypes = {
  appName: PropTypes.string.isRequired,
  isPageLoading: PropTypes.bool,
  handleLoadingChange: PropTypes.func,
  translateTo: PropTypes.string,
  translateToShortCode: PropTypes.string
}

export default LocalizationForm
