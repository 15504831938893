import { useTranslations } from '@qonsoll/translation'

const useGetNewspaperExpirationValidationRules = ({ setActiveLanguage }) => {
  const { t } = useTranslations()

  const expirationTitleRule = (shortCode) => () => ({
    validator(_, value) {
      if (shortCode === 'en' && !value) {
        setActiveLanguage?.('en')
        return Promise.reject(
          new Error(t('English notification title is required'))
        )
      } else return Promise.resolve()
    }
  })

  const expirationMessageRule = (shortCode) => () => ({
    validator(_, value) {
      if (shortCode === 'en' && !value) {
        setActiveLanguage?.('en')
        return Promise.reject(
          new Error(t('English notification message is required'))
        )
      } else return Promise.resolve()
    }
  })

  return { expirationMessageRule, expirationTitleRule }
}

export default useGetNewspaperExpirationValidationRules
