import { Col, Container, Row, Text, Title } from '@qonsoll/react-design'
import {
  CommentsCard,
  ContentCard,
  GeneralDetailsCard,
  StyledImg,
  StyledVideo
} from './NewsTipAdvancedView.styles'
import {
  NewsTipMediaList,
  NewsTipNewsTipStatus
} from 'domains/NewsTip/components'

import { Empty } from 'components'
import { NewsTipCommentsList } from 'domains/NewsTipComment/components'
import PropTypes from 'prop-types'
import { TagList } from 'domains/Tag/components'
import { getFormattedDateAndTime } from 'helpers'
import { usePhoneNumber } from 'hooks'
import { useTranslations } from 'contexts/Translation'

const NewsTipAdvancedView = (props) => {
  const { newsTip, author, location } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Getting formatted date and time */
  const dateAndTime = getFormattedDateAndTime(newsTip?._createdAt?.seconds)
  const { transformPhoneNumber } = usePhoneNumber()

  // Form map with title details (objects contain title, data or component)
  const generalDetails = [
    {
      title: 'Sno',
      data: newsTip?.sno
    },
    {
      title: 'Status',
      component: (
        <NewsTipNewsTipStatus
          status={newsTip?.status}
          isBlocked={newsTip?.isBlocked}
          noDataTextProps={{
            textAlign: 'right'
          }}
        />
      )
    },
    {
      title: 'Posted on',
      data: dateAndTime
    },

    {
      title: 'Location',
      data: location?.formattedAddress
    },

    {
      title: 'Points',
      data: newsTip?.points
    },

    {
      title: 'Posted by',
      data: author?.fullName
    },
    {
      title: 'Email',
      data: author?.email
    },
    {
      title: 'Phone',
      data: transformPhoneNumber(author?.phoneNumber || author?.phone)
    },
    {
      title: 'Tags',
      component: <TagList tags={newsTip.tags} />
    }
  ].filter(({ data, component }) => data || component)

  const computedCoverPhoto = newsTip?.coverPhotoUrl || newsTip?.photoUrls?.[0]
  return (
    <Container>
      <Row mx="var(--ql-grid-gutter-negative)">
        <Col cw={4}>
          <GeneralDetailsCard>
            <Row noGutters mb={2} v="center">
              <Col h="left">
                {computedCoverPhoto ? (
                  <StyledImg src={computedCoverPhoto} />
                ) : newsTip?.videoUrl ? (
                  //Added video tag without controls for preview image on tip, for poster image enough video metadata
                  <StyledVideo src={newsTip?.videoUrl} preload="metadata" />
                ) : (
                  <Empty message={t('No image provided')} />
                )}
              </Col>
            </Row>
            <Row noGutters mb={2} v="center">
              <Col h="left">
                <Title level={5}>{newsTip?.title}</Title>
              </Col>
            </Row>
            {generalDetails?.map(({ title, data, component }, index) => (
              <Row
                noGutters
                v="center"
                mb={index !== generalDetails?.length - 1 && 2}
                key={title}>
                <Col cw="auto" h="left">
                  <Text variant="overline" type="secondary">
                    {t(title)}:
                  </Text>
                </Col>
                <Col h="right">
                  {component || <Text textAlign="right">{data}</Text>}
                </Col>
              </Row>
            ))}
          </GeneralDetailsCard>
        </Col>
        <Col cw={8}>
          <Row noGutters>
            <Col cw={12} mb={3}>
              <ContentCard>
                <Row noGutters>
                  <Col cw={12} mb={2}>
                    <Text variant="overline" type="secondary">
                      {t('Content')}
                    </Text>
                  </Col>
                  <Col cw={12}>
                    {newsTip?.content ? (
                      <Text>{newsTip?.content}</Text>
                    ) : (
                      <Text type="secondary">{t('There is no content')}</Text>
                    )}
                  </Col>
                </Row>
              </ContentCard>
            </Col>
            <Col cw={12} mb={3}>
              <NewsTipMediaList
                photoUrls={newsTip?.photoUrls}
                videoUrl={newsTip?.videoUrl}
              />
            </Col>
            <Col cw={12}>
              <CommentsCard>
                <Text variant="overline" type="secondary" mb={3}>
                  {t('Comments')}
                </Text>

                <NewsTipCommentsList newsTipId={newsTip?._id} author={author} />
              </CommentsCard>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

NewsTipAdvancedView.propTypes = {
  newsTip: PropTypes.shape({
    _id: PropTypes.string,
    _createdAt: PropTypes.object,
    videoUrl: PropTypes.string,
    photoUrls: PropTypes.array,
    title: PropTypes.string,
    content: PropTypes.string,
    tags: PropTypes.array,
    coverPhotoUrl: PropTypes.string,
    location: PropTypes.string,
    sno: PropTypes.number,
    points: PropTypes.number,
    status: PropTypes.string,
    isBlocked: PropTypes.bool
  }),
  author: PropTypes.shape({
    fullName: PropTypes.string,
    phoneNumber: PropTypes.string,
    phone: PropTypes.string,
    email: PropTypes.string
  }),
  location: PropTypes.shape({
    formattedAddress: PropTypes.string
  })
}

export default NewsTipAdvancedView
