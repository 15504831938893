import { Col, Row } from '@qonsoll/react-design'
import {
  HeaderBreadcrumbs,
  Spinner,
  PageActions,
  PageWrapperActionsUnderTitle
} from 'components'
import { WebTippsConfigurationTable } from 'domains/WebTippsConfiguration/components'
import {
  useGetWebTippsConfigurations,
  useWebTippsConfigurationBaseActions
} from 'domains/WebTippsConfiguration/hooks'
import { useMemo, useState } from 'react'

import { useFilter, useGetCustomDataSource } from 'hooks'
import { useTranslations } from 'contexts/Translation'
import { COLLECTIONS } from '__constants__'

const WebTippsConfigurationsAll = () => {
  const [collection, setCollection] = useState()
  /* Getting translations instance */
  const { t } = useTranslations()

  /* Getting filter params */
  const filterParams = useFilter(COLLECTIONS.WEB_TIPPS_CONFIGURATIONS)

  /* Destructuring filter params */
  const { filterVisibility, filterData, setFilterVisibility } = filterParams
  /* Building filter query */
  const ref = useMemo(() => filterData, [filterData])

  /* Fetching web tipps */
  const [webTipps, loading] = useGetWebTippsConfigurations(ref)
  const [customDataSource, customDataSourceLoading] =
    useGetCustomDataSource(webTipps)

  /* Getting base actions */
  const { onBack } = useWebTippsConfigurationBaseActions()

  /* Configuring page header */
  const headingProps = {
    title: t('Web tipps'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  /* Configuring page actions */
  const actions = {
    filter: true,
    search: true
  }

  return (
    <PageWrapperActionsUnderTitle
      action={
        <PageActions
          actions={actions}
          circle
          filterVisibility={filterVisibility}
          setFilterVisibility={setFilterVisibility}
          searchPlaceholder="Search by domain name"
          collection={webTipps}
          setCollection={setCollection}
          searchField="domainName"
        />
      }
      onBack={onBack}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}>
      <Row noGutters width="100%">
        {loading || customDataSourceLoading ? (
          <Col>
            <Spinner height="60vh" text={t('Web tipps loading')} />
          </Col>
        ) : (
          <Col cw={12}>
            <WebTippsConfigurationTable
              dataSource={collection ?? customDataSource}
            />
          </Col>
        )}
      </Row>
    </PageWrapperActionsUnderTitle>
  )
}

export default WebTippsConfigurationsAll
