import { Col, Row } from '@qonsoll/react-design'
import { Input, Tooltip, notification } from 'antd'

import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { assembleTranslationLanguages } from '../../helpers'
import firebase from 'firebase/compat/app'
import { useCreateTranslation } from '../../hooks'
import { useState } from 'react'
import { useTranslations } from '../../../../contexts/Translation'
import { RDB_COLLECTIONS } from '__constants__'
import { ENGLISH_SHORT_CODE_LANGUAGE } from 'domains/Translation/__constants__'

const LocalizationItem = (props) => {
  // Props destructuring
  const {
    data = {},
    appName,
    enPlaceholder = '',
    noPlaceholder = '',
    withTooltips = false,
    creationEnabled = false,
    translateToShortCode,
    isEnglish,
    triggerTranslationsRefetch,
    withLeftColumn = true,
    withRightColumn = true
  } = props

  // Hooks
  const { t, languages, saveTranslationForLanguage } = useTranslations()
  const { createAllTranslations } = useCreateTranslation(appName)

  // Data destructuring
  const { original, enKey } = data
  const translateTo = data?.[translateToShortCode]

  // States
  const [textInApp, setTextInApp] = useState(enKey)
  const [translateToLabel, setTranslateToLabel] = useState(translateTo)

  // Functions
  const save = (lang, value) => {
    /**
     * It shows a success notification, clears the inputs if the creation is enabled, and triggers the
     * translations data source update
     */
    const handleSuccessOperation = () => {
      // showing success in-app notification
      notification.success({
        message: t('Success'),
        description: `"${value}" ${t('label was saved to the')} ${appName}`
      })

      if (creationEnabled) {
        // clearing inputs from old values
        setTranslateToLabel('')
        setTextInApp('')
      }

      // triggering of translations data source update
      triggerTranslationsRefetch?.()
    }
    /**
     * It takes an error object as an argument, logs the error to the console, and shows an error
     * notification to the user
     *
     */
    const handleError = () => {
      // showing error in-app notification
      notification.error({
        message: t('Error'),
        description: `${t('error')}`
      })
    }

    if (creationEnabled) {
      // create operation
      createAllTranslations?.({
        languages: assembleTranslationLanguages({
          textInApp,
          translateToLabel,
          languages,
          translateToShortCode
        }),
        // for version where keys are hashes
        // refEnding: md5(textInApp)
        refEnding: textInApp
      })
        .then(handleSuccessOperation)
        .catch(handleError)
    } else {
      const updateTranslation = ({ textLabel, shortCode, refEnding }) => {
        /* Creating a reference to the database. */
        const ref = `${RDB_COLLECTIONS.TRANSLATIONS}/${appName}/${shortCode}/${refEnding}`
        return firebase.database().ref(ref).set(textLabel)
      }
      const update = saveTranslationForLanguage || updateTranslation

      // update operation
      update({
        textLabel: value,
        refEnding: original,
        shortCode: lang,
        appName
      })
        .then(handleSuccessOperation)
        .catch(handleError)
    }
  }

  // Template
  return (
    <Row mb={2} noOuterGutters>
      {withLeftColumn && (
        <Col>
          <Input
            value={textInApp}
            placeholder={enPlaceholder}
            onChange={(e) => setTextInApp(e.target.value)}
            onPressEnter={() => save(ENGLISH_SHORT_CODE_LANGUAGE, textInApp)}
            suffix={
              withTooltips ? (
                <Tooltip
                  title={t(
                    'You can add new translation by filling this input and pressing "Enter"'
                  )}>
                  <Icon
                    name="AlertCircleFilled"
                    cursor="help"
                    fill="var(--ql-color-accent1)"
                  />
                </Tooltip>
              ) : null
            }
          />
        </Col>
      )}
      {withRightColumn && (
        <Col>
          {!isEnglish && (
            <Input
              value={translateToLabel}
              placeholder={noPlaceholder}
              onChange={(e) => setTranslateToLabel(e.target.value)}
              onPressEnter={() => save(translateToShortCode, translateToLabel)}
              suffix={
                withTooltips ? (
                  <Tooltip
                    title={t(
                      'You can add new translation by filling this input and pressing "Enter"'
                    )}>
                    <Icon
                      name="AlertCircleFilled"
                      cursor="help"
                      fill="var(--ql-color-accent1)"
                    />
                  </Tooltip>
                ) : null
              }
            />
          )}
        </Col>
      )}
    </Row>
  )
}

LocalizationItem.propTypes = {
  data: PropTypes.object,
  appName: PropTypes.string.isRequired,
  enPlaceholder: PropTypes.string,
  noPlaceholder: PropTypes.string,
  withTooltips: PropTypes.bool,
  creationEnabled: PropTypes.bool,
  triggerTranslationsRefetch: PropTypes.func,
  translateToShortCode: PropTypes.string.isRequired,
  isEnglish: PropTypes.bool,
  withLeftColumn: PropTypes.bool,
  withRightColumn: PropTypes.bool
}

export default LocalizationItem
