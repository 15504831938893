import * as firebaseui from 'firebaseui'

import { Button } from '@qonsoll/react-design'
import { LastSessionUser } from 'domains/Session/components'
import { MailOutlined } from '@ant-design/icons'
import PATHS from 'pages/paths'
import PropTypes from 'prop-types'
import firebase from 'firebase/compat/app'
import { useHistory } from 'react-router-dom'
import { useSessionActions } from 'domains/Session/hooks'
import { useTranslations } from 'contexts/Translation'

const LoginMethods = (props) => {
  const { t } = useTranslations()
  const {
    authTypeText,
    // googleAuth,
    redirectToFormWithEmail,
    lastSession
  } = props

  // [ADDITIONAL_HOOKS]
  const history = useHistory()
  const { getLastSessionProvider } = useSessionActions()

  // [CLEAN_FUNCTIONS]
  const redirectToLoginWithEmail = () => {
    history.push(PATHS.UNAUTHENTICATED.LOGIN_WITH_EMAIL)
  }
  const sessionProvider = getLastSessionProvider()

  return (
    <>
      {/* <StyledFirebaseAuth
        uiCallback={(ui) => ui.disableAutoSignIn()}
        uiConfig={uiConfig}
        firebaseAuth={firebase.auth()}
      /> */}

      {/* <Box>
        <Divider>
          <Text variant="overline" type="secondary">
            {t('Or')}
          </Text>
        </Divider>
      </Box> */}

      {sessionProvider === 'email' && lastSession?.email ? (
        <LastSessionUser
          lastSession={lastSession}
          onChangeAccount={redirectToLoginWithEmail}
          redirectTo={redirectToFormWithEmail}
        />
      ) : (
        <Button
          size="large"
          block
          icon={<MailOutlined />}
          onClick={redirectToFormWithEmail}>
          {authTypeText} {t('with email')}
        </Button>
      )}
    </>
  )
}

LoginMethods.propTypes = {
  authSwitchLinktext: PropTypes.any,
  authSwitchPath: PropTypes.any,
  authSwitchText: PropTypes.any,
  authTypeText: PropTypes.any,
  lastSession: PropTypes.any,
  redirectToFormWithEmail: PropTypes.any
}

export default LoginMethods
