import { Col, Divider, Row, Text } from '@qonsoll/react-design'

import { NewsTipCommentSimpleView } from 'domains/NewsTipComment/components'
import PropTypes from 'prop-types'
import { Spinner } from 'components'
import { useGetNewsTipComments } from 'domains/NewsTipComment/hooks'
import { useTranslations } from 'contexts/Translation'

const NewsTipCommentsList = ({ newsTipId, author }) => {
  /* Getting translations instance */
  const { t } = useTranslations()

  /* Getting comments for current news tip */
  const [comments, loading] = useGetNewsTipComments(newsTipId)

  return loading ? (
    <Spinner flex={1} />
  ) : comments?.length ? (
    comments?.map((comment, index) => {
      /* Check if comment is last */
      const isLastComment = index === comments?.length - 1

      return (
        <Row noGutters key={comment?._id}>
          <Col cw={12} mb={!isLastComment && 3}>
            <NewsTipCommentSimpleView comment={comment} author={author} />
          </Col>
          {!isLastComment && (
            <Col cw={12} mb={3}>
              <Divider />
            </Col>
          )}
        </Row>
      )
    })
  ) : (
    <Text type="secondary">{t('There are no comments')}</Text>
  )
}
NewsTipCommentsList.propTypes = {
  newsTipId: PropTypes.string,
  author: PropTypes.object
}

export default NewsTipCommentsList
