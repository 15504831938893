import { Col, Row } from '@qonsoll/react-design'
import {
  HeaderBreadcrumbs,
  PageActions,
  PageWrapperActionsUnderTitle,
  Spinner
} from 'components'
import { NewspaperFilter, NewspaperTable } from 'domains/Newspaper/components'
import { useFilter, useGetSubadminData } from 'hooks'
import {
  useGetNewspapers,
  useNewspaperBaseActions
} from 'domains/Newspaper/hooks'
import { useMemo, useState } from 'react'

import PATHS from 'pages/paths'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const NewspapersAllPage = (props) => {
  const { withHeader = true } = props

  const [collection, setCollection] = useState()

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Getting user role */
  const { isSubadmin } = useGetSubadminData()

  /* Getting filter params */
  const filterParams = useFilter('newspaper')

  /* Destructuring filter params */
  const { filterVisibility, filterData, setFilterVisibility } = filterParams

  /* Building filter query */
  const ref = useMemo(() => filterData, [filterData])

  /* Fetching newspapers */
  const [newspapers, loading] = useGetNewspapers(ref)

  /* Getting base actions */
  const listActions = useNewspaperBaseActions()

  /* Configuring page header */
  const headingProps = {
    title: t('Newspapers'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  /* Configuring page actions */
  const actions = {
    filter: true,
    create: !isSubadmin && PATHS.AUTHENTICATED.NEWSPAPER_CREATE,
    search: true
  }

  /* Extracting base actions */
  const { onBack, onCreate } = listActions

  return (
    <PageWrapperActionsUnderTitle
      action={
        withHeader ? (
          <PageActions
            {...filterParams}
            onCreate={onCreate}
            actions={actions}
            circle
            filterMenu={<NewspaperFilter {...filterParams} />}
            filterVisibility={filterVisibility}
            setFilterVisibility={setFilterVisibility}
            searchPlaceholder="Search by Newspaper Name"
            collection={newspapers}
            setCollection={setCollection}
            searchField="name"
          />
        ) : null
      }
      onBack={withHeader ? onBack : null}
      breadcrumbs={withHeader ? <HeaderBreadcrumbs /> : null}
      headingProps={withHeader ? headingProps : null}>
      <Row noGutters>
        {loading ? (
          <Col>
            <Spinner height="60vh" text={t('Newspaper loading')} />
          </Col>
        ) : (
          <Col cw={12}>
            <NewspaperTable
              dataSource={collection ?? newspapers}
              isSubadminRole={isSubadmin}
            />
          </Col>
        )}
      </Row>
    </PageWrapperActionsUnderTitle>
  )
}

NewspapersAllPage.propTypes = {
  isListWithCreate: PropTypes.bool,
  withHeader: PropTypes.bool
}

export default NewspapersAllPage
