import { Col, Container, Row, Text } from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import { capitalize } from 'helpers'
import { useTranslations } from 'contexts/Translation'

const RewardOverviewStatus = (props) => {
  const { rewardOverview } = props

  /* Getting translation instance */
  const { t } = useTranslations()

  return (
    <Container>
      <Row noGutters>
        <Col>
          {rewardOverview?.status ? (
            <Text>{t(capitalize(rewardOverview?.status))}</Text>
          ) : (
            <Text type="secondary" variant="overline">
              {t('No statuses have been added yet')}
            </Text>
          )}
        </Col>
      </Row>
    </Container>
  )
}

RewardOverviewStatus.propTypes = {
  rewardOverview: PropTypes.object.isRequired
}

export default RewardOverviewStatus
