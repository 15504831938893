import { Box, Button } from '@qonsoll/react-design'

import { ContentTextEditor } from 'domains/Content/components'
import PropTypes from 'prop-types'
import { Spinner } from 'components'
import { Tooltip } from 'antd'
import { useSaveContent } from 'domains/Content/hooks'
import { useTranslations } from '@qonsoll/translation'

const ContentForm = (props) => {
  const { contentType } = props
  /* Getting translations instance */
  const { t } = useTranslations()

  const {
    template,
    onTextEditorChange,
    saveContentHandler,
    contentLoading,
    handleReset
  } = useSaveContent(contentType)

  const handleChangeEditorState = (value) => onTextEditorChange(value)

  return contentLoading ? (
    <Spinner height="50vh" text={t('Content loading')} />
  ) : (
    <>
      <Box minHeight="300px" mb="24px">
        <ContentTextEditor
          contentType={contentType}
          onChange={handleChangeEditorState}
          initState={template?.state}
        />
      </Box>
      <Box display="flex">
        <Tooltip title={t('Reset all text to default')}>
          <Button type="ghost" mr="8px" onClick={handleReset}>
            {t('Reset')}
          </Button>
        </Tooltip>
        <Button type="primary" onClick={saveContentHandler}>
          {t('Save')}
        </Button>
      </Box>
    </>
  )
}

ContentForm.propTypes = {
  contentType: PropTypes.string
}
export default ContentForm
