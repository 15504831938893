import { useTranslations } from 'contexts/Translation'
import { HeaderBreadcrumbs, Spinner } from 'components'
import { PageWrapper, Row, Col } from '@qonsoll/react-design'
import { useParams, useHistory } from 'react-router-dom'
import { StoreAdvancedView } from 'domains/Store/components'
import PATHS from 'pages/paths'
import { generatePath } from 'react-router-dom'
import { PageActions } from 'components'
import { useDocument } from 'services/api/firebase'
import { COLLECTIONS } from '__constants__'

const StoreShowPage = (props) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()
  /* Getting params from router */
  const params = useParams()
  /* Fetching document data from useDocument hook */
  const [store, loading] = useDocument({
    ref: COLLECTIONS.STORES + '/' + params.storeId
  })

  /* Configuring page header */
  const headingProps = {
    title: store?.name || t('Store show'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  /* Getting id from params */
  const { storeId } = params

  /* Configuring page actions */
  const actions = { edit: true }

  /* Function to handle back click */
  const handleBack = () => history.goBack()
  /* Handling opening of the edit page */
  const handleEdit = () => {
    const pathParams = { storeId }
    const path = generatePath(PATHS.AUTHENTICATED.STORE_EDIT, pathParams)
    history.push(path)
  }

  return (
    <PageWrapper
      action={<PageActions actions={actions} onEdit={handleEdit} />}
      onBack={handleBack}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}>
      <Row noGutters>
        {loading ? (
          <Col>
            <Spinner height="70vh" text={t('Store loading')} />
          </Col>
        ) : (
          <Col cw={12}>
            <StoreAdvancedView store={store} />
          </Col>
        )}
      </Row>
    </PageWrapper>
  )
}

export default StoreShowPage
