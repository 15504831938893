const LS = {
  LAST_SESSION_PROVIDER: 'lastSessionProvider',
  LAST_SESSION: 'lastSession',
  SESSIONS: 'sessions',
  GDPR: 'gdpr',
  LANGUAGE: 'language'
}

export default LS
export { LS }
