import { Container, Row, Col, Text } from '@qonsoll/react-design'
import { NewspaperSimpleView } from '../../../Newspaper/components'
import { useGetRewardNewspaper } from '../../hooks'
import PropTypes from 'prop-types'
import { Spinner } from 'components'
import { useTranslations } from 'contexts/Translation'

export default function RewardNewspaper(props) {
  const { reward } = props

  /* Getting required data */
  const [newspaper, loading] = useGetRewardNewspaper(reward)

  /* Getting translation instance */
  const { t } = useTranslations()

  return (
    <Container>
      <Row noGutters>
        <Col>
          {loading ? (
            <Spinner flex={1} />
          ) : newspaper ? (
            <NewspaperSimpleView {...props} newspaper={newspaper} />
          ) : (
            <Text type="secondary" variant="overline">
              {t('No newspapers have been added yet')}
            </Text>
          )}
        </Col>
      </Row>
    </Container>
  )
}

RewardNewspaper.propTypes = {
  reward: PropTypes.object.isRequired
}
