import { Button, TextArea, Title } from '@qonsoll/react-design'
import { Form, Input } from 'antd'
import { KEYS, MAX_LENGTH_DESCRIPTION, MAX_LENGTH_FIELD } from '__constants__'

import PropTypes from 'prop-types'
import { onTextAreaEnterPress } from 'helpers'
import { useTranslations } from 'contexts/Translation'

const AnyPageConfigurationSimpleForm = (props) => {
  const { initialValues, onSubmit, onCancel, showTitle, form, group } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting new form instance from Form.useForm hook */
  const [baseForm] = Form.useForm()

  /* Getting used form from props or using base form */
  const usedForm = form || baseForm

  /* Resetting form on cancel */
  const handleCancel = () => {
    usedForm.resetFields()
    onCancel?.()
  }
  /* Submitting form on enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      e.stopPropagation()
      usedForm?.submit()
    }
  }

  return form ? (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Any page setup')}
        </Title>
      )}
      <Form.Item
        label={t('Page title')}
        name={[...group, 'title']}
        rules={[
          {
            required: true,
            message: t('Please enter page title!')
          },
          {
            max: MAX_LENGTH_FIELD,
            message: t('Page title is too long!')
          }
        ]}>
        <Input placeholder={t('Enter page title')} />
      </Form.Item>
      <Form.Item
        label={t('Page description')}
        name={[...group, 'description']}
        rules={[
          {
            max: MAX_LENGTH_DESCRIPTION,
            message: t('Page description is too long!')
          }
        ]}>
        <TextArea
          onPressEnter={onTextAreaEnterPress}
          placeholder={t('Please enter page description')}
        />
      </Form.Item>
    </>
  ) : (
    <Form
      form={usedForm}
      initialValues={initialValues}
      onFinish={onSubmit}
      layout="vertical"
      onKeyPress={handleKeyPress}
      autoComplete="off">
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Domain')}
        </Title>
      )}
      <Form.Item
        label={t('Page title')}
        name={[...group, 'title']}
        rules={[
          {
            required: true,
            message: t('Please enter page title!')
          },
          {
            max: MAX_LENGTH_FIELD,
            message: t('Page title is too long!')
          }
        ]}>
        <Input placeholder={t('Enter page title')} />
      </Form.Item>
      <Form.Item
        label={t('Page description')}
        name={[...group, 'description']}
        rules={[
          {
            max: MAX_LENGTH_DESCRIPTION,
            message: t('Page description is too long!')
          }
        ]}>
        <TextArea
          onPressEnter={onTextAreaEnterPress}
          placeholder={t('Please enter page description')}
        />
      </Form.Item>
      {!form && (
        <>
          <Button mr={2} htmlType="button" onClick={handleCancel}>
            {t('Cancel')}
          </Button>
          <Button type="primary" onClick={() => usedForm.submit()}>
            {t('Save')}
          </Button>
        </>
      )}
    </Form>
  )
}

AnyPageConfigurationSimpleForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  showTitle: PropTypes.bool,
  form: PropTypes.any,
  group: PropTypes.array
}

export default AnyPageConfigurationSimpleForm
