import { CardDropdown, SelectableCard } from 'components'
import { Col, Img, Row, Text, Title } from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import styles from './StoreSimpleView.styles'
import { useStoreBaseActions } from 'domains/Store/hooks'
import { useTranslations } from 'contexts/Translation'

const StoreSimpleView = (props) => {
  const { store, onSelect, index, isSelected, actions } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting base actions */
  const { onOpen, onEdit, onDelete } = useStoreBaseActions({
    storeId: store?._id,
    actions
  })

  /* Handling selection of the item */
  const handleSelect = (e) => {
    e.stopPropagation()
    onSelect?.(store?._id)
  }

  return (
    <CardDropdown
      onDelete={onDelete}
      onEdit={onEdit}
      onDoubleClick={onOpen}
      onClick={handleSelect}
      document={store}
      index={index}
      data-testid="storeListItem"
      {...actions}>
      <SelectableCard isSelected={isSelected}>
        <Row noGutters {...styles.fieldRowProps}>
          <Col cw={4}>
            <Img style={styles.imageStyles} src={store?.logoUrl} />
          </Col>
          <Col>
            <Row noGutters {...styles.fieldRowProps}>
              <Col cw={12}>
                <Title level={5} {...styles.fieldTitleProps}>
                  {store?.name}
                </Title>
              </Col>
            </Row>
            <Row noGutters {...styles.fieldRowProps}>
              <Col>
                <Text type="secondary">{t('Sno')}</Text>
              </Col>
              <Col cw="auto">
                <Text {...styles.fieldTitleProps}>{store?.sno}</Text>
              </Col>
            </Row>
            <Row noGutters {...styles.fieldRowProps}>
              <Col>
                <Text type="secondary">{t('Status')}</Text>
              </Col>
              <Col cw="auto">
                <Text {...styles.fieldTitleProps}>{store?.status}</Text>
              </Col>
            </Row>
            <Row noGutters {...styles.fieldRowProps}>
              <Col>
                <Text type="secondary">{t('Total coupons')}</Text>
              </Col>
              <Col cw="auto">
                <Text {...styles.fieldTitleProps}>{store?.totalCoupons}</Text>
              </Col>
            </Row>
            <Row noGutters {...styles.fieldRowProps}>
              <Col>
                <Text type="secondary">{t('Redeemed')}</Text>
              </Col>
              <Col cw="auto">
                <Text {...styles.fieldTitleProps}>{store?.redeemed}</Text>
              </Col>
            </Row>
          </Col>
        </Row>
      </SelectableCard>
    </CardDropdown>
  )
}

StoreSimpleView.propTypes = {
  store: PropTypes.object,
  onSelect: PropTypes.func,
  index: PropTypes.number,
  isSelected: PropTypes.bool,
  actions: PropTypes.object
}

export default StoreSimpleView
