import { uid } from 'helpers'

const generateNewLanguage = () => ({
  uid: uid(),
  label: null,
  name: null,
  shortCode: null,
  value: null
})

export default generateNewLanguage
