import { useCallback, useEffect, useState } from 'react'

import { COLLECTIONS } from '__constants__'
import { getDocument } from 'services/api/firebase'

/**
 * It fetches a rewardStatus document from Firestore and returns it
 * @param reward - The reward object that we're getting the rewardStatus for.
 * @returns An array with four values. The first value is the rewardStatus object, and the second value is a
 * boolean that indicates whether the rewardStatus is loading.
 */
const useGetRewardRewardStatus = (reward) => {
  return [reward?.status, false]
  // TODO To remove code below after 09.07.2023 if there is no need
  // to use reward status separation (separate collection)

  // /* State for data */
  // const [rewardStatus, setRewardStatus] = useState(null)
  // /* State for loading */
  // const [loading, setLoading] = useState(!!reward?.status)
  // /* State for error */
  // const [error, setError] = useState(null)

  // /* Fetching data */
  // const getRewardStatus = useCallback(async () => {
  //   try {
  //     setLoading(true)
  //     const document = await getDocument(
  //       COLLECTIONS.REWARD_STATUSES,
  //       reward?.statusId
  //     )
  //     setRewardStatus(document)
  //     setLoading(false)
  //   } catch (error) {
  //     setError(error)
  //   }
  // }, [reward?.statusId])

  // useEffect(() => {
  //   reward?.statusId && getRewardStatus()
  // }, [reward?.statusId, getRewardStatus])

  // return [rewardStatus, loading, error, getRewardStatus]
}

export default useGetRewardRewardStatus
