import { useGetSubadminData } from 'hooks'
import { useMemo } from 'react'

const useGetQuery = ({
  collectionName,
  where,
  orderBy,
  fieldName,
  statuses
}) => {
  // Add query conditions for subadmin
  const { subadminQuery } = useGetSubadminData(fieldName)

  const statusesValues = statuses ? Object?.values?.(statuses) : []

  /* Query for getting collection reference */
  return useMemo(() => {
    const query = { ref: collectionName }

    const computedWhere = [
      !!statusesValues?.length && ['status', 'in', statusesValues],
      ...(where || []),
      ...subadminQuery
    ]?.filter(Boolean)

    const computedOrderBy = [['_createdAt', 'desc'], ...(orderBy || [])]

    if (computedWhere?.length) query.where = computedWhere
    if (computedOrderBy.length) query.orderBy = computedOrderBy

    return query
  }, [collectionName, where, orderBy, subadminQuery])
}

export default useGetQuery
