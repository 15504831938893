import { Box, Col, Row } from '@qonsoll/react-design'
import { ChevronStyled, ColorBoxStyled } from './ColorPicker.styled'
import { useRef, useState } from 'react'

import ColorChooser from 'color-chooser'
import { DEFAULT_COLOR_RGB } from '__constants__'
import { Icon } from '@qonsoll/icons'
import { Popover } from 'antd'
import PropTypes from 'prop-types'
import { useClickAway } from 'ahooks'

const ColorPicker = (props) => {
  const { value, onChange, setColor, disabled } = props

  const [showPicker, setShowPicker] = useState(false)
  const [colorValue, setColorValue] = useState()

  const triggerRef = useRef(null)

  useClickAway(() => {
    setShowPicker(false)

    onChange?.(colorValue || value)
    setColor?.(colorValue || value)
  }, [triggerRef])

  const colorPickerValue = colorValue || value || DEFAULT_COLOR_RGB

  const handleChange = (color) => {
    onChange?.(color)
    setColorValue(color)
  }

  const handleClick = (e) => {
    if (!disabled) {
      e.stopPropagation()
      setShowPicker(!showPicker)
    }
  }

  return (
    <Row noGutters>
      <Col cw="auto">
        <Popover
          visible={showPicker}
          onClick={handleClick}
          overlayClassName="color-picker-popover"
          content={
            <Box ref={triggerRef}>
              <ColorChooser color={colorPickerValue} onChange={handleChange} />
            </Box>
          }>
          <ColorBoxStyled bg={colorPickerValue} disabled={disabled} />
        </Popover>
      </Col>
      <Col cw="auto">
        <ChevronStyled
          isOpen={showPicker}
          onClick={handleClick}
          disabled={disabled}>
          <Icon size="1.5rem" name="ArrowShortDownFilled" fill="currentColor" />
        </ChevronStyled>
      </Col>
    </Row>
  )
}

ColorPicker.propTypes = {
  setColor: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
}
export default ColorPicker
