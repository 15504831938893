import { Link, Text } from '@qonsoll/react-design'
import { cloneElement, useMemo } from 'react'
import { generatePath, useHistory } from 'react-router-dom'

import MOMENT_FORMATS from '__constants__/momentFormats'
import { StoreStoreStatus } from 'domains/Store/components'
import { TABLE_PATHS } from '__constants__'
import { TableActions } from 'components'
import moment from 'moment'
import { sortByAlphabet } from 'helpers'
import { useGetColumnSearchProps } from 'hooks'
import { useStoreBaseActions } from 'domains/Store/hooks'
import { useTranslations } from '@qonsoll/translation'

const StoreTableActions = ({ storeId, isBlocked }) => {
  const actions = useStoreBaseActions({ storeId, isBlocked })

  return cloneElement(<TableActions />, {
    actions,
    isBlocked,
    name: 'store'
  })
}

const useStoreTableColumns = () => {
  const { t } = useTranslations()
  const history = useHistory()

  const getColumnSearchProps = useGetColumnSearchProps()

  return useMemo(() => {
    const handleOpen = (path, params) => () =>
      history.push(generatePath(path, params))

    return [
      {
        title: <Text fontWeight="600">{t('Sno')}</Text>,
        width: 'var(--table-number-column-width)',
        dataIndex: 'sno',
        key: 'sno',
        align: 'center',
        sorter: (a, b) => a?.sno - b?.sno,
        ...getColumnSearchProps('sno')
      },
      {
        title: <Text fontWeight="600">{t('Store name')}</Text>,
        dataIndex: 'name',
        key: 'storeName',
        align: 'center',
        sorter: (a, b) => sortByAlphabet(a?.name, b?.name),
        ...getColumnSearchProps('name', 'store name'),
        render: (_, record) => (
          <Link
            onClick={handleOpen(TABLE_PATHS.STORE_SHOW, {
              storeId: record?._id
            })}>
            {record?.name}
          </Link>
        )
      },
      {
        title: t('Date'),
        dataIndex: '_createdAt',
        key: 'date',
        align: 'center',
        sorter: (a, b) => a?._createdAt?.seconds - b?._createdAt?.seconds,
        render: (date) => (
          <Text>
            {moment
              .unix(date?.seconds)
              .format(MOMENT_FORMATS.MONTH_DAYS_COMMA_YEAR)}
          </Text>
        )
      },

      {
        title: <Text fontWeight="600">{t('Newspaper')}</Text>,
        dataIndex: 'newspaper',
        key: 'newspaper',
        align: 'center',
        sorter: (a, b) =>
          sortByAlphabet(a?.newspaper?.name, b?.newspaper?.name),
        ...getColumnSearchProps(['newspaper', 'name']),
        render: (value) => (
          <Link
            onClick={handleOpen(TABLE_PATHS.NEWSPAPER_SHOW, {
              newspaperId: value?._id
            })}>
            {value?.name}
          </Link>
        )
      },

      {
        title: t('Total coupons'),
        dataIndex: 'totalCoupons',
        width: 'var(--table-total-coupons-column-width)',
        key: 'totalCoupons',
        align: 'center',
        sorter: (a, b) => a?.totalCoupons - b?.totalCoupons
      },
      {
        title: <Text fontWeight="600">{t('Redeemed')}</Text>,
        dataIndex: 'redeemed',
        width: 'var(--table-redeemed-column-width)',
        key: 'redeemed',
        align: 'center',
        sorter: (a, b) => a?.redeemed - b?.redeemed
      },
      {
        title: <Text fontWeight="600">{t('Status')}</Text>,
        dataIndex: 'status',
        width: 'var(--table-status-column-width)',
        key: 'status',
        align: 'center',
        sorter: (a, b) => sortByAlphabet(a?.status, b?.status),
        render: (_, record) => <StoreStoreStatus status={record?.status} />
      },
      {
        title: <Text fontWeight="600">{t('Actions')}</Text>,
        width: 'var(--table-actions-column-width)',
        dataIndex: 'actions',
        key: 'actions',
        align: 'center',
        render: (_, record) => (
          <StoreTableActions
            storeId={record?._id}
            isBlocked={record?.isBlocked}
            logoUrl={record?.logoUrl}
          />
        )
      }
    ]
  }, [t, getColumnSearchProps, history])
}

export default useStoreTableColumns
