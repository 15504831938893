import { Button, Title } from '@qonsoll/react-design'
import {
  useActionsStoreAdvancedForm,
  useInitializeStoreAdvancedForm
} from './hooks'

import { Form } from 'antd'
import { KEYS } from '__constants__'
import { NewspaperSelect } from 'domains/Newspaper/components'
import PropTypes from 'prop-types'
import { StoreSimpleForm } from 'domains/Store/components'
import { StoreStatusSelect } from 'domains/StoreStatus/components'
import { useCheckChangeFormValue } from 'hooks'
import { useTranslations } from 'contexts/Translation'

const StoreAdvancedForm = (props) => {
  const { initialData, group, showTitle } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting form instance from hook */
  const { form } = useInitializeStoreAdvancedForm(initialData)

  /* Getting loading state and actions from hook */
  const { loading, onFinish, onReset } = useActionsStoreAdvancedForm({
    initialData,
    form
  })

  /* Handling enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      form.submit()
    }
  }

  const { triggerValueChange, isEqualForms } =
    useCheckChangeFormValue(initialData)

  return group ? (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Store')}
        </Title>
      )}
      <StoreSimpleForm showTitle={false} group={[...group, 'store']} />
      <Form.Item name={[group, 'newspaper']}>
        <NewspaperSelect />
      </Form.Item>
      <Form.Item name={[group, 'status']}>
        <StoreStatusSelect />
      </Form.Item>
    </>
  ) : (
    <Form
      onKeyPress={handleKeyPress}
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
      onValuesChange={triggerValueChange}
      form={form}>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Store')}
        </Title>
      )}
      <StoreSimpleForm group={['store']} />
      <Form.Item
        name={['newspaper']}
        rules={[
          {
            required: true,
            message: t('Please select newspaper!')
          }
        ]}>
        <NewspaperSelect />
      </Form.Item>
      <Form.Item
        name={['store', 'status']}
        rules={[
          {
            required: true,
            message: t('Please select status!')
          }
        ]}>
        <StoreStatusSelect />
      </Form.Item>
      <Button mr={2} htmlType="button" onClick={onReset}>
        {t('Cancel')}
      </Button>
      <Button
        type="primary"
        loading={loading}
        disabled={isEqualForms}
        onClick={() => form.submit()}>
        {t('Save')}
      </Button>
    </Form>
  )
}

StoreAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  group: PropTypes.array,
  showTitle: PropTypes.bool
}

export default StoreAdvancedForm
