import PropTypes from 'prop-types'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { StyledModal, StyledFilter, okButtonStyle } from './FilterModal.styles'
import { useTranslations } from 'contexts/Translation'

const FilterModal = (props) => {
  const { handleReset, handleOk, handleCancel, filterVisibility, filterMenu } =
    props

  /* Getting translations instance */
  const { t } = useTranslations()

  return (
    <StyledModal
      closeIcon={<ArrowLeftOutlined />}
      okButtonProps={{ style: okButtonStyle }}
      cancelButtonProps={{ type: 'ghost', onClick: handleReset }}
      okText={t('Apply')}
      cancelText={t('Reset')}
      visible={filterVisibility}
      title={t('Filter')}
      onOk={handleOk}
      onCancel={handleCancel}>
      <StyledFilter>{filterMenu}</StyledFilter>
    </StyledModal>
  )
}
FilterModal.propTypes = {
  handleReset: PropTypes.func,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  filterVisibility: PropTypes.bool,
  filterMenu: PropTypes.node
}

export default FilterModal
