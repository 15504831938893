import { NewspaperFilter, SubadminStatusFilter } from './components'

import PropTypes from 'prop-types'

const SubadminFilter = (props) => {
  const { filterVisibility, filterData, setFilterData, isSuperAdmin } = props

  return filterVisibility ? (
    <>
      {!isSuperAdmin && (
        <NewspaperFilter
          filterData={filterData}
          setFilterData={setFilterData}
        />
      )}
      <SubadminStatusFilter
        filterData={filterData}
        setFilterData={setFilterData}
        isSuperAdmin={isSuperAdmin}
      />
    </>
  ) : null
}

SubadminFilter.propTypes = {
  filterVisibility: PropTypes.bool,
  filterData: PropTypes.object,
  setFilterData: PropTypes.func,
  isSuperAdmin: PropTypes.bool
}

export default SubadminFilter
