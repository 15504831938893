import { Col, PageWrapper, Row } from '@qonsoll/react-design'
import { HeaderBreadcrumbs, Spinner, PageActions } from 'components'
import { useParams } from 'react-router-dom'
import { COLLECTIONS } from '__constants__'
import { UserAdvancedView } from 'domains/User/components'
import { useDocument } from 'services/api/firebase'
import { useTranslations } from 'contexts/Translation'
import { useUserBaseActions } from 'domains/User/hooks'

const UserShowPage = (props) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting params from router */
  const { userId } = useParams()
  /* Fetching document data from useDocument hook */
  const [user, loading] = useDocument({
    ref: COLLECTIONS.USERS + '/' + userId
  })

  /* Getting base actions */
  const { onEdit, onBack } = useUserBaseActions({
    userId
  })

  /* Configuring page header */
  const headingProps = {
    title: t('User Details'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  /* Configuring page actions */
  const actions = { edit: true }

  return (
    <PageWrapper
      action={<PageActions actions={actions} onEdit={onEdit} />}
      onBack={onBack}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}>
      <Row noGutters>
        {loading ? (
          <Col>
            <Spinner height="70vh" text={t('User loading')} />
          </Col>
        ) : (
          <Col cw={12}>
            <UserAdvancedView user={user} />
          </Col>
        )}
      </Row>
    </PageWrapper>
  )
}

export default UserShowPage
