import { useEffect, useRef, useState } from 'react'
import { AutoComplete } from 'antd'
import { TextArea } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { checkBrackets } from 'helpers'
import { AUTOCOMPLETE_POSSIBLE_VARIABLES } from '__constants__'
import { useTranslations } from '@qonsoll/translation'

const DEFAULT_EXPIRATION_MESSAGE =
  "Hey {userFullName}, the {points} points you earned for {newsTipTitle} will expire in {daysUntilExpiration} days! Log in to the app and spend them before it's too late!"

const NewspaperExpirationMessageInput = (props) => {
  const { value, onChange, disabled, ...rest } = props

  const [textAreaValue, setTextAreaValue] = useState(value)
  const { t } = useTranslations()

  const textAreaRef = useRef()

  useEffect(
    () => {
      // Set default value if value is empty only on first render
      if (!value) {
        setTextAreaValue(DEFAULT_EXPIRATION_MESSAGE)
        onChange?.(DEFAULT_EXPIRATION_MESSAGE)
      }
    },
    // This effect should be called only on first render, so we pass empty array as second argument
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const handleSearch = () => {
    // Getting symbol before cursor
    const cursorPosition =
      textAreaRef.current?.resizableTextArea?.textArea.selectionStart

    // Getting symbol before cursor
    const symbolBeforeCursor = textAreaValue?.[cursorPosition - 1]

    // Check if we have unclosed bracket in text
    if (symbolBeforeCursor === '{' || !checkBrackets(textAreaValue)) {
      // Return all possible options
      return AUTOCOMPLETE_POSSIBLE_VARIABLES.map((option) => ({
        value: option.value
      }))
    }

    return []
  }

  const onSelectAutocompleteValue = (value) => {
    const newValue = textAreaValue + value + '} '

    setTextAreaValue(newValue)
    onChange?.(newValue)
  }

  const onChangeTextArea = (value) => {
    onChange?.(value)
    setTextAreaValue(value)
  }

  return (
    <AutoComplete
      disabled={disabled}
      options={handleSearch(value)}
      onChange={onChangeTextArea}
      onSelect={onSelectAutocompleteValue}
      value={value}
      {...rest}>
      <TextArea
        autoSize={{ minRows: 3 }}
        disabled={disabled}
        ref={textAreaRef}
        placeholder={`${t(
          "Please enter the message that will appear in the tipper's notifications"
        )}.`}
      />
    </AutoComplete>
  )
}

NewspaperExpirationMessageInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
}

export default NewspaperExpirationMessageInput
