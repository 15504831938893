import { useTranslations } from 'contexts/Translation'
import { HeaderBreadcrumbs } from 'components'
import { PageWrapper, Row, Col } from '@qonsoll/react-design'
import { RejectReasonAdvancedForm } from 'domains/RejectReason/components'
import { useRejectReasonBaseActions } from 'domains/RejectReason/hooks'

const RejectReasonCreate = (props) => {
  /* Getting translations instance */
  const { t } = useTranslations()

  /* Getting base actions */
  const { onBack } = useRejectReasonBaseActions()

  /* Configuring page header */
  const headingProps = {
    title: t('Reject reason create'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  return (
    <PageWrapper
      onBack={onBack}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}>
      <Row noGutters>
        <Col cw={[12, 12, 10, 8, 7]}>
          <RejectReasonAdvancedForm showTitle={false} />
        </Col>
      </Row>
    </PageWrapper>
  )
}

export default RejectReasonCreate
