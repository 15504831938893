import styled from 'styled-components'
import { Button } from '@qonsoll/react-design'
import { withAlphaHex } from 'with-alpha-hex'

const POSITION_TO_ABSOLUTE = {
  'bottom-right': {
    bottom: '20px',
    right: '20px'
  },
  'bottom-left': {
    bottom: '20px',
    left: '20px'
  },
  'top-left': {
    top: '20px',
    left: '20px'
  },
  'top-right': {
    top: '20px',
    right: '20px'
  }
}
const WidgetWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  position: absolute;
  z-index: 1;
  top: ${({ position }) => POSITION_TO_ABSOLUTE[position]?.top || 'unset'};
  bottom: ${({ position }) =>
    POSITION_TO_ABSOLUTE[position]?.bottom || 'unset'};
  left: ${({ position }) => POSITION_TO_ABSOLUTE[position]?.left || 'unset'};
  right: ${({ position }) => POSITION_TO_ABSOLUTE[position]?.right || 'unset'};
`
const StyledButton = styled(Button).attrs(({ isGlowing }) => ({
  className: isGlowing && 'glowing-effect'
}))`
  --glowingColor: ${({ isGlowing, backgroundColor }) =>
    isGlowing && backgroundColor};
  --glowingColor-fused: ${({ isGlowing, backgroundColor }) =>
    isGlowing && withAlphaHex(backgroundColor, 0.8)};
`

export { WidgetWrapper, StyledButton }
