import { COLLECTIONS } from '__constants__'

import { useCollection } from 'services/api/firebase'
import { useGetQuery } from 'hooks'

/**
 * It returns an array of values that are used to render web tipps
 * @param props - An object that contains the query parameters.
 * @returns An array of values.
 */

const useGetWebTippsConfigurations = (props) => {
  /* Query for getting collection reference */
  const query = useGetQuery({
    collectionName: COLLECTIONS.WEB_TIPPS_CONFIGURATIONS,
    where: props?.where,
    orderBy: props?.orderBy
  })

  /* Getting collection data */
  return useCollection(query)
}

export default useGetWebTippsConfigurations
