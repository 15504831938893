import { Form } from 'antd'
import { useEffect } from 'react'

const useInitializeNewspaperAdvancedSettingForm = (
  initialData,
  activeLanguage
) => {
  /* Getting form instance */
  const [form] = Form.useForm()

  /* Setting initial data */
  useEffect(() => {
    if (initialData) {
      /* Getting initial values */
      const { newspaper } = initialData

      /* Formatting date fields */
      const formValues = {
        expirationPoints: {
          expirationMessageTitle:
            {
              [activeLanguage]:
                newspaper?.expirationMessageTitle?.[activeLanguage]
            } ?? null,
          arePointsExpiring: newspaper?.arePointsExpiring ?? null,
          daysToExpiration: newspaper?.daysToExpiration ?? null,
          notificationRanges: newspaper?.notificationRanges ?? null,
          expirationMessage: {
            [activeLanguage]:
              newspaper?.expirationMessage?.[activeLanguage] ?? null
          }
        }
      }

      /* Setting initial values to form instance */
      form.setFieldsValue(formValues)
    }
  }, [initialData, form])

  return { form }
}

export default useInitializeNewspaperAdvancedSettingForm
