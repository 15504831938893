import { useState } from 'react'
import { TOKEN_SESSION_PROVIDERS } from '__constants__'

const useTokenExpirationTime = () => {
  const [tokenExpirationTime, setTokenExpirationTime] = useState(
    localStorage.getItem(TOKEN_SESSION_PROVIDERS.TOKEN_EXPIRATION_TIME) || null
  )

  const updateTokenExpirationTime = (newTokenExpirationTime) => {
    localStorage.setItem(
      TOKEN_SESSION_PROVIDERS.TOKEN_EXPIRATION_TIME,
      newTokenExpirationTime
    )
    setTokenExpirationTime(newTokenExpirationTime)
  }

  return [tokenExpirationTime, updateTokenExpirationTime]
}

export default useTokenExpirationTime
