import { Col, Container, Row, Text } from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

export default function EditorEditorStatus(props) {
  const { editor } = props

  /* Getting translation instance */
  const { t } = useTranslations()

  const status = editor?.status

  return (
    <Container>
      <Row noGutters>
        <Col>
          {status ? (
            <Text>{t(status)}</Text>
          ) : (
            <Text type="secondary" variant="overline">
              {t('No editor statuses have been added yet')}
            </Text>
          )}
        </Col>
      </Row>
    </Container>
  )
}

EditorEditorStatus.propTypes = {
  editor: PropTypes.object.isRequired
}
