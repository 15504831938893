import { Select } from 'antd'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'
import PropTypes from 'prop-types'

const DefaultLanguageSelect = (props) => {
  const { value } = props
  const { languages, t } = useTranslations()

  const options = useMemo(
    () => languages?.filter(({ value }) => value !== value?.value),
    [languages, value]
  )

  return (
    <Select
      placeholder={t('Select default language')}
      labelInValue
      options={options}
      {...props}
    />
  )
}

DefaultLanguageSelect.propTypes = {
  value: PropTypes.object
}

export default DefaultLanguageSelect
