import {
  WebTippsPagePreview,
  WidgetSimpleView
} from 'domains/WebTippsConfiguration/components'

import PropTypes from 'prop-types'

const WidgetPreview = (props) => {
  const { pageConfiguration, initialValues, widgetConfiguration } = props

  return (
    <WebTippsPagePreview
      initialValues={initialValues}
      pageConfiguration={pageConfiguration}
      countTipps={1}
      children={
        widgetConfiguration?.isActive && (
          <WidgetSimpleView widgetConfig={widgetConfiguration} />
        )
      }
    />
  )
}

WidgetPreview.propTypes = {
  initialValues: PropTypes.object,
  widgetConfiguration: PropTypes.object,
  pageConfiguration: PropTypes.object
}

export default WidgetPreview
