import { COLLECTIONS, EMOJI_REGEX, STATUSES, STORAGE_PATH } from '__constants__'
import {
  createDocument,
  getId,
  updateDocument,
  updateParent
} from 'services/api/firebase'
import { deleteReplacedFile, uploadImage } from 'helpers'

import { Reward } from 'models'
import { message } from 'antd'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const useActionsRewardAdvancedForm = ({ initialData, form } = {}) => {
  /* State for loading */
  const [loading, setLoading] = useState(false)
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()
  /* Getting history state */
  const historyState = history.location.state

  /* Function for preparing values for saving */
  const prepareValues = async (values = {}, additionalValues = {}) => {
    /* Getting id */
    const rewardId = initialData?.reward?._id || getId(COLLECTIONS.REWARDS)
    const { ACTIVE, BLOCKED } = STATUSES.REWARD_STATUSES

    // Converting emoji to unicode string
    const computedEmoji = values?.reward?.emoji?.codePointAt(0)?.toString(16)

    // Check if data is emoji or not
    const emoji = EMOJI_REGEX.test(values?.reward?.emoji) ? computedEmoji : null

    /* Preparing reward values */
    const preparedValues = {
      _id: rewardId,
      ...additionalValues,
      name: values?.reward?.name ?? null,
      redeemed: initialData?.reward?.redeemed ?? 0,
      points: values?.reward?.points ?? null,
      newspaperId: values?.newspaper?._id ?? null,
      type: values?.reward?.type ?? null,
      storeId: values?.store?._id ?? null,
      imageUrl:
        (await uploadImage(values?.reward?.imageUrl, STORAGE_PATH.REWARD)) ??
        null,
      isTotalUsesEnabled: values?.reward?.isTotalUsesEnabled ?? false,
      isBlocked: !values?.reward?.isVisible ?? false,
      isDeleted: initialData?.reward?.isDeleted ?? false,
      status: initialData?.reward?.status ?? ACTIVE,
      description: values?.reward?.description ?? null,
      direction: values?.reward?.direction ?? null,
      sno: initialData?.reward?.sno ?? null,
      emoji: emoji ?? null
    }

    // rewrite of status field, according to the form value
    // if there is no deleted status currently applied
    if ((initialData && !initialData.isDeleted) || !initialData) {
      // if status should be changed due to visibility (blocked) change
      if (!values?.reward?.isVisible) {
        preparedValues.status = preparedValues.isBlocked ? BLOCKED : ACTIVE
      }
    }

    return preparedValues
  }

  /* Saving form data */
  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)

      if (historyState?.parent)
        await updateParent(historyState.parent, data._id)

      // // Save data
      if (initialData) {
        // Delete replaced image
        await deleteReplacedFile(initialData?.reward?.imageUrl, data?.imageUrl)

        await updateDocument(COLLECTIONS.REWARDS, initialData.reward?._id, data)
        message.success(t('Reward successfully updated'))
      } else {
        await createDocument(COLLECTIONS.REWARDS, data, data._id)
        message.success(t('Reward successfully created'))
      }
      // Final callback
      callback?.()
    } catch (error) {
      throw new Error(
        t('Something went wrong during data save. ', error?.message)
      )
    }
  }

  /* Function for validating form */
  const validateForm = (values) => {
    try {
      // Prepare data to be validated
      const validationData = {
        name: values?.reward?.name,
        redeemed: values?.reward?.redeemed,
        points: values?.reward?.points,
        emoji: EMOJI_REGEX.test(values?.reward?.emoji)
      }
      Reward.validationSchema.validateSync(validationData)
    } catch (error) {
      throw new Error(t('Reward validation error: ') + t(error.message))
    }
  }

  /* On finish callback */
  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()
      // Validate fields
      validateForm(formValues)
      // Final callback
      const callback = () => history.goBack()
      // Save data
      await saveForm(formValues, callback)
    } catch (error) {
      message.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  /* On cancel callback */
  const onReset = () => {
    form.resetFields()
    history.goBack()
  }

  return { onFinish, onReset, loading, saveForm, validateForm, prepareValues }
}

export default useActionsRewardAdvancedForm
