import { useCollectionData } from 'react-firebase-hooks/firestore'
import { useMemo } from 'react'
import { COLLECTIONS } from '__constants__'
import { collection, query } from 'firebase/firestore'
import firestore from 'services/firebase/firestore'

const useGetContent = () => {
  const [contents = [], loading, error] = useCollectionData(
    query(collection(firestore, COLLECTIONS.CONTENT))
  )

  return useMemo(() => [contents, loading, error], [contents, loading, error])
}

export default useGetContent
