import { Col, Row } from '@qonsoll/react-design'
import { HeaderBreadcrumbs, Spinner } from 'components'
import { UserFilter, UserTable } from 'domains/User/components'
import { useGetUsers, useTransformedDataSource } from 'domains/User/hooks'
import { useMemo, useState } from 'react'

import { PageActions } from 'components'
import { PageWrapperActionsUnderTitle } from 'components'
import PropTypes from 'prop-types'
import { useFilter } from 'hooks'
import { useTranslations } from 'contexts/Translation'
import { useUserBaseActions } from 'domains/User/hooks'

const UsersAllPage = (props) => {
  const { withHeader = true } = props

  const [collection, setCollection] = useState()
  /* Getting translations instance */
  const { t } = useTranslations()

  /* Getting filter params */
  const filterParams = useFilter('user')

  /* Destructuring filter params */
  const { filterVisibility, filterData, setFilterVisibility } = filterParams
  /* Building filter query */
  const ref = useMemo(() => filterData, [filterData])

  /* Fetching users */
  const [users, loading] = useGetUsers(ref)

  const transformedDataSource = useTransformedDataSource(users)
  /* Getting base actions */
  const listActions = useUserBaseActions()

  /* Configuring page header */
  const headingProps = {
    title: t('Users'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  /* Configuring page actions */
  const actions = {
    filter: true,
    search: true
  }
  /* Extracting base actions */
  const { onBack } = listActions

  return (
    <PageWrapperActionsUnderTitle
      action={
        withHeader ? (
          <PageActions
            {...filterParams}
            actions={actions}
            circle
            filterMenu={<UserFilter {...filterParams} />}
            filterVisibility={filterVisibility}
            setFilterVisibility={setFilterVisibility}
            collection={transformedDataSource}
            setCollection={setCollection}
            searchField="fullName"
            searchPlaceholder="Search by Name"
          />
        ) : null
      }
      onBack={withHeader ? onBack : null}
      breadcrumbs={withHeader ? <HeaderBreadcrumbs /> : null}
      headingProps={withHeader ? headingProps : null}>
      <Row noGutters width="100%">
        {loading ? (
          <Col>
            <Spinner height="60vh" text={t('User loading')} />
          </Col>
        ) : (
          <Col cw={12}>
            <UserTable dataSource={collection ?? transformedDataSource} />
          </Col>
        )}
      </Row>
    </PageWrapperActionsUnderTitle>
  )
}

UsersAllPage.propTypes = {
  isListWithCreate: PropTypes.bool,
  withHeader: PropTypes.bool
}

export default UsersAllPage
