import { notification } from 'antd'
import { useTranslations } from 'contexts/Translation'

const useHandleSuccess = () => {
  const { t } = useTranslations()

  const handleSuccess = (success) => {
    notification.success({
      message: t('Success'),
      description: success?.message || ''
    })
  }

  return handleSuccess
}

export default useHandleSuccess
