import { EmailStatusFilter } from './components'
import PropTypes from 'prop-types'

const EmailFilter = (props) => {
  const { filterVisibility, filterData, setFilterData } = props

  return filterVisibility ? (
    <>
      <EmailStatusFilter
        filterData={filterData}
        setFilterData={setFilterData}
      />
    </>
  ) : null
}

EmailFilter.propTypes = {
  filterVisibility: PropTypes.bool,
  filterData: PropTypes.object,
  setFilterData: PropTypes.func
}

export default EmailFilter
