import PropTypes from 'prop-types'
import { Select } from '@qonsoll/react-design'
import { useGetCountries } from 'domains/Country/hooks'
import { useMemo } from 'react'

const CountriesSelect = (props) => {
  const { value } = props

  const ref = useMemo(
    () => ({
      where: [['isBlocked', '==', false]]
    }),
    []
  )

  const [countries, countriesLoading] = useGetCountries(ref)

  const options = useMemo(
    () =>
      countries?.map((item) => ({
        ...item,
        value: item?._id,
        label: item?.internationalName
      })),
    [countries]
  )

  const filterOption = (input, option) =>
    (option?.internationalName ?? '')
      .toLowerCase()
      .includes(input.toLowerCase())

  return (
    <Select
      mode="multiple"
      loading={countriesLoading}
      options={options}
      value={value}
      filterOption={filterOption}
      {...props}
    />
  )
}

CountriesSelect.propTypes = {
  value: PropTypes.array,
  setCountriesLanguages: PropTypes.func
}
export default CountriesSelect
