import {
  MAX_LENGTH_FIELD,
  MAX_LENGTH_NOTIFICATION_MESSAGE
} from '__constants__'

import { useTranslations } from 'contexts/Translation'

const useNotificationSimpleFormFieldsRules = () => {
  const { t } = useTranslations()

  const nameFieldRules = [
    {
      required: true,
      message: t('Please enter your notification name!')
    },
    {
      max: MAX_LENGTH_FIELD,
      message: t('Notification name is too long!')
    }
  ]
  const textEnFieldRules = [
    {
      required: true,
      message: t('Please enter your notification text in English!')
    },
    {
      max: MAX_LENGTH_NOTIFICATION_MESSAGE,
      message: t('Notification text is too long!')
    }
  ]
  const textNoFieldRules = [
    {
      required: true,
      message: t('Please enter your notification text in Norway!')
    },
    {
      max: MAX_LENGTH_NOTIFICATION_MESSAGE,
      message: t('Notification text is too long!')
    }
  ]
  const receiversTypeFieldRules = [
    {
      required: true,
      message: t('Please select your notification receivers!')
    }
  ]
  return {
    nameFieldRules,
    textEnFieldRules,
    textNoFieldRules,
    receiversTypeFieldRules
  }
}

export default useNotificationSimpleFormFieldsRules
