import { Button, Title } from '@qonsoll/react-design'
import {
  useActionsEmailAdvancedForm,
  useInitializeEmailAdvancedForm
} from './hooks'

import { EmailSimpleForm } from 'domains/Email/components'
import { Form } from 'antd'
import { KEYS } from '__constants__'
import { NewspaperSelect } from 'domains/Newspaper/components'
import PropTypes from 'prop-types'
import { SUPER_ADMIN_ROLE } from '__constants__'
import { useCheckChangeFormValue } from 'hooks'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

const EmailAdvancedForm = (props) => {
  const { initialData, showTitle } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting form instance from hook */
  const { form } = useInitializeEmailAdvancedForm(initialData)

  const { role, newspaperId } = useUser()

  const isSuperAdmin = role === SUPER_ADMIN_ROLE

  /* Handling enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      form.submit()
    }
  }

  /* Getting loading state and actions from hook */
  const { loading, onFinish, onReset } = useActionsEmailAdvancedForm({
    initialData,
    form,
    isSuperAdmin,
    newspaperId // in case if user is local admin, his newspaper is already chosen
    // so we have to pass it further into the function which assembles the actual document to put it into the DB
  })

  const { triggerValueChange, isEqualForms } = useCheckChangeFormValue(form)

  return (
    <Form
      onKeyPress={handleKeyPress}
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
      onValuesChange={triggerValueChange}
      form={form}>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Email')}
        </Title>
      )}
      <EmailSimpleForm group={['email']} />

      {isSuperAdmin && (
        <Form.Item
          name="newspaper"
          rules={[{ required: true, message: t('Please select newspaper') }]}>
          <NewspaperSelect />
        </Form.Item>
      )}

      <Button mr={2} htmlType="button" onClick={onReset} mb={4}>
        {t('Cancel')}
      </Button>
      <Button
        type="primary"
        loading={loading}
        onClick={() => form.submit()}
        disabled={isEqualForms}>
        {t('Save')}
      </Button>
    </Form>
  )
}

EmailAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  group: PropTypes.array,
  showTitle: PropTypes.bool
}

export default EmailAdvancedForm
