import { Box, Card, Col, PageWrapper, Row, Text } from '@qonsoll/react-design'
import { HeaderBreadcrumbs, PageActions, Spinner } from 'components'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import {
  useDomainConfigurationModalActions,
  useGetWebTippsConfigurationInitialValues,
  useGetWebTippsConfigurationTabComponents,
  useWebTippsConfigurationBaseActions
} from 'domains/WebTippsConfiguration/hooks'

import { DOMAIN_NAME_PREFIX } from 'domains/WebTippsConfiguration/__constants__'
import { DomainConfigurationModal } from 'domains/WebTippsConfiguration/components'
import PATHS from 'pages/paths'
import { SUPER_ADMIN_ROLE } from '__constants__'
import { Tabs } from 'antd'
import { useGetNewspaper } from 'domains/Newspaper/hooks'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

const { TabPane } = Tabs

const WebTippsConfigurationEdit = () => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting params from router */
  const { webTippsConfigurationId } = useParams()
  const { role } = useUser()

  /* Getting history */
  const history = useHistory()

  /* Fetching web tipps initial values */
  const [
    initialValues,
    loadingInitialValues,
    getDomainConfigurationsInitialValues
  ] = useGetWebTippsConfigurationInitialValues(webTippsConfigurationId)
  /* Getting actions and states for web tipps configuration modal */
  const {
    handleClickAddDomain,
    handleCancel,
    handleNextStep,
    handleSubmitLoading,
    submitLoading,
    modalVisible,
    currentStep,
    domainConfigurationForm,
    okText
  } = useDomainConfigurationModalActions()

  /* Getting base actions */
  const { onBack, onUpdate } = useWebTippsConfigurationBaseActions({
    webTippsConfigurationId,
    handleSubmitLoading,
    initialValues
  })

  /* Get newspaper document */
  const [newspaper, newspaperLoading] = useGetNewspaper(
    initialValues?.newspaperId
  )

  const computedLoading = !newspaperLoading && !loadingInitialValues

  /* Configuring page header */
  const headingProps = {
    title: t('Web-Tipps configurations'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  const isSuperAdmin = role === SUPER_ADMIN_ROLE

  /* Configuring page actions */
  const actions = {
    create: computedLoading && isSuperAdmin
  }

  const tabsList = useGetWebTippsConfigurationTabComponents(
    initialValues,
    webTippsConfigurationId
  )

  const handleAddDomain = async (data) => {
    try {
      // IN PROGRESS, please don't delete it
      const domainNameWithoutSubdomain = data?.domainName

      // Get domain name with prefix "tipp."
      const formattedDomainName = `${DOMAIN_NAME_PREFIX}${domainNameWithoutSubdomain}`

      // Third parameter "true" is needed to throw error above
      await onUpdate(
        { ...data, domainName: formattedDomainName },
        initialValues,
        true
      )
    } catch (error) {
      throw Error(error)
    }
  }

  const handleOpenNewspaper = () => {
    const pathParams = { newspaperId: initialValues?.newspaperId }
    const path = generatePath(PATHS.AUTHENTICATED.NEWSPAPER_SHOW, pathParams)

    history.push(path)
  }

  const headerCardData = [
    {
      text: 'Newspaper name',
      data: newspaper?.name,
      cursor: 'pointer',
      onClick: handleOpenNewspaper
    },
    { text: 'Domain name', data: initialValues?.domainName }
  ]

  const isHasDomainName = !!initialValues?.domainName

  const computedHeadingButtonText = isHasDomainName
    ? t('Edit custom domain')
    : t('Add custom domain')
  return (
    <PageWrapper
      action={
        <PageActions
          onCreate={handleClickAddDomain}
          createText={computedHeadingButtonText}
          actions={actions}
        />
      }
      onBack={onBack}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}>
      <Card mb={2}>
        <Row noGutters display="flex" alignItems="center">
          <Col>
            {computedLoading ? (
              headerCardData?.map(({ text, data, cursor, onClick }, index) => (
                <Box
                  display="flex"
                  alignItems="center"
                  key={`headerCardData-${index}`}>
                  <Box mr={2}>
                    <Text type="secondary">{`${text}:`}</Text>
                  </Box>
                  <Box>
                    <Text cursor={cursor} onClick={data && onClick}>
                      {data || `n/a`}
                    </Text>
                  </Box>
                </Box>
              ))
            ) : (
              <Spinner height="6vh" />
            )}
          </Col>
        </Row>
      </Card>

      <Row noGutters>
        {loadingInitialValues ? (
          <Col>
            <Spinner height="70vh" text={t('Web tipps is loading')} />
          </Col>
        ) : (
          <>
            <DomainConfigurationModal
              addDomainFormProps={{
                form: domainConfigurationForm,
                initialValues:
                  getDomainConfigurationsInitialValues(initialValues),
                onSubmit: handleAddDomain
              }}
              visible={modalVisible}
              currentStep={currentStep}
              submitLoading={submitLoading}
              okText={okText}
              handleNextStep={handleNextStep}
              handleCancel={handleCancel}
            />
            <Col cw={12}>
              <Card mb={2} width="100%">
                <Tabs defaultActiveKey="1">
                  {tabsList?.map(({ name, component, cw }) => (
                    <TabPane tab={t(name)} key={name}>
                      <Row noGutters width="100%">
                        <Col cw={cw || 6}>{component}</Col>
                      </Row>
                    </TabPane>
                  ))}
                </Tabs>
              </Card>
            </Col>
          </>
        )}
      </Row>
    </PageWrapper>
  )
}

export default WebTippsConfigurationEdit
