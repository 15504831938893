import { ENDPOINTS, MOMENT_FORMATS } from '__constants__'
import { getBlob, getMetadata, ref } from 'firebase/storage'

import { REWARD_OVERVIEW_TYPES_ALL } from 'domains/RewardOverview/constants'
import { capitalize } from 'helpers'
import { message } from 'antd'
import moment from 'moment'
import { storage } from 'services/firebase'
import { useEvent } from 'hooks'
import { useState } from 'react'
import { useTranslations } from '@qonsoll/translation'
import { useUser } from 'domains/User/context'

const FIREBASE_FUNCTIONS_API_URL =
  process.env.REACT_APP_FIREBASE_FUNCTIONS_API_URL

const useExportRewardOverviewCSV = () => {
  const { t } = useTranslations()
  const { token, email, role } = useUser()
  const [loading, setLoading] = useState(false)
  const [loadingAll, setLoadingAll] = useState(false)

  const downloadFileToDevice = (blob, fileFormat, title) => {
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.style.display = 'none'

    link.href = url

    link.setAttribute('download', `${title}.${fileFormat}`)

    // Append to html link element page
    document.body.appendChild(link)

    // Start download
    link.click()

    // Clean up and remove the link
    link.parentNode.removeChild(link)
  }

  const exportCSVRewardOverview = useEvent(async (rewardOverviewType) => {
    try {
      rewardOverviewType === REWARD_OVERVIEW_TYPES_ALL
        ? setLoadingAll(true)
        : setLoading(true)

      const requestData = {
        rewardOverviewType,
        email,
        role
      }

      const requestBodyParametersFormatted = JSON.stringify(requestData)

      const response = await fetch(
        FIREBASE_FUNCTIONS_API_URL + ENDPOINTS.GET_REWARD_OVERVIEW_CSV,
        {
          method: 'POST',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`
          },
          body: requestBodyParametersFormatted
        }
      )

      if (response?.ok) {
        const responseJSON = await response.json()

        const downloadUrl = responseJSON?.data?.fileUrl

        const reference = ref(storage, downloadUrl)

        const blobPromise = getBlob(reference)
        const metadataPromise = getMetadata(reference)

        const [blob, metadata] = await Promise.all([
          blobPromise,
          metadataPromise
        ])

        const fileFormat = metadata?.contentType?.split('/').at(-1)

        const currentDateAndTime = moment()
          .format(MOMENT_FORMATS.FILE_DATE_FORMATS)
          ?.split(' ')
          ?.join(' at ')

        const fileTitle = [
          'Reward Overview',
          capitalize(rewardOverviewType),
          currentDateAndTime
        ].join(' ')

        downloadFileToDevice(blob, fileFormat, fileTitle)
        message.success(
          t('CSV with rewards overview were successfully saved to device')
        )
      } else {
        message.error(t('Error exporting csv file'), 3)
      }
    } catch (error) {
      message.error(t('Error exporting csv file'), 3)
    } finally {
      setLoading(false)
      setLoadingAll(false)
    }
  })

  return {
    exportCSVRewardOverview,
    exportCSVLoading: loading,
    exportCSVAllLoading: loadingAll
  }
}

export default useExportRewardOverviewCSV
