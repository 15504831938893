import { useCallback, useEffect, useState } from 'react'

import { COLLECTIONS } from '__constants__'
import { getDocument } from 'services/api/firebase'

/**
 * It fetches a newspaper document from Firestore and returns it
 * @param store - The store object that we're getting the newspaper for.
 * @returns An array with four values. The first value is the newspaper object, and the second value is a
 * boolean that indicates whether the newspaper is loading.
 */
const useGetStoreNewspaper = (store) => {
  /* State for data */
  const [newspaper, setNewspaper] = useState(null)
  /* State for loading */
  const [loading, setLoading] = useState(!!store?.newspaperId)
  /* State for error */
  const [error, setError] = useState(null)

  /* Fetching data */
  const getNewspaper = useCallback(async () => {
    try {
      setLoading(true)
      const document = await getDocument(
        COLLECTIONS.NEWSPAPERS,
        store?.newspaperId
      )
      setNewspaper(document)
      setLoading(false)
    } catch (error) {
      setError(error)
    }
  }, [store?.newspaperId])

  useEffect(() => {
    store?.newspaperId && getNewspaper()
  }, [store?.newspaperId, getNewspaper])

  return [newspaper, loading, error, getNewspaper]
}

export default useGetStoreNewspaper
