import { AccountMenu, Footer, LayoutHeader, Logo, MainMenu } from './components'
import {
  Box,
  Button,
  Col,
  Header,
  Layout,
  LayoutSystemProvider,
  Row,
  Title
} from '@qonsoll/react-design'
import { CloseOutlined, MenuOutlined } from '@ant-design/icons'
import {
  MenuCollapseWrapper,
  StyledAside
} from './components/LayoutAside/LayoutAside.styled'
import { useMemo, useRef } from 'react'

import Back from '../Back'
import { LanguageMenu } from 'domains/Translation/components'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import { Tooltip } from 'antd'
import breakpoints from '../../styles/breakpoints'
import firebase from 'firebase/compat/app'
import { styles } from './BoilerplateLayout.styles'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useStateWithStorage } from 'hooks'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

const ASIDE_VISIBILITY = 'aside_visibility'

const BoilerplateLayout = ({ children }) => {
  const [authUser, loading] = useAuthState(firebase.auth())

  const user = useUser()
  const { firstName, lastName } = user

  const { t } = useTranslations()

  /* If the authUser is authenticated, the component will render the children. Otherwise, it will render
  the fallback component. */
  const isAuthenticated = useMemo(
    () => authUser?.email && authUser?.emailVerified && !loading,
    [authUser?.email, authUser?.emailVerified, loading]
  )
  const [asideLeftCollapsed, setAsideLeftCollapsed] = useStateWithStorage(
    true,
    ASIDE_VISIBILITY
  )
  const handleAsideCollapse = () => {
    setTimeout(() => {
      setAsideLeftCollapsed((prev) => {
        localStorage.setItem(ASIDE_VISIBILITY, !prev)
        return !prev
      })
      setAsideLeftCollapsed(!asideLeftCollapsed)
    }, 200)
  }

  const displayName = firstName && lastName ? `${firstName} ${lastName}` : null

  /* The layoutConfig is memoized to only re-render when isAuthenticated changes. */
  const layoutConfig = {
    isAsideLeft: isAuthenticated,
    asideLeftCollapseVariant: 'full',
    asideLeftCollapsed,
    asideLeftOuter: false,
    isHeaderSticky: true
  }

  const ButtonRef = useRef()

  const menuTitle = asideLeftCollapsed ? t('Show menu') : t('Close menu')
  const menuIcon = asideLeftCollapsed ? <MenuOutlined /> : <CloseOutlined />

  return (
    <ThemeProvider theme={breakpoints}>
      {isAuthenticated ? (
        <LayoutSystemProvider {...layoutConfig}>
          <Layout
            header={
              <Header
                px={3}
                // we need this className to work properly with qonsoll layout component
                className="headerTop"
                {...styles.headerStyles}
                asideToggleBtnLeft={
                  <MenuCollapseWrapper
                    ref={ButtonRef}
                    asideLeftCollapsed={asideLeftCollapsed}
                    onClick={handleAsideCollapse}>
                    <Tooltip placement="bottom" title={menuTitle}>
                      <Button shape="circle" color="var(--ql-color-white)">
                        {menuIcon}
                      </Button>
                    </Tooltip>
                  </MenuCollapseWrapper>
                }>
                <Box
                  flex="1"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center">
                  <Box ml={3} display="flex" alignItems="center">
                    <Title level={3} mr={3} color="var(--ql-color-white)">
                      {t('Tipps')}
                    </Title>
                    <Logo />
                  </Box>
                  <AccountMenu
                    id={authUser?.uid}
                    avatar={user?.avatarUrl}
                    displayName={displayName}
                    email={authUser?.email}
                  />
                </Box>
              </Header>
            }
            asideLeft={
              <StyledAside
                // we need this className to catch layout overflow - fix qonsoll layout cons
                className="qlAsideLeft">
                <Box mx={-24} mb="auto" overflowY="auto">
                  <MainMenu collapse={asideLeftCollapsed} />
                </Box>
              </StyledAside>
            }
            footer={<Footer />}>
            {children}
          </Layout>
        </LayoutSystemProvider>
      ) : (
        <LayoutSystemProvider {...layoutConfig}>
          <Layout
            header={
              <LayoutHeader
                left={<Back />}
                center={
                  <Row h="center">
                    <Col cw="auto">
                      <Logo />
                    </Col>
                  </Row>
                }
                right={<LanguageMenu />}
              />
            }>
            {children}
          </Layout>
        </LayoutSystemProvider>
      )}
    </ThemeProvider>
  )
}

BoilerplateLayout.propTypes = {
  children: PropTypes.element
}

export default BoilerplateLayout
