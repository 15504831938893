import { Box } from '@qonsoll/react-design'
import { Select } from 'antd'
import PropTypes from 'prop-types'

const TextEditBlockTypeSelect = (props) => {
  const { config, onChange, currentState } = props

  const value = config?.options?.find(
    (option) => option === currentState?.blockType
  )

  const options = config?.options?.map((option) => ({
    label: option,
    value: option
  }))

  return (
    <Box px={2}>
      <Select
        value={value}
        size="small"
        options={options}
        style={{ minWidth: '120px' }}
        onChange={onChange}
      />
    </Box>
  )
}

TextEditBlockTypeSelect.propTypes = {
  config: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  currentState: PropTypes.object.isRequired
}

export default TextEditBlockTypeSelect
