import { COLLECTIONS } from '__constants__'
import firebase from 'firebase/compat/app'
import { useDocumentData } from 'react-firebase-hooks/firestore'
/**
 * It fetches a storeId document from Firestore and returns it
 * @param reward - The reward object that we're getting the storeId for.
 * @returns An array with four values. The first value is the storeId object, and the second value is a
 * boolean that indicates whether the storeId is loading.
 */
const useGetRewardStore = (reward) => {
  const db = firebase.firestore()

  const rewardRef =
    reward?.storeId && db.collection(COLLECTIONS.STORES).doc(reward?.storeId)

  return useDocumentData(rewardRef)
}

export default useGetRewardStore
