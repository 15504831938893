import { Checkbox } from 'antd'
import PropTypes from 'prop-types'
import { STATUSES } from '__constants__'
import { Text } from '@qonsoll/react-design'
import { useSubfilterActions } from 'hooks'
import { useTranslations } from 'contexts/Translation'

const StoreStatusFilter = ({ filterData, setFilterData }) => {
  const { t } = useTranslations()

  const { onToggleDetails, onChange, checkIsEnabled } = useSubfilterActions({
    filterData,
    setFilterData,
    fieldName: 'isBlocked',
    operand: '==',
    type: 'storeStatus'
  })

  const storeStatuses = [
    { value: true, name: STATUSES.STORE_STATUSES.BLOCKED },
    { value: false, name: STATUSES.STORE_STATUSES.ACTIVE }
  ]
  return (
    <details
      open={
        localStorage.getItem(`storeStatus-details-visibility`) === 'opened'
      }>
      <summary onClick={onToggleDetails}>{t('Store status')}</summary>
      {storeStatuses?.map((newspaper, index) => (
        <Checkbox
          key={`status-${index}`}
          checked={checkIsEnabled(newspaper)}
          onChange={() => onChange(newspaper)}>
          <Text wordBreak="break-all">
            {newspaper?.name || t('Unnamed filter')}
          </Text>
        </Checkbox>
      ))}
    </details>
  )
}

StoreStatusFilter.propTypes = {
  filterData: PropTypes.object,
  setFilterData: PropTypes.func
}

export default StoreStatusFilter
