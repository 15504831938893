const onTableFilterAction = (value, record, dataIndex) => {
  const isArrayDataIndex = Array.isArray(dataIndex)
  let deepQuery = {}

  // If dataIndex is array, checks if there is data inside an object, and returns the last found value
  isArrayDataIndex &&
    dataIndex?.forEach((item, i) => {
      const isFirst = i === 0
      if (isFirst && record?.[item]) deepQuery = record?.[item]
      if (deepQuery[item]) deepQuery = deepQuery[item]
    })

  const filterData = isArrayDataIndex ? deepQuery : record?.[dataIndex]

  return filterData?.toString()?.toLowerCase()?.includes(value?.toLowerCase())
}

export default onTableFilterAction
