const ENDPOINTS = {
  CHANGE_USER_PASSWORD: '/changeUserPassword',
  GET_USERS_CSV: '/getUsersCSV',
  GET_REWARD_OVERVIEW_CSV: '/getRewardOverviewCSV',
  DELETE_USER: '/deleteUser',
  GET_USER_CUSTOM_CLAIMS_AND_UID: '/getUserCustomClaimsAndUid'
}

export default ENDPOINTS
export { ENDPOINTS }
