import { Col, Container, Row } from '@qonsoll/react-design'
import { FooterStyled, StyledTextWrapper } from './Footer.styles'
import { useGetSubadminData } from 'hooks'

const Footer = () => {
  const { isSubadmin, newspaperWebConfig } = useGetSubadminData()

  const { footer } = newspaperWebConfig || {}

  return footer && isSubadmin ? (
    <FooterStyled backgroundColor={footer?.backgroundColor}>
      <Container bounded>
        <Row>
          <Col px={['24px', 2, '12px', 3]} cw={12}>
            <StyledTextWrapper
              dangerouslySetInnerHTML={{ __html: footer?.mainText }}
            />
          </Col>
        </Row>
      </Container>
    </FooterStyled>
  ) : (
    <></>
  )
}

export default Footer
