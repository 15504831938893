import { Box, Button, Col, Row, Text, Title } from '@qonsoll/react-design'
import { Collapse, Form, Input, Select, Switch } from 'antd'
import { KEYS, MAX_LENGTH_BUTTON, WIDGET_CONFIG } from '__constants__'
import { useEffect, useState } from 'react'

import { ColorPicker } from 'components'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const { Panel } = Collapse

const WIDGET_CONFIG_KEY = 'widgetConfigKey'
const WIDGET_SCRIPT_CONFIG_KEY = 'widgetScriptConfigKey'

const WidgetConfigurationSimpleForm = (props) => {
  const {
    initialValues,
    onSubmit,
    onCancel,
    setWidgetConfiguration,
    showTitle,
    form,
    group
  } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting new form instance from Form.useForm hook */
  const [baseForm] = Form.useForm()
  const [isActive, setIsActive] = useState(
    initialValues?.widgetConfig?.isActive
  )
  const [activePanel, setActivePanel] = useState(false)
  const script = initialValues?.widgetConfig?.script

  /* Getting used form from props or using base form */
  const usedForm = form || baseForm

  /* Rules for reusing */
  const buttonTextRule = [
    {
      max: MAX_LENGTH_BUTTON,
      message: t('Text is too long!')
    },
    {
      required: true,
      message: t('Please enter text!')
    }
  ]
  const linkRule = [
    {
      required: true,
      message: t('Please enter link!')
    }
  ]
  const additionalTextRule = [
    {
      max: MAX_LENGTH_BUTTON,
      message: t('Text is too long!')
    }
  ]

  /* Resetting form on cancel */
  const handleCancel = () => {
    usedForm.resetFields()
    onCancel?.()
  }
  /* Submitting form on enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      e.stopPropagation()
      usedForm?.submit()
    }
  }

  const updateFormData = () => {
    setWidgetConfiguration(usedForm?.getFieldsValue()?.widgetConfig)
  }
  const handleClick = (panel) =>
    panel === activePanel ? setActivePanel(false) : setActivePanel(panel)

  const handleChangeSwitch = (value) => {
    setIsActive(value)
  }

  const handleCopy = () => {
    const button = document.getElementById('scriptButton')
    navigator.clipboard.writeText(button?.innerText)
  }

  useEffect(() => {
    isActive ? setActivePanel(WIDGET_CONFIG_KEY) : setActivePanel(false)
  }, [isActive])

  return form ? (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Widget configurations')}
        </Title>
      )}

      <Form.Item
        label={t('Button text')}
        name={[...group, 'button', 'text']}
        rules={buttonTextRule}>
        <Input
          disabled={!isActive}
          placeholder={t('Please enter text for button')}
        />
      </Form.Item>
      <Form.Item
        label={t('Redirect to')}
        name={[...group, 'button', 'redirectTo']}
        rules={linkRule}>
        <Input
          disabled={!isActive}
          placeholder={t('Please enter link for redirect')}
        />
      </Form.Item>
      <Row negativeBlockMargin>
        <Col cw={[12, 12, 12, 6, 4]}>
          <Form.Item label={t('Position')} name={[...group, 'position']}>
            <Select
              disabled={!isActive}
              options={WIDGET_CONFIG.POSITIONS}
              placeholder={t('Select button position')}
            />
          </Form.Item>
        </Col>
        <Col cw={[12, 12, 12, 6, 4]}>
          <Form.Item label={t('Size')} name={[...group, 'button', 'size']}>
            <Select disabled={!isActive} options={WIDGET_CONFIG.SIZES} />
          </Form.Item>
        </Col>
        <Col cw={[12, 12, 12, 6, 4]}>
          <Form.Item
            label={t('Border radius')}
            name={[...group, 'button', 'borderRadius']}>
            <Select
              disabled={!isActive}
              options={WIDGET_CONFIG.BORDER_RADIUS}
            />
          </Form.Item>
        </Col>
        <Col cw={[12, 12, 12, 6, 4]}>
          <Form.Item
            label={t('Background color')}
            name={[...group, 'button', 'backgroundColor']}>
            <ColorPicker disabled={!isActive} />
          </Form.Item>
        </Col>
        <Col cw={[12, 12, 12, 6, 4]}>
          <Form.Item
            label={t('Text color')}
            name={[...group, 'button', 'color']}>
            <ColorPicker disabled={!isActive} />
          </Form.Item>
        </Col>
        <Col cw={[12, 12, 12, 6, 4]}>
          <Form.Item
            label={t('Glowing')}
            name={[...group, 'button', 'isGlowing']}>
            <Switch disabled={!isActive} defaultChecked={false} />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label={t('Additional text')}
        name={[...group, 'additionalText']}
        rules={additionalTextRule}>
        <Input
          disabled={!isActive}
          placeholder={t('Please enter additional text')}
        />
      </Form.Item>
    </>
  ) : (
    <Form
      onFieldsChange={updateFormData}
      form={usedForm}
      initialValues={initialValues}
      onFinish={onSubmit}
      layout="vertical"
      onKeyPress={handleKeyPress}
      autoComplete="off">
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Widget configurations')}
        </Title>
      )}
      <Form.Item
        label={t('Enable widget configurations')}
        name={[...group, 'isActive']}>
        <Switch onChange={handleChangeSwitch} checked={isActive} />
      </Form.Item>
      <Box mb={4}>
        <Collapse
          accordion
          expandIconPosition="right"
          activeKey={activePanel}
          onChange={handleClick}
          collapsible>
          <Panel
            forceRender
            header={t('Widget config')}
            key={WIDGET_CONFIG_KEY}>
            <Form.Item
              label={t('Button text')}
              name={[...group, 'button', 'text']}
              rules={buttonTextRule}>
              <Input
                disabled={!isActive}
                placeholder={t('Please enter text for button')}
              />
            </Form.Item>
            <Form.Item
              label={t('Redirect to')}
              name={[...group, 'button', 'redirectTo']}
              rules={linkRule}>
              <Input
                disabled={!isActive}
                placeholder={t('Please enter link for redirect')}
              />
            </Form.Item>
            <Row negativeBlockMargin>
              <Col cw={[12, 12, 12, 6, 4]}>
                <Form.Item label={t('Position')} name={[...group, 'position']}>
                  <Select
                    disabled={!isActive}
                    options={WIDGET_CONFIG.POSITIONS}
                    placeholder={t('Select button position')}
                  />
                </Form.Item>
              </Col>
              <Col cw={[12, 12, 12, 6, 4]}>
                <Form.Item
                  label={t('Size')}
                  name={[...group, 'button', 'size']}>
                  <Select disabled={!isActive} options={WIDGET_CONFIG.SIZES} />
                </Form.Item>
              </Col>
              <Col cw={[12, 12, 12, 6, 4]}>
                <Form.Item
                  label={t('Border radius')}
                  name={[...group, 'button', 'borderRadius']}>
                  <Select
                    disabled={!isActive}
                    options={WIDGET_CONFIG.BORDER_RADIUS}
                  />
                </Form.Item>
              </Col>
              <Col cw={[12, 12, 12, 6, 4]}>
                <Form.Item
                  label={t('Background color')}
                  name={[...group, 'button', 'backgroundColor']}>
                  <ColorPicker disabled={!isActive} />
                </Form.Item>
              </Col>
              <Col cw={[12, 12, 12, 6, 4]}>
                <Form.Item
                  label={t('Text color')}
                  name={[...group, 'button', 'color']}>
                  <ColorPicker disabled={!isActive} />
                </Form.Item>
              </Col>
              <Col cw={[12, 12, 12, 6, 4]}>
                <Form.Item
                  label={t('Glowing')}
                  name={[...group, 'button', 'isGlowing']}>
                  <Switch disabled={!isActive} defaultChecked={false} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label={t('Additional text')}
              name={[...group, 'additionalText']}
              rules={additionalTextRule}>
              <Input
                disabled={!isActive}
                placeholder={t('Please enter additional text')}
              />
            </Form.Item>
          </Panel>
        </Collapse>
      </Box>
      <Box mb={4}>
        <Collapse
          expandIconPosition="right"
          activeKey={activePanel}
          onChange={handleClick}
          collapsible
          accordion>
          <Panel
            forceRender
            header={t('Script to install')}
            key={WIDGET_SCRIPT_CONFIG_KEY}>
            <Text>
              <pre
                id="scriptButton"
                className="code-block"
                dangerouslySetInnerHTML={{
                  __html: script
                }}
              />
              <Box display="flex" justifyContent="flex-end">
                <Button onClick={handleCopy}>
                  <Text>{t('Copy script')}</Text>
                </Button>
              </Box>
            </Text>
          </Panel>
        </Collapse>
      </Box>

      {!form && (
        <>
          <Button mr={2} htmlType="button" onClick={handleCancel}>
            {t('Cancel')}
          </Button>
          <Button type="primary" onClick={() => usedForm.submit()}>
            {t('Save')}
          </Button>
        </>
      )}
    </Form>
  )
}

WidgetConfigurationSimpleForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  setWidgetConfiguration: PropTypes.func,
  showTitle: PropTypes.bool,
  form: PropTypes.any,
  group: PropTypes.array
}

export default WidgetConfigurationSimpleForm
