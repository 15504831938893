import { COLLECTIONS, STATUSES } from '__constants__'
import { deleteDocument, updateDocument } from 'services/api/firebase'
import { generatePath, useHistory } from 'react-router-dom'

import PATHS from 'pages/paths'
import { message } from 'antd'
import { useEvent } from 'hooks'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

/* Path's */
const createPath =
  PATHS.AUTHENTICATED.EDITOR_CREATE || PATHS.AUTHENTICATED.EDITOR_WIZARD
const editPath = PATHS.AUTHENTICATED.EDITOR_EDIT
const showPath = PATHS.AUTHENTICATED.EDITOR_SHOW

const useEditorBaseActions = ({ editorId, isBlocked, actions } = {}) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()

  const { USER_STATUSES } = STATUSES
  /* Function to handle back click */
  const handleBack = useEvent(() => history.goBack())

  /* Handling opening of the create page */
  const handleCreate = useEvent(() => {
    history.push(createPath)
  })

  /* Handling opening of the show page */
  const handleOpen = useEvent(() => {
    const pathParams = { editorId }
    const path = generatePath(showPath, pathParams)
    history.push(path)
  })

  /* Handling opening of the edit page or change view to edit mode */
  const handleEdit = useEvent(() => {
    const pathParams = { editorId }
    const path = generatePath(editPath, pathParams)
    history.push(path)
  })

  const handleDelete = useEvent(async () => {
    try {
      await deleteDocument(COLLECTIONS.EDITORS, editorId)
      message.success(t('Editor successfully deleted'))
    } catch (error) {
      message.error(error.message)
    }
  })

  const handleBlockedOrUnblockedEditor = useEvent(async () => {
    try {
      await updateDocument(COLLECTIONS.EDITORS, editorId, {
        isBlocked: !isBlocked,
        status: isBlocked ? USER_STATUSES.ACTIVE : USER_STATUSES.BLOCKED
      })
      message.success(
        t(`Editor successfully ${isBlocked ? 'unblocked' : 'blocked'}`)
      )
    } catch (error) {
      message.error(error.message)
    }
  })
  return useMemo(
    () => ({
      onBack: handleBack,
      onCreate: handleCreate,
      onOpen: handleOpen,
      onDelete: handleDelete,
      onEdit: handleEdit,
      onBlock: handleBlockedOrUnblockedEditor,
      ...(actions || {}),
      createPath,
      editPath,
      showPath
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      actions?.onBack,
      actions?.onCreate,
      actions?.onDelete,
      actions?.onOpen,
      actions?.onEdit,
      handleBack,
      handleCreate,
      handleDelete,
      handleOpen,
      handleEdit
    ]
  )
}

export default useEditorBaseActions
