import { Link, Text } from '@qonsoll/react-design'
import {
  NewspaperLink,
  NewspaperNewspaperStatus
} from 'domains/Newspaper/components'
import { cloneElement, useMemo } from 'react'
import { generatePath, useHistory } from 'react-router-dom'

import MOMENT_FORMATS from '__constants__/momentFormats'
import PATHS from 'pages/paths'
import { TableActions } from 'components'
import { actions } from 'react-table'
import moment from 'moment'
import { sortByAlphabet } from 'helpers'
import { useGetColumnSearchProps } from 'hooks'
import { useRejectReasonBaseActions } from 'domains/RejectReason/hooks'
import { useTranslations } from '@qonsoll/translation'

const RejectReasonTableActions = ({ rejectReasonId, isBlocked }) => {
  const actions = useRejectReasonBaseActions({
    rejectReasonId,
    isBlocked
  })

  return cloneElement(<TableActions />, {
    actions,
    isBlocked,
    name: 'reject reason'
  })
}

const useGetRejectReasonTableColumns = () => {
  const { t } = useTranslations()

  const history = useHistory()

  const getColumnSearchProps = useGetColumnSearchProps()

  return useMemo(() => {
    const handleOpen = (rejectReasonId) =>
      history.push(
        generatePath(PATHS.AUTHENTICATED.REJECT_REASON_SHOW, {
          rejectReasonId
        })
      )

    return [
      {
        title: <Text fontWeight="600">{t('Sno')}</Text>,
        dataIndex: 'sno',
        width: 'var(--table-number-column-width)',
        key: 'sno',
        align: 'center',
        sorter: (a, b) => a?.sno - b?.sno,
        ...getColumnSearchProps('sno'),
        render: (_, record) => <Text>{record?.sno}</Text>
      },
      {
        title: <Text fontWeight="600">{t('Reason Title')}</Text>,
        dataIndex: 'title',
        key: 'title',
        align: 'center',
        onClick: actions?.onOpen,
        sorter: (a, b) => sortByAlphabet(a?.title, b?.title),
        ...getColumnSearchProps('title', 'Reason Title'),
        render: (_, record) => (
          <Link onClick={() => handleOpen(record?._id)}>{record?.title}</Link>
        )
      },

      {
        title: <Text fontWeight="600">{t('Added on')}</Text>,
        width: 'var(--table-date-column-width)',
        dataIndex: '_createdAt',
        key: '_createdAt',
        align: 'center',
        sorter: (a, b) => a?._createdAt?.seconds - b?._createdAt?.seconds,
        render: (date) => (
          <Text>
            {moment
              .unix(date?.seconds)
              .format(MOMENT_FORMATS.MONTH_DAYS_COMMA_YEAR)}
          </Text>
        )
      },
      {
        title: <Text fontWeight="600">{t('Newspaper')}</Text>,
        dataIndex: 'newspaper',
        key: 'newspaper',
        align: 'center',
        onClick: actions?.onOpen,
        sorter: (a, b) =>
          sortByAlphabet(a?.newspaper?.name, b?.newspaper?.name),
        ...getColumnSearchProps(['newspaper', 'name'], 'Newspaper'),
        render: (_, record) => (
          <NewspaperLink newspaperId={record?.newspaperId} />
        )
      },

      {
        title: <Text fontWeight="600">{t('Status')}</Text>,
        width: 'var(--table-status-column-width)',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        sorter: (a, b) => sortByAlphabet(a?.status, b?.status),
        render: (_, record) => <NewspaperNewspaperStatus newspaper={record} />
      },
      {
        title: <Text fontWeight="600">{t('Actions')}</Text>,
        width: 'var(--table-actions-column-width)',
        dataIndex: 'actions',
        key: 'actions',
        align: 'center',
        render: (_, record) => (
          <RejectReasonTableActions
            rejectReasonId={record?._id}
            isBlocked={record?.isBlocked}
          />
        )
      }
    ]
  }, [t, getColumnSearchProps, history])
}
export default useGetRejectReasonTableColumns
