const TIPSER_ROLE = 'TIPSER'
const EDITOR_ROLE = 'EDITOR'
const LOCAL_ADMIN_ROLE = 'LOCAL_ADMIN'
const SUPER_ADMIN_ROLE = 'SUPER_ADMIN'

const USER_ROLES = {
  TIPSER_ROLE: 'TIPSER',
  EDITOR_ROLE: 'EDITOR',
  LOCAL_ADMIN_ROLE: 'LOCAL_ADMIN',
  SUPER_ADMIN_ROLE: 'SUPER_ADMIN'
}
export {
  TIPSER_ROLE,
  EDITOR_ROLE,
  LOCAL_ADMIN_ROLE,
  SUPER_ADMIN_ROLE,
  USER_ROLES
}
