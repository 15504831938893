import { Col, PageWrapper, Row } from '@qonsoll/react-design'

import PropTypes from 'prop-types'

const PageWrapperActionsUnderTitle = (props) => {
  const { action, children, ...rest } = props
  return (
    <PageWrapper {...rest}>
      <Row mb={3} noGutters>
        <Col cw={12}>{action}</Col>
      </Row>
      {children}
    </PageWrapper>
  )
}
PageWrapperActionsUnderTitle.propTypes = {
  action: PropTypes.node,
  children: PropTypes.node
}
export default PageWrapperActionsUnderTitle
