import { get, getDatabase, ref } from 'firebase/database'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { formatTranslations } from '../../../helpers'
import { message } from 'antd'
import { RDB_COLLECTIONS } from '__constants__'

const useGetTranslations = (appName, translateToShortCode) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  //Added first loading for display loading on whole page
  const [firstLoading, setFirstLoading] = useState(false)

  const fetchData = useCallback(async () => {
    try {
      setLoading(true)

      const db = getDatabase()
      const path = `${RDB_COLLECTIONS.TRANSLATIONS}/${appName}`
      const dbRef = ref(db, path)

      const snapshot = await get(dbRef)

      const data = snapshot.val()

      setData(formatTranslations(data, translateToShortCode))
    } catch (error) {
      message.error(error.message)
    } finally {
      setLoading(false)
      !firstLoading && setFirstLoading(true)
    }
  }, [appName, translateToShortCode])

  useEffect(() => {
    appName && translateToShortCode && fetchData()
  }, [appName, fetchData, translateToShortCode])

  const loadings = useMemo(
    () => [loading, firstLoading],
    [loading, firstLoading]
  )

  return [data, loadings, fetchData]
}

export default useGetTranslations
