import { Box, Button } from '@qonsoll/react-design'
import { HISTORY_OPTIONS_CONFIG } from 'domains/Content/constants'
import PropTypes from 'prop-types'

const TextEditHistoryBlock = (props) => {
  const { config, onChange, currentState } = props

  const handleOnClick = (option) => onChange(option)
  return (
    <Box mx={-1} pl={2}>
      {config?.options?.map((option, index) => (
        <Button
          key={`TextEditHistoryBlock-${index}`}
          disabled={currentState[`${option}Disabled`]}
          mx={1}
          size="small"
          type={currentState[option] ? 'primary' : 'text'}
          onClick={() => handleOnClick(option)}
          icon={HISTORY_OPTIONS_CONFIG[option]}
        />
      ))}
    </Box>
  )
}

TextEditHistoryBlock.propTypes = {
  config: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  currentState: PropTypes.object.isRequired
}

export default TextEditHistoryBlock
