import { COLLECTIONS, ENDPOINTS, STATUSES } from '__constants__'
import { LOCAL_ADMIN_ROLE, SUPER_ADMIN_ROLE } from '__constants__/roles'
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState
} from 'react'
import { doc, onSnapshot, updateDoc } from 'firebase/firestore'
import { useEvent, useHandleError } from 'hooks'
import { useGDPRStatus, useSessionActions } from 'domains/Session/hooks'
import {
  useManageAuthToken,
  useTokenExpirationTime
} from 'domains/Session/hooks'

import PATHS from 'pages/paths'
import PropTypes from 'prop-types'
import Spinner from 'components/Spinner'
import UserContext from './UserContext'
import firebase from 'firebase/compat/app'
import { firestore } from 'services/firebase'
import { notification } from 'antd'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useHistory } from 'react-router-dom'
import { useTranslations } from '@qonsoll/translation'

const FIREBASE_FUNCTIONS_API_URL =
  process.env.REACT_APP_FIREBASE_FUNCTIONS_API_URL

const USER_CONFIG_ROLE = {
  [SUPER_ADMIN_ROLE]: {
    collectionName: COLLECTIONS.SUPER_ADMINS,
    role: SUPER_ADMIN_ROLE
  },
  [LOCAL_ADMIN_ROLE]: {
    collectionName: COLLECTIONS.SUBADMINS,
    role: LOCAL_ADMIN_ROLE
  }
}

const SessionDispatchContext = createContext()

const initialState = {
  _isSessionLoading: false,
  _isSessionExists: false,
  _isSessionFetched: false
}

function sessionReducer(state, action) {
  switch (action.type) {
    case 'START_DATA_FETCHING': {
      return {
        ...state,
        _isSessionLoading: true
      }
    }
    case 'UPDATE_DATA': {
      return Object.assign(
        {
          ...state
        },
        action.data
      )
    }
    case 'SET_DATA': {
      return Object.assign(
        {
          ...state,
          _isSessionLoading: false,
          _isSessionExists: Boolean(action.data),
          _isSessionFetched: true
        },
        action.data
      )
    }
    case 'LOG_OUT': {
      return {
        ...initialState,
        _isSessionFetched: true
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const UserProvider = ({ children }) => {
  const [error, setError] = useState()
  const [collectionName, setCollectionName] = useState(null)
  const [auth, authLoading] = useAuthState(firebase.auth())
  const [extendedUserData, dispatch] = useReducer(sessionReducer, initialState)
  const { t } = useTranslations()
  const history = useHistory()

  /* The above code is a function that takes in a callback function as an argument.
    The callback function is then called with the error as an argument. */
  const handleError = useHandleError()

  /* Using the useGDPRStatus hook to get the GDPR status of the user. */
  const gdpr = useGDPRStatus()

  // This hook handles auth token expiration time
  const [tokenExpirationTime, updateTokenExpirationTime] =
    useTokenExpirationTime()

  // This hooks manages update of auth-token
  const [token, setToken] = useManageAuthToken(
    tokenExpirationTime,
    updateTokenExpirationTime
  )

  // Session methods
  const {
    updateEmailVerificationStatus,
    getLastSessionFromLocalStorage,
    setSessionToLocalStorage,
    updateGDPRStatus
  } = useSessionActions()

  const initSessionData = (user) => {
    const userSessionObject = {
      ...user,
      _email: user.email,
      _id: user.uid,
      _isEmailVerified: user.emailVerified
    }
    dispatch({ type: 'SET_DATA', data: userSessionObject })

    return userSessionObject
  }

  // This function check user role and return name of the role and collection as an object
  const getUserRoleConfig = async () => {
    try {
      // Getting token result
      const idTokenResult = await auth.getIdTokenResult()

      // Checks if the key exists
      const userRoleConfig = Object.keys(USER_CONFIG_ROLE).filter(
        (collectionRole) =>
          idTokenResult?.claims?.roles?.some?.(
            (role) => collectionRole === role
          )
      )

      // Check if user is admin
      const isAdmin = idTokenResult?.claims?.admin

      // Return name of the role and collection as an object
      return userRoleConfig
        ?.map((role) => {
          if (
            isAdmin &&
            (role === SUPER_ADMIN_ROLE || role === LOCAL_ADMIN_ROLE)
          )
            return USER_CONFIG_ROLE[role]

          return false
        })
        ?.filter(Boolean)?.[0]
    } catch (e) {
      console.error(e)
    }
  }

  /**
   * Function is used to check if user has resolved password
   * @returns {bool} true - if password is resolved, otherwise - false
   */
  const checkIfTemporaryPasswordResolved = async () => {
    // Not needed here try/catch, it is above
    // Getting token result
    const idTokenResult = await auth.getIdTokenResult()

    // Get value for isTemporaryPasswordResolved
    return idTokenResult?.claims?.isTemporaryPasswordResolved
  }
  const updateSessionData = (data) => {
    dispatch({ type: 'UPDATE_DATA', data: data })
  }

  const completeNewPassword = useCallback(
    async (password) => {
      try {
        const userRoleConfig = await getUserRoleConfig()

        const requestData = {
          email: extendedUserData?.email,
          password: password,
          role: userRoleConfig?.role
        }
        const requestBodyParametersFormatted = JSON.stringify(requestData)

        const response = await fetch(
          FIREBASE_FUNCTIONS_API_URL + ENDPOINTS.CHANGE_USER_PASSWORD,
          {
            method: 'POST',
            cache: 'no-cache',
            headers: {
              'Content-Type': 'application/json',
              authorization: `Bearer ${token}`
            },
            body: requestBodyParametersFormatted
          }
        )

        if (response?.ok) {
          // initializing data fetching
          dispatch({ type: 'START_DATA_FETCHING' })

          // making force login with new password
          const userSessionObject = await firebase
            .auth()
            .signInWithEmailAndPassword(extendedUserData?.email, password)

          initSessionData({
            ...userSessionObject.user,
            isTemporaryPasswordResolved: true
          })
          notification.success({
            message: t('Success'),
            description: t('Password was successfully changes')
          })
          history.push(PATHS.AUTHENTICATED.DASHBOARD)
        } else {
          notification.error({
            message: t('Error'),
            description: t(
              'Server responded with 404, cannot complete operation'
            )
          })
        }
      } catch (error) {
        notification.error({
          message: t('Error'),
          description: t(`Error during changing password. ${error?.message}`)
        })
      }
    },
    // t and history are excessive
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [extendedUserData?.email]
  )

  const logout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: 'LOG_OUT' })
      })
  }
  // Function is used to set token into state on login action
  const setTokenIntoState = (user) => {
    user.getIdToken(false).then((signedToken) => {
      setToken(signedToken)
      return signedToken
    })
  }

  const handleChangeAuthState = useEvent(async (user) => {
    try {
      // Setting token into state on login action
      setTokenIntoState(user)

      const userRoleConfig = await getUserRoleConfig()

      // Check if temporary password was resolved
      const isTemporaryPasswordResolved =
        await checkIfTemporaryPasswordResolved()

      if (!userRoleConfig?.collectionName) {
        logout()
        notification.error({
          message: 'Error',
          description: t('Sorry, you are not authorized to access this app'),
          placement: 'topRight'
        })
        return
      }

      setCollectionName(userRoleConfig?.collectionName)
      //get data from snapshot
      return onSnapshot(
        doc(firestore, userRoleConfig?.collectionName, user?.uid),
        (userSnap) => {
          if (!userSnap.exists()) {
            logout()
            notification.error({
              message: 'Error',
              description: t(
                'Sorry, you are not authorized to access this app'
              ),
              placement: 'topRight'
            })
            return
          }

          const userDataFromFirestore = userSnap.data()

          const { isBlocked = false } = userDataFromFirestore

          if (isBlocked) {
            logout()
            notification.error({
              message: 'Error',
              description: t(
                'Sorry, you are not have access to this app as you are blocked'
              ),
              placement: 'topRight'
            })
            return
          }
          if (
            userDataFromFirestore?.status === STATUSES.USER_STATUSES.INVITED
          ) {
            const userRef = doc(
              firestore,
              userRoleConfig?.collectionName,
              user?.uid
            )

            // async but there is no need to wait here
            updateDoc(userRef, {
              status: STATUSES.USER_STATUSES.ACTIVE
            })
          }

          // initialize user session data if all checks were passed
          initSessionData({
            uid: user?.uid,
            phoneNumber: user?.phoneNumber,
            photoURL: user?.photoURL,
            emailVerified: user.emailVerified,
            role: userRoleConfig?.role,
            ...userDataFromFirestore,
            isTemporaryPasswordResolved
          })
        }
      )
    } catch (error) {
      setError(error)
      // Showing notification with error
      notification.error({
        message: 'Error',
        description: error.message,
        placement: 'topRight'
      })
    }
  })

  const isUserDataLoaded = useMemo(
    () =>
      auth &&
      !extendedUserData?._isSessionLoading &&
      extendedUserData._isSessionFetched &&
      extendedUserData._isSessionExists &&
      !authLoading,
    [
      auth,
      authLoading,
      extendedUserData._isSessionExists,
      extendedUserData._isSessionFetched,
      extendedUserData?._isSessionLoading
    ]
  )

  useEffect(() => {
    let isComponentMounted = true
    let unsubscribe = null

    if (isComponentMounted && auth && !authLoading) {
      // Handle user update changes
      dispatch({ type: 'START_DATA_FETCHING' })

      handleChangeAuthState(auth).then((snapshot) => {
        unsubscribe = snapshot
      })
    }

    return () => {
      isComponentMounted = false
      unsubscribe?.()
    }
  }, [auth, authLoading, handleChangeAuthState])

  // Update GDPR status
  useEffect(() => {
    /* If the user is logged in, and the user's data is loaded, and the user's data has not yet been
    updated to reflect the new GDPR status, then update the user's data to reflect the new GDPR
    status. */
    if (
      isUserDataLoaded &&
      collectionName &&
      !extendedUserData?.isGdprAccepted &&
      gdpr !== extendedUserData?.isGdprAccepted
    )
      updateGDPRStatus({
        id: auth?.uid,
        isGdprAccepted: gdpr,
        collectionName,
        onError: handleError
      })
  }, [
    isUserDataLoaded,
    collectionName,
    gdpr,
    auth,
    extendedUserData,
    updateGDPRStatus,
    handleError,
    authLoading
  ])

  // Updating LS with user session info
  useEffect(() => {
    if (isUserDataLoaded) {
      const lastSession = getLastSessionFromLocalStorage()
      const { firstName, lastName } = extendedUserData
      const displayName =
        firstName && lastName ? `${firstName} ${lastName}` : null

      const isChanged =
        lastSession?.email !== auth?.email ||
        lastSession?.avatarUrl !== extendedUserData?.avatarUrl ||
        lastSession?.displayName !== displayName

      /* Set the session to the user's local storage. */
      isChanged &&
        setSessionToLocalStorage({
          email: auth?.email,
          avatarUrl: extendedUserData?.avatarUrl,
          displayName,
          provider: localStorage.getItem('lastSessionProvider')
        })
    }
  }, [
    auth,
    isUserDataLoaded,
    extendedUserData,
    getLastSessionFromLocalStorage,
    setSessionToLocalStorage
  ])

  // Updating user's email verification status
  useEffect(() => {
    // Check if data is loaded

    // Updating email verification status
    isUserDataLoaded &&
      collectionName &&
      !extendedUserData.isEmailVerified &&
      updateEmailVerificationStatus({
        id: auth.uid,
        sessionUserEmailVerified: auth?.emailVerified,
        dbUserEmailVerified: extendedUserData?.isEmailVerified,
        collectionName,
        onError: handleError
      })
  }, [
    collectionName,
    extendedUserData,
    auth,
    updateEmailVerificationStatus,
    isUserDataLoaded,
    handleError
  ])

  useEffect(() => {
    let isMounted = true

    if (isMounted && token) {
      dispatch({ type: 'UPDATE_DATA', data: { token } })
    }

    return () => (isMounted = false)
  }, [token])

  // Handling user fetching error
  useEffect(() => {
    error && handleError(error)
  }, [error, handleError])

  // Preparing contexts
  const computedContextData = useMemo(() => {
    return {
      ...extendedUserData,
      updateSessionData,
      logout,
      completeNewPassword,
      token,
      isUserDataLoaded
    }
  }, [extendedUserData, token, isUserDataLoaded, completeNewPassword])

  return (
    <UserContext.Provider value={computedContextData}>
      <SessionDispatchContext.Provider value={dispatch}>
        {extendedUserData._isSessionLoading ? (
          <Spinner text={t('Loading user data')} />
        ) : (
          children
        )}
      </SessionDispatchContext.Provider>
    </UserContext.Provider>
  )
}

UserProvider.propTypes = { children: PropTypes.node }

export default UserProvider
