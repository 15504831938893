import { useObjectVal } from 'react-firebase-hooks/database'
import { ref, update } from 'firebase/database'
import { database } from 'services/firebase'
import { RDB_COLLECTIONS } from '__constants__'
import { useEvent, useLoading } from 'hooks'
import { useEffect, useMemo } from 'react'
import { TRANSLATION_LOADING_CONFIG } from 'domains/Translation/__constants__'

const useGetTranslationLoading = () => {
  const [
    translationLoadingTexts = {},
    translationLoading,
    translationLoadingError
  ] = useObjectVal(ref(database, RDB_COLLECTIONS.TRANSLATIONS_LOADING))

  const addDefaultTranslationLoadingConfig = useEvent(async () => {
    try {
      // Write the new language data in the languages list.
      await update(
        ref(database, RDB_COLLECTIONS.TRANSLATIONS_LOADING),
        TRANSLATION_LOADING_CONFIG
      )
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(
        'Error during set default translation loading  to rdb',
        error
      )
    }
  })

  useEffect(() => {
    if (
      !Object.keys(translationLoadingTexts || {})?.length &&
      !translationLoading &&
      !translationLoadingError
    ) {
      addDefaultTranslationLoadingConfig()
    }
  }, [
    addDefaultTranslationLoadingConfig,
    translationLoading,
    translationLoadingError,
    translationLoadingTexts
  ])

  /* Merge all loading states */
  const loadings = useMemo(
    () => [translationLoading, !!translationLoadingError],
    [translationLoading, translationLoadingError]
  )

  /* Getting loading state using merged loadings */
  const loading = useLoading(loadings)

  return [translationLoadingTexts, loading]
}

export default useGetTranslationLoading
