import { useMemo } from 'react'
import { useLoading } from 'hooks'
import { useDocument } from 'services/api/firebase'
import { COLLECTIONS } from '__constants__'
import { doc } from 'firebase/firestore'
import { firestore } from 'services/firebase'
import { useDocumentData } from 'react-firebase-hooks/firestore'

const useGetRejectReasonInitialValues = (id) => {
  /* Fetching data using useDocument hook */
  const [rejectReason, rejectReasonLoading] = useDocumentData(
    id && doc(firestore, COLLECTIONS.REJECT_REASONS, id)
  )

  /* Fetching newspaper data */
  const [newspaper, newspaperLoading] = useDocument({
    ref: id ? COLLECTIONS.NEWSPAPERS + '/' + rejectReason?.newspaperId : null
  })

  /* Merge all fetched data */
  const initialValues = useMemo(
    () => ({
      rejectReason,
      newspaper
    }),
    [rejectReason, newspaper]
  )

  /* Merge all loading states */
  const loadings = useMemo(
    () => (id ? [!rejectReason, rejectReasonLoading, newspaperLoading] : []),
    [id, rejectReason, rejectReasonLoading, newspaperLoading]
  )

  /* Getting loading state using merged loadings */
  const loading = useLoading(loadings)

  return [initialValues, loading]
}

export default useGetRejectReasonInitialValues
