import { useState } from 'react'
import { Form, message } from 'antd'
import { useTranslations } from 'contexts/Translation'
import { get, ref, update, set } from 'firebase/database'
import { database } from 'services/firebase'
import { RDB_COLLECTIONS } from '__constants__'
import {
  DEFAULT_LANGUAGE,
  AVAILABLE_APPS
} from 'domains/Translation/__constants__'

const useHandleAddNewLanguage = () => {
  const { t } = useTranslations()
  const [visibleModal, setVisibleModal] = useState(false)
  const [loading, setLoading] = useState(false)

  /* Getting form instance */
  const [form] = Form.useForm()

  const toggleVisibleModal = () => setVisibleModal(!visibleModal)
  const handleOk = () => form.submit()

  const writeLanguageData = async (newLanguage, shortCode) => {
    // Write the new language data in the languages list.
    const updates = {}
    updates[RDB_COLLECTIONS.LANGUAGES + '/' + shortCode] = newLanguage

    await update(ref(database), updates)
  }

  const writeTranslationLoadingData = async (newTranslationLoading) => {
    await update(
      ref(database, RDB_COLLECTIONS.TRANSLATIONS_LOADING),
      newTranslationLoading
    )
  }

  const fetchTranslationsPerApp = async (appName) => {
    try {
      //User here no language as translations for this language should exist as it is default language

      //This case works for "@qonsoll/translation": "0.4.3",
      // if you want to update version of library where keys are hashes, possible solution is fetch english translation and use values as keys
      // for creating translations for new languages
      const path = `${RDB_COLLECTIONS.TRANSLATIONS}/${appName}/${DEFAULT_LANGUAGE}`
      const dbRef = ref(database, path)
      const snapshot = await get(dbRef)

      return { [appName]: snapshot.val() }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(`Error fetching translations for ${appName}`, error)
    }
  }

  const fetchTranslations = async () => {
    const fetchNewLanguagesPromises = Object.values(AVAILABLE_APPS).map(
      (appName) => fetchTranslationsPerApp(appName)
    )

    return await Promise.all(fetchNewLanguagesPromises)
  }

  const addTranslationsForNewLanguage = async (shortCode) => {
    try {
      const translations = await fetchTranslations()

      const translationsPromises = translations.map(async (item) => {
        const computedTranslations = {}
        const [appName, translations] = Object.entries(item).flatMap(
          ([key, value]) => [key, value]
        )

        //This case works for "@qonsoll/translation": "0.4.3",
        // if you want to update version of library where keys are hashes, possible solution is fetch english translation and use values as keys
        // and values pair for creating translations for new language
        Object.keys(translations).forEach(
          (key) => (computedTranslations[key] = key)
        )

        const path = `${RDB_COLLECTIONS.TRANSLATIONS}/${appName}/${shortCode}`
        await set(ref(database, path), computedTranslations)
      })

      await Promise.all(translationsPromises)
    } catch (error) {
      message.error(t('Error adding translations for new language'))
    }
  }

  const handleAddNewLanguage = async (data) => {
    try {
      setLoading(true)
      const { newLanguage, translateDefaultPhrase } = data
      const { value, label } = newLanguage

      const computedLanguage = { name: label, label, value, shortCode: value }
      const writeLanguageDataPromise = writeLanguageData(
        computedLanguage,
        value
      )
      const writeTranslationLoadingDataPromise = writeTranslationLoadingData({
        [value]: translateDefaultPhrase
      })

      const addTranslationsForNewLanguagePromise =
        addTranslationsForNewLanguage(value)

      await Promise.all([
        writeLanguageDataPromise,
        writeTranslationLoadingDataPromise,
        addTranslationsForNewLanguagePromise
      ])
      toggleVisibleModal()
      form.resetFields()
      setLoading(false)

      message.success(t('New language was added successfully'))
    } catch (error) {
      message.error(t('Error during save new language'))
    }
  }

  return {
    toggleVisibleModal,
    handleAddNewLanguage,
    handleOk,
    loading,
    visibleModal,
    form
  }
}

export default useHandleAddNewLanguage
