import { NewspaperFilter, StoreStatusFilter } from './components'

import PropTypes from 'prop-types'
import { useGetSubadminData } from 'hooks'

const StoreFilter = (props) => {
  const { filterVisibility, filterData, setFilterData } = props

  const { isSubadmin } = useGetSubadminData()

  return filterVisibility ? (
    <>
      {!isSubadmin && (
        <NewspaperFilter
          filterData={filterData}
          setFilterData={setFilterData}
        />
      )}

      <StoreStatusFilter
        filterData={filterData}
        setFilterData={setFilterData}
      />
    </>
  ) : null
}

StoreFilter.propTypes = {
  filterVisibility: PropTypes.bool,
  filterData: PropTypes.object,
  setFilterData: PropTypes.func
}

export default StoreFilter
