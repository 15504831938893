import { Spinner, StyledTable } from 'components'
import PropTypes from 'prop-types'
import {
  useNewspaperTableColumns,
  useTransformNewspaperTableData
} from 'domains/Newspaper/hooks'
import { useTranslations } from '@qonsoll/translation'

const NewspaperTable = (props) => {
  const { dataSource, isSubadminRole } = props

  const { t } = useTranslations()

  /* Getting table columns */
  const columns = useNewspaperTableColumns()
  const { loading, tableParams, transformedDataSource, handleTableChange } =
    useTransformNewspaperTableData(dataSource, isSubadminRole)

  return loading ? (
    <Spinner height="60vh" text={t('Newspapers loading')} />
  ) : (
    <StyledTable
      {...props}
      rowKey="_id"
      dataSource={transformedDataSource}
      columns={columns}
      pagination={!isSubadminRole && tableParams}
      onChange={handleTableChange}
    />
  )
}

NewspaperTable.propTypes = {
  dataSource: PropTypes.array,
  isSubadminRole: PropTypes.bool
}

export default NewspaperTable
