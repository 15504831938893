import { Aside, Box } from '@qonsoll/react-design'

import styled from 'styled-components'

export const StyledAside = styled(Aside)`
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  padding-top: 12px;
  transition: 1s;
  max-height: calc(100vh - var(--ql-header-height));
  overflow-y: auto;
`
export const MenuCollapseWrapper = styled(Box)`
  gap: var(--ql-spacing-sm);
  height: 100%;
  padding-right: var(--ql-padding-md);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1s;
  z-index: 1;
  border-right: 1px solid var(--ql-color-white-t-lighten3);
`
