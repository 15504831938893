import { MOMENT_FORMATS, STATUSES } from '__constants__'
import { cloneElement, useMemo } from 'react'

import { FullNameLink } from 'domains/User/components'
import { TableActions } from 'components'
import { Text } from '@qonsoll/react-design'
import moment from 'moment'
import { sortByAlphabet } from 'helpers'
import { useGetColumnSearchProps } from 'hooks'
import { useTranslations } from '@qonsoll/translation'
import { useUserBaseActions } from 'domains/User/hooks'

const UserTableActions = ({ status, userId, isBlocked }) => {
  const actions = useUserBaseActions({ userId, isBlocked })

  const isInvitedUser = status === STATUSES.USER_STATUSES.INVITED

  return cloneElement(<TableActions />, {
    actions,
    isBlocked,
    name: 'user',
    isInvitedUser
  })
}

const useUserTableColumns = (isDashboardView) => {
  const { t } = useTranslations()

  const getColumnSearchProps = useGetColumnSearchProps()

  const dashboardView = useMemo(
    () => [
      {
        title: t('Sno'),
        width: 'var(--table-number-column-width)',
        dataIndex: 'sno',
        key: 'sno',
        align: 'center'
      },
      {
        title: t('Name'),
        dataIndex: 'name',
        width: 'var(--table-name-column-width)',
        key: 'name',
        align: 'center',
        render: (_, record) => (
          <FullNameLink text={record?.fullName} userId={record?._id} />
        )
      },
      {
        title: t('Added On'),
        width: 'var(--table-date-column-width)',
        dataIndex: '_createdAt',
        key: '_createdAt',
        align: 'center',
        render: (date) => (
          <Text>
            {moment
              .unix(date?.seconds)
              .format(MOMENT_FORMATS.MONTH_DAYS_COMMA_YEAR)}
          </Text>
        )
      },
      {
        title: t('Email address'),
        dataIndex: 'email',
        width: 'var(--table-email-column-width)',
        key: 'email',
        align: 'center'
      },
      {
        title: t('Mobile Number'),
        width: 'var(--table-phone-column-width)',
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
        align: 'center'
      },
      {
        title: t('Status'),
        width: 'var(--table-status-column-width)',
        dataIndex: 'isBlocked',
        key: 'isBlocked',
        align: 'center',
        render: (status) => <Text> {status ? 'Blocked' : 'Active'}</Text>
      },
      {
        title: t('Actions'),
        dataIndex: 'actions',
        width: 'var(--table-actions-column-width)',
        key: 'actions',
        align: 'center',
        render: (_, record) => (
          <UserTableActions userId={record?._id} {...record} />
        )
      }
    ],
    [t]
  )
  const usersView = useMemo(
    () => [
      {
        title: t('Sno'),
        width: 100,
        dataIndex: 'sno',
        key: 'sno',
        align: 'center',
        sorter: (a, b) => a?.sno - b?.sno,
        ...getColumnSearchProps('sno')
      },
      {
        title: t('Name'),
        dataIndex: 'fullName',
        width: 400,
        key: 'name',
        align: 'center',
        sorter: (a, b) => sortByAlphabet(a?.fullName, b?.fullName),
        ...getColumnSearchProps('fullName', 'name'),
        render: (text, record) => (
          <FullNameLink text={text} userId={record?._id} />
        )
      },
      {
        title: t('Email ID'),
        dataIndex: 'email',
        width: 'var(--table-email-column-width)',
        key: 'email',
        align: 'center',
        sorter: (a, b) => sortByAlphabet(a?.email, b?.email),
        ...getColumnSearchProps('email')
      },
      {
        title: t('Mobile Number'),
        width: 'var(--table-phoneNumber-column-width)',
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
        align: 'center',
        sorter: (a, b) => sortByAlphabet(a?.phoneNumber, b?.phoneNumber),
        ...getColumnSearchProps('phoneNumber', 'phone')
      },
      {
        title: t('Registration Date'),
        width: 'var(--table-date-column-width)',
        dataIndex: '_createdAt',
        key: '_createdAt',
        align: 'center',
        sorter: (a, b) => a?._createdAt?.seconds - b?._createdAt?.seconds,
        render: (date) => (
          <Text>
            {moment
              .unix(date?.seconds)
              .format(MOMENT_FORMATS.MONTH_DAYS_COMMA_YEAR)}
          </Text>
        )
      },
      {
        title: t('Status'),
        width: 'var(--table-status-column-width)',
        dataIndex: 'isBlocked',
        key: 'isBlocked',
        align: 'center',
        sorter: (a, b) => a?.isBlocked - b?.isBlocked,
        render: (status) => <Text> {status ? 'Blocked' : 'Active'}</Text>
      },
      {
        title: t('Actions'),
        width: 'var(--table-actions-column-width)',
        dataIndex: 'actions',
        key: 'actions',
        align: 'center',
        render: (_, record) => (
          <UserTableActions
            userId={record?._id}
            status={record?.status}
            {...record}
          />
        )
      }
    ],
    [t, getColumnSearchProps]
  )

  return useMemo(
    () => (isDashboardView ? dashboardView : usersView),
    [dashboardView, isDashboardView, usersView]
  )
}

export default useUserTableColumns
