import { BadgeStyled, CreateButtonStyled } from './PageActions.styles'
import { Box, Button, Divider, Input } from '@qonsoll/react-design'
import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { Popconfirm, Tooltip } from 'antd'

import { FilterModal } from 'components'
import PropTypes from 'prop-types'
import { useSearchFilter } from 'hooks'
import { useTranslations } from 'contexts/Translation'

/**
 * It renders a set of buttons that are used to perform actions on a list of items
 * @param filterButtonText - The text that will be displayed when the user hovers over the filter
 * button
 * @param onFilterButtonClick - function that will be called when the filter button is clicked
 * @param filterButtonIcon - Icon to be displayed on the filter button

 * @param onCreate - function to be called when the create button is clicked
 * @param onWizard - function to be called when the wizard button is clicked
 * @param onEdit - Function to be called when the edit button is clicked
 * @param onCopy - Function to be called when the copy button is clicked
 * @param onDownload - Function to be called when the download button is clicked
 * @param onEnable - Function to be called when the enable button is clicked
 * @param onAllExportCSV - Function to be called when the export all csv is clicked
 * @param onExportCSV - Function to be called when the export button is clicked
 * @param actions - an object with the following properties:
 * @param createText - The text to be displayed on the create button
 * @returns A function that returns a JSX element
 */
const PageActions = (props) => {
  const {
    filterButtonText,
    onFilterButtonClick,
    filterButtonIcon,
    onCreate,
    onWizard,
    onEdit,
    onBlock,
    isBlocked,
    onDelete,
    onCopy,
    onDownload,
    onEnable,
    isEnable,
    enableButtonText,
    onAllExportCSV,
    allExportCSVLoading,
    onExportCSV,
    exportCSVLoading,
    actions,
    createText,
    circle,
    filterVisibility,
    setFilterVisibility,
    setFilterData,
    filterData,
    filterMenu,
    searchPlaceholder = 'Search',
    collection,
    setCollection,
    searchField
  } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  const buttonShape = circle ? 'circle' : 'default'

  // for now ok and cancel do the same but later we'll add logic here to catch modal filter selections
  const handleOk = () => {
    setFilterVisibility(false)
  }
  const handleCancel = () => {
    setFilterVisibility(false)
  }

  const handleReset = () => {
    setFilterData(null)
    setFilterVisibility(false)
  }

  const [handleSearch] = useSearchFilter({
    collection,
    setCollection,
    searchField
  })

  const filterCount = filterData?.where?.length
  const computedEnableButtonText =
    enableButtonText || (isEnable ? 'Disable' : 'Enable')

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      {actions?.search && (
        <Box className="searchWrapper" flex="1">
          <Input
            onChange={handleSearch}
            onKeyPress={handleSearch}
            placeholder={`${t(searchPlaceholder)} ${t(
              '(press enter to apply)'
            )}`}
            suffix={<SearchOutlined />}
            width="100%"
            maxWidth={[200, 300, 400, 500, 600]}
          />
        </Box>
      )}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {actions?.edit && (
          <Button ml={2} onClick={onEdit}>
            {t('Edit')}
          </Button>
        )}
        {actions?.enable && (
          <Button ml={2} onClick={onEnable}>
            {t(computedEnableButtonText)}
          </Button>
        )}
        {actions?.block && (
          <Button ml={2} type="primary" onClick={onBlock}>
            {t(isBlocked ? 'Unblock' : 'Block')}
          </Button>
        )}
        {actions?.delete && (
          <Popconfirm
            okButtonProps={{ danger: true }}
            title={t('Are you sure you want to remove this item?')}
            onConfirm={onDelete}
            okText={t('Yes')}
            cancelText={t('No')}>
            <Button ml={2} type="ghost">
              {t('Delete')}
            </Button>
          </Popconfirm>
        )}

        {actions?.copy && (
          <Button ml={2} onClick={onCopy}>
            {t('Copy')}
          </Button>
        )}
        {actions?.download && (
          <Button ml={2} onClick={onDownload}>
            {t('Download')}
          </Button>
        )}

        {actions?.wizard && (
          <>
            <Divider mx={3} height="24px" alignSelf="center" type="vertical" />
            <Button
              ml={2}
              type="primary"
              icon={<PlusOutlined />}
              onClick={onWizard}>
              {createText}
            </Button>
          </>
        )}
        {actions?.create && (
          <>
            <CreateButtonStyled
              shape={buttonShape}
              type="primary"
              ml={2}
              icon={<PlusOutlined />}
              onClick={onCreate}>
              {createText}
            </CreateButtonStyled>
          </>
        )}
        {actions?.allExport && (
          <Button
            ml={2}
            type="primary"
            onClick={onAllExportCSV}
            loading={allExportCSVLoading}>
            {t('All Export CSV')}
          </Button>
        )}
        {actions?.export && (
          <Button
            ml={2}
            type="primary"
            onClick={onExportCSV}
            loading={exportCSVLoading}>
            {t('Export CSV')}
          </Button>
        )}
        {actions?.filter && (
          <>
            <BadgeStyled count={filterCount}>
              <Tooltip title={filterButtonText}>
                <Button
                  shape="circle"
                  type="primary"
                  ml={2}
                  onClick={onFilterButtonClick}
                  icon={filterButtonIcon}
                />
              </Tooltip>
            </BadgeStyled>
            <FilterModal
              handleOk={handleOk}
              handleCancel={handleCancel}
              handleReset={handleReset}
              filterVisibility={filterVisibility}
              filterMenu={filterMenu}
            />
          </>
        )}
      </Box>
    </Box>
  )
}

PageActions.propTypes = {
  actions: PropTypes.shape({
    create: PropTypes.bool,
    edit: PropTypes.bool,
    block: PropTypes.bool,
    delete: PropTypes.bool,
    copy: PropTypes.bool,
    filter: PropTypes.bool,
    wizard: PropTypes.bool,
    search: PropTypes.bool,
    download: PropTypes.bool,
    export: PropTypes.bool,
    allExport: PropTypes.bool,
    enable: PropTypes.bool
  }),
  isBlocked: PropTypes.bool,
  filterVisibility: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
  setFilterVisibility: PropTypes.func,
  setFilterData: PropTypes.func,
  filterMenu: PropTypes.node,
  circle: PropTypes.bool,
  createText: PropTypes.string,
  filterButtonIcon: PropTypes.any,
  filterButtonText: PropTypes.string,
  filterData: PropTypes.object,
  onCreate: PropTypes.func,
  onEdit: PropTypes.func,
  onBlock: PropTypes.func,
  onDelete: PropTypes.func,
  onCopy: PropTypes.func,
  onDownload: PropTypes.func,
  onEnable: PropTypes.func,
  isEnable: PropTypes.bool,
  enableButtonText: PropTypes.string,
  onAllExport: PropTypes.func,
  onExportCSV: PropTypes.func,
  onFilterButtonClick: PropTypes.func,
  onWizard: PropTypes.func,
  setListView: PropTypes.func,
  collection: PropTypes.array,
  setCollection: PropTypes.func,
  searchField: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  onAllExportCSV: PropTypes.func,
  allExportCSVLoading: PropTypes.bool,
  exportCSVLoading: PropTypes.bool
}

export default PageActions
