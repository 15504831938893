export const CURRENT_APP = process.env.REACT_APP_APPLICATION_NAME

export const DEFAULT_LANGUAGE = 'nn'
export const ENGLISH_SHORT_CODE_LANGUAGE = 'en'
export const LANGUAGES = [
  { name: 'English', shortCode: 'en', label: 'English', value: 'en' },
  {
    name: 'Norsk, nynorsk',
    shortCode: 'nn',
    label: 'Norsk, nynorsk',
    value: 'nn'
  }
]
export const TRANSLATION_LOADING_CONFIG = {
  nn: 'Oversettelser lastes inn...',
  en: 'Translation is loading...'
}

export const AVAILABLE_APPS = {
  ADMIN: CURRENT_APP,
  CLIENT: 'tipps-client',
  EDITOR: 'tipps-editor',
  WEB_TIPPS: 'tipps-client-web'
}
