import {
  AppstoreOutlined,
  BellOutlined,
  BookOutlined,
  GlobalOutlined,
  MailOutlined,
  NotificationOutlined,
  ShopOutlined,
  TranslationOutlined,
  UserAddOutlined,
  UserOutlined,
  UserSwitchOutlined
} from '@ant-design/icons'
import { generatePath, useHistory } from 'react-router-dom'

import { Icon } from '@qonsoll/icons'
import PATHS from 'pages/paths'
import { styles } from 'components/BoilerplateLayout/components/MainMenu/MainMenu.styles'
import useGetNewspaperInitialValues from 'domains/Newspaper/hooks/get/useGetNewspaperInitialValues'
import { useGetSubadminData } from 'hooks'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

const useGetMenuItems = () => {
  const { t } = useTranslations()
  const history = useHistory()

  const { isSubadmin, newspaperId } = useGetSubadminData()

  /* Get initial value for newspaper */
  const [newspaperInitialValues] = useGetNewspaperInitialValues(newspaperId)

  const iconProps = useMemo(
    () => ({
      ...styles.qonsollIconStyles
    }),
    []
  )

  /* Check if web tipps is enabled */
  const isWebTippsEnabled =
    newspaperInitialValues?.webTippsConfiguration?.isWebTippsEnabled
  /* Get web tipps id */
  const webTippsConfigurationId =
    newspaperInitialValues?.webTippsConfiguration?._id

  return useMemo(
    () =>
      [
        {
          key: '/dashboard',
          icon: <AppstoreOutlined style={styles.antIconStyles} />,
          text: t('Dashboard'),
          onClick: () => history.push('/dashboard'),
          divided: true
        },
        {
          key: '/users',
          icon: <UserOutlined style={styles.antIconStyles} />,
          text: t('Users'),
          onClick: () => history.push('/users'),
          divided: true
        },
        {
          key: '/newspapers',
          icon: <NotificationOutlined style={styles.antIconStyles} />,
          text: t('Newspapers'),
          onClick: () => history.push('/newspapers'),
          divided: true
        },
        /* Show menu item if current user is super admin or if web tipps is enabled */
        ((isSubadmin && isWebTippsEnabled) || !isSubadmin) && {
          key: PATHS.AUTHENTICATED.WEB_TIPPS_CONFIGURATIONS_ALL,
          icon: <GlobalOutlined style={styles.antIconStyles} />,
          text: t('Web tipps'),
          onClick: () =>
            isSubadmin
              ? history.push(
                  generatePath(
                    PATHS.AUTHENTICATED.WEB_TIPPS_CONFIGURATION_EDIT,
                    { webTippsConfigurationId }
                  )
                )
              : history.push(PATHS.AUTHENTICATED.WEB_TIPPS_CONFIGURATIONS_ALL),
          divided: true
        },
        {
          key: '/news-tips',
          icon: <Icon name="Announcement2Outlined" {...iconProps} />,
          text: t('News tips'),
          onClick: () => history.push(PATHS.AUTHENTICATED.NEWS_TIP_ALL),
          divided: true
        },
        {
          key: '/stores',
          icon: <ShopOutlined style={styles.antIconStyles} />,
          text: t('Stores'),
          onClick: () => history.push('/stores'),
          divided: true
        },
        {
          key: '/rewards',
          icon: <Icon name="Award3Outlined" {...iconProps} />,
          text: t('Rewards'),
          onClick: () => history.push('/rewards'),
          divided: true
        },
        {
          key: '/reward-overview',
          icon: <Icon name="Award3Outlined" {...iconProps} />,
          text: t('Rewards overview'),
          onClick: () => history.push(PATHS.AUTHENTICATED.REWARD_OVERVIEW_ALL),
          divided: true
        },
        {
          key: '/notifications',
          icon: <BellOutlined style={styles.antIconStyles} />,
          text: t('Notifications'),
          onClick: () => history.push('/notifications'),
          divided: true
        },
        !isSubadmin && {
          key: '/content',
          icon: <Icon name="File6Outlined" {...iconProps} />,
          text: t('Content'),
          onClick: () => history.push(PATHS.AUTHENTICATED.CONTENT),
          divided: true
        },
        {
          key: '/reject-reasons',
          icon: <Icon name="BarLineChartOutlined" {...iconProps} />,
          text: t('Reject Reason'),
          onClick: () => history.push(PATHS.AUTHENTICATED.REJECT_REASONS_ALL),
          divided: true
        },
        {
          key: '/editors',
          icon: <BookOutlined style={styles.antIconStyles} />,
          text: t('Editors'),
          onClick: () => history.push('/editors'),
          divided: true
        },
        {
          key: '/emails',
          icon: <MailOutlined style={styles.antIconStyles} />,
          text: t('Emails'),
          onClick: () => history.push(PATHS.AUTHENTICATED.EMAILS_ALL),
          divided: true
        },
        {
          key: '/countries',
          icon: <Icon name="Globe5Outlined" {...iconProps} />,
          text: t('Countries'),
          onClick: () => history.push(PATHS.AUTHENTICATED.COUNTRIES_ALL),
          divided: true
        },

        !isSubadmin && {
          key: '/subadmins',
          icon: <UserSwitchOutlined style={styles.antIconStyles} />,
          text: t('Subadmins'),
          onClick: () => history.push('/subadmins'),
          divided: true
        },
        !isSubadmin && {
          key: '/superadmins',
          icon: <UserAddOutlined style={styles.antIconStyles} />,
          text: t('Super admins'),
          onClick: () => history.push('/superadmins'),
          divided: true
        },

        !isSubadmin && {
          key: '/translations',
          text: t('Translations'),
          onClick: () => history.push(PATHS.AUTHENTICATED.TRANSLATIONS),
          icon: <TranslationOutlined style={styles.antIconStyles} />
        }
      ]?.filter(Boolean),
    [
      t,
      iconProps,
      history,
      isSubadmin,
      webTippsConfigurationId,
      isWebTippsEnabled
    ]
  )
}

export default useGetMenuItems
