import { Checkbox } from 'antd'
import PropTypes from 'prop-types'
import { REWARD_OVERVIEW_TYPES } from 'domains/RewardOverview/constants'
import { Text } from '@qonsoll/react-design'
import { capitalize } from 'helpers'
import { useSubfilterActions } from 'hooks'
import { useTranslations } from 'contexts/Translation'

const RewardTypeFilter = ({ filterData, setFilterData }) => {
  const { t } = useTranslations()

  const { onToggleDetails, onChange, checkIsEnabled } = useSubfilterActions({
    filterData,
    setFilterData,
    fieldName: 'type',
    operand: '==',
    type: 'rewardType'
  })

  const rewardTypes = Object.keys(REWARD_OVERVIEW_TYPES)?.map((rewardType) => ({
    value: rewardType,
    name: capitalize(rewardType)
  }))

  return (
    <details
      open={localStorage.getItem(`rewardType-details-visibility`) === 'opened'}>
      <summary onClick={onToggleDetails}>{t('Reward type')}</summary>
      {rewardTypes?.map((rewardType, index) => (
        <Checkbox
          key={`status-${index}`}
          checked={checkIsEnabled(rewardType)}
          onChange={() => onChange(rewardType)}>
          <Text wordBreak="break-all">
            {rewardType?.name || t('Unnamed filter')}
          </Text>
        </Checkbox>
      ))}
    </details>
  )
}

RewardTypeFilter.propTypes = {
  filterData: PropTypes.object,
  setFilterData: PropTypes.func
}

export default RewardTypeFilter
