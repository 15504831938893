import { COLLECTIONS, STATUSES } from '__constants__'

import { useCollection } from 'services/api/firebase'
import { useMemo } from 'react'

/**
 * It returns an array of values that are used to render the emails
 * @param props - An object that contains the query parameters.
 * @returns An array of values.
 */
const useGetCountries = (props) => {
  /* Query for getting collection reference */

  const computedQuery = useMemo(() => {
    const query = { ref: COLLECTIONS.COUNTRIES }
    const statusesValues = Object.values(STATUSES.COUNTRY_STATUSES)

    const computedWhere = [
      !!statusesValues?.length && ['status', 'in', statusesValues],
      ...(props?.where || [])
    ]?.filter(Boolean)

    if (computedWhere?.length) query.where = computedWhere
    query.orderBy = [['internationalName', 'asc']]

    return query
  }, [props])

  /* Getting collection data */
  return useCollection(computedQuery)
}

export default useGetCountries
