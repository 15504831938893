import { Box, Text } from '@qonsoll/react-design'

import { PASSWORD_STRENGTH_LEVELS } from '__constants__'
import ProgressStyled from './StrengthIndicator.styled'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const StrengthIndicator = (props) => {
  const { level } = props

  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()

  // [COMPUTED_PROPERTIES]
  const levelConfig = PASSWORD_STRENGTH_LEVELS?.[level]

  return (
    <Box mb={'24px'}>
      <Box>
        <ProgressStyled
          percent={levelConfig?.percent}
          strokeColor={levelConfig?.color}
        />
      </Box>
      <Box textAlign="right" mx={0}>
        <Text color={levelConfig?.color || 'var(--ql-color-dark-t-lighten3)'}>
          {t(levelConfig?.text) || t('Very weak')}
        </Text>
      </Box>
    </Box>
  )
}

StrengthIndicator.propTypes = {
  level: PropTypes.number
}

export default StrengthIndicator
