import { COLLECTIONS, STATUSES } from '__constants__'
import {
  createDocument,
  getId,
  updateDocument,
  updateParent
} from 'services/api/firebase'
import { useMemo, useState } from 'react'

import { message } from 'antd'
import { useGetEmails } from 'domains/Email/hooks'
import { useHistory } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'

const useActionsEmailAdvancedForm = ({
  initialData,
  form,
  isSuperAdmin,
  newspaperId = null
} = {}) => {
  /* Get emails */
  const [emails] = useGetEmails()

  // get email from email document
  const computedEmail = useMemo(
    () => emails?.map(({ email }) => email),
    [emails]
  )

  const checkIsEmailExist = useMemo(
    () => (email) => computedEmail?.find((item) => item === email),
    [computedEmail]
  )

  /* State for loading */
  const [loading, setLoading] = useState(false)

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Getting history instance */
  const history = useHistory()

  /* Getting history state */
  const historyState = history.location.state

  /* Function for preparing values for saving */
  const prepareValues = async (values = {}, additionalValues = {}) => {
    /* Getting id */
    const emailId = initialData?.email?._id || getId(COLLECTIONS.EMAILS)

    const computedNewspaperId = isSuperAdmin
      ? values?.newspaper?._id || initialData?.newspaper?._id
      : newspaperId // in this case user is local admin so the newspaper id will be the same as he has

    /* Preparing email values */
    const preparedValues = {
      _id: emailId,
      ...additionalValues,
      email: values?.email?.email ?? null,
      sno: initialData?.email?.sno ?? null,
      isBlocked: initialData?.email?.isBlocked ?? false,
      isEditor: initialData?.email?.isEditor ?? false,
      editorId: initialData?.email?.emailId ?? null,
      newspaperId: computedNewspaperId ?? null,
      status: initialData?.email?.status || STATUSES.EMAIL_STATUSES.ACTIVE
    }
    return preparedValues
  }

  /* Saving form data */
  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)

      if (historyState?.parent)
        await updateParent(historyState.parent, data._id)

      // Save data
      if (initialData) {
        await updateDocument(COLLECTIONS.EMAILS, initialData.email?._id, data)
        message.success(t('Email successfully updated'))
      } else {
        await createDocument(COLLECTIONS.EMAILS, data, data._id)
        message.success(t('Email successfully created'))
      }
      // Final callback
      callback?.()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Something went wrong during data save'))
    }
  }

  /* On finish callback */
  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)

      // Get form values
      const formValues = form.getFieldsValue()

      // Check of email exist in system
      const isEmailAlreadyExist = checkIsEmailExist(formValues?.email?.email)

      if (isEmailAlreadyExist && !initialData) {
        message.error(t('This email already exists, please enter another!'))
        return
      }

      // Final callback
      const callback = () => history.goBack()

      // Save data
      await saveForm(formValues, callback)
    } catch (error) {
      message.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  /* On cancel callback */
  const onReset = () => {
    form.resetFields()
    history.goBack()
  }

  return { onFinish, onReset, loading, saveForm, prepareValues }
}

export default useActionsEmailAdvancedForm
