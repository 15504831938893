const styles = {
  antIconStyles: {
    backgroundColor: 'var(--btn-default-bg)',
    padding: 'var(--ql-padding-sm)',
    fontSize: '20px',
    borderRadius: 'var(--ql-border-radius-small)'
  },
  qonsollIconStyles: {
    fill: 'currentColor',
    size: 20,
    backgroundColor: 'var(--btn-default-bg)',
    padding: 'var(--ql-padding-sm)',
    borderRadius: 'var(--ql-border-radius-small)'
  }
}
export { styles }
