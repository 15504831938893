import { Button, Input } from '@qonsoll/react-design'

import { DefaultLanguageSelect } from 'domains/WebTippsConfiguration/components'
import { Form } from 'antd'
import PropTypes from 'prop-types'
import { useSettingsSimpleForm } from './hooks'
import { useTranslations } from 'contexts/Translation'

const SettingsSimpleForm = (props) => {
  const { initialValues, onSubmit, onCancel, form, setPageConfiguration } =
    props
  /* Getting translations instance */
  const { t } = useTranslations()
  const { usedForm, handleKeyPress, updateFormData, handleCancel } =
    useSettingsSimpleForm({
      form,
      initialValues,
      onCancel,
      setPageConfiguration
    })

  return (
    <Form
      onFieldsChange={updateFormData}
      form={usedForm}
      initialValues={initialValues}
      onFinish={onSubmit}
      layout="vertical"
      onKeyPress={handleKeyPress}
      autoComplete="off">
      <Form.Item
        label={t('Default language')}
        name={['app', 'defaultLanguage']}>
        <DefaultLanguageSelect />
      </Form.Item>

      <Form.Item
        label={t('Newspaper frontpage address')}
        name={['app', 'newspaperFrontpageAddress']}>
        <Input placeholder={t('Enter newspaper frontpage address')} />
      </Form.Item>

      {!form && (
        <>
          <Button mr={2} htmlType="button" onClick={handleCancel}>
            {t('Cancel')}
          </Button>
          <Button type="primary" onClick={() => usedForm.submit()}>
            {t('Save')}
          </Button>
        </>
      )}
    </Form>
  )
}

SettingsSimpleForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  showTitle: PropTypes.bool,
  form: PropTypes.any,
  group: PropTypes.array,
  setPageConfiguration: PropTypes.func
}

export default SettingsSimpleForm
