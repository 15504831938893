import { Button, Select } from 'antd'
import { Col, Row, Title } from '@qonsoll/react-design'

import { Draggable } from 'react-beautiful-dnd'
import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import { styles } from './LanguageDraggableListItem.styles'
import { useMemo } from 'react'

const LanguageDraggableListItem = (props) => {
  const { language, onRemoveLanguage, index, languages, form } = props

  const idComputed = language?.uid || language?.shortCode

  const handleSelect = (_, data) =>
    form.setFieldsValue({ languages: { [idComputed]: data } })

  const isShow = useMemo(
    () =>
      language?.value &&
      language?.name &&
      language?.shortCode &&
      language?.label,
    [language]
  )
  return (
    <Draggable key={idComputed} draggableId={idComputed} index={index}>
      {(provided) => {
        return (
          <div
            {...provided.draggableProps}
            style={{ ...provided.draggableProps.style }}>
            <Row ref={provided.innerRef} {...styles.rowProps}>
              <Col cw="auto">
                <div style={styles.iconWrapper}>
                  <Icon {...styles.iconProps} {...provided.dragHandleProps} />
                </div>
              </Col>
              <Col>
                {isShow ? (
                  <Title level={5}>{language?.name}</Title>
                ) : (
                  <Select options={languages} onChange={handleSelect} />
                )}
              </Col>

              {/* Delete button */}
              <Col cw={'auto'}>
                <Button onClick={onRemoveLanguage(idComputed)} type="link">
                  <Icon {...styles.deleteIconProps} />
                </Button>
              </Col>
            </Row>
          </div>
        )
      }}
    </Draggable>
  )
}

LanguageDraggableListItem.propTypes = {
  language: PropTypes.object,
  index: PropTypes.number,
  onRemoveLanguage: PropTypes.func,
  languages: PropTypes.arrayOf(PropTypes.object),
  form: PropTypes.object
}

export default LanguageDraggableListItem
