import { Img, Text } from '@qonsoll/react-design'
import { Upload, message } from 'antd'
import { UploadButton, styles } from './ImageUploader.styles'
import { memo, useEffect, useState } from 'react'

import ImgCrop from 'antd-img-crop'
import PropTypes from 'prop-types'
import { UploadOutlined } from '@ant-design/icons'
import { uploadImage } from 'helpers'
import { useTranslations } from 'contexts/Translation'

/**
 * It's a React component that renders an image uploader
 * @param value {string} - The image url
 * @param onChange {function} - The function to call when the image is changed
 * @param withDirectUpload {boolean} - If true, returns an url to the image
 * @param imageCropProps {Object} - check available props on https://github.com/nanxiaobei/antd-img-crop
 * @param uploadProps {Object} - check available props on https://4x.ant.design/components/upload/
 * @param withImageCrop {boolean} - Should image be cropped before upload
 * @param withImageSizeValidation {boolean} - if true image size will be validated (1024px by 1024px for example)
 * @param withFileSizeValidation {boolean} - if true image size will be validated by size (2 Mib for example)
 * @param maxWidthUploadedImage {number} - max width of upload image (work's only with withImageSizeValidation = true)
 * @param maxHeightUploadedImage {number} - max height of upload image (work's only with withImageSizeValidation = true)
 * @param maxFileSize {number} - max size of upload image (work's only with withFileSizeValidation = true)
 * @param isResetValueAllowed {bool} - Determine if state file can be reset in outer scope of component. Used to reset image when crop type changes
 */
const AVAILABLE_FORMATS = ['image/jpeg', 'image/png', 'image/jpg', 'image/tiff']

const ImageUploader = (props) => {
  const {
    value,
    onChange,
    withImageCrop = true,
    withDirectUpload = false,
    withImageSizeValidation = false,
    withFileSizeValidation = false,
    imgCropProps,
    uploadProps,
    maxWidthUploadedImage = 1024,
    maxHeightUploadedImage = 1024,
    maxFileSize = 2,
    imageCropAspect,
    isResetValueAllowed
  } = props

  // [COMPONENT_STATE_HOOKS]
  const [file, setFile] = useState(null)
  // [ADDITIONAL_HOOKS]
  const { t } = useTranslations()
  // [HANDLER_FUNCTIONS]
  const getBase64 = (img, callback) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }
  const handleUpload = async ({ onSuccess, file }) => {
    if (withDirectUpload) {
      const [url] = await uploadImage(file)
      onChange(url)
    } else {
      onChange?.(file)
      getBase64(file, (result) => setFile(result))
    }
    onSuccess()
  }

  const validateImageSize = (file) =>
    new Promise((resolve) => {
      const image = new Image()
      image.src = window.URL.createObjectURL(file)
      image.onload = () => {
        resolve(
          image?.width >= maxWidthUploadedImage &&
            image?.height >= maxHeightUploadedImage
        )
      }
    })

  const validateFileSize = (file) => {
    const fileSize = file.size / 1024 / 1024 // in MiB

    return fileSize < maxFileSize
  }

  const beforeUpload = async (file) => {
    try {
      const isAvailableFormat = AVAILABLE_FORMATS.includes(file?.type)
      if (!isAvailableFormat) {
        message.error(
          t(
            `You can upload only ${AVAILABLE_FORMATS.map((availableFormat) =>
              availableFormat.split('/').at(1).toUpperCase()
            ).join('/')} files`
          )
        )
        return false
      }

      if (withImageSizeValidation) {
        const isImgValid = await validateImageSize(file)
        if (!isImgValid) {
          message.error(
            t(
              `Images must be at least ${maxWidthUploadedImage} by ${maxHeightUploadedImage}`
            )
          )

          return false
        }
      }

      if (withFileSizeValidation) {
        const isFileSizeValid = validateFileSize(file)
        if (!isFileSizeValid) {
          message.error(t(`Images must be ${maxFileSize} Mib`))

          return false
        }
      }

      return true
    } catch (error) {
      message.error(t('Something went wrong during image upload'))
      throw new Error(error)
    }
  }

  // [LIFECYCLE]
  useEffect(() => (value || isResetValueAllowed) && setFile(value), [value])

  return withImageCrop ? (
    <ImgCrop
      aspect={imageCropAspect || undefined}
      fillColor="transparent"
      modalOk={t('Apply')}
      modalCancel={t('Cancel')}
      modalTitle={t('Crop image')}
      grid
      rotate
      minZoom={0.9}
      quality={1}
      beforeCrop={beforeUpload}
      cropperProps={{
        restrictPosition: false
      }}
      {...imgCropProps}>
      <Upload
        fileList={null}
        customRequest={handleUpload}
        accept={AVAILABLE_FORMATS.join()}
        {...uploadProps}>
        {file ? (
          <Img alt={t('Image')} style={styles.image} src={file || value} />
        ) : (
          <UploadButton>
            <UploadOutlined />
            <Text>{t('Please upload image')}</Text>
          </UploadButton>
        )}
      </Upload>
    </ImgCrop>
  ) : (
    <Upload
      fileList={null}
      customRequest={handleUpload}
      accept={AVAILABLE_FORMATS.join()}
      {...uploadProps}>
      {file ? (
        <Img alt="Image" style={styles.image} src={file || value} />
      ) : (
        <UploadButton>
          <UploadOutlined />
          <Text type="secondary">{t('Please upload image')}</Text>
        </UploadButton>
      )}
    </Upload>
  )
}
ImageUploader.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  withDirectUpload: PropTypes.bool,
  imgCropProps: PropTypes.object,
  uploadProps: PropTypes.object,
  withImageSizeValidation: PropTypes.bool,
  withFileSizeValidation: PropTypes.bool,
  withImageCrop: PropTypes.bool,
  maxWidthUploadedImage: PropTypes.number,
  maxHeightUploadedImage: PropTypes.number,
  maxFileSize: PropTypes.number,
  imageCropAspect: PropTypes.oneOfType([PropTypes.number, PropTypes.any]),
  isResetValueAllowed: PropTypes.bool
}

export default memo(ImageUploader)
