import { Form, Input } from 'antd'
import {
  LOCAL_ADMIN_NOTIFICATION_RECEIVERS_OPTIONS,
  NOTIFICATION_RECEIVERS_OPTIONS
} from '__constants__'
import { Option, Select, Title } from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import { useNotificationSimpleFormFieldsRules } from '../NotificationSimpleForm/hooks'
import { useTranslations } from 'contexts/Translation'

const NotificationSimpleForm = (props) => {
  const { showTitle, group, isCurrentUserLocalAdmin } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  const {
    nameFieldRules,
    textEnFieldRules,
    textNoFieldRules,
    receiversTypeFieldRules
  } = useNotificationSimpleFormFieldsRules()

  const receiversGroupOptionsComputed = isCurrentUserLocalAdmin
    ? LOCAL_ADMIN_NOTIFICATION_RECEIVERS_OPTIONS
    : NOTIFICATION_RECEIVERS_OPTIONS

  return (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Notification')}
        </Title>
      )}
      <Form.Item
        label={t('Name')}
        name={[...group, 'name']}
        rules={nameFieldRules}>
        <Input placeholder={t('Please enter your notification name')} />
      </Form.Item>
      <Form.Item
        label={t('English Text')}
        name={[...group, 'textEn']}
        rules={textEnFieldRules}>
        <Input
          placeholder={t('Please enter your notification text in English')}
        />
      </Form.Item>
      <Form.Item
        label={t('Norway Text')}
        name={[...group, 'textNo']}
        rules={textNoFieldRules}>
        <Input
          placeholder={t('Please enter your notification text in Norway')}
        />
      </Form.Item>
      <Form.Item
        label={t('Receivers group')}
        name={[...group, 'receiversType']}
        rules={receiversTypeFieldRules}>
        <Select
          placeholder={t('Please select notification receivers group')}
          disabled={isCurrentUserLocalAdmin}>
          {receiversGroupOptionsComputed?.map((option) => (
            <Option key={option.value} value={option.value}>
              {t(option.label)}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </>
  )
}

NotificationSimpleForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  showTitle: PropTypes.bool,
  form: PropTypes.any,
  group: PropTypes.array,
  isCurrentUserLocalAdmin: PropTypes.bool
}

export default NotificationSimpleForm
