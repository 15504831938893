import { useEffect, useState } from 'react'

import PropTypes from 'prop-types'
import { StyledTable } from 'components'
import { useUserTableColumns } from 'domains/User/hooks'

const UserTable = (props) => {
  const { dataSource, isDashboardView = false } = props

  /* Pagination params for table */
  const [tableParams, setTableParams] = useState({
    current: 1,
    pageSize: 30,
    total: 0,
    showQuickJumper: true,
    showSizeChanger: true
  })

  /* Getting table columns */
  const columns = useUserTableColumns(isDashboardView)

  /* Handle changes for table */
  const handleTableChange = (pagination) => {
    setTableParams(pagination)
  }

  /* Update total value for tableParams */
  useEffect(() => {
    dataSource?.length &&
      setTableParams((prev) => ({
        ...prev,
        total: dataSource?.length
      }))
  }, [dataSource])

  return (
    <StyledTable
      {...props}
      rowKey="_id"
      dataSource={dataSource}
      columns={columns}
      pagination={!isDashboardView && tableParams}
      onChange={handleTableChange}
    />
  )
}

UserTable.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.object),
  isDashboardView: PropTypes.bool
}

export default UserTable
