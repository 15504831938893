import { Col, Row } from '@qonsoll/react-design'
import {
  HeaderBreadcrumbs,
  PageActions,
  PageWrapperActionsUnderTitle,
  Spinner
} from 'components'
import {
  useGetNotifications,
  useNotificationBaseActions
} from 'domains/Notification/hooks'
import { useMemo, useState } from 'react'

import { LOCAL_ADMIN_ROLE } from '__constants__'
import { NotificationTable } from 'domains/Notification/components'
import PATHS from 'pages/paths'
import PropTypes from 'prop-types'
import { useFilter } from 'hooks'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

const NotificationsAllPage = (props) => {
  const { withHeader = true } = props

  /* Getting translations instance */
  const { t, language } = useTranslations()

  const { role, newspaperId } = useUser()

  // state for the records related to the search (with search applied)
  const [searchedRecords, setSearchedRecords] = useState()

  // super admin has no limitations, but local admin has, he is connected to certain newspaper
  const isUserLocalAdmin = role === LOCAL_ADMIN_ROLE

  /* Getting filter params */
  const filterParams = useFilter('notification')

  /* Destructuring filter params */
  const { filterData } = filterParams

  /* Building filter query */
  const ref = useMemo(() => {
    const whereQueryComputed = filterData?.where || []

    // querying notifications by newspaper
    if (isUserLocalAdmin) {
      whereQueryComputed.push(['receiversNewspaper', '==', newspaperId])
    }

    return {
      ...filterData,
      where: whereQueryComputed
    }
  }, [filterData])

  /* Fetching notifications */
  const [notifications, loading] = useGetNotifications(ref)
  const notificationsModified = useMemo(
    () =>
      notifications?.map((el) => ({
        ...el,
        name: el?.name?.[language?.toLocaleUpperCase()] || el?.name
      })),
    [notifications]
  )

  /* Getting base actions */
  const listActions = useNotificationBaseActions()

  /* Configuring page header */
  const headingProps = {
    title: t('Notifications'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  /* Configuring page actions */
  const actions = {
    filter: false,
    create: Boolean(
      PATHS.AUTHENTICATED.NOTIFICATION_CREATE ||
        PATHS.AUTHENTICATED.NOTIFICATION_WIZARD
    ),
    search: true
  }

  /* Extracting base actions */
  const { onBack, onCreate, onSend } = listActions

  return (
    <PageWrapperActionsUnderTitle
      action={
        withHeader ? (
          <PageActions
            {...filterParams}
            onCreate={onCreate}
            onSend={onSend}
            actions={actions}
            circle
            collection={notificationsModified}
            setCollection={setSearchedRecords}
            searchPlaceholder="Search by notification Title"
            searchField="name"
          />
        ) : null
      }
      onBack={withHeader ? onBack : null}
      breadcrumbs={withHeader ? <HeaderBreadcrumbs /> : null}
      headingProps={withHeader ? headingProps : null}>
      <Row noGutters>
        {loading ? (
          <Col>
            <Spinner height="70vh" text={t('Notification loading')} />
          </Col>
        ) : (
          <Col cw={12}>
            <NotificationTable
              dataSource={searchedRecords ?? notificationsModified}
            />
          </Col>
        )}
      </Row>
    </PageWrapperActionsUnderTitle>
  )
}

NotificationsAllPage.propTypes = {
  isListWithCreate: PropTypes.bool,
  withHeader: PropTypes.bool
}

export default NotificationsAllPage
