import { Button, Title } from '@qonsoll/react-design'
import {
  useActionsNewspaperAdvancedForm,
  useInitializeNewspaperAdvancedForm
} from './hooks'

import { Form } from 'antd'
import { KEYS } from '__constants__'
import { NewspaperSimpleForm } from 'domains/Newspaper/components'
import PropTypes from 'prop-types'
import { useCheckChangeFormValue } from 'hooks'
import { useTranslations } from 'contexts/Translation'

const NewspaperAdvancedForm = (props) => {
  const { initialData, group, showTitle } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting form instance from hook */
  const { form } = useInitializeNewspaperAdvancedForm(initialData)

  /* Handling enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      form.submit()
    }
  }

  /* Getting loading state and actions from hook */
  const { loading, onFinish, onReset } = useActionsNewspaperAdvancedForm({
    initialData,
    form
  })

  const { triggerValueChange, isEqualForms } = useCheckChangeFormValue(form)

  return group ? (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Newspaper')}
        </Title>
      )}
      <NewspaperSimpleForm showTitle={false} group={[...group, 'newspaper']} />
    </>
  ) : (
    <Form
      onKeyPress={handleKeyPress}
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
      onValuesChange={triggerValueChange}
      form={form}>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Newspaper')}
        </Title>
      )}
      <NewspaperSimpleForm
        group={['newspaper']}
        form={form}
        initialValues={initialData}
      />
      <Button mr={2} htmlType="button" onClick={onReset}>
        {t('Cancel')}
      </Button>
      <Button
        type="primary"
        loading={loading}
        disabled={isEqualForms}
        onClick={() => form.submit()}>
        {t('Save')}
      </Button>
    </Form>
  )
}

NewspaperAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  group: PropTypes.array,
  showTitle: PropTypes.bool
}

export default NewspaperAdvancedForm
