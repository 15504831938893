import 'firebase/auth'

import { Box } from '@qonsoll/react-design'
import { generatePath, useHistory, useLocation } from 'react-router-dom'

import PATHS from './paths'
import PropTypes from 'prop-types'
import { Spinner } from 'components'
import firebase from 'firebase/compat/app'
import { useAuthState } from 'react-firebase-hooks/auth'
import { useEffect } from 'react'
import { useGetSubadminData, useHandleError } from 'hooks'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'
import { useGetNewspaperInitialValues } from 'domains/Newspaper/hooks'

const unauthenticatedPaths = Object.values(PATHS.UNAUTHENTICATED)
const servicePaths = Object.values(PATHS.SERVICE)

const RoutesRedirect = ({ children }) => {
  const { t } = useTranslations()
  const history = useHistory()
  const location = useLocation()
  const handleError = useHandleError()
  const [user, loading, error] = useAuthState(firebase.auth())
  const { _isSessionFetched, isTemporaryPasswordResolved } = useUser()
  const { isSubadmin, newspaperId } = useGetSubadminData()

  /* Get initial value for newspaper */
  const [newspaperInitialValues] = useGetNewspaperInitialValues(newspaperId)

  const isWebTippsDisabled =
    !newspaperInitialValues?.webTippsConfiguration?.isWebTippsEnabled

  // Making decision how to redirect
  useEffect(() => {
    /* This code is checking if the current path is in the unauthenticatedPaths array. */
    const isUnauthenticatedPath = unauthenticatedPaths.includes(
      location.pathname
    )

    const isServicePath = servicePaths.includes(location.pathname)

    /* If the user is logged in, and the user's email is verified, then the user is logged in. */
    const isEmailNotVerified = user && !user.emailVerified
    const isLoggedIn =
      isUnauthenticatedPath &&
      !isServicePath &&
      user &&
      !loading &&
      !isEmailNotVerified
    const isLoggedOut =
      !(isUnauthenticatedPath || isServicePath) && !user && !loading

    const webTippsConfigurationId =
      newspaperInitialValues?.webTippsConfiguration?._id

    /* Check if web tipps is enabled */
    const isNeedToRedirectFromWebConfigurationPage =
      isSubadmin &&
      webTippsConfigurationId &&
      location.pathname ===
        generatePath(PATHS.AUTHENTICATED.WEB_TIPPS_CONFIGURATION_EDIT, {
          webTippsConfigurationId
        }) &&
      isWebTippsDisabled

    /* Check if temporary password is resolved, if not - redirect to Reset password page */
    user &&
      !loading &&
      _isSessionFetched &&
      !isTemporaryPasswordResolved &&
      history.push(PATHS.AUTHENTICATED.RESET_PASSWORD)

    /* If the user is logged in, redirect to the config page. If the user is logged out, redirect to
    the logout page. If the user's email is not verified, redirect to the email confirmation page.
    */
    isLoggedIn && history.push(PATHS.CONFIG.AFTER_LOGIN)
    isLoggedOut && history.push(PATHS.CONFIG.AFTER_LOGOUT)
    isEmailNotVerified && history.push(PATHS.UNAUTHENTICATED.CONFIRM_EMAIL)

    /* If the user is subadmin and web tipps is disabled, redirect from web configuration page */
    isNeedToRedirectFromWebConfigurationPage &&
      history.push(PATHS.CONFIG.AFTER_LOGIN)
  }, [
    history,
    user,
    loading,
    isTemporaryPasswordResolved,
    _isSessionFetched,
    location.pathname,
    isSubadmin,
    isWebTippsDisabled
  ])

  // Session fetching error handling
  useEffect(() => {
    error && handleError(error)
  }, [error, handleError])

  return loading ? (
    <Box
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center">
      <Spinner text={t('Loading')} />
    </Box>
  ) : (
    children
  )
}

RoutesRedirect.propTypes = {
  children: PropTypes.element
}

export default RoutesRedirect
