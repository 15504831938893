import { Col, Row, Title } from '@qonsoll/react-design'

import { MOCKED_TIPS } from '__constants__'
import { NewsTipSimpleView } from 'domains/NewsTip/components'
import PropTypes from 'prop-types'
import React from 'react'
import { SettingsCombinedList } from 'components'
import { Spinner } from 'components'
import { StyledBody } from './Body.styled'
import { useGetNewsTipsToPreview } from 'domains/NewsTip/hooks'
import { useTranslations } from 'contexts/Translation'

const Body = ({
  pageConfiguration,
  newspaperId,
  countTipps = 5,
  customBodyContent,
  customTitle
}) => {
  const { t } = useTranslations()
  const {
    // pageTitleColor,
    backgroundColor
  } = pageConfiguration?.app || {}

  const [newsTips, loading] = useGetNewsTipsToPreview(newspaperId, countTipps)

  const tipsToShow = newsTips?.length ? newsTips : MOCKED_TIPS

  return (
    <StyledBody bg={backgroundColor}>
      <Row mb={3} noGutters>
        <Col>
          <Title
            variant="h3"
            // color={pageTitleColor || 'var(--ql-typography-heading-color)'}
          >
            {customTitle ? t(customTitle) : t('My tipps list')}
          </Title>
        </Col>
      </Row>
      {loading ? (
        <Spinner height="70vh" />
      ) : customBodyContent ? (
        customBodyContent
      ) : (
        <SettingsCombinedList
          responsiveColWidthArr={[12, 12, 12, 12, 12, 6]}
          hideAddCard
          items={tipsToShow}
          dataPropName="tip">
          <NewsTipSimpleView />
        </SettingsCombinedList>
      )}
    </StyledBody>
  )
}
Body.propTypes = {
  pageConfiguration: PropTypes.object,
  newspaperId: PropTypes.string,
  countTipps: PropTypes.number,
  customBodyContent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  customTitle: PropTypes.string
}
export default Body
