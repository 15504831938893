import { Option, Select, Title } from '@qonsoll/react-design'
import { useEffect, useMemo, useState } from 'react'

import { EXPIRATION_NOTIFICATION_DATE_RANGES } from '__constants__'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

// Takes a value of type object and onChange function
// Returns selected option object
const NewspaperNotificationRangesSelect = (props) => {
  const {
    value,
    onChange,
    showTitle = false,
    allowClear = true,
    mode,
    disabled,
    daysToExpiration
  } = props

  const { t } = useTranslations()
  const [computedValue, setComputedValue] = useState()
  // The items chosen by the admin

  // through which days notifications about the expiration of the points will be sent to the tippser.
  const computedOption = useMemo(
    () =>
      // An array of values after how many days a notification will be sent to the user
      EXPIRATION_NOTIFICATION_DATE_RANGES?.map((day) => {
        // if daysToExpiration is less than day of notification, disable notification day option
        const isDisabled = day > daysToExpiration
        return (
          <Option disabled={isDisabled} key={day} value={day}>
            {`${day} ${t('Day')}`}
          </Option>
        )
      }),
    [daysToExpiration]
  )

  // Set value to select if the value "above" has changed
  useEffect(() => setComputedValue(value), [value])

  useEffect(() => {
    // Remove from array selected ranges values which are greater than expiration days
    const filteredValues = computedValue?.filter((v) => v <= daysToExpiration)
    setComputedValue(filteredValues)
    filteredValues && onChange?.(filteredValues)
  }, [daysToExpiration])

  return (
    <>
      {showTitle && (
        <Title mb={2} level={4}>
          {t('Notification range')}
        </Title>
      )}
      <Select
        disabled={disabled}
        mode={mode}
        allowClear={allowClear}
        placeholder={t('Please, select notification ranges')}
        value={computedValue ?? []}
        onChange={onChange}>
        {computedOption}
      </Select>
    </>
  )
}

NewspaperNotificationRangesSelect.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  showTitle: PropTypes.bool,
  allowClear: PropTypes.bool,
  mode: PropTypes.string,
  disabled: PropTypes.bool,
  daysToExpiration: PropTypes.number
}

export default NewspaperNotificationRangesSelect
