import { Table } from 'antd'
import PropTypes from 'prop-types'
import { SettingsTableColumnRender } from '../../components'

const DomainConfigurationDNSSettingsTable = (props) => {
  const { dataSource } = props

  return (
    <Table
      columns={[
        {
          title: 'Type',
          dataIndex: 'type',
          key: 'type',
          render: (type) => <SettingsTableColumnRender value={type} />,
          width: 120
        },
        {
          title: 'Host',
          dataIndex: 'host',
          key: 'host',
          render: (host) => <SettingsTableColumnRender value={host} />,
          width: 120
        },
        {
          title: 'Value',
          dataIndex: 'value',
          key: 'value',
          render: (value) => <SettingsTableColumnRender value={value} />,
          ellipsis: true
        }
      ]}
      dataSource={dataSource}
      pagination={false}
      rowKey="type"
    />
  )
}

DomainConfigurationDNSSettingsTable.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.object)
}

export default DomainConfigurationDNSSettingsTable
