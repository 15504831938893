import { Card, Col, PageWrapper, Row } from '@qonsoll/react-design'
import {
  HeaderBreadcrumbs,
  PageActions,
  PageWrapperActionsUnderTitle,
  Spinner
} from 'components'
import {
  REWARD_OVERVIEW_TYPES,
  REWARD_OVERVIEW_TYPES_ALL
} from 'domains/RewardOverview/constants'
import {
  RewardOverviewFilter,
  RewardOverviewTable
} from 'domains/RewardOverview/components'
import {
  useExportRewardOverviewCSV,
  useGetRewardsOverview
} from 'domains/RewardOverview/hooks'
import { useMemo, useState } from 'react'

import PropTypes from 'prop-types'
import { Tabs } from 'antd'
import { capitalize } from 'lodash'
import { useFilter } from 'hooks'
import { useNewspaperBaseActions } from 'domains/Newspaper/hooks'
import { useStateWithStorage } from 'hooks'
import { useTranslations } from 'contexts/Translation'

const { TabPane } = Tabs

const RewardOverviewAll = (props) => {
  const { withHeader = true } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Getting filter params */
  const filterParams = useFilter('rewardOverview')
  /* Destructuring filter params */
  const { filterVisibility, setFilterVisibility, filterData } = filterParams

  // TODO create listAction for Reward Overview if this functional will be in use in future / or delete actions if not
  const listActions = useNewspaperBaseActions()

  const [filteredRewardOverview, setFilteredRewardOverview] = useState()
  /* Building filter query */
  const ref = useMemo(() => filterData, [filterData])

  const [rewardOverviews, loading] = useGetRewardsOverview(ref)

  const { exportCSVRewardOverview, exportCSVLoading, exportCSVAllLoading } =
    useExportRewardOverviewCSV()

  /* Configuring page header */
  const headingProps = {
    title: t('Rewards Overview'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  /* Configuring page actions */
  const actions = {
    filter: true,
    search: true,
    allExport: true,
    export: true
  }

  /* Extracting base actions */
  const { onBack } = listActions

  const onChangeTab = (key) => setLastActiveTab(key)
  const [lastActiveTab, setLastActiveTab] = useStateWithStorage(
    REWARD_OVERVIEW_TYPES.STORE,
    `rewardOverciew-last-active-tab`
  )

  return (
    <PageWrapper
      action={withHeader ? <PageActions {...filterParams} /> : null}
      onBack={withHeader ? onBack : null}
      breadcrumbs={withHeader ? <HeaderBreadcrumbs /> : null}
      headingProps={withHeader ? headingProps : null}>
      <Row mx="var(--ql-grid-gutter-negative)">
        <Col cw={12}>
          <Card>
            <Tabs
              defaultActiveKey={lastActiveTab}
              activeKey={lastActiveTab}
              onChange={onChangeTab}>
              {Object.keys(REWARD_OVERVIEW_TYPES)?.map((type) => (
                <TabPane tab={t(capitalize(type))} key={type}>
                  <PageWrapperActionsUnderTitle
                    index={type}
                    action={
                      withHeader ? (
                        <PageActions
                          {...filterParams}
                          actions={actions}
                          filterMenu={
                            <RewardOverviewFilter {...filterParams} />
                          }
                          filterVisibility={filterVisibility}
                          setFilterVisibility={setFilterVisibility}
                          collection={rewardOverviews}
                          setCollection={setFilteredRewardOverview}
                          searchField={[
                            'rewardName',
                            'fullName',
                            'storeName',
                            'email'
                          ]}
                          searchPlaceholder="Search by full name, email, reward name, store name"
                          onExportCSV={() => exportCSVRewardOverview(type)}
                          onAllExportCSV={() =>
                            exportCSVRewardOverview(REWARD_OVERVIEW_TYPES_ALL)
                          }
                          allExportCSVLoading={exportCSVAllLoading}
                          exportCSVLoading={exportCSVLoading}
                        />
                      ) : null
                    }>
                    {loading ? (
                      <Spinner
                        height="35vh"
                        text={t('Reward overviews loading')}
                      />
                    ) : (
                      <RewardOverviewTable
                        lastActiveTab={lastActiveTab}
                        dataSource={filteredRewardOverview ?? rewardOverviews}
                      />
                    )}
                  </PageWrapperActionsUnderTitle>
                </TabPane>
              ))}
            </Tabs>
          </Card>
        </Col>
      </Row>
    </PageWrapper>
  )
}

RewardOverviewAll.propTypes = {
  isListWithCreate: PropTypes.bool,
  withHeader: PropTypes.bool
}

export default RewardOverviewAll
