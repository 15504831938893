import { Col, PageWrapper, Row } from '@qonsoll/react-design'
import { HeaderBreadcrumbs, Spinner } from 'components'

import { ProfileAdvancedForm } from 'domains/Profile/components'
import { useProfileBaseActions } from 'domains/Profile/hooks'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

const ProfileEdit = (props) => {
  const { onBack } = useProfileBaseActions()

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Getting user from context */
  const user = useUser()

  /* Configuring page header */
  const headingProps = {
    title: t('Profile edit'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  return (
    <PageWrapper
      onBack={onBack}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}>
      <Row noGutters>
        {!user ? (
          <Col>
            <Spinner height="70vh" text={t('Profile is  loading')} />
          </Col>
        ) : (
          <Col cw={[12, 12, 10, 8, 7]}>
            <ProfileAdvancedForm initialData={{ user }} />
          </Col>
        )}
      </Row>
    </PageWrapper>
  )
}

export default ProfileEdit
