import {
  BookOutlined,
  CloudDownloadOutlined,
  CloudUploadOutlined,
  FileTextOutlined,
  PhoneOutlined,
  TeamOutlined
} from '@ant-design/icons'
import {
  Box,
  Button,
  Card,
  Col,
  PageWrapper,
  Row,
  Text,
  Title
} from '@qonsoll/react-design'
import {
  useExportUsersCSV,
  useGetLimitUsers,
  useTransformedDataSource
} from 'domains/User/hooks'

import PATHS from 'pages/paths'
import { Spinner } from 'components'
import { Tooltip } from 'antd'
import { UserTable } from 'domains/User/components'
import { rowStyles } from './Dashboard.styles'
import { useGetDashboardStatistic } from 'domains/Statistic/hooks'
import { useHistory } from 'react-router-dom'
import { useMemo } from 'react'
import { useTranslations } from 'contexts'

const Dashboard = () => {
  const { t } = useTranslations()

  const history = useHistory()

  const [users, loadingUsers] = useGetLimitUsers()
  const transformedDataSource = useTransformedDataSource(users)
  const statisticData = useGetDashboardStatistic()
  const { handleExportCSV, exportCSVLoading } = useExportUsersCSV()

  const statisticItems = useMemo(() => {
    const {
      totalUsers,
      totalEditors,
      totalNewsTips,
      totalReward,
      totalPointsGiven,
      totalPointsNotUsed
    } = statisticData

    return [
      {
        description: t('Users count'),
        count: totalUsers,
        icon: <TeamOutlined />
      },
      {
        description: t('Editors count'),
        count: totalEditors,
        icon: <BookOutlined />
      },
      {
        description: t('News uploads count'),
        count: totalNewsTips,
        icon: <FileTextOutlined />
      },
      {
        description: t('Rewards added count'),
        count: totalReward,
        icon: <CloudUploadOutlined />
      },
      {
        description: t('Total points given'),
        count: totalPointsGiven,
        icon: <PhoneOutlined />
      },
      {
        description: t('Points not used'),
        count: totalPointsNotUsed,
        icon: <CloudDownloadOutlined />
      }
    ]
  }, [statisticData, t])

  const headingProps = {
    title: t('Dashboard'),
    titleSize: 3,
    textAlign: 'left'
  }

  const handleViewAll = () => history.push(PATHS.AUTHENTICATED.USERS_ALL)

  return (
    <PageWrapper headingProps={headingProps}>
      <Row
        mx="var(--ql-grid-gutter-negative)"
        mb={24}
        justifyContent="space-between"
        flexWrap="wrap"
        style={rowStyles}>
        {statisticItems.map(({ description, count, icon }) => (
          <Col cw={[12, 12, 6, 4, 4]} key={description}>
            <Card>
              <Box overflow="hidden" display="flex" maxWidth="300">
                <Text fontSize={20} mr={2}>
                  {icon}
                </Text>
                <Tooltip title={count} placement="top">
                  <Title
                    overflow="hidden"
                    isEllipsis
                    whiteSpace="nowrap"
                    level={4}>
                    {count}
                  </Title>
                </Tooltip>
              </Box>
              <Text variant="body2" type="secondary">
                {description}
              </Text>
            </Card>
          </Col>
        ))}
      </Row>

      <Card mb={2}>
        <Row noGutters h="between" mb="16px">
          <Col cw="auto">
            <Title level={4}>{t('Users')}</Title>
          </Col>
          <Col cw="auto">
            <Box display="flex">
              <Button
                mr="8px"
                type="primary"
                onClick={handleExportCSV}
                loading={exportCSVLoading}>
                {t('All Export CSV')}
              </Button>
              <Button type="primary" onClick={handleViewAll}>
                {t('View All')}
              </Button>
            </Box>
          </Col>
        </Row>
        {loadingUsers ? (
          <Spinner height="30vh" text={t('Loading users')} />
        ) : (
          <UserTable
            dataSource={transformedDataSource}
            isDashboardView
            pagination={false}
          />
        )}
      </Card>
    </PageWrapper>
  )
}

export default Dashboard
