import { Form } from 'antd'
import { useEffect } from 'react'

const useInitializeNewspaperAdvancedForm = (initialData) => {
  /* Getting form instance */
  const [form] = Form.useForm()

  /* Setting initial data */
  useEffect(() => {
    if (initialData) {
      /* Getting initial values */
      const { newspaper } = initialData

      /* Formatting date fields */
      const formValues = {
        newspaper: {
          logoUrl: newspaper?.logoUrl ?? null,
          name: newspaper?.name ?? null,
          description: newspaper?.description ?? null,
          countries: newspaper?.countries ?? {}
        }
      }

      /* Setting initial values to form instance */
      form.setFieldsValue(formValues)
    }
  }, [initialData, form])

  return { form }
}

export default useInitializeNewspaperAdvancedForm
