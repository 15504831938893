import { Box, Button, Text } from '@qonsoll/react-design'
import { Dropdown, Menu } from 'antd'
import {
  iconLanguageSelectedStyles,
  iconLanguageStyles
} from './LanguageButtonStyled.style'

import { Icon } from '@qonsoll/icons'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslations } from 'contexts/Translation'

const LanguageMenu = ({ color }) => {
  const { language, languages, setCurrentLanguage } = useTranslations()

  const languageCode = language?.toUpperCase()
  const shortCodetoUpperCase = (item) => item?.shortCode?.toUpperCase()

  const languageMenu = (
    <Menu defaultSelectedKeys={language} selectable>
      {languages?.map((item) => (
        <Menu.Item
          key={item.shortCode}
          onClick={() => {
            setCurrentLanguage(item.shortCode)
          }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between">
            <Text>{shortCodetoUpperCase(item)}</Text>
            {language === item?.shortCode && (
              <Icon {...iconLanguageSelectedStyles} />
            )}
          </Box>
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <Dropdown overlay={languageMenu} placement="bottomRight" trigger="click">
      <Button color={color} type="text" width="90px">
        <Icon {...iconLanguageStyles} />
        <Text color="inherit">{languageCode}</Text>
      </Button>
    </Dropdown>
  )
}
LanguageMenu.propTypes = {
  color: PropTypes.string
}

export default LanguageMenu
