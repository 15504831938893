import { Col, Row } from '@qonsoll/react-design'
import {
  HeaderBreadcrumbs,
  PageActions,
  PageWrapperActionsUnderTitle,
  Spinner
} from 'components'
import { SubadminFilter, SubadminTable } from 'domains/Subadmin/components'
import { useGetSubadmins, useSubadminBaseActions } from 'domains/Subadmin/hooks'
import { useMemo, useState } from 'react'

import PATHS from 'pages/paths'
import PropTypes from 'prop-types'
import { useFilter, useGetCustomDataSource } from 'hooks'
import { useTranslations } from 'contexts/Translation'

const AdminsAllPage = (props) => {
  const { withHeader = true } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Getting filter params */
  const filterParams = useFilter('admin')
  /* Destructuring filter params */
  const { filterVisibility, filterData, setFilterVisibility } = filterParams

  /* Building filter query */
  const ref = useMemo(() => filterData, [filterData])
  /* Fetching of super admins */
  // this hook will fetch admins, because we are passing special prop there
  const [superAdmins, loading] = useGetSubadmins(ref, true)

  const [superAdminsTransformedData, isSuperAdminsTransformedDataLoading] =
    useGetCustomDataSource(superAdmins)
  /* Getting base actions */
  const listActions = useSubadminBaseActions({ isSuperAdmin: true })

  const [filteredSuperAdmins, setFilteredSuperAdmins] = useState()

  /* Configuring page header */
  const headingProps = {
    title: t('Super admins'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  /* Configuring page actions */
  const actions = {
    filter: true,
    create: Boolean(PATHS.AUTHENTICATED.ADMIN_CREATE),
    search: true
  }

  /* Extracting base actions */
  const { onBack, onCreate } = listActions

  /* The line `const loadingStateComputed = loading || isSuperAdminsTransformedDataLoading` is creating
	a new variable `loadingStateComputed` that is assigned the value of `loading` OR
	`isSuperAdminsTransformedDataLoading`. */
  const loadingStateComputed = loading || isSuperAdminsTransformedDataLoading

  return (
    <PageWrapperActionsUnderTitle
      action={
        withHeader ? (
          <PageActions
            {...filterParams}
            onCreate={onCreate}
            actions={actions}
            circle
            filterMenu={<SubadminFilter {...filterParams} isSuperAdmin />}
            filterVisibility={filterVisibility}
            setFilterVisibility={setFilterVisibility}
            // search props
            collection={superAdminsTransformedData}
            setCollection={setFilteredSuperAdmins}
            searchField={['fullName', 'email']}
            searchPlaceholder={t('Search by Name or email')}
          />
        ) : null
      }
      onBack={withHeader ? onBack : null}
      breadcrumbs={withHeader ? <HeaderBreadcrumbs /> : null}
      headingProps={withHeader ? headingProps : null}>
      <Row noGutters>
        {loadingStateComputed ? (
          <Col>
            <Spinner height="70vh" text={t('Admins are loading')} />
          </Col>
        ) : (
          <Col cw={12}>
            <SubadminTable
              dataSource={filteredSuperAdmins ?? superAdmins}
              isSuperAdmin
            />
          </Col>
        )}
      </Row>
    </PageWrapperActionsUnderTitle>
  )
}

AdminsAllPage.propTypes = {
  isListWithCreate: PropTypes.bool,
  withHeader: PropTypes.bool
}

export default AdminsAllPage
