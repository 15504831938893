import { Col, Row, Text } from '@qonsoll/react-design'
import {
  DomainConfigurationDNSSettingsTable,
  DomainConfigurationSimpleForm
} from '../../components'
import { Grid, Steps, message } from 'antd'

import { DOMAIN_CONFIGURATION_STEPS } from '../../__constants__'
import { ModalStyled } from './DomainConfigurationModal.styles'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const { useBreakpoint } = Grid

const { ENTER_DOMAIN, VERIFY_OWNERSHIP, GO_LIVE } = DOMAIN_CONFIGURATION_STEPS

const { Step } = Steps

const DomainConfigurationModal = (props) => {
  const {
    visible,
    currentStep,
    submitLoading,
    handleNextStep,
    handleCancel,
    addDomainFormProps,
    // temporarily mocked data
    dnsSettings = [
      {
        type: 'TXT',
        host: 'sample' || 'hello.ua',
        value:
          'google-site-verification sample' ||
          'google-site-verification=itezEgnu8mAxkdV3ahPaQT1i-GJxXsO1V_nF-PQiHfY'
      }
    ]
  } = props

  const { t } = useTranslations()
  const breakpoints = useBreakpoint()

  /* Form steps config */
  const STEPS_CONFIG = [
    {
      ...ENTER_DOMAIN,
      component: (
        <DomainConfigurationSimpleForm
          {...addDomainFormProps}
          handleNextStep={handleNextStep}
        />
      ),
      onSubmit: addDomainFormProps?.onSubmit,
      form: addDomainFormProps.form
    },
    {
      ...VERIFY_OWNERSHIP,
      component: (
        <DomainConfigurationDNSSettingsTable dataSource={dnsSettings} />
      )
    },
    {
      ...GO_LIVE,
      component: (
        <DomainConfigurationDNSSettingsTable dataSource={dnsSettings} />
      )
    }
  ]

  /* Get direction for Step */
  const stepDirection = breakpoints?.md ? 'horizontal' : 'vertical'

  /* Get description for current step */
  const description = t(STEPS_CONFIG?.[currentStep]?.description)
  /* Get component for current step */
  const component = STEPS_CONFIG?.[currentStep]?.component
  /* Get text for ok button for current step */
  const okText = STEPS_CONFIG?.[currentStep]?.okText
  /* Get submit function for "ok" button for current step */
  const handleOk = async () => {
    try {
      // Perform validate fields function if form exists
      // it is important to validate form before moving on to the next step
      const form = STEPS_CONFIG?.[currentStep]?.form
      const onSubmit = STEPS_CONFIG?.[currentStep]?.onSubmit
      form && (await form.validateFields())

      // Perform submit function if such exists
      onSubmit && form && (await onSubmit(form?.getFieldsValue()))
      // Go to the next step if there was no error
      handleNextStep()
    } catch (error) {
      // Get a simple message error or a first error by field
      const specificErrorMessage =
        error?.message || error?.errorFields?.[0]?.errors?.[0]

      message.error(
        `${t('Error during saving domain')}. ${t(specificErrorMessage)}`
      )
    }
  }

  return (
    <ModalStyled
      centered
      breakpoints={breakpoints}
      visible={visible}
      title={t('Add domain')}
      okText={okText}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{ loading: submitLoading }}
      destroyOnClose>
      <Row noGutters>
        <Col cw={12} my={2}>
          <Steps
            current={currentStep}
            breakpoints={breakpoints}
            direction={stepDirection}>
            {STEPS_CONFIG?.map(({ title }) => (
              <Step title={t(title)} key={title} />
            ))}
          </Steps>
        </Col>
        <Col cw={12} mb={3}>
          <Text>{t(description)}</Text>
        </Col>
        <Col cw={12}>{component}</Col>
      </Row>
    </ModalStyled>
  )
}

DomainConfigurationModal.propTypes = {
  visible: PropTypes.bool,
  addDomainFormProps: PropTypes.shape({
    onSubmit: PropTypes.func,
    initialValues: PropTypes.shape({
      domainName: PropTypes.string
    }),
    form: PropTypes.object
  }),
  currentStep: PropTypes.number,
  submitLoading: PropTypes.bool,
  dnsSettings: PropTypes.arrayOf(PropTypes.object),
  handleNextStep: PropTypes.func,
  handleCancel: PropTypes.func
}

export default DomainConfigurationModal
