import { useMemo } from 'react'
import { useLoading } from 'hooks'
import { useDocument } from 'services/api/firebase'
import { COLLECTIONS } from '__constants__'
import { useGetUserNewspaper, useGetUserNewsTips } from 'domains/User/hooks'

const useGetUserInitialValues = (id) => {
  /* Fetching data using useDocument hook */
  const [user, userLoading] = useDocument({
    ref: id ? COLLECTIONS.USERS + '/' + id : null
  })

  /* Fetching newspaper data */
  const [newspaperAssociated, newspaperLoading] = useGetUserNewspaper(user)

  /* Fetching newsTip data */
  const [newsTips, newsTipLoading] = useGetUserNewsTips(user)

  /* Merge all fetched data */
  const initialValues = useMemo(
    () => ({
      user,
      newspaperAssociated,
      newsTips
    }),
    [user, newspaperAssociated, newsTips]
  )

  /* Merge all loading states */
  const loadings = useMemo(
    () => (id ? [!user, userLoading, newspaperLoading, newsTipLoading] : []),
    [id, user, userLoading, newspaperLoading, newsTipLoading]
  )

  /* Getting loading state using merged loadings */
  const loading = useLoading(loadings)

  return [initialValues, loading]
}

export default useGetUserInitialValues
