import { Col, Container, Row, Text } from '@qonsoll/react-design'

import { NewspaperSimpleView } from '../../../Newspaper/components'
import PropTypes from 'prop-types'
import { Spinner } from 'components'
import { useGetStoreNewspaper } from '../../hooks'
import { useTranslations } from 'contexts/Translation'

export default function StoreNewspaper(props) {
  const { store } = props

  /* Getting required data */
  const [newspaper, loading] = useGetStoreNewspaper(store)

  /* Getting translation instance */
  const { t } = useTranslations()

  return (
    <Container>
      <Row noGutters>
        <Col>
          {loading ? (
            <Spinner flex={1} />
          ) : newspaper ? (
            <NewspaperSimpleView {...props} newspaper={newspaper} />
          ) : (
            <Text type="secondary" variant="overline">
              {t('No newspapers have been added yet')}
            </Text>
          )}
        </Col>
      </Row>
    </Container>
  )
}

StoreNewspaper.propTypes = {
  store: PropTypes.object.isRequired
}
