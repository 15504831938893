import { Icon } from '@qonsoll/icons'
import { Text } from '@qonsoll/react-design'
import { EditorEmailWrapper, iconStyles } from './EditorEmailSimpleView.styles'
import PropTypes from 'prop-types'
import { Tooltip } from 'antd'
import { useTranslations } from 'contexts/Translation'

const EditorEmailSimpleView = (props) => {
  const { email } = props

  const { t } = useTranslations()

  return (
    <EditorEmailWrapper>
      <Text>{email}</Text>
      <Tooltip title={t('Editor')}>
        <Icon {...iconStyles} name="Star1Outlined" />
      </Tooltip>
    </EditorEmailWrapper>
  )
}
EditorEmailSimpleView.propTypes = {
  email: PropTypes.string
}

export default EditorEmailSimpleView
