import styled from 'styled-components'

const FooterStyled = styled.footer`
  background-color: ${({ bg }) => bg || 'var(--ql-footer-bg)'};
  color: ${({ textColor }) => textColor || 'var(--tabs-color)'};
  width: 100%;
  padding: 24px;
`
const StyledTextWrapper = styled.div`
  & > p {
    margin: 0;
  }
`

export { FooterStyled, StyledTextWrapper }
