import { Box, Button } from '@qonsoll/react-design'
import { INLINE_OPTIONS_CONFIG } from 'domains/Content/constants'
import PropTypes from 'prop-types'

const TextEditInlineBlock = (props) => {
  const { config, onChange, currentState } = props

  const handleOnClick = (option) => onChange(option, !currentState[option])
  return (
    <Box mx={-1} pr={2}>
      {config?.options?.map((option, index) => (
        <Button
          key={`TextEditInlineBlock-${index}`}
          mx={1}
          size="small"
          type={currentState[option] ? 'primary' : 'text'}
          onClick={() => handleOnClick(option)}
          icon={INLINE_OPTIONS_CONFIG[option]}
        />
      ))}
    </Box>
  )
}

TextEditInlineBlock.propTypes = {
  config: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  currentState: PropTypes.object.isRequired
}

export default TextEditInlineBlock
