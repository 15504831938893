import { Col, Row } from '@qonsoll/react-design'
import { EditorFilter, EditorTable } from 'domains/Editor/components'
import {
  HeaderBreadcrumbs,
  PageActions,
  PageWrapperActionsUnderTitle,
  Spinner
} from 'components'
import { useEditorBaseActions, useGetEditors } from 'domains/Editor/hooks'
import { useFilter, useGetCustomDataSource } from 'hooks'
import { useMemo, useState } from 'react'

import PATHS from 'pages/paths'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const EditorsAllPage = (props) => {
  const { withHeader = true } = props

  const [collection, setCollection] = useState()
  /* Getting translations instance */
  const { t } = useTranslations()

  /* Getting filter params */
  const filterParams = useFilter('editor')

  /* Destructuring filter params */
  const { filterVisibility, filterData, setFilterVisibility } = filterParams

  /* Building filter query */
  const ref = useMemo(() => filterData, [filterData])

  /* Fetching editors */
  const [editors, loading] = useGetEditors(ref)

  const [customDataSource, customDataSourceLoading] =
    useGetCustomDataSource(editors)

  /* Getting base actions */
  const listActions = useEditorBaseActions()

  /* Configuring page header */
  const headingProps = {
    title: t('Editors'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  /* Configuring page actions */
  const actions = {
    filter: true,
    create: Boolean(
      PATHS.AUTHENTICATED.EDITOR_CREATE || PATHS.AUTHENTICATED.EDITOR_WIZARD
    ),
    search: true
  }

  /* Extracting base actions */
  const { onBack, onCreate } = listActions

  return (
    <PageWrapperActionsUnderTitle
      action={
        withHeader ? (
          <PageActions
            {...filterParams}
            onCreate={onCreate}
            actions={actions}
            circle
            filterMenu={<EditorFilter {...filterParams} />}
            filterVisibility={filterVisibility}
            setFilterVisibility={setFilterVisibility}
            collection={customDataSource}
            setCollection={setCollection}
            searchField={['fullName', 'email']}
            searchPlaceholder={t('Search by Editor Name or email')}
          />
        ) : null
      }
      onBack={withHeader ? onBack : null}
      breadcrumbs={withHeader ? <HeaderBreadcrumbs /> : null}
      headingProps={withHeader ? headingProps : null}>
      <Row noGutters>
        {loading || customDataSourceLoading ? (
          <Col>
            <Spinner height="60vh" text={t('Editor loading')} />
          </Col>
        ) : (
          <Col cw={12}>
            <EditorTable dataSource={collection ?? customDataSource} />
          </Col>
        )}
      </Row>
    </PageWrapperActionsUnderTitle>
  )
}

EditorsAllPage.propTypes = {
  isListWithCreate: PropTypes.bool,
  withHeader: PropTypes.bool
}

export default EditorsAllPage
