import {
  createDocument,
  getId,
  updateDocument,
  updateParent
} from 'services/api/firebase'

import { COLLECTIONS } from '__constants__'
import { Notification } from 'models'
import { RECEIVERS_NEWSPAPER_USERS_TYPE } from '__constants__/notificationReceiversTypes'
import { message } from 'antd'
import { useCheckChangeFormValue } from 'hooks'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const useActionsNotificationAdvancedForm = ({
  initialData,
  isCurrentUserLocalAdmin = false,
  form
} = {}) => {
  /* State for loading */
  const [loading, setLoading] = useState(false)
  const [isNewspaperUsersReceive, setIsNewspaperUsersReceive] = useState(
    isCurrentUserLocalAdmin
  )

  const { triggerValueChange, isEqualForms } =
    useCheckChangeFormValue(initialData)

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()
  /* Getting history state */
  const historyState = history.location.state

  /* Function for preparing values for saving */
  const prepareValues = async (values = {}, additionalValues = {}) => {
    /* Getting id */
    const notificationId =
      initialData?.['notification']?._id || getId(COLLECTIONS.NOTIFICATIONS)

    /* Preparing notification values */
    const preparedValues = {
      _id: notificationId,
      ...additionalValues,
      sno: null,
      name: values?.notification?.name ?? null,
      text: {
        EN: values?.notification?.textEn ?? null,
        NO: values?.notification?.textNo ?? null
      },
      receiversType: values?.notification?.receiversType ?? null,
      receiversNewspaper: values?.receiversNewspaper?._id ?? null,
      receiverId: null,
      isResend: false
    }
    return preparedValues
  }

  /* Saving form data */
  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)

      if (historyState?.parent)
        await updateParent(historyState.parent, data._id)

      // Save data
      if (initialData) {
        await updateDocument(
          COLLECTIONS.NOTIFICATIONS,
          initialData.notification?._id,
          data
        )
        message.success(t('Notification successfully updated'))
      } else {
        await createDocument(COLLECTIONS.NOTIFICATIONS, data, data._id)
        message.success(t('Notification successfully created'))
      }
      // Final callback
      callback?.()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Something went wrong during data save'))
    }
  }

  /* Function for validating form */
  const validateForm = (values) => {
    try {
      // Prepare data to be validated
      const validationData = {
        name: values?.notification?.name ?? null,
        text: {
          EN: values?.notification?.textEn ?? null,
          NO: values?.notification?.textNo ?? null
        },
        receiversType: values?.notification?.receiversType ?? null,
        receiversNewspaper: values?.receiversNewspaper?._id ?? null
      }
      Notification.validationSchema.validateSync(validationData)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      throw new Error(t('Notification validation error: ') + t(error.message))
    }
  }

  /* On finish callback */
  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()
      // Validate fields
      validateForm(formValues)
      // Final callback
      const callback = () => history.goBack()
      // Save data
      await saveForm(formValues, callback)
    } catch (error) {
      setLoading(false)
      message.error(error.message)
    }
  }

  const onFormValuesChange = (changedValues) => {
    const isNewspaperUsersSelected =
      changedValues?.notification?.receiversType ===
      RECEIVERS_NEWSPAPER_USERS_TYPE

    triggerValueChange(changedValues)
    // Make newspaper select active
    if (isNewspaperUsersSelected) {
      setIsNewspaperUsersReceive(true)
    }

    // Clear selected newspaper for receivers and disable newspapers select
    if (
      changedValues?.notification?.receiversType &&
      !isNewspaperUsersSelected
    ) {
      form.setFieldsValue({ receiversNewspaper: null })
      setIsNewspaperUsersReceive(false)
    }
  }

  /* On cancel callback */
  const onReset = () => {
    form.resetFields()
    history.goBack()
  }

  return {
    isEqualForms,
    isNewspaperUsersReceive,
    loading,
    onFinish,
    onReset,
    onFormValuesChange,
    saveForm,
    validateForm,
    prepareValues
  }
}

export default useActionsNotificationAdvancedForm
