import {
  Col,
  Container,
  Option,
  Row,
  Select,
  Title
} from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import { capitalizeFirstLetter } from 'utils'
import { useGetRewardTypes } from 'domains/RewardType/hooks'
import { useTranslations } from 'contexts/Translation'

// Takes a value of type object and onChange function
// Returns selected option object
const RewardTypeSelect = (props) => {
  const {
    value,
    onChange,
    showTitle = true,
    allowClear = true,
    mode,
    setRewardType,
    ...rest
  } = props
  const { t } = useTranslations()

  const [rewardTypesAll] = useGetRewardTypes()

  const handleChange = (value) => {
    onChange?.(value)
    setRewardType(value)
  }

  return (
    <Container {...rest}>
      {showTitle && (
        <Title mb={2} level={4}>
          {t('Reward type')}
        </Title>
      )}
      <Row noGutters>
        <Col>
          <Select
            mode={mode}
            allowClear={allowClear}
            placeholder={t('Please, select reward type')}
            value={value}
            onChange={handleChange}>
            {rewardTypesAll?.map((rewardType) => (
              <Option key={rewardType} value={rewardType}>
                {capitalizeFirstLetter(rewardType?.toLocaleLowerCase())}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
    </Container>
  )
}

RewardTypeSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  setRewardType: PropTypes.func,
  showTitle: PropTypes.bool,
  allowClear: PropTypes.bool,
  mode: PropTypes.string
}

export default RewardTypeSelect
