import { Link, Text } from '@qonsoll/react-design'
import { cloneElement, useMemo } from 'react'
import { generatePath, useHistory } from 'react-router-dom'

import { MOMENT_FORMATS } from '__constants__'
import { NewspaperNewspaperStatus } from 'domains/Newspaper/components'
import PATHS from 'pages/paths'
import { TableActions } from 'components'
import moment from 'moment'
import { sortByAlphabet } from 'helpers'
import { useGetColumnSearchProps } from 'hooks'
import { useNewspaperBaseActions } from 'domains/Newspaper/hooks'
import { useTranslations } from '@qonsoll/translation'

const NewspaperTableActions = ({ newspaperId, logoUrl, isBlocked }) => {
  const actions = useNewspaperBaseActions({ newspaperId, logoUrl, isBlocked })

  return cloneElement(<TableActions />, {
    actions,
    isBlocked,
    name: 'newspaper'
  })
}

const useNewspaperTableColumns = () => {
  const { t } = useTranslations()
  const history = useHistory()

  const getColumnSearchProps = useGetColumnSearchProps()

  return useMemo(() => {
    const handleOpen = (newspaperId) =>
      history.push(
        generatePath(PATHS.AUTHENTICATED.NEWSPAPER_SHOW, {
          newspaperId
        })
      )

    return [
      {
        title: <Text fontWeight="600">{t('Sno')}</Text>,
        dataIndex: 'sno',
        width: 'var(--table-number-column-width)',
        key: 'sno',
        align: 'center',
        sorter: (a, b) => a?.sno - b?.sno,
        ...getColumnSearchProps('sno'),
        render: (_, record) => <Text>{record?.sno}</Text>
      },
      {
        title: <Text fontWeight="600">{t('Newspaper')}</Text>,
        dataIndex: 'name',
        key: 'name',
        align: 'center',
        sorter: (a, b) => sortByAlphabet(a?.name, b?.name),
        ...getColumnSearchProps('name', 'Newspaper'),
        render: (_, record) => (
          <Link onClick={() => handleOpen(record?._id)}>{record?.name}</Link>
        )
      },
      {
        title: <Text fontWeight="600">{t('Added on')}</Text>,
        dataIndex: '_createdAt',
        key: '_createdAt',
        align: 'center',
        sorter: (a, b) => a?._createdAt?.seconds - b?._createdAt?.seconds,
        render: (date) => (
          <Text>
            {moment
              .unix(date?.seconds)
              .format(MOMENT_FORMATS.MONTH_DAYS_COMMA_YEAR)}
          </Text>
        )
      },
      {
        title: <Text fontWeight="600">{t('Total editors')}</Text>,
        dataIndex: 'totalEditors',
        key: 'totalEditors',
        align: 'center',
        sorter: (a, b) => a?.totalEditors - b?.totalEditors,
        render: (_, record) => <Text>{record?.totalEditors}</Text>
      },
      {
        title: <Text fontWeight="600">{t('Total tips')}</Text>,
        dataIndex: 'totalTips',
        key: 'totalTips',
        align: 'center',
        sorter: (a, b) => a?.totalTips - b?.totalTips,
        render: (_, record) => <Text>{record?.totalTips}</Text>
      },
      {
        title: <Text fontWeight="600">{t('Status')}</Text>,
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        sorter: (a, b) => sortByAlphabet(a?.status, b?.status),
        render: (_, record) => <NewspaperNewspaperStatus newspaper={record} />
      },
      {
        title: <Text fontWeight="600">{t('Actions')}</Text>,
        width: 'var(--table-actions-column-width)',
        dataIndex: 'actions',
        key: 'actions',
        align: 'center',
        render: (_, record) => (
          <NewspaperTableActions
            newspaperId={record?._id}
            logoUrl={record?.logoUrl}
            isBlocked={record?.isBlocked}
          />
        )
      }
    ]
  }, [t, getColumnSearchProps, history])
}

export default useNewspaperTableColumns
