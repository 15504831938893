const EMAIL_TO_EXISTENCE = 'userEmailExistance'
const STATISTICS = 'statistic'
const STATISTICS_NEWS_TIPS_BY_NEWSPAPER = 'newsTipsByNewspaper'
const STATISTIC_TOTAL_GIVEN_POINTS = 'totalGivenPoints'
const STATISTIC_POINTS_NOT_USED = 'pointsNotUsed'
const LANGUAGES = 'languages'
const TRANSLATIONS_LOADING = 'translationsLoading'
const TRANSLATIONS = 'translations'
const STATISTIC_TOTAL_GIVEN_POINTS_BY_NEWSPAPER = 'totalGivenPointsByNewspaper'
const STATISTIC_POINTS_NOT_USED_BY_NEWSPAPER = 'pointsNotUsedByNewspaper'
const EDITORS_BY_NEWSPAPER = 'editorsByNewspaper'
const REWARDS_BY_NEWSPAPER = 'rewardsByNewspaper'
const USERS_BY_NEWSPAPER = 'usersByNewspaper'

export const RDB_COLLECTIONS = {
  EMAIL_TO_EXISTENCE,
  STATISTICS,
  STATISTICS_NEWS_TIPS_BY_NEWSPAPER,
  STATISTIC_TOTAL_GIVEN_POINTS,
  STATISTIC_POINTS_NOT_USED,
  LANGUAGES,
  TRANSLATIONS_LOADING,
  TRANSLATIONS,
  STATISTIC_TOTAL_GIVEN_POINTS_BY_NEWSPAPER,
  STATISTIC_POINTS_NOT_USED_BY_NEWSPAPER,
  EDITORS_BY_NEWSPAPER,
  REWARDS_BY_NEWSPAPER,
  USERS_BY_NEWSPAPER
}

export default RDB_COLLECTIONS
