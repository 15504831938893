import { Checkbox } from 'antd'
import PropTypes from 'prop-types'
import { STATUSES } from '__constants__'
import { Text } from '@qonsoll/react-design'
import { useSubfilterActions } from 'hooks'
import { useTranslations } from 'contexts/Translation'

const RewardStatusFilter = ({ filterData, setFilterData }) => {
  const { t } = useTranslations()

  const { onToggleDetails, onChange, checkIsEnabled } = useSubfilterActions({
    filterData,
    setFilterData,
    fieldName: 'status',
    operand: '==',
    type: 'rewardStatus'
  })

  const rewardStatuses = Object.values(STATUSES.REWARD_STATUSES)?.map(
    (rewardStatus) => ({ value: rewardStatus, name: rewardStatus })
  )

  return (
    <details
      open={
        localStorage.getItem(`rewardStatus-details-visibility`) === 'opened'
      }>
      <summary onClick={onToggleDetails}>{t('Reward status')}</summary>
      {rewardStatuses?.map((rewardStatus, index) => (
        <Checkbox
          key={`status-${index}`}
          checked={checkIsEnabled(rewardStatus)}
          onChange={() => onChange(rewardStatus)}>
          <Text wordBreak="break-all">
            {rewardStatus?.name || t('Unnamed filter')}
          </Text>
        </Checkbox>
      ))}
    </details>
  )
}

RewardStatusFilter.propTypes = {
  filterData: PropTypes.object,
  setFilterData: PropTypes.func
}

export default RewardStatusFilter
