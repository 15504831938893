import { COLLECTIONS, STATUSES } from '__constants__'
import { collection, limit, orderBy, query, where } from 'firebase/firestore'

import firestore from 'services/firebase/firestore'
import { useCollectionData } from 'react-firebase-hooks/firestore'
import { useGetSubadminData } from 'hooks'
import { useMemo } from 'react'

const useGetLimitUsers = (count = 5) => {
  const { isSubadmin, newspaperId } = useGetSubadminData()

  const statuses = Object?.values(STATUSES.USER_STATUSES)

  const queryConfig = useMemo(
    () => [
      where('status', 'in', statuses),
      isSubadmin && where('newspaperAssociated', '==', newspaperId || null),
      orderBy('_createdAt', 'desc'),
      limit(count)
    ],
    [isSubadmin, count, newspaperId]
  )

  return useCollectionData(
    query(
      collection(firestore, COLLECTIONS.USERS),
      ...queryConfig.filter(Boolean)
    )
  )
}

export default useGetLimitUsers
