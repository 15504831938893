const PATHS = {
  CONFIG: {
    DEFAULT: '/auth',
    AFTER_LOGIN: '/dashboard',
    AFTER_LOGOUT: '/auth',
    AFTER_SIGNUP: '/dashboard'
  },
  UNAUTHENTICATED: {
    LOGIN: '/auth',
    LOGIN_WITH_EMAIL: '/auth/login-with-email',
    SIGNUP: '/auth/signup',
    SIGNUP_WITH_EMAIL: '/auth/sign-up-with-email',
    FORGOT_PASSWORD: '/auth/forgot-password',
    CONFIRM_EMAIL: '/auth/confirm-email'
  },
  SERVICE: {
    ACCESS_DENIED: '/service/access-denied',
    GDPR: '/service/gdpr',
    NOT_FOUND: '/service/404',
    TERMS_AND_CONDITIONS: '/service/terms-and-conditions'
  },
  AUTHENTICATED: {
    RESET_PASSWORD: '/resetPassword',
    DASHBOARD: '/dashboard',
    CONTENT: '/content',
    TRANSLATIONS: '/translations',
    STATISTICS: '/statistics',

    USERS_ALL: '/users',
    USER_SHOW: '/users/:userId',
    USER_EDIT: '/users/:userId/edit',

    NEWSPAPER_SHOW: '/newspapers/:newspaperId',
    NEWSPAPER_EDIT: '/newspapers/:newspaperId/edit',

    NEWSPAPER_ADVANCED_SETTING_EDIT:
      '/newspapers/:newspaperId/newspapersAdvancedSetting/edit',

    WEB_TIPPS_CONFIGURATIONS_ALL: '/web-tipps-configurations',
    WEB_TIPPS_CONFIGURATION_EDIT:
      '/web-tipps-configurations/:webTippsConfigurationId/edit',

    NEWSPAPERS_ALL: '/newspapers',
    NEWSPAPER_CREATE: '/newspaper/create',

    EDITOR_SHOW: '/editors/:editorId',
    EDITOR_EDIT: '/editors/:editorId/edit',
    EDITORS_ALL: '/editors',
    EDITOR_CREATE: '/editor/create',

    NEWS_TIP_ALL: '/news-tips',
    NEWS_TIP_SHOW: '/news-tips/:newsTipId',
    NEWS_TIP_EDIT: '/news-tips/:newsTipId/edit',

    STORES_ALL: '/stores',
    STORE_SHOW: '/stores/:storeId',
    STORE_CREATE: '/store/create',
    STORE_EDIT: '/stores/:storeId/edit',

    REWARDS_ALL: '/rewards',
    REWARD_SHOW: '/rewards/:rewardId',
    REWARD_CREATE: '/reward/create',
    REWARD_EDIT: '/rewards/:rewardId/edit',

    REWARD_OVERVIEW_ALL: '/reward-overview',

    NOTIFICATIONS_ALL: '/notifications',
    NOTIFICATION_CREATE: '/notification/create',

    SUBADMINS_ALL: '/subadmins',
    SUBADMIN_CREATE: '/subadmin/create',

    ADMINS_ALL: '/superadmins',
    ADMIN_CREATE: '/admin/create',

    PROFILE_SHOW: '/profile-show',
    PROFILE_EDIT: '/profile-edit',

    EMAILS_ALL: '/emails',
    EMAIL_EDIT: '/emails/:emailId/edit',
    EMAIL_CREATE: '/emails/create',

    COUNTRIES_ALL: '/countries',
    COUNTRY_EDIT: '/countries/:countryId/edit',

    REJECT_REASONS_ALL: '/reject-reasons',
    REJECT_REASON_CREATE: '/reject-reason/create',
    REJECT_REASON_EDIT: '/reject-reasons/:rejectReasonId/edit',
    REJECT_REASON_SHOW: '/reject-reasons/:rejectReasonId'
  }
}

export default PATHS
