import { message } from 'antd'
import { Text } from '@qonsoll/react-design'
import { useTranslations } from 'contexts/Translation'
import PropTypes from 'prop-types'

const SettingsTableColumnRender = (props) => {
  const { value } = props

  const { t } = useTranslations()

  const handleCopyValue = (value) => {
    navigator?.clipboard?.writeText?.(value)
    message.success(t('Copied successfully'))
  }

  return (
    <Text cursor="pointer" onClick={() => handleCopyValue(value)}>
      {value}
    </Text>
  )
}

SettingsTableColumnRender.propTypes = {
  value: PropTypes.string
}

export default SettingsTableColumnRender
