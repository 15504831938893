import { createDocument, getId, updateDocument } from 'services/api/firebase'

import { COLLECTIONS } from '__constants__'
import { Newspaper } from 'models'
import { message } from 'antd'
import { serverTimestamp } from 'firebase/firestore'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const useActionsNewspaperAdvancedForm = ({ initialData, form } = {}) => {
  /* State for loading */
  const [loading, setLoading] = useState(false)

  /* Getting translations instance */
  const { t } = useTranslations()

  /* Getting history instance */
  const history = useHistory()

  /* Function for preparing values for saving */
  const prepareValues = async (values = {}, additionalValues = {}) => {
    /* Getting id */
    const newspaperId =
      initialData?.['newspaper']?._id || getId(COLLECTIONS.NEWSPAPERS)

    /* Preparing newspaper values */

    const preparedValues = {
      _id: newspaperId,
      ...additionalValues,
      arePointsExpiring: values?.expirationPoints?.arePointsExpiring ?? null,
      daysToExpiration: values?.expirationPoints?.daysToExpiration ?? null,
      notificationRanges: values?.expirationPoints?.notificationRanges ?? null,
      expirationMessageTitle:
        values?.expirationPoints?.expirationMessageTitle ?? null,
      expirationMessage: values?.expirationPoints?.expirationMessage ?? null
    }

    const isChangedArePointsExpiring =
      values?.expirationPoints?.arePointsExpiring !==
      initialData?.newspaper?.arePointsExpiring

    if (isChangedArePointsExpiring) {
      const arePointsExpiring = values?.expirationPoints?.arePointsExpiring
      preparedValues._pointsExpirationEnabledAt = arePointsExpiring
        ? serverTimestamp()
        : null
    }

    return preparedValues
  }

  /* Saving form data */
  const saveForm = async (values, callback) => {
    try {
      // Prepare data to be saved
      const data = await prepareValues(values)

      // Save data
      if (initialData) {
        await updateDocument(
          COLLECTIONS.NEWSPAPERS,
          initialData.newspaper?._id,
          data
        )

        message.success(t('Newspaper advanced setting successfully updated'))
      } else {
        await createDocument(COLLECTIONS.NEWSPAPERS, data, data._id)
        message.success(t('Newspaper advanced setting successfully created'))
      }
      // Final callback
      callback?.()
    } catch (error) {
      throw new Error(t('Something went wrong during data save'))
    }
  }

  /* Function for validating form */
  const validateForm = (values) => {
    try {
      // Prepare data to be validated
      const validationData = {
        daysToExpiration: values?.expirationPoints?.daysToExpiration,
        notificationRanges: values?.expirationPoints?.notificationRanges,
        arePointsExpiring: values?.expirationPoints?.arePointsExpiring
      }
      Newspaper.validationSchema.validateSync(validationData)
    } catch (error) {
      throw new Error(t('Newspaper validation error: ') + error.message)
    }
  }

  /* On finish callback */
  const onFinish = async () => {
    if (loading) return // Avoid multiple calls

    try {
      setLoading(true)
      // Get form values
      const formValues = form.getFieldsValue()
      // Validate fields
      validateForm(formValues)
      // Final callback
      const callback = () => history.goBack()

      // Save data
      await saveForm(formValues, callback)
    } catch (error) {
      message.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  /* On cancel callback */
  const onReset = () => {
    form.resetFields()
    history.goBack()
  }

  return { onFinish, onReset, loading, saveForm, validateForm, prepareValues }
}

export default useActionsNewspaperAdvancedForm
