import { cloneElement, useMemo } from 'react'
import { WebTippsConfigurationDomainNameLink } from 'domains/WebTippsConfiguration/components'
import { TableActions } from 'components'
import { Text } from '@qonsoll/react-design'
import { useTranslations } from '@qonsoll/translation'
import { useWebTippsConfigurationBaseActions } from 'domains/WebTippsConfiguration/hooks'
import { NewspaperLink } from 'domains/Newspaper/components'
import { sortByAlphabet } from 'helpers'
import { useGetColumnSearchProps } from 'hooks'

const WebTippsConfigurationTableActions = ({
  _id: webTippsConfigurationId,
  isWebTippsEnabled
}) => {
  const actions = useWebTippsConfigurationBaseActions({
    webTippsConfigurationId,
    isWebTippsEnabled
  })

  return cloneElement(<TableActions />, {
    actions,
    isBlocked: !isWebTippsEnabled,
    name: 'web tipps'
  })
}

const useWebTippsConfigurationTableColumns = () => {
  const { t } = useTranslations()
  const getColumnSearchProps = useGetColumnSearchProps()

  return useMemo(
    () => [
      {
        title: t('Sno'),
        width: 'var(--table-number-column-width)',
        dataIndex: 'sno',
        key: 'sno',
        align: 'center'
      },
      {
        title: t('Domain name'),
        dataIndex: 'name',
        width: 'var(--table-name-column-width)',
        key: 'name',
        align: 'center',
        render: (_, record) => (
          <WebTippsConfigurationDomainNameLink
            text={record?.domainName}
            webTippsConfigurationId={record?._id}
          />
        )
      },
      {
        title: t('Newspaper'),
        dataIndex: 'newspaper',
        key: 'newspaper',
        align: 'center',
        sorter: (a, b) =>
          sortByAlphabet(a?.newspaper?.name, b?.newspaper?.name),
        ...getColumnSearchProps(['newspaper', 'name']),
        render: (value, record) => (
          <NewspaperLink newspaperId={record?.newspaperId} text={value?.name} />
        )
      },
      {
        title: t('Status'),
        width: 'var(--table-status-column-width)',
        dataIndex: 'isWebTippsEnabled',
        key: 'isWebTippsEnabled',
        align: 'center',
        render: (isWebTippsEnabled) => (
          <Text>{isWebTippsEnabled ? t('Enabled') : t('Disabled')}</Text>
        )
      },
      {
        title: t('Actions'),
        dataIndex: 'actions',
        width: 'var(--table-actions-column-width)',
        key: 'actions',
        align: 'center',
        render: (_, record) => <WebTippsConfigurationTableActions {...record} />
      }
    ],
    [t]
  )
}

export default useWebTippsConfigurationTableColumns
