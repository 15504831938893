import { Col, Container, Row, Text, Title } from '@qonsoll/react-design'

import { Card } from 'antd'
import PropTypes from 'prop-types'
import { ReasonCard } from './RejectReasonAdvancedView.styles'
import { useTranslations } from 'contexts/Translation'

const RejectReasonAdvancedView = (props) => {
  const { rejectReason, newspaper } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  return (
    <Container>
      <Row mx="var(--ql-grid-gutter-negative)">
        <Col cw={4}>
          <Card>
            <Row noGutters mb={2}>
              <Col cw={12}>
                <Title level={5}>{rejectReason?.title}</Title>
              </Col>
            </Row>

            <Row noGutters v="center" mb={2}>
              <Col h="left">
                <Text variant="overline" type="secondary">
                  {t('Status')}:
                </Text>
              </Col>
              <Col h="right">
                <Text>{t(rejectReason?.status)}</Text>
              </Col>
            </Row>
            <Row noGutters v="center" mb={2}>
              <Col h="left">
                <Text variant="overline" type="secondary">
                  {t('Newspaper associated')}:
                </Text>
              </Col>
              <Col h="right">
                <Text>{newspaper?.name}</Text>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col cw={8}>
          <ReasonCard>
            <Row noGutters mb={2}>
              <Col cw={12} mb={2}>
                <Text variant="overline" type="secondary">
                  {t('Reason')}
                </Text>
              </Col>
              <Col cw={12}>
                <Text>{rejectReason?.title}</Text>
              </Col>
            </Row>
            <Row noGutters>
              <Col cw={12} mb={2}>
                <Text variant="overline" type="secondary">
                  {t('Description')}
                </Text>
              </Col>
              <Col cw={12}>
                <Text>{rejectReason?.text}</Text>
              </Col>
            </Row>
          </ReasonCard>
        </Col>
      </Row>
    </Container>
  )
}

RejectReasonAdvancedView.propTypes = {
  rejectReason: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    newspaperId: PropTypes.string,
    status: PropTypes.bool
  }),
  newspaper: PropTypes.shape({
    name: PropTypes.string
  })
}

export default RejectReasonAdvancedView
