import { COLLECTIONS } from '__constants__'
import { doc } from 'firebase/firestore'
import { firestore } from 'services/firebase'
import { getFormattedDateAndTime } from 'helpers'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { useLoading } from 'hooks'
import { useMemo } from 'react'

const useGetNewspaperInitialValues = (id) => {
  /* Fetching data using useDocument hook */
  const [newspaper, newspaperLoading] = useDocumentData(
    id && doc(firestore, COLLECTIONS.NEWSPAPERS, id)
  )

  /* Get web tipps configurations */
  const [webTippsConfiguration, webTippsConfigurationLoading] = useDocumentData(
    newspaper?.webTippsConfigurationId &&
      doc(
        firestore,
        COLLECTIONS.WEB_TIPPS_CONFIGURATIONS,
        newspaper?.webTippsConfigurationId
      )
  )

  /* Get formatted date and time */
  const dateAndTime =
    !newspaperLoading && getFormattedDateAndTime(newspaper?._createdAt?.seconds)

  /* Merge all fetched data */
  const initialValues = useMemo(
    () => ({
      newspaper: {
        ...newspaper,
        countries: Object.keys(newspaper?.countries ?? {}),
        dateAndTime
      },
      webTippsConfiguration
    }),
    [newspaper, dateAndTime, webTippsConfiguration]
  )

  /* Merge all loading states */
  const loadings = useMemo(
    () =>
      id ? [!newspaper, newspaperLoading, webTippsConfigurationLoading] : [],
    [id, newspaper, newspaperLoading, webTippsConfigurationLoading]
  )

  /* Getting loading state using merged loadings */
  const loading = useLoading(loadings)

  return [initialValues, loading]
}

export default useGetNewspaperInitialValues
