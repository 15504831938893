import {
  NewspaperFilter,
  RewardStatusFilter,
  RewardTypeFilter,
  StoreFilter
} from './components'

import PropTypes from 'prop-types'
import { useGetSubadminData } from 'hooks'

const RewardFilter = (props) => {
  const { filterVisibility, filterData, setFilterData } = props

  const { isSubadmin } = useGetSubadminData()

  return filterVisibility ? (
    <>
      {!isSubadmin && (
        <NewspaperFilter
          filterData={filterData}
          setFilterData={setFilterData}
        />
      )}

      <RewardTypeFilter filterData={filterData} setFilterData={setFilterData} />

      <StoreFilter filterData={filterData} setFilterData={setFilterData} />

      <RewardStatusFilter
        filterData={filterData}
        setFilterData={setFilterData}
      />
    </>
  ) : null
}

RewardFilter.propTypes = {
  filterVisibility: PropTypes.bool,
  setFilterVisibility: PropTypes.func,
  filterData: PropTypes.object,
  setFilterData: PropTypes.func
}

export default RewardFilter
