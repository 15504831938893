// Function to check if brackets are balanced
const checkBrackets = (str) => {
  if (!str) return true

  const stack = []
  for (let i = 0; i < str.length; i++) {
    if (str[i] === '{') {
      stack.push(str[i])
    } else if (str[i] === '}') {
      if (stack.length === 0 || stack[stack.length - 1] !== '{') {
        return false
      }
      stack.pop()
    }
  }
  return stack.length === 0
}

export default checkBrackets
