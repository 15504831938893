import { Box, Col, Row } from '@qonsoll/react-design'
import { NewsTipFilter, NewsTipTable } from 'domains/NewsTip/components'
import { PageActions, PageWrapperActionsUnderTitle, Spinner } from 'components'
import { useFilter, useGetCustomDataSource } from 'hooks'
import { useMemo, useState } from 'react'

import { useGetNewsTips } from 'domains/NewsTip/hooks'
import { useParams } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'

const NewspaperNewsTips = (props) => {
  const { newspaperId } = useParams()

  const [filteredNewsTipsCollection, setFilteredNewsTipsCollection] = useState()

  const { t } = useTranslations()
  /* Getting filter params */
  const filterParams = useFilter('newspaperNewsTips')

  /* Destructuring filter params */
  const { filterVisibility, filterData, setFilterVisibility } = filterParams

  const ref = useMemo(
    () => ({
      where: [['newspaperId', '==', newspaperId], ...(filterData?.where || [])]
    }),
    [newspaperId, filterData]
  )

  /* Getting relationship data */
  const [tipsByNewspaper, tipsByNewspaperLoading] = useGetNewsTips(ref)

  const [customNewsTips, customNewsTipsLoading] = useGetCustomDataSource(
    tipsByNewspaper,
    {
      fetchUserData: true
    }
  )

  /* Configuring page actions */
  const actions = {
    search: true,
    filter: true
  }

  return (
    <PageWrapperActionsUnderTitle
      action={
        <PageActions
          {...filterParams}
          actions={actions}
          circle
          searchPlaceholder="Search by Title"
          searchField="title"
          collection={customNewsTips}
          setCollection={setFilteredNewsTipsCollection}
          filterMenu={<NewsTipFilter {...filterParams} hiddenNewspaperFilter />}
          filterVisibility={filterVisibility}
          setFilterVisibility={setFilterVisibility}
        />
      }>
      <Row noGutters>
        <Col cw={12}>
          {tipsByNewspaperLoading || customNewsTipsLoading ? (
            <Box height="30vh">
              <Spinner flex={1} text={t('News tips loading')} />
            </Box>
          ) : (
            <NewsTipTable
              currentNewspaperTipsOnly
              dataSource={filteredNewsTipsCollection ?? tipsByNewspaper}
            />
          )}
        </Col>
      </Row>
    </PageWrapperActionsUnderTitle>
  )
}

export default NewspaperNewsTips
