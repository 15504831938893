import { Col, Row } from '@qonsoll/react-design'
import { HeaderBreadcrumbs, Spinner } from 'components'
import { PageActions, PageWrapperActionsUnderTitle } from 'components'
import {
  StoreFilter,
  StoreSortMenu,
  StoreTable
} from 'domains/Store/components'
import { useFilter, useGetCustomDataSource } from 'hooks'
import { useGetStores, useStoreBaseActions } from 'domains/Store/hooks'
import { useMemo, useState } from 'react'

import { COLLECTIONS } from '__constants__'
import PATHS from 'pages/paths'
import PropTypes from 'prop-types'
import { useTranslations } from 'contexts/Translation'

const StoresAllPage = (props) => {
  const { withHeader = true } = props

  const [collection, setCollection] = useState(null)
  /* Getting translations instance */
  const { t } = useTranslations()

  /* Getting filter params */
  const filterParams = useFilter('store')

  /* Destructuring filter params */
  const { filterVisibility, filterData, setFilterData, setFilterVisibility } =
    filterParams

  /* Building filter query */
  const ref = useMemo(() => filterData, [filterData])

  /* Fetching stores */
  const [stores, loading] = useGetStores(ref)
  const [customDataSource, customDataSourceLoading] = useGetCustomDataSource(
    stores,
    {
      status: COLLECTIONS.STORE_STATUSES
    }
  )

  /* Getting base actions */
  const listActions = useStoreBaseActions()

  /* Configuring page header */
  const headingProps = {
    title: t('Stores'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  /* Configuring page actions */
  const actions = {
    filter: true,
    create: Boolean(
      PATHS.AUTHENTICATED.STORE_CREATE || PATHS.AUTHENTICATED.STORE_WIZARD
    ),
    search: true
  }

  /* Extracting base actions */
  const { onBack, onCreate } = listActions

  return (
    <PageWrapperActionsUnderTitle
      action={
        withHeader ? (
          <PageActions
            {...filterParams}
            onCreate={onCreate}
            actions={actions}
            collection={customDataSource}
            setCollection={setCollection}
            searchField="name"
            searchPlaceholder="Search by Store Name"
            circle
            sortMenu={
              <StoreSortMenu
                filteredData={filterData}
                setFilteredData={setFilterData}
              />
            }
            filterMenu={<StoreFilter {...filterParams} />}
            filterVisibility={filterVisibility}
            setFilterVisibility={setFilterVisibility}
          />
        ) : null
      }
      onBack={withHeader ? onBack : null}
      breadcrumbs={withHeader ? <HeaderBreadcrumbs /> : null}
      headingProps={withHeader ? headingProps : null}>
      <Row noGutters>
        {loading || customDataSourceLoading ? (
          <Col>
            <Spinner height="60vh" text={t('Store loading')} />
          </Col>
        ) : (
          <Col cw={12}>
            <StoreTable dataSource={collection ?? customDataSource} />
          </Col>
        )}
      </Row>
    </PageWrapperActionsUnderTitle>
  )
}

StoresAllPage.propTypes = {
  isListWithCreate: PropTypes.bool,
  withHeader: PropTypes.bool
}

export default StoresAllPage
