import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { EditorState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import { Box } from '@qonsoll/react-design'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useTranslations } from '@qonsoll/translation'
import { EDITOR_STYLES, TOOLBAR_STYLES } from './ContentTextEditor.styles'
import {
  HISTORY_OPTIONS_CONFIG,
  INLINE_OPTIONS_CONFIG,
  TEXT_ALIGN_OPTIONS_CONFIG,
  LINK_OPTIONS_CONFIG
} from 'domains/Content/constants'
import {
  TextEditInlineBlock,
  TextEditTextAlignBlock,
  TextEditHistoryBlock,
  TextEditBlockTypeSelect,
  TextEditLinkBlock
} from 'domains/Content/components'

const ContentTextEditor = (props) => {
  const { wrapperProps, onChange, initState, contentType } = props

  const { t } = useTranslations()
  const [editorState, setEditorState] = useState(
    EditorState.createWithText(
      contentType
        ? `Default text for ${contentType.split('_').join(' ').toLowerCase()}`
        : ''
    )
  )

  useEffect(() => {
    onChange?.(editorState)
    // eslint-disable-next-line
  }, [editorState])

  useEffect(() => {
    initState && setEditorState(initState)
  }, [initState])

  return (
    <Box width="100%" height="inherit" flex={1} {...wrapperProps}>
      <Editor
        placeholder={t('Enter your text here')}
        editorState={editorState}
        onEditorStateChange={setEditorState}
        toolbarStyle={TOOLBAR_STYLES}
        editorStyle={EDITOR_STYLES}
        toolbar={{
          options: ['inline', 'blockType', 'textAlign', 'history', 'link'],
          inline: {
            component: TextEditInlineBlock,
            options: Object.keys(INLINE_OPTIONS_CONFIG)
          },
          blockType: {
            component: TextEditBlockTypeSelect
          },
          textAlign: {
            component: TextEditTextAlignBlock,
            options: Object.keys(TEXT_ALIGN_OPTIONS_CONFIG)
          },
          history: {
            component: TextEditHistoryBlock,
            options: Object.keys(HISTORY_OPTIONS_CONFIG)
          },
          link: {
            component: TextEditLinkBlock,
            options: Object.keys(LINK_OPTIONS_CONFIG)
          }
        }}
      />
    </Box>
  )
}

ContentTextEditor.propTypes = {
  wrapperProps: PropTypes.object,
  onChange: PropTypes.func,
  initState: PropTypes.object,
  contentType: PropTypes.string
}

export default ContentTextEditor
