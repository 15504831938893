import mockedImage1 from 'assets/MockedTipsPreview-1.jpeg'
import mockedImage2 from 'assets/MockedTipsPreview-2.jpeg'
const MOCKED_TIPS = [
  {
    content: 'content',
    photoUrls: [mockedImage1],
    status: 'Approved',
    tags: ['Tag1', 'Tag2', 'Tag3'],
    title: 'New Year Eve sales in the city',
    _createdAt: { seconds: 1677000256, nanoseconds: 766000000 }
  },
  {
    content: 'content',
    photoUrls: [mockedImage2],
    status: 'Approved',
    tags: ['Tag1', 'Tag2', 'Tag3'],
    title: 'Test location',
    _createdAt: { seconds: 1677000256, nanoseconds: 766000000 }
  },
  {
    content: 'content',
    photoUrls: [mockedImage2],
    status: 'Approved',
    tags: ['Tag1', 'Tag2', 'Tag3'],
    title: 'Test location',
    _createdAt: { seconds: 1677000256, nanoseconds: 766000000 }
  },
  {
    content: 'content',
    photoUrls: [mockedImage1],
    status: 'Approved',
    tags: ['Tag1', 'Tag2', 'Tag3'],
    title: 'What about us ?',
    _createdAt: { seconds: 1677000256, nanoseconds: 766000000 }
  },
  {
    content: 'content',
    photoUrls: [mockedImage1],
    status: 'Approved',
    tags: ['Tag1', 'Tag2', 'Tag3'],
    title: 'News preview',
    _createdAt: { seconds: 1677000256, nanoseconds: 766000000 }
  }
]
export { MOCKED_TIPS }
