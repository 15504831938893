import { capitalize } from 'helpers'

const useTransformName = () => {
  const transformName = (name) => {
    const splittedName = name?.split('_') || ''
    const indexLastElement = splittedName.length - 1

    if (splittedName.length > 2) {
      const variant = splittedName?.[indexLastElement]

      return variant === 'ALL'
        ? capitalize(splittedName?.slice(0, indexLastElement).join(' '))
        : capitalize(variant || '')
    } else {
      const [domain, variant] = splittedName || []

      // Use a first element from splittedName as name if there is ALL ot there is no variant
      return variant === 'ALL' || !variant
        ? capitalize(domain || '')
        : capitalize(variant || '')
    }
  }

  return transformName
}

export default useTransformName
