import { useTranslations } from 'contexts/Translation'
import { HeaderBreadcrumbs, Spinner } from 'components'
import { PageWrapper, Row, Col } from '@qonsoll/react-design'
import { useParams } from 'react-router-dom'
import { EditorAdvancedForm } from 'domains/Editor/components'
import { useGetEditorInitialValues } from 'domains/Editor/hooks'
import { useEditorBaseActions } from 'domains/Editor/hooks'

const EditorEditPage = (props) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting params from router */
  const { editorId } = useParams()

  /* Fetching editor initial values */
  const [initialValues, loading] = useGetEditorInitialValues(editorId)
  /* Getting base actions for page */
  const { onBack } = useEditorBaseActions()

  /* Configuring page header */
  const headingProps = {
    title: t('Editor edit'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  return (
    <PageWrapper
      onBack={onBack}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}>
      <Row noGutters>
        {loading ? (
          <Col>
            <Spinner height="70vh" text={t('Editor loading')} />
          </Col>
        ) : (
          <Col cw={[12, 12, 10, 8, 7]}>
            <EditorAdvancedForm initialData={initialValues} isEdit />
          </Col>
        )}
      </Row>
    </PageWrapper>
  )
}

export default EditorEditPage
