import * as yup from 'yup'

import { attr, model } from 'services/model'

const validationSchema = yup.object().shape({
  name: yup.string().default(null),
  text: yup.object().shape({
    EN: yup.string().default(null),
    NO: yup.string().default(null)
  }),
  receiversType: yup.string().default(null).nullable(),
  receiversNewspaper: yup.object().default(null).nullable()
})

const Notification = model(
  'notification',
  {
    name: attr('string'),
    text: {
      EN: attr('string'),
      NO: attr('string')
    },
    receiversType: attr('string'),
    receiversNewspaper: attr('string')
  },
  validationSchema
)

export default Notification
