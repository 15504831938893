import { Box, Button, Input } from '@qonsoll/react-design'
import { Icon } from '@qonsoll/icons'
import { Form, Modal } from 'antd'
import { useTranslations } from 'contexts/Translation'
import { AddNewLanguageSelect } from 'domains/Translation/components'
import PropTypes from 'prop-types'
import { useHandleAddNewLanguage } from 'domains/Translation/hooks'
import { MAX_LENGTH_FIELD } from '__constants__'

const AddNewLanguageForm = (props) => {
  const { triggerNode } = props

  const { t } = useTranslations()

  const {
    handleOk,
    toggleVisibleModal,
    handleAddNewLanguage,
    loading,
    visibleModal,
    form
  } = useHandleAddNewLanguage()

  const iconProps = {
    mr: '8px',
    size: 20,
    name: 'Plus1Outlined',
    fill: 'currentColor'
  }

  return (
    <>
      {triggerNode ? (
        <Box onClick={toggleVisibleModal}>{triggerNode}</Box>
      ) : (
        <Button onClick={toggleVisibleModal} icon={<Icon {...iconProps} />}>
          {t('Add new language')}
        </Button>
      )}

      <Modal
        destroyOnClose
        width="50vw"
        title={t('Add new language')}
        centered
        closable={false}
        visible={visibleModal}
        okButtonProps={{ loading: loading }}
        onOk={handleOk}
        onCancel={toggleVisibleModal}>
        <Form
          {...props}
          layout="vertical"
          onFinish={handleAddNewLanguage}
          form={form}>
          <Form.Item
            label={t('New language')}
            name={['newLanguage']}
            rules={[
              {
                required: true,
                message: t('Please select new language!')
              }
            ]}>
            <AddNewLanguageSelect />
          </Form.Item>
          <Form.Item
            label={`${t(
              'Enter translate in new language for phrase'
            )}: Translations are loading `}
            name={['translateDefaultPhrase']}
            rules={[
              {
                required: true,
                message: t(
                  'Please enter your default translate text for loading!'
                )
              },
              { max: MAX_LENGTH_FIELD, message: t('translate is too long!') }
            ]}>
            <Input placeholder={t('Translations are loading')} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

AddNewLanguageForm.propTypes = {
  triggerNode: PropTypes.node
}

export default AddNewLanguageForm
