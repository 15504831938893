import styled from 'styled-components'
import { Card } from 'antd'

const DetailsCard = styled(Card)`
  height: 100%;

  .ant-card-body {
    height: 100%;
  }
`

export { DetailsCard }
