import { useGetCountry } from 'domains/Country/hooks'
import { useGetLanguages } from 'domains/Language/hooks'
import { useLoading } from 'hooks'
import { useMemo } from 'react'

const useGetCountryInitialValues = (id) => {
  /* Fetching data using useDocument hook */
  const [country, countryLoading] = useGetCountry(id)

  const [languages, languagesLoading] = useGetLanguages(
    country?.languagesOrdered
  )

  /* Merge all fetched data */
  const initialValues = useMemo(
    () => ({
      country,
      languages
    }),
    [country, languages, languagesLoading]
  )

  /* Merge all loading states */
  const loadings = useMemo(
    () => (id ? [!country, countryLoading, languagesLoading] : []),
    [id, country, countryLoading, languagesLoading]
  )

  /* Getting loading state using merged loadings */
  const loading = useLoading(loadings)

  return [initialValues, loading]
}

export default useGetCountryInitialValues
