import { useEffect, useState } from 'react'

import { Img } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import logoTips from 'assets/TipsLogo.png'
import { useTranslations } from 'contexts/Translation'

const Logo = ({ logoUrl, initialValues, additionalStyles }) => {
  const { t } = useTranslations()

  const [file, setFile] = useState(null)
  const getBase64 = (img, callback) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }
  useEffect(() => {
    logoUrl &&
      logoUrl instanceof File &&
      getBase64(logoUrl, (result) => setFile(result))
  }, [logoUrl])

  useEffect(() => {
    initialValues?.app?.logoUrl && setFile(initialValues?.app?.logoUrl)
  }, [initialValues])

  return (
    <Img
      src={file || logoTips}
      alt={t('Logo')}
      style={additionalStyles}
      height="40px"
      width="40px"
    />
  )
}
Logo.propTypes = {
  logoUrl: PropTypes.string,
  isPreview: PropTypes.bool,
  initialValues: PropTypes.object,
  additionalStyles: PropTypes.object
}
export default Logo
