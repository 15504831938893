import { NewspaperFilter, StatusFilter } from './components'

import PropTypes from 'prop-types'
import { useGetSubadminData } from 'hooks'

const RejectReasonFilter = (props) => {
  const { filterData, setFilterData } = props

  const { isSubadmin } = useGetSubadminData()

  return (
    <>
      <StatusFilter filterData={filterData} setFilterData={setFilterData} />
      {!isSubadmin && (
        <NewspaperFilter
          filterData={filterData}
          setFilterData={setFilterData}
        />
      )}
    </>
  )
}

RejectReasonFilter.propTypes = {
  filterData: PropTypes.object,
  setFilterData: PropTypes.func
}

export default RejectReasonFilter
