import styled from 'styled-components'

const StyledWrapper = styled.div`
  max-height: 300px;
  height: 100%;
  overflow-y: scroll;
  margin-left: -20px;
  padding-right: 4px;

  ::-webkit-scrollbar {
    width: hidden;
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    visibility: hidden;
  }
  ::-webkit-scrollbar-thumb {
    visibility: hidden;
  }
  ::-webkit-scrollbar-button {
    visibility: hidden;
  }
  &:hover {
    ::-webkit-scrollbar {
      visibility: visible;
      width: 4px;
      height: 4px;
    }
    ::-webkit-scrollbar-track {
      visibility: visible;
      background-color: transparent;
      border-radius: 100px;
    }
    ::-webkit-scrollbar-thumb {
      visibility: visible;
      border-radius: 100px;
    }
    ::-webkit-scrollbar-button {
      visibility: visible;
      background: transparent;
    }
  }
`

export { StyledWrapper }
