import firebase from 'firebase/compat/app'
import { RDB_COLLECTIONS } from '__constants__'

const useCreateTranslation = (appName) => {
  // [ADDITIONAL HOOKS]

  // [HELPER FUNCTIONS]
  const saveTranslations = ({ textLabel, shortCode, refEnding }) => {
    /* Creating a reference to the database. */
    const ref = `${RDB_COLLECTIONS.TRANSLATIONS}/${appName}/${shortCode}/${refEnding}`
    return firebase.database().ref(ref).set(textLabel)
  }

  const createAllTranslations = ({ languages, refEnding }) => {
    const promisesToCompute = languages?.map((lang) => {
      return saveTranslations?.({
        textLabel: lang.value,
        shortCode: lang?.shortCode,
        refEnding
      })
    })

    try {
      return Promise.all(promisesToCompute)
    } catch (error) {
      return error
    }
  }

  return { createAllTranslations }
}

export default useCreateTranslation
