import { Col, PageWrapper, Row } from '@qonsoll/react-design'
import { useHistory, useParams } from 'react-router-dom'

import { HeaderBreadcrumbs } from 'components'
import { PageActions } from 'components'
import { ProfileAdvancedView } from 'domains/Profile/components'
import { useProfileBaseActions } from 'domains/Profile/hooks'
import { useTranslations } from 'contexts/Translation'

const ProfileShow = (props) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  const { onEdit, onBack } = useProfileBaseActions()

  /* Configuring page header */
  const headingProps = {
    title: t('Profile details'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  /* Configuring page actions */
  const actions = { edit: true }

  return (
    <PageWrapper
      action={<PageActions actions={actions} onEdit={onEdit} />}
      onBack={onBack}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}>
      <Row noGutters>
        <Col cw={12}>
          <ProfileAdvancedView />
        </Col>
      </Row>
    </PageWrapper>
  )
}

export default ProfileShow
