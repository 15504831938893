import { useEffect, useMemo, useState } from 'react'
import { useEvent } from 'hooks'
import { message } from 'antd'
import { useTranslations } from '@qonsoll/translation'
import { ref, onValue } from 'firebase/database'
import { database } from 'services/firebase'
import { COLLECTIONS, RDB_COLLECTIONS, SUPER_ADMIN_ROLE } from '__constants__'
import { useUser } from 'domains/User/context'

const useGetDashboardStatistic = () => {
  const { t } = useTranslations()
  const user = useUser()
  const { role, newspaperId } = user

  const [statisticData, setStatisticData] = useState({})

  const isSuperAdmin = useMemo(() => role === SUPER_ADMIN_ROLE, [role])

  const getStatisticDataForSuperAdmin = useEvent(async () => {
    try {
      const usersRef = ref(
        database,
        [RDB_COLLECTIONS.STATISTICS, COLLECTIONS.USERS].join('/')
      )

      const editorsRef = ref(
        database,
        [RDB_COLLECTIONS.STATISTICS, COLLECTIONS.EDITORS].join('/')
      )

      const newsTipsRef = ref(
        database,
        [RDB_COLLECTIONS.STATISTICS, COLLECTIONS.NEWS_TIPS].join('/')
      )

      const rewardRef = ref(
        database,
        [RDB_COLLECTIONS.STATISTICS, COLLECTIONS.REWARDS].join('/')
      )

      const totalPointsGiven = ref(
        database,
        [
          RDB_COLLECTIONS.STATISTICS,
          RDB_COLLECTIONS.STATISTIC_TOTAL_GIVEN_POINTS
        ].join('/')
      )

      const totalPointsNotUsed = ref(
        database,
        [
          RDB_COLLECTIONS.STATISTICS,
          RDB_COLLECTIONS.STATISTIC_POINTS_NOT_USED
        ].join('/')
      )

      //Get total users value
      onValue(usersRef, (snapshot) => {
        snapshot.exists() &&
          setStatisticData((prev) => ({
            ...prev,
            totalUsers: snapshot.val()?.lastIndex || 0
          }))
      })

      //Get total editor value
      onValue(editorsRef, (snapshot) => {
        snapshot.exists() &&
          setStatisticData((prev) => ({
            ...prev,
            totalEditors: snapshot.val()?.lastIndex || 0
          }))
      })

      //Get total news tips value
      onValue(newsTipsRef, (snapshot) => {
        snapshot.exists() &&
          setStatisticData((prev) => ({
            ...prev,
            totalNewsTips: snapshot.val()?.lastIndex || 0
          }))
      })

      //Get total rewards value
      onValue(rewardRef, (snapshot) => {
        snapshot.exists() &&
          setStatisticData((prev) => ({
            ...prev,
            totalReward: snapshot.val()?.lastIndex || 0
          }))
      })

      //Get total points given value
      onValue(totalPointsGiven, (snapshot) => {
        snapshot.exists() &&
          setStatisticData((prev) => ({
            ...prev,
            totalPointsGiven: snapshot.val()?.points || 0
          }))
      })

      //Get total points not used value
      onValue(totalPointsNotUsed, (snapshot) => {
        snapshot.exists() &&
          setStatisticData((prev) => ({
            ...prev,
            totalPointsNotUsed: snapshot.val()?.points || 0
          }))
      })
    } catch (error) {
      message.error(t('Error while getting statistic data'))
    }
  })

  const getStatisticDataForLocalAdmin = useEvent(async () => {
    try {
      const usersRef = ref(
        database,
        [RDB_COLLECTIONS.STATISTICS, RDB_COLLECTIONS.USERS_BY_NEWSPAPER].join(
          '/'
        )
      )

      const editorsRef = ref(
        database,
        [RDB_COLLECTIONS.STATISTICS, RDB_COLLECTIONS.EDITORS_BY_NEWSPAPER].join(
          '/'
        )
      )

      const newsTipsRef = ref(
        database,
        [
          RDB_COLLECTIONS.STATISTICS,
          RDB_COLLECTIONS.STATISTICS_NEWS_TIPS_BY_NEWSPAPER
        ].join('/')
      )

      const rewardRef = ref(
        database,
        [RDB_COLLECTIONS.STATISTICS, RDB_COLLECTIONS.REWARDS_BY_NEWSPAPER].join(
          '/'
        )
      )

      const totalPointsGivenRef = ref(
        database,
        [
          RDB_COLLECTIONS.STATISTICS,
          RDB_COLLECTIONS.STATISTIC_TOTAL_GIVEN_POINTS_BY_NEWSPAPER
        ].join('/')
      )

      const totalPointsNotUsedRef = ref(
        database,
        [
          RDB_COLLECTIONS.STATISTICS,
          RDB_COLLECTIONS.STATISTIC_POINTS_NOT_USED_BY_NEWSPAPER
        ].join('/')
      )

      //Get total users value
      onValue(usersRef, (snapshot) => {
        snapshot.exists() &&
          setStatisticData((prev) => ({
            ...prev,
            totalUsers: snapshot.val()?.[newspaperId] || 0
          }))
      })

      //Get total editor value
      onValue(editorsRef, (snapshot) => {
        snapshot.exists() &&
          setStatisticData((prev) => ({
            ...prev,
            totalEditors: snapshot.val()?.[newspaperId] || 0
          }))
      })

      //Get total news tips value
      onValue(newsTipsRef, (snapshot) => {
        if (snapshot.exists()) {
          const newsTipsByNewspaper = snapshot.val()?.[newspaperId] || {}

          const totalNewsTips = Object.values(newsTipsByNewspaper).reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          )

          setStatisticData((prev) => ({
            ...prev,
            totalNewsTips: totalNewsTips
          }))
        }
      })

      //Get total rewards value
      onValue(rewardRef, (snapshot) => {
        snapshot.exists() &&
          setStatisticData((prev) => ({
            ...prev,
            totalReward: snapshot.val()?.[newspaperId] || 0
          }))
      })

      //Get total points given value
      onValue(totalPointsGivenRef, (snapshot) => {
        snapshot.exists() &&
          setStatisticData((prev) => ({
            ...prev,
            totalPointsGiven: snapshot.val()?.[newspaperId]?.points || 0
          }))
      })

      //Get total points not used value
      onValue(totalPointsNotUsedRef, (snapshot) => {
        snapshot.exists() &&
          setStatisticData((prev) => ({
            ...prev,
            totalPointsNotUsed: snapshot.val()?.[newspaperId]?.points || 0
          }))
      })
    } catch (error) {
      message.error(t('Error while getting statistic data'))
    }
  })

  useEffect(() => {
    let isMount = true

    if (isMount)
      isSuperAdmin
        ? getStatisticDataForSuperAdmin()
        : getStatisticDataForLocalAdmin()

    return () => {
      isMount = false
    }
  }, [
    getStatisticDataForSuperAdmin,
    getStatisticDataForLocalAdmin,
    isSuperAdmin
  ])

  return statisticData
}

export default useGetDashboardStatistic
