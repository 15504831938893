import { NewsTipLink, NewsTipNewsTipStatus } from 'domains/NewsTip/components'

import { FullNameLink } from 'domains/User/components'
import { MOMENT_FORMATS } from '__constants__'
import { NewspaperLink } from 'domains/Newspaper/components'
import { Text } from '@qonsoll/react-design'
import { actions } from 'react-table'
import moment from 'moment'
import { sortByAlphabet } from 'helpers'
import { useGetColumnSearchProps } from 'hooks'
import { useMemo } from 'react'
import { useTranslations } from '@qonsoll/translation'

const useNewspaperTableColumns = (currentNewspaperTipsOnly) => {
  const { t } = useTranslations()

  // Get column search props
  const getColumnSearchProps = useGetColumnSearchProps()

  return useMemo(() => {
    let finalArr = [
      {
        title: <Text fontWeight="600">{t('Sno')}</Text>,
        dataIndex: 'sno',
        width: 'var(--table-number-column-width)',
        key: 'sno',
        align: 'center',
        sorter: (a, b) => a?.sno - b?.sno,
        ...getColumnSearchProps('sno'),
        render: (_, record) => <Text>{record?.sno}</Text>
      },
      {
        title: <Text fontWeight="600">{t('Title')}</Text>,
        dataIndex: 'title',
        key: 'title',
        align: 'center',
        onClick: actions?.onOpen,
        sorter: (a, b) => sortByAlphabet(a?.title, b?.title),
        ...getColumnSearchProps('title'),
        render: (_, record) => (
          <NewsTipLink text={record?.title} newsTipId={record?._id} />
        )
      },
      {
        title: <Text fontWeight="600">{t('Posted by')}</Text>,
        dataIndex: 'fullName',
        key: 'fullName',
        align: 'center',
        // add sorting when name will be fetched from db
        sorter: (a, b) => sortByAlphabet(a?.user?.fullName, b?.user?.fullName),

        ...getColumnSearchProps(['user', 'fullName'], 'posted by'),
        render: (_, record) => {
          return (
            <FullNameLink
              text={record?.user?.fullName}
              userId={record?._createdBy}
              maxWidth="300px"
              ellipsis
            />
          )
        }
      },
      {
        title: <Text fontWeight="600">{t('Newspaper')}</Text>,
        dataIndex: 'newspaper',
        key: 'newspaper',
        align: 'center',
        onClick: actions?.onOpen,
        sorter: (a, b) => sortByAlphabet(a.newspaper?.name, b?.newspaper?.name),

        ...getColumnSearchProps(['newspaper', 'name'], 'Newspaper'),
        render: (_, record) => (
          <NewspaperLink
            maxWidth="300px"
            ellipsis
            newspaperId={record?.newspaperId}
            text={record?.newspaper?.name}
          />
        )
      },
      {
        title: <Text fontWeight="600">{t('Posted on')}</Text>,
        width: 'var(--table-date-column-width)',
        dataIndex: '_createdAt',
        key: '_createdAt',
        align: 'center',
        sorter: (a, b) => a?._createdAt?.seconds - b?._createdAt?.seconds,
        render: (date) => (
          <Text>
            {moment
              .unix(date?.seconds)
              .format(MOMENT_FORMATS.MONTH_DAYS_COMMA_YEAR)}
          </Text>
        )
      },
      {
        title: <Text fontWeight="600">{t('Points')}</Text>,
        width: 'var(--table-points-column-width)',
        dataIndex: 'points',
        key: 'points',
        align: 'center',
        sorter: (a, b) => a?.points - b?.points,
        render: (_, record) => <Text>{record?.points}</Text>
      },

      {
        title: <Text fontWeight="600">{t('Status')}</Text>,
        width: 'var(--table-status-column-width)',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        sorter: (a, b) => sortByAlphabet(a?.status, b?.status),
        render: (_, record) => (
          <NewsTipNewsTipStatus
            status={record?.status}
            isBlocked={record?.isBlocked}
          />
        )
      }
    ]
    if (currentNewspaperTipsOnly) {
      finalArr = finalArr.filter(
        ({ dataIndex }) => !['postedBy', 'newspaper'].includes(dataIndex)
      )
    } else {
      finalArr = finalArr.filter(
        ({ dataIndex }) => !['phoneNumber'].includes(dataIndex)
      )
    }
    return finalArr
  }, [t, getColumnSearchProps, currentNewspaperTipsOnly])
}
export default useNewspaperTableColumns
