import { Button, Title } from '@qonsoll/react-design'
import { Divider, Form, message } from 'antd'
import {
  useActionsCountryAdvancedForm,
  useInitializeCountryAdvancedForm
} from './hooks'

import { CountrySimpleForm } from 'domains/Country/components'
import { FormItemStyled } from './CountryAdvancedForm.styled'
import { KEYS } from '__constants__'
import { LanguageDraggableList } from 'domains/Language/components'
import PropTypes from 'prop-types'
import { SUPER_ADMIN_ROLE } from '__constants__'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

const CountryAdvancedForm = (props) => {
  const { initialData, showTitle } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting form instance from hook */
  const { form } = useInitializeCountryAdvancedForm(initialData)

  const { role, newspaperId } = useUser()

  const isSuperAdmin = role === SUPER_ADMIN_ROLE

  /* Handling enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      form.submit()
    }
  }

  /* Getting loading state and actions from hook */
  const { loading, onFinish, onReset } = useActionsCountryAdvancedForm({
    initialData,
    form,
    isSuperAdmin,
    newspaperId
  })

  const languagesValidation = () => ({
    validator: (_, value, callback) => {
      const valueArray = Object.values(value)
      const emptyFields = valueArray?.find((item) => !item?.label && !item.name)

      if (emptyFields) {
        message.error(t('Please complete the blank fields'))
        return callback(true)
      }

      return callback()
    }
  })

  const emptyLanguagesValidation = () => ({
    validator: (_, value, callback) => {
      const valueArray = Object.values(value)

      if (!valueArray?.length) {
        message.error(t('Please add language'))
        return callback(true)
      }

      return callback()
    }
  })

  return (
    <Form
      onKeyPress={handleKeyPress}
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
      validateTrigger="click"
      form={form}>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Country')}
        </Title>
      )}
      <CountrySimpleForm group={['country']} />
      <Divider>{t('Languages')}</Divider>

      <FormItemStyled
        name={['languages']}
        rules={[emptyLanguagesValidation(), languagesValidation()]}>
        <LanguageDraggableList form={form} />
      </FormItemStyled>

      <Button mr={2} htmlType="button" onClick={onReset} mb={4}>
        {t('Cancel')}
      </Button>
      <Button type="primary" loading={loading} onClick={() => form.submit()}>
        {t('Save')}
      </Button>
    </Form>
  )
}

CountryAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  group: PropTypes.array,
  showTitle: PropTypes.bool
}

export default CountryAdvancedForm
