import { Form, Input } from 'antd'

import { MAX_LENGTH_FIELD } from '__constants__'
import PropTypes from 'prop-types'
import { Title } from '@qonsoll/react-design'
import { useTranslations } from 'contexts/Translation'

const EmailSimpleForm = (props) => {
  const { showTitle, group } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  return (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Email')}
        </Title>
      )}
      <Form.Item
        label={t('Email')}
        name={[...group, 'email']}
        rules={[
          {
            required: true,
            message: t('Please enter email!')
          },
          {
            type: 'email',
            message: t('Email is not valid')
          },

          { max: MAX_LENGTH_FIELD, message: t('email is too long!') }
        ]}>
        <Input placeholder={t('Please enter email ')} />
      </Form.Item>
    </>
  )
}

EmailSimpleForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  showTitle: PropTypes.bool,
  form: PropTypes.any,
  group: PropTypes.array
}

export default EmailSimpleForm
