import { COLLECTIONS } from '__constants__'
import { useLoading } from 'hooks'
import { useMemo } from 'react'
import { useDocumentData } from 'react-firebase-hooks/firestore'
import { firestore } from 'services/firebase'
import { doc } from 'firebase/firestore'
import { DOMAIN_NAME_PREFIX } from 'domains/WebTippsConfiguration/__constants__'

const useGetWebTippsConfigurationInitialValues = (id) => {
  /* Fetching data using useDocument hook */
  const [webTippsConfiguration, webTippsConfigurationLoading] = useDocumentData(
    id && doc(firestore, COLLECTIONS.WEB_TIPPS_CONFIGURATIONS, id)
  )

  /* Merge all fetched data */
  const initialValues = useMemo(
    () => webTippsConfiguration,
    [webTippsConfiguration]
  )

  /* Merge all loading states */
  const loadings = useMemo(
    () => (id ? [!webTippsConfiguration, webTippsConfigurationLoading] : []),
    [id, webTippsConfiguration, webTippsConfigurationLoading]
  )

  /* Getting loading state using merged loadings */
  const loading = useLoading(loadings)

  const getDomainConfigurationsInitialValues = (dataAll) => ({
    domainName: initialValues?.domainName?.replace(DOMAIN_NAME_PREFIX, '')
  })

  return [initialValues, loading, getDomainConfigurationsInitialValues]
}

export default useGetWebTippsConfigurationInitialValues
