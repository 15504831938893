import * as yup from 'yup'

import { attr, hasMany, hasOne, model } from 'services/model'

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .typeError('Field name has wrong type')
    .default(null)
    .nullable(),
  lastName: yup
    .string()
    .typeError('Field name has wrong type')
    .default(null)
    .nullable(),
  email: yup
    .string()
    .typeError('Field email has wrong type')
    .default(null)
    .nullable(),
  phoneNumber: yup
    .string()
    .typeError('Field phoneNumber has wrong type')
    .default(null)
    .nullable(),
  avatarUrl: yup
    .string()
    .typeError('Field avatarUrl has wrong type')
    .default(null)
    .nullable()
})

const Profile = model(
  'user',
  {
    firstName: attr('string'),
    lastName: attr('string'),
    email: attr('string'),
    phoneNumber: attr('string'),
    avatarUrl: attr('string')
  },
  validationSchema
)

export default Profile
