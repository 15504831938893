import { COLLECTIONS, STATUSES } from '__constants__'

import { useCollection } from 'services/api/firebase'
import { useGetQuery } from 'hooks'

/**
 * It returns an array of values that are used to render the rewards
 * @param props - An object that contains the query parameters.
 * @returns An array of values.
 */
const useGetRewards = (props) => {
  /* Query for getting collection reference */
  const query = useGetQuery({
    collectionName: COLLECTIONS.REWARDS,
    where: props?.where,
    orderBy: props?.orderBy,
    statuses: STATUSES.REWARD_STATUSES
  })

  return useCollection(query)
}

export default useGetRewards
