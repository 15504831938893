import {
  Col,
  Container,
  Option,
  Row,
  Select,
  Title
} from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import { useGetNewspapers } from 'domains/Newspaper/hooks'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

// Takes a value of type object and onChange function
// Returns selected option object
const NewspaperSelect = (props) => {
  const {
    value,
    onChange,
    showTitle = true,
    allowClear = true,
    exclude = [],
    mode,
    disabled,
    ...rest
  } = props

  /* Getting all possibility variants for select */
  const ref = useMemo(
    () => ({
      orderBy: [['sno', 'asc']]
    }),
    []
  )
  const [allNewspapers = [], loading] = useGetNewspapers(ref)
  /* Getting translation instance */
  const { t } = useTranslations()

  /* Checking if mode is multiple */
  const isMultiple = useMemo(() => mode === 'multiple', [mode])
  /* Handling case when db record not added yet but value already exists and ready to be used */
  const valueComputed = useMemo(() => {
    if (isMultiple) return value
    return value?._id || null
  }, [value, isMultiple])
  /* Filtering out excluded values */
  const optionsComputed = useMemo(() => {
    const _options = allNewspapers
    return _options.filter((o) => !exclude.includes(o._id))
  }, [allNewspapers, exclude])

  /* Handling change selected option */
  const handleChange = (value) => {
    if (isMultiple) {
      const _options = optionsComputed.filter((o) => value.includes(o._id))
      onChange?.(_options || null, value)
    } else {
      const _option = optionsComputed.find(({ _id: id }) => id === value)
      onChange?.(_option || null, value)
    }
  }

  return (
    <Container {...rest}>
      {showTitle && (
        <Title mb={2} level={4}>
          {t('Newspaper')}
        </Title>
      )}
      <Row noGutters>
        <Col>
          <Select
            disabled={disabled}
            mode={mode}
            allowClear={allowClear}
            loading={loading}
            placeholder={t('Please, select newspaper')}
            value={valueComputed}
            onChange={handleChange}>
            {optionsComputed.map((newspaper) => (
              <Option key={newspaper?._id} value={newspaper?._id}>
                {newspaper?.name}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
    </Container>
  )
}

NewspaperSelect.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  showTitle: PropTypes.bool,
  allowClear: PropTypes.bool,
  exclude: PropTypes.array,
  mode: PropTypes.string,
  disabled: PropTypes.bool
}

export default NewspaperSelect
