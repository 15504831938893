const MAX_LENGTH_FIELD = 256
const MAX_LENGTH_DESCRIPTION = 10000
const MAX_LENGTH_NOTIFICATION_TITLE = 50
const MAX_LENGTH_NOTIFICATION_MESSAGE = 240
const MAX_LENGTH_BUTTON = 50

export {
  MAX_LENGTH_FIELD,
  MAX_LENGTH_DESCRIPTION,
  MAX_LENGTH_NOTIFICATION_TITLE,
  MAX_LENGTH_NOTIFICATION_MESSAGE,
  MAX_LENGTH_BUTTON
}
