import { StyledTag, TagListWrapper } from './TagList.styles'

import PropTypes from 'prop-types'
import { Text } from '@qonsoll/react-design'
import { useTranslations } from 'contexts/Translation'

const TagList = (props) => {
  const { tags } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  return tags?.length ? (
    <TagListWrapper>
      {tags?.map((tag) => (
        <StyledTag key={tag}>{tag}</StyledTag>
      ))}
    </TagListWrapper>
  ) : (
    <Text type="secondary">{t('There are no tags')}</Text>
  )
}

TagList.propTypes = {
  tags: PropTypes.array
}

export default TagList
