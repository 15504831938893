import { HeaderBreadcrumbs } from 'components'
import { PageWrapper } from '@qonsoll/react-design'
import { ResetPasswordForm } from 'domains/Session/components'
import { useHistory } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'
const ResetPassword = () => {
  const { t } = useTranslations()
  const history = useHistory()
  const handleBack = () => history.goBack()
  return (
    <PageWrapper
      alignMiddle
      height="100%"
      onBack={handleBack}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={{
        title: t('Reset password'),
        textAlign: 'center',
        marginBottom: 40
      }}
      contentWidth={['100%', '100%', 400]}>
      <ResetPasswordForm />
    </PageWrapper>
  )
}

export default ResetPassword
