import { createFullUserName } from 'helpers'
import { useMemo } from 'react'

const useTransformedDataSource = (dataSource) =>
  useMemo(
    () =>
      dataSource?.map(
        ({
          firstName,
          lastName,
          isBlocked = false,
          phoneNumber = 'N/A',
          ...rest
        }) => ({
          fullName: createFullUserName(firstName, lastName),
          isBlocked,
          phoneNumber,
          ...rest
        })
      ),
    [dataSource]
  )

export default useTransformedDataSource
