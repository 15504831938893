const styles = {
  img: {
    maxHeight: '500px',
    objectFit: 'cover',
    width: '100%',
    borderRadius: 'var(--card-radius)'
  }
}

export default styles
