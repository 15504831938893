import { NewspaperFilter, UserStatusFilter } from './components'

import PropTypes from 'prop-types'
import { useGetSubadminData } from 'hooks'

const UserFilter = (props) => {
  const { filterVisibility, filterData, setFilterData } = props

  const { isSubadmin } = useGetSubadminData()
  /* Closing filter */

  return filterVisibility ? (
    <>
      <UserStatusFilter filterData={filterData} setFilterData={setFilterData} />
      {!isSubadmin && (
        <NewspaperFilter
          filterData={filterData}
          setFilterData={setFilterData}
        />
      )}
    </>
  ) : null
}

UserFilter.propTypes = {
  filterVisibility: PropTypes.bool,
  setFilterVisibility: PropTypes.func,
  filterData: PropTypes.object,
  setFilterData: PropTypes.func
}

export default UserFilter
