import { useEffect, useState } from 'react'
import { RDB_COLLECTIONS } from '__constants__'
import { useEvent } from 'hooks'
import { message } from 'antd'
import { useTranslations } from '@qonsoll/translation'
import { getDatabase, ref, child, get } from 'firebase/database'

const useTransformNewspaperTableData = (dataSource, isSubadminRole) => {
  const { t } = useTranslations()

  /* Transformed data */
  const [transformedDataSource, setTransformedDataSource] = useState([])
  const [loading, setLoading] = useState(!transformedDataSource?.length)

  /* Pagination params for table */
  const [tableParams, setTableParams] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showQuickJumper: true,
    showSizeChanger: true
  })

  /* Handle changes for table */
  const handleTableChange = (pagination) =>
    !isSubadminRole && setTableParams(pagination)

  const getCountDocumentsByNewspaperIdFromRDB = async ({
    _id,
    collectionName
  }) => {
    try {
      const dbRef = ref(getDatabase())
      const snapshot = await get(
        child(dbRef, `${RDB_COLLECTIONS.STATISTICS}/${collectionName}/${_id}`)
      )

      if (snapshot.exists()) {
        if (
          collectionName === RDB_COLLECTIONS.STATISTICS_NEWS_TIPS_BY_NEWSPAPER
        ) {
          return Object.values(snapshot.val()).reduce(
            (previousValue, currentValue) => +previousValue + currentValue,
            0
          )
        }

        return snapshot.val()
      }

      return 0
    } catch (error) {
      return 0
    }
  }

  const fetchRelatedData = useEvent(async () => {
    try {
      const promises = dataSource?.map(async (data) => ({
        ...data,
        totalEditors: await getCountDocumentsByNewspaperIdFromRDB({
          _id: data?._id,
          collectionName: RDB_COLLECTIONS.EDITORS_BY_NEWSPAPER
        }),
        totalTips: await getCountDocumentsByNewspaperIdFromRDB({
          _id: data?._id,
          collectionName: RDB_COLLECTIONS.STATISTICS_NEWS_TIPS_BY_NEWSPAPER
        })
      }))

      const newspapersPromises = await Promise.allSettled(promises)
      const newspapers = newspapersPromises?.map(({ value }) => value)

      setTransformedDataSource(newspapers)

      if (!isSubadminRole && !!newspapers?.length) {
        setTableParams((prev) => ({
          ...prev,
          total: newspapers?.length
        }))
      }
    } catch (error) {
      message.error(t('Error getting statistic data for newspapers'))
    } finally {
      setLoading(false)
    }
  })

  useEffect(() => {
    !!dataSource?.length && fetchRelatedData()
  }, [dataSource])

  return { loading, tableParams, transformedDataSource, handleTableChange }
}

export default useTransformNewspaperTableData
