import { Checkbox } from 'antd'
import PropTypes from 'prop-types'
import { STATUSES } from '__constants__'
import { Text } from '@qonsoll/react-design'
import { useMemo } from 'react'
import { useSubfilterActions } from 'hooks'
import { useTranslations } from 'contexts/Translation'

const CountryStatusFilter = ({ filterData, setFilterData }) => {
  const { t } = useTranslations()

  const { COUNTRY_STATUSES } = STATUSES

  const countryStatuses = Object.values(COUNTRY_STATUSES)?.map(
    (countryStatus) =>
      countryStatus === COUNTRY_STATUSES.BLOCKED
        ? { value: true, name: countryStatus }
        : { value: false, name: countryStatus }
  )

  const { onToggleDetails, onChange, checkIsEnabled } = useSubfilterActions({
    filterData,
    setFilterData,
    fieldName: 'isBlocked',
    operand: '==',
    type: 'countryStatus'
  })

  const isOpenFilter = useMemo(
    () => localStorage.getItem(`countryStatus-details-visibility`) === 'opened',
    []
  )

  return (
    <details open={isOpenFilter}>
      <summary onClick={onToggleDetails}>{t('Country status')}</summary>
      {countryStatuses?.map((countryStatus, index) => (
        <Checkbox
          key={`status-${index}`}
          checked={checkIsEnabled(countryStatus)}
          onChange={() => onChange(countryStatus)}>
          <Text wordBreak="break-all">
            {countryStatus?.name || t('Unnamed filter')}
          </Text>
        </Checkbox>
      ))}
    </details>
  )
}

CountryStatusFilter.propTypes = {
  filterData: PropTypes.object,
  setFilterData: PropTypes.func
}

export default CountryStatusFilter
