import {
  AVAILABLE_APPS,
  DEFAULT_LANGUAGE,
  ENGLISH_SHORT_CODE_LANGUAGE
} from 'domains/Translation/__constants__'
import {
  AddNewLanguageForm,
  LanguagesList,
  LocalizationForm
} from 'domains/Translation/components'
import { Box, PageWrapper } from '@qonsoll/react-design'
import { useEffect, useMemo, useState } from 'react'

import { AddNewLanguageSelect } from 'domains/Translation/components'
import { CustomTabs } from './Translations.styles'
import { HeaderBreadcrumbs } from 'components'
import PropTypes from 'prop-types'
import { Tabs } from 'antd'
import { useHistory } from 'react-router-dom'
import { useStateWithStorage } from 'hooks'
import { useTranslations } from 'contexts/Translation'

const { TabPane } = Tabs

const MANAGE_LANGUAGES_TAB = 'Manage languages'

const Translations = (props) => {
  const { withHeader = true } = props

  const { t, languages, language, loading } = useTranslations()
  const history = useHistory()
  const [toTranslateShortCodeLanguage, setToTranslateShortCodeLanguage] =
    useStateWithStorage(language, 'toTranslateShortCodeLanguage')

  /* State for active tab control */
  const [lastActiveTab, setLastActiveTab] = useStateWithStorage(
    AVAILABLE_APPS.ADMIN,
    `translations-last-active-tab`
  )

  /* Saving short code for selected language for adding translations  */
  const [translateToShortCode, setTranslateToShortCode] = useState()
  const [isPageLoading, setIsPageLoading] = useState(true)

  const onChangeTab = (tabName) => setLastActiveTab(tabName)
  const handleLoadingChange = (newValue = false) => {
    if (isPageLoading !== newValue) setIsPageLoading(newValue)
  }

  const handleChangeToTranslateLanguage = ({ value }) => {
    setToTranslateShortCodeLanguage(value)
  }

  const initLanguageInSelect = useMemo(() => {
    const translateToLanguage = languages?.find(
      ({ value }) => value === toTranslateShortCodeLanguage
    )
    // Exclude ENGLISH from select
    if (
      !translateToLanguage ||
      translateToLanguage?.value === ENGLISH_SHORT_CODE_LANGUAGE
    ) {
      setToTranslateShortCodeLanguage(DEFAULT_LANGUAGE)
      return languages?.find(({ value }) => value === DEFAULT_LANGUAGE)
    }

    return translateToLanguage
  }, [languages, toTranslateShortCodeLanguage])

  const translateToLabel = useMemo(
    () => languages?.find(({ value }) => value === translateToShortCode)?.label,
    [languages, translateToShortCode]
  )

  /* Exclude English language from values that select gets */
  useEffect(
    () =>
      toTranslateShortCodeLanguage === ENGLISH_SHORT_CODE_LANGUAGE
        ? setTranslateToShortCode(DEFAULT_LANGUAGE)
        : setTranslateToShortCode(toTranslateShortCodeLanguage),
    [toTranslateShortCodeLanguage]
  )

  const tabs = [...Object.values(AVAILABLE_APPS), MANAGE_LANGUAGES_TAB]

  return (
    <PageWrapper
      action={
        <Box display="flex" gap="16px">
          {loading ? null : (
            <AddNewLanguageSelect
              onChange={handleChangeToTranslateLanguage}
              style={{ width: '225px' }}
              defaultValue={initLanguageInSelect}
              withOnlyExistLanguages
            />
          )}
          <AddNewLanguageForm />
        </Box>
      }
      height="100%"
      innerContentWrapperStyles={{ overflow: 'hidden' }}
      headingProps={{
        title: t('Translations'),
        textAlign: 'left',
        titleSize: 3,
        marginBottom: 1
      }}
      onBack={() => history?.goBack()}
      breadcrumbs={withHeader ? <HeaderBreadcrumbs /> : null}>
      <CustomTabs
        defaultActiveKey={lastActiveTab}
        size="large"
        height="100%"
        onChange={onChangeTab}>
        {tabs.map((appName) => (
          <TabPane tab={appName} key={appName}>
            {appName === MANAGE_LANGUAGES_TAB ? (
              <LanguagesList />
            ) : (
              <LocalizationForm
                translateTo={translateToLabel}
                translateToShortCode={toTranslateShortCodeLanguage}
                appName={appName}
                isPageLoading={isPageLoading}
                handleLoadingChange={handleLoadingChange}
              />
            )}
          </TabPane>
        ))}
      </CustomTabs>
    </PageWrapper>
  )
}

Translations.propTypes = {
  withHeader: PropTypes.bool
}

export default Translations
