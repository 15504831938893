import { Card, Tabs } from 'antd'
import { Col, Container, Row, Text, Title } from '@qonsoll/react-design'
import {
  RewardNewspaper,
  RewardRewardStatus,
  RewardRewardType,
  RewardStore
} from 'domains/Reward/components'
import {
  useGetRewardRewardStatus,
  useGetRewardRewardType,
  useGetRewardStore
} from 'domains/Reward/hooks'

import { ImageStyled } from './RewardAdvancedView.styles'
import PropTypes from 'prop-types'
import { StickyContainer } from 'react-sticky'
import { convertEmoji } from 'domains/Reward/helpers'
import { useStateWithStorage } from 'hooks'
import { useTranslations } from 'contexts/Translation'

const RewardAdvancedView = (props) => {
  const { reward } = props

  /* State for active tab in AdvancedView with saving it to storage */
  const [activeTab, setActiveTab] = useStateWithStorage(
    null,
    'reward-advanced-view-active-tab'
  )

  /* Getting translations instance */
  const { t } = useTranslations()

  const [rewardStatus] = useGetRewardRewardStatus(reward)

  const [rewardType] = useGetRewardRewardType(reward)
  const [store] = useGetRewardStore(reward)

  /* Getting TabPane component from Tabs */
  const { TabPane } = Tabs

  /* Handling change tab */
  const changeTab = (key) => {
    setActiveTab(key)
  }

  const computedEmoji = convertEmoji(reward)

  const tabsConfig = [
    {
      tab: t('Newspaper'),
      key: '#newspaper',
      component: <RewardNewspaper reward={reward} />
    },
    {
      tab: t('Store'),
      key: '#store',
      component: <RewardStore reward={reward} />
    }
  ]
  return (
    <Container>
      <Row mx="var(--ql-grid-gutter-negative)">
        <Col cw={4} mb={3}>
          <Card>
            <Row noGutters mb={2} v="center">
              <Col h="left" cw={12} mb={2}>
                <ImageStyled src={reward?.imageUrl} />
              </Col>
              <Col h="left" mb={2}>
                <Title level={5}>{reward?.name}</Title>
              </Col>
            </Row>

            <Row noGutters v="center" mb={2}>
              <Col h="left">
                <Text variant="overline" type="secondary">
                  {t('Store')}:
                </Text>
              </Col>
              <Col h="right">
                <Text>{store?.name}</Text>
              </Col>
            </Row>
            <Row noGutters v="center" mb={2}>
              <Col h="left">
                <Text variant="overline" type="secondary">
                  {t('Redeemed')}:
                </Text>
              </Col>
              <Col h="right">
                <Text>{reward?.redeemed || 0}</Text>
              </Col>
            </Row>
            <Row noGutters v="center" mb={2}>
              <Col h="left">
                <Text variant="overline" type="secondary">
                  {t('Points')}:
                </Text>
              </Col>
              <Col h="right">
                <Text>{reward?.points}</Text>
              </Col>
            </Row>
            {!!computedEmoji && (
              <Row noGutters v="center" mb={2}>
                <Col h="left">
                  <Text variant="overline" type="secondary">
                    {t('Emoji')}:
                  </Text>
                </Col>
                <Col h="right">
                  <Text>{computedEmoji}</Text>
                </Col>
              </Row>
            )}
          </Card>
        </Col>
        <Col cw={8} mb={3}>
          <Card>
            <Row noGutters v="center" mb={2}>
              <Col h="left">
                <Text fontWeight="bold">{t('Reward type')}:</Text>
              </Col>
              <Col cw="auto" h="right">
                <RewardRewardType
                  emptyProps={{ withIllustration: false, withCreate: false }}
                  reward={{ ...reward, rewardType: rewardType }}
                />
              </Col>
            </Row>
            <Row noGutters v="center" mb={2}>
              <Col h="left">
                <Text fontWeight="bold">{t('Status')}:</Text>
              </Col>
              <Col cw="auto" h="right">
                <RewardRewardStatus
                  emptyProps={{ withIllustration: false, withCreate: false }}
                  reward={{ ...reward, status: rewardStatus }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col cw={12}>
          <StickyContainer>
            <Tabs defaultActiveKey={activeTab} onChange={changeTab}>
              {tabsConfig?.map(({ tab, key, component }) => (
                <TabPane key={key} tab={tab}>
                  {component}
                </TabPane>
              ))}
            </Tabs>
          </StickyContainer>
        </Col>
      </Row>
    </Container>
  )
}

RewardAdvancedView.propTypes = {
  reward: PropTypes.object
}

export default RewardAdvancedView
