import { Form } from 'antd'
import { useEffect } from 'react'

const useInitializeStoreAdvancedForm = (initialData) => {
  /* Getting form instance */
  const [form] = Form.useForm()

  /* Setting initial data */
  useEffect(() => {
    if (initialData) {
      /* Getting initial values */
      const { store, newspaper } = initialData

      /* Formatting date fields */
      const formValues = {
        store: {
          logoUrl: store?.logoUrl ?? null,
          name: store?.name ?? null,
          description: store?.description ?? null,
          status: store?.status ?? null
        },
        newspaper: newspaper ?? null
      }

      /* Setting initial values to form instance */
      form.setFieldsValue(formValues)
    }
  }, [initialData, form])

  return { form }
}

export default useInitializeStoreAdvancedForm
