import { COLLECTIONS, USER_ROLES } from '__constants__'
import { useDocument } from 'services/api/firebase'
import { useGetEditorNewspaper } from 'domains/Editor/hooks'
import { useLoading } from 'hooks'
import { useMemo } from 'react'
import { createFullUserName, capitalize } from 'helpers'

const useGetEditorInitialValues = (id) => {
  /* Fetching data using useDocument hook */
  const [editor, editorLoading] = useDocument({
    ref: id ? COLLECTIONS.EDITORS + '/' + id : null
  })

  /* Get editor full name */
  const fullName = createFullUserName(editor?.firstName, editor?.lastName)

  /* Fetching newspaper data */
  const [newspaper, newspaperLoading] = useGetEditorNewspaper(editor)

  /* Merge all fetched data */
  const initialValues = useMemo(
    () => ({
      editor: {
        ...editor,
        fullName,
        role: capitalize(USER_ROLES.EDITOR_ROLE)
      },
      newspaper
    }),
    [editor, newspaper, fullName]
  )

  /* Merge all loading states */
  const loadings = useMemo(
    () => (id ? [!editor, editorLoading, newspaperLoading] : []),
    [id, editor, editorLoading, newspaperLoading]
  )

  /* Getting loading state using merged loadings */
  const loading = useLoading(loadings)

  return [initialValues, loading]
}

export default useGetEditorInitialValues
