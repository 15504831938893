import { Col, PageWrapper, Row } from '@qonsoll/react-design'
import { HeaderBreadcrumbs, PageActions, Spinner } from 'components'
import {
  useGetNewsTipInitialValues,
  useNewsTipBaseActions
} from 'domains/NewsTip/hooks'

import { NewsTipAdvancedView } from 'domains/NewsTip/components'
import { useParams } from 'react-router-dom'
import { useTranslations } from 'contexts/Translation'
import STATUSES from '__constants__/statuses'

const NewsTipShowPage = () => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting params from router */
  const { newsTipId } = useParams()

  /* Fetching news tip data from useGetNewsTipInitialValues hook */
  const [newsTipData, loading] = useGetNewsTipInitialValues(newsTipId)

  const isAllowedDeleteDraft =
    newsTipData?.newsTip?.status === STATUSES.NEWS_TIP_STATUSES.DRAFT

  /* Get actions for page */
  const actions = { copy: true, download: true, delete: isAllowedDeleteDraft }
  const { onBack, onCopy, onDownload, onDelete } = useNewsTipBaseActions({
    newsTipId: newsTipId,
    actions,
    extraProps: newsTipData
  })

  /* Configuring page header */
  const headingProps = {
    title: newsTipData?.newsTip?.title || t('News tip show'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  const handleDownloadFiles = () => {
    const { newsTip } = newsTipData
    const { videoUrl = null, photoUrls = [], title } = newsTip
    const computedUrls = [...photoUrls, videoUrl]?.filter(Boolean)

    return onDownload(computedUrls, title)
  }

  return (
    <PageWrapper
      action={
        <PageActions
          actions={actions}
          onDelete={onDelete}
          onCopy={onCopy}
          onDownload={handleDownloadFiles}
        />
      }
      onBack={onBack}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}>
      <Row noGutters>
        {loading ? (
          <Col>
            <Spinner height="70vh" text={t('News tip loading')} />
          </Col>
        ) : (
          <Col cw={12}>
            <NewsTipAdvancedView {...newsTipData} />
          </Col>
        )}
      </Row>
    </PageWrapper>
  )
}

export default NewsTipShowPage
