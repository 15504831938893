import { Box, Col, Divider, Menu, MenuItem, Row } from '@qonsoll/react-design'

import { Fragment } from 'react'
import { LanguageSelect } from 'domains/Translation/components'
import { useGetMenuItems } from './hooks'
import { useGetSelectedMenuItem } from 'components/BoilerplateLayout/components/MainMenu/hooks'

const MainMenu = () => {
  const selectedMenuItem = useGetSelectedMenuItem()

  const menuItems = useGetMenuItems()
  return (
    <Fragment>
      <Menu
        mode="inline"
        defaultSelectedKeys={[selectedMenuItem]}
        selectedKeys={selectedMenuItem}>
        {menuItems?.map((item) => (
          <Fragment key={item.key}>
            <MenuItem
              style={{
                paddingLeft: 'var(--ql-padding-md)',
                marginTop: item.extraMargin ? 'var(--ql-margin-xl)' : '0'
              }}
              key={item.key}
              onClick={item.onClick}
              icon={item.icon}>
              {item.text}
            </MenuItem>
            {item.divided && (
              <Box>
                <Divider />
              </Box>
            )}
          </Fragment>
        ))}
      </Menu>
      <Row mt="var(--ql-margin-md)">
        <Col>
          <LanguageSelect />
        </Col>
      </Row>
    </Fragment>
  )
}

export default MainMenu
