import { Col, Container, Link, Row, Text, Title } from '@qonsoll/react-design'
import { generatePath, useHistory } from 'react-router-dom'

import { AvatarWithLetter } from 'components'
import { Card } from 'antd'
import PATHS from 'pages/paths'
import PropTypes from 'prop-types'
import { UserNewsTips } from 'domains/User/components'
import { createFullUserName } from 'helpers'
import { useGetUserNewspaper } from 'domains/User/hooks'
import { usePhoneNumber } from 'hooks'
import { useTranslations } from 'contexts/Translation'

const UserAdvancedView = (props) => {
  const { user } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance from useHistory hook for redirecting */
  const history = useHistory()

  // get Newspaper
  const [newspaper] = useGetUserNewspaper(user)
  const { transformPhoneNumber } = usePhoneNumber()

  const handleOpen = () =>
    history.push(
      generatePath(PATHS.AUTHENTICATED.NEWSPAPER_SHOW, {
        newspaperId: newspaper?._id
      })
    )

  const userFullName = createFullUserName(user?.firstName, user?.lastName)
  const userNumber = transformPhoneNumber(user?.phoneNumber)
  const userAssociatedNewsPaper = newspaper?.name || 'N/A'

  const userPoints = user?.pointsGiven?.[user?.newspaperAssociated] || 0
  return (
    <Container>
      <Row mx="var(--ql-grid-gutter-negative)">
        <Col cw={12} mb={3}>
          <Card>
            <Row
              noGutters
              mb={2}
              v="center"
              h="between"
              style={{ gap: '48px', alignItems: 'flex-start' }}>
              <Col h="left" cw="auto" minWidth="200px">
                <AvatarWithLetter user={user} />
              </Col>
              <Col h="left">
                <Text type="secondary">{t('Name')}</Text>
                <Title level={5} mb={2}>
                  {userFullName}
                </Title>

                <Text type="secondary">{t('Mobile number')}:</Text>
                <Title level={5}>{userNumber}</Title>
              </Col>

              <Col h="left">
                <Text type="secondary">{t('Points')}:</Text>
                <Title level={5} mb={2}>
                  {userPoints}
                </Title>

                <Text type="secondary">{t('Newspaper associated')}:</Text>

                <Link
                  level={5}
                  fontWeight="var(--ql-typography-heading-font-weight)"
                  onClick={handleOpen}>
                  {userAssociatedNewsPaper}
                </Link>
              </Col>

              <Col h="left">
                <Text type="secondary">{t('Email')}:</Text>

                <Title level={5}>{user?.email}</Title>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col cw={12}>
          <Card>
            <UserNewsTips user={user} />
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

UserAdvancedView.propTypes = {
  user: PropTypes.object
}

export default UserAdvancedView
