import { Col, Container, Menu, Row } from '@qonsoll/react-design'
import { StyledMenuItem, StyledMenuWrapper } from './MainMenu.styled'

import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

const MainMenu = ({ pageConfiguration }) => {
  const { t } = useTranslations()

  const menuItems = useMemo(() => {
    return [
      { text: t('Dashboard') },
      { text: t('Create tips') },
      { text: t('Use my points') },
      { text: t('Points history') }
    ]
  }, [t])
  const { backgroundColor, textColor, textColorActive } =
    pageConfiguration?.menu || {}

  return (
    <StyledMenuWrapper bg={backgroundColor}>
      <Container bounded>
        <Row noGutters px={3}>
          <Col cw={12}>
            <Menu
              mode="horizontal"
              defaultSelectedKeys={[`${menuItems?.[0]?.key}-0`]}>
              {menuItems?.map((item, index) => (
                <StyledMenuItem
                  key={`${item?.key}-${index}`}
                  icon={item?.icon}
                  textcolor={textColor} // was lowercased purposely, to avoid errors in the browser console
                  textcoloractive={textColorActive} // same as previous comment
                >
                  {item?.text}
                </StyledMenuItem>
              ))}
            </Menu>
          </Col>
        </Row>
      </Container>
    </StyledMenuWrapper>
  )
}
MainMenu.propTypes = {
  pageConfiguration: PropTypes.object
}
export default MainMenu
