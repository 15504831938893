import { Col, Container, Row, Text, Title } from '@qonsoll/react-design'

import { AvatarWithLetter } from 'components'
import { Card } from 'antd'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { createFullUserName } from 'helpers'
import { usePhoneNumber } from 'hooks'
import { useTranslations } from 'contexts/Translation'
import { useUser } from 'domains/User/context'

const ProfileAdvancedView = (props) => {
  // get user from context
  const user = useUser()

  /* Getting translations instance */
  const { t } = useTranslations()

  const { transformPhoneNumber } = usePhoneNumber()

  /* Getting history instance from useHistory hook for redirecting */
  const userFullName = createFullUserName(user?.firstName, user?.lastName)

  const userNumber = transformPhoneNumber(user?.phoneNumber)

  const role = _.upperFirst(_.lowerCase(user?.role))

  return (
    <Container>
      <Row mx="var(--ql-grid-gutter-negative)">
        <Col cw={12} mb={3}>
          <Card>
            <Row
              noGutters
              mb={2}
              v="center"
              h="between"
              style={{ gap: '48px', alignItems: 'flex-start' }}>
              <Col h="left" cw="auto" minWidth="200px">
                <AvatarWithLetter user={user} />
              </Col>
              <Col h="left">
                <Text type="secondary">{t('Name')}</Text>
                <Title level={5} mb={2}>
                  {userFullName}
                </Title>

                <Text type="secondary">{t('Role')}</Text>
                <Title level={5} mb={2}>
                  {role}
                </Title>

                <Text type="secondary">{t('Mobile number')}:</Text>
                <Title level={5}>{userNumber}</Title>
              </Col>

              <Col h="left">
                <Text type="secondary">{t('Email')}:</Text>

                <Title level={5}>{user?.email}</Title>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

ProfileAdvancedView.propTypes = {
  subadmin: PropTypes.object
}

export default ProfileAdvancedView
