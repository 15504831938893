import { Box } from '@qonsoll/react-design'
import styled from 'styled-components'

const StyledBody = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${({ bg }) => bg || 'var(--ql-layout-background)'};
  padding: 24px;
`

export { StyledBody }
