export const RECEIVERS_ALL_USERS_TYPE = 'ALL USERS'
export const RECEIVERS_NEWSPAPER_USERS_TYPE = 'NEWSPAPER USERS'
export const RECEIVERS_SINGLE_USER_TYPE = 'SINGLE USER'

export const NOTIFICATION_RECEIVERS_OPTIONS = [
  { value: RECEIVERS_ALL_USERS_TYPE, label: 'All users' },
  { value: RECEIVERS_NEWSPAPER_USERS_TYPE, label: 'Newspaper users' }
]

export const LOCAL_ADMIN_NOTIFICATION_RECEIVERS_OPTIONS = [
  { value: RECEIVERS_NEWSPAPER_USERS_TYPE, label: 'Newspaper users' }
]

export const RECEIVERS_TYPES_OBJ = {
  RECEIVERS_ALL_USERS_TYPE,
  RECEIVERS_NEWSPAPER_USERS_TYPE
}
