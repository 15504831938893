import { Col, PageWrapper, Row } from '@qonsoll/react-design'

import { HeaderBreadcrumbs } from 'components'
import { NewspaperAdvancedForm } from 'domains/Newspaper/components'
import { useTranslations } from 'contexts/Translation'
import { useNewspaperBaseActions } from 'domains/Newspaper/hooks'

const NewspaperCreatePage = (props) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting base actions */
  const { onBack } = useNewspaperBaseActions()

  /* Configuring page header */
  const headingProps = {
    title: t('Add new newspaper'),
    titleSize: 3,
    marginBottom: 3,
    textAlign: 'left'
  }

  return (
    <PageWrapper
      onBack={onBack}
      breadcrumbs={<HeaderBreadcrumbs />}
      headingProps={headingProps}>
      <Row noGutters>
        <Col cw={[12, 12, 10, 8, 7]}>
          <NewspaperAdvancedForm />
        </Col>
      </Row>
    </PageWrapper>
  )
}

export default NewspaperCreatePage
