import { Box, Button, Input } from '@qonsoll/react-design'
import { useCallback, useRef } from 'react'

import { SearchOutlined } from '@ant-design/icons'
import { Space } from 'antd'
import { onTableFilterAction } from 'helpers'
import styles from './useGetColumnsSearchProps.styles'
import { useTranslations } from '@qonsoll/translation'

const useGetColumnSearchProps = () => {
  const { t } = useTranslations()

  const searchInput = useRef(null)
  const handleSearch = (selectedKeys, confirm, dataIndex) => confirm()
  const handleReset = (clearFilters) => clearFilters()

  const getColumnSearchProps = useCallback(
    (dataIndex, placeholder) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters
      }) => {
        const handleChange = (e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        return (
          <Box p="8px">
            <Input
              ref={searchInput}
              placeholder={`${t('Search')} ${t(placeholder) || t(dataIndex)}`}
              value={selectedKeys[0]}
              onChange={handleChange}
              onPressEnter={() =>
                handleSearch(selectedKeys, confirm, dataIndex)
              }
              style={styles.inputStyles}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small">
                {t('Search')}
              </Button>
              <Button
                onClick={() => clearFilters && handleReset(clearFilters)}
                size="small">
                {t('Reset')}
              </Button>
            </Space>
          </Box>
        )
      },
      filterIcon: (filtered) => (
        <SearchOutlined
          style={{
            color: filtered ? 'var(--ql-color-accent1)' : undefined
          }}
        />
      ),
      onFilter: (value, record) =>
        onTableFilterAction(value, record, dataIndex),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100)
        }
      }
    }),
    [t]
  )
  return getColumnSearchProps
}
export default useGetColumnSearchProps
