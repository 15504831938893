import { useEffect, useMemo, useState } from 'react'

import PropTypes from 'prop-types'
import { StyledTable } from 'components'
import { createFullUserName } from 'helpers'
import { useSubadminTableColumns } from 'domains/Subadmin/hooks'

const SubadminTable = (props) => {
  const { dataSource, isSuperAdmin = false } = props

  /* Pagination params for table */
  const [tableParams, setTableParams] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showQuickJumper: true,
    showSizeChanger: true
  })

  /* Getting table columns */
  const columns = useSubadminTableColumns(isSuperAdmin)

  const transformedDataSource = useMemo(
    () =>
      dataSource?.map(({ firstName, lastName, ...rest }) => ({
        fullName: createFullUserName(firstName, lastName),
        ...rest
      })),
    [dataSource]
  )

  /* Handle changes for table */
  const handleTableChange = (pagination) => setTableParams(pagination)

  /* Update total value for tableParams */
  useEffect(() => {
    transformedDataSource?.length &&
      setTableParams((prev) => ({
        ...prev,
        total: transformedDataSource?.length
      }))
  }, [transformedDataSource])

  return (
    <StyledTable
      // tableOuterHeight={290} // should be comment why do we need it, otherwise it's different from other tables, which we have in the web app
      {...props}
      dataSource={transformedDataSource}
      columns={columns}
      rowKey="_id"
      pagination={tableParams}
      onChange={handleTableChange}
    />
  )
}

SubadminTable.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.object),
  isSuperAdmin: PropTypes.bool
}

export default SubadminTable
