import { Button, Text, Title } from '@qonsoll/react-design'
import { Col, Row } from '@qonsoll/react-design'
import { ColorPicker, ImageUploader } from 'components'

import { ContentTextEditor } from 'domains/Content/components'
import { Form } from 'antd'
import PropTypes from 'prop-types'
import StyledDivider from './GeneralConfigurationSimpleForm.styled'
import { useGeneralConfigurationSimpleForm } from './hooks'
import { useTranslations } from 'contexts/Translation'

const GeneralConfigurationSimpleForm = (props) => {
  const {
    initialValues,
    onSubmit,
    onCancel,
    showTitle,
    form,
    setPageConfiguration
  } = props
  /* Getting translations instance */
  const { t } = useTranslations()
  const {
    fieldsLayout,
    usedForm,
    validationRules,
    editorInitialState,
    updateFormData,
    handleCancel
  } = useGeneralConfigurationSimpleForm({
    form,
    initialValues,
    onCancel,
    setPageConfiguration
  })

  return (
    <Form
      onFieldsChange={updateFormData}
      form={usedForm}
      initialValues={initialValues}
      onFinish={onSubmit}
      layout="vertical"
      // onKeyPress={handleKeyPress}
      autoComplete="off">
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Domain')}
        </Title>
      )}
      {/* Such construction needed for correct work and displaying*/}
      <Form.Item noStyle shouldUpdate>
        {() => (
          <Form.Item
            label={t('App logo')}
            name={['app', 'logoUrl']}
            rules={validationRules?.logo}>
            <ImageUploader withImageCrop={false} />
          </Form.Item>
        )}
      </Form.Item>

      <StyledDivider>
        <Text variant="overline" type="secondary">
          {t('App colors')}
        </Text>
      </StyledDivider>
      <Row mx="var(--ql-grid-gutter-negative)">
        <Col cw={fieldsLayout?.cw}>
          <Form.Item
            label={t('Background color')}
            name={['app', 'backgroundColor']}>
            <ColorPicker />
          </Form.Item>
        </Col>
        {/* code below partly solves our problem. app color vars should be
				rewritten in the upper level component by styled-components.
				Check solution in the tips-web => BoilerplateLayout */}
        {/* <Col cw={fieldsLayout?.cw}>
          <Form.Item
            label={t('Page title color')}
            name={['app', 'pageTitleColor']}>
            <ColorPicker />
          </Form.Item>
        </Col> */}
      </Row>

      <StyledDivider>
        <Text variant="overline" type="secondary">
          {t('Header colors')}
        </Text>
      </StyledDivider>
      <Row mx="var(--ql-grid-gutter-negative)">
        <Col cw={fieldsLayout?.cw}>
          <Form.Item
            label={t('Background color')}
            name={['header', 'backgroundColor']}>
            <ColorPicker />
          </Form.Item>
        </Col>
        <Col cw={fieldsLayout?.cw}>
          <Form.Item label={t('Text color')} name={['header', 'textColor']}>
            <ColorPicker />
          </Form.Item>
        </Col>
        <Col cw={fieldsLayout?.cw}>
          <Form.Item
            label={t('Avatar background')}
            name={['header', 'avatarBackgroundColor']}>
            <ColorPicker />
          </Form.Item>
        </Col>
        <Col cw={fieldsLayout?.cw}>
          <Form.Item
            label={t('Avatar text color')}
            name={['header', 'avatarTextColor']}>
            <ColorPicker />
          </Form.Item>
        </Col>
      </Row>

      <StyledDivider>
        <Text variant="overline" type="secondary">
          {t('Header menu colors')}
        </Text>
      </StyledDivider>
      <Row mx="var(--ql-grid-gutter-negative)">
        <Col cw={fieldsLayout?.cw}>
          <Form.Item
            label={t('Background color')}
            name={['menu', 'backgroundColor']}>
            <ColorPicker />
          </Form.Item>
        </Col>
        <Col cw={fieldsLayout?.cw}>
          <Form.Item label={t('Text color')} name={['menu', 'textColor']}>
            <ColorPicker />
          </Form.Item>
        </Col>
        <Col cw={fieldsLayout?.cw}>
          <Form.Item
            label={t('Active text color')}
            name={['menu', 'textColorActive']}>
            <ColorPicker />
          </Form.Item>
        </Col>
      </Row>

      <StyledDivider>
        <Text variant="overline" type="secondary">
          {t('Footer colors')}
        </Text>
      </StyledDivider>
      <Row mx="var(--ql-grid-gutter-negative)">
        <Col cw={fieldsLayout?.cw}>
          <Form.Item
            label={t('Background color')}
            name={['footer', 'backgroundColor']}>
            <ColorPicker />
          </Form.Item>
        </Col>
        <Col cw={fieldsLayout?.cw}>
          <Form.Item label={t('Text color')} name={['footer', 'textColor']}>
            <ColorPicker />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label={t('Footer text')}
        name={['footer', 'mainText']}
        rules={validationRules?.footer}>
        <ContentTextEditor initState={editorInitialState} />
      </Form.Item>
      {!form && (
        <>
          <Button mr={2} htmlType="button" onClick={handleCancel}>
            {t('Cancel')}
          </Button>
          <Button type="primary" onClick={() => usedForm.submit()}>
            {t('Save')}
          </Button>
        </>
      )}
    </Form>
  )
}

GeneralConfigurationSimpleForm.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  showTitle: PropTypes.bool,
  form: PropTypes.any,
  group: PropTypes.array,
  setPageConfiguration: PropTypes.func
}

export default GeneralConfigurationSimpleForm
