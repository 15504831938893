import {
  Col,
  Container,
  Option,
  Row,
  Select,
  Title
} from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import { STATUSES } from '__constants__'
import { useTranslations } from 'contexts/Translation'

// Takes a value of type object and onChange function
// Returns selected option object
const StoreStatusSelect = (props) => {
  const {
    value,
    onChange,
    showTitle = true,
    allowClear = true,
    mode,
    ...rest
  } = props

  /* Getting translation instance */
  const { t } = useTranslations()

  const { STORE_STATUSES } = STATUSES

  return (
    <Container {...rest}>
      {showTitle && (
        <Title mb={2} level={4}>
          {t('Store status')}
        </Title>
      )}
      <Row noGutters>
        <Col>
          <Select
            mode={mode}
            allowClear={allowClear}
            placeholder={t('Please, select store status')}
            value={value}
            onChange={onChange}>
            {Object.values(STORE_STATUSES).map((storeStatus) => (
              <Option key={storeStatus} value={storeStatus}>
                {storeStatus}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
    </Container>
  )
}

StoreStatusSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  showTitle: PropTypes.bool,
  allowClear: PropTypes.bool,
  mode: PropTypes.string
}

export default StoreStatusSelect
