import { Form, Input } from 'antd'

import { MAX_LENGTH_FIELD } from '__constants__'
import PropTypes from 'prop-types'
import { Title } from '@qonsoll/react-design'
import { useTranslations } from 'contexts/Translation'

const CountrySimpleForm = (props) => {
  const { showTitle, group } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  return (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Country')}
        </Title>
      )}
      <Form.Item
        label={t('Country native name')}
        name={[...group, 'internationalName']}
        rules={[
          {
            required: true,
            message: t('Please enter country name!')
          },

          { max: MAX_LENGTH_FIELD, message: t('country is too long!') }
        ]}>
        <Input placeholder={t('Please enter country name')} />
      </Form.Item>
    </>
  )
}

CountrySimpleForm.propTypes = {
  showTitle: PropTypes.bool,
  group: PropTypes.array
}

export default CountrySimpleForm
