import { EditorStatusFilter, NewspaperFilter } from './components'

import PropTypes from 'prop-types'
import { useGetSubadminData } from 'hooks'

const EditorFilter = (props) => {
  const { filterVisibility, filterData, setFilterData, hiddenNewspaperFilter } =
    props

  const { isSubadmin } = useGetSubadminData()

  return filterVisibility ? (
    <>
      {!isSubadmin && !hiddenNewspaperFilter && (
        <NewspaperFilter
          filterData={filterData}
          setFilterData={setFilterData}
        />
      )}
      <EditorStatusFilter
        filterData={filterData}
        setFilterData={setFilterData}
      />
    </>
  ) : null
}

EditorFilter.propTypes = {
  filterVisibility: PropTypes.bool,
  filterData: PropTypes.object,
  setFilterData: PropTypes.func,
  hiddenNewspaperFilter: PropTypes.bool
}

export default EditorFilter
