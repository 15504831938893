import { ENGLISH_SHORT_CODE_LANGUAGE } from 'domains/Translation/__constants__'

/**
 * Function converts obj from RDB to the array of objects
 * It takes an object with two keys, `en` and `nn`, and returns an array of objects with three keys,
 * `original`, `en` and `nn`
 * @param obj - The object that contains the translations
 * @returns An array of objects with the following structure:
 *   {
 *     original: 'label',
 *     en: 'translation',
 *     nn: 'translation'
 *   }
 */
const formatTranslations = (obj, translateToShortCode) => {
  const labels =
    obj?.[translateToShortCode] && Object.entries(obj?.[translateToShortCode])
  let formattedTranslations = []
  labels?.length &&
    labels.forEach(([key, translation]) => {
      formattedTranslations.push({
        original: key,
        enKey: obj?.[ENGLISH_SHORT_CODE_LANGUAGE]?.[key],
        [translateToShortCode]: translation
      })
    })

  return formattedTranslations
}

export default formatTranslations
