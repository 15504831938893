import { HeaderBreadcrumbs } from 'components'
import { useTranslations } from '@qonsoll/translation'
import { ContentAdvancedView } from 'domains/Content/components'
import PropTypes from 'prop-types'
import { PageWrapper } from '@qonsoll/react-design'

const ContentAll = (props) => {
  const { withHeader = true } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  const headingProps = {
    title: t('Content'),
    titleSize: 3,
    textAlign: 'left'
  }

  return (
    <PageWrapper
      headingProps={headingProps}
      breadcrumbs={withHeader ? <HeaderBreadcrumbs /> : null}>
      <ContentAdvancedView />
    </PageWrapper>
  )
}

ContentAll.propTypes = {
  withHeader: PropTypes.bool
}

export default ContentAll
