import { Col, Container, Row, Text } from '@qonsoll/react-design'

import { AvatarWithLetter } from 'components'
import { Card } from 'antd'
import { DetailsCard } from './EditorAdvancedView.styles'
import { NewspaperLink } from 'domains/Newspaper/components'
import PropTypes from 'prop-types'
import { usePhoneNumber } from 'hooks'
import { useTranslations } from 'contexts/Translation'

const EditorAdvancedView = ({ editor, newspaper }) => {
  const { fullName, role, status, phoneNumber, email, totalTips } = editor

  const newspaperName = newspaper?.name
  const newspaperId = newspaper?._id

  /* Getting translations instance */
  const { t } = useTranslations()

  const { transformPhoneNumber } = usePhoneNumber()
  const personalDetailsConfig = [
    {
      title: 'Full name',
      data: fullName
    },

    {
      title: 'Role',
      data: role
    },

    {
      title: 'Status',
      data: status
    },
    {
      title: 'Phone',
      data: transformPhoneNumber(phoneNumber)
    },
    {
      title: 'Email',
      data: email
    }
  ].filter(({ data, component }) => data || component)

  const serviceDetailsConfig = [
    {
      title: 'Newspaper',
      component: (newspaperId || newspaperName) && (
        <NewspaperLink newspaperId={newspaperId} text={newspaperName} />
      )
    },
    {
      title: 'Total tips',
      /* toString() in order to display the total tips if there is 0 tips */
      data: totalTips.toString()
    }
  ].filter(({ data, component }) => data || component)

  return (
    <Container>
      <Row mx="var(--ql-grid-gutter-negative)">
        <Col cw="auto">
          <Card>
            {/** Size of the avatar was increased for a better view */}
            <AvatarWithLetter user={editor} size={320} />
          </Card>
        </Col>
        <Col>
          <DetailsCard>
            <Row mb={2} noGutters>
              <Col cw={6}>
                {personalDetailsConfig?.map(({ title, data }) => (
                  <Row noGutters mb={3} key={title}>
                    <Col mb={1} cw={12} h="left">
                      <Text variant="overline" type="secondary">
                        {t(title)}:
                      </Text>
                    </Col>
                    <Col cw={12}>
                      <Text>{data}</Text>
                    </Col>
                  </Row>
                ))}
              </Col>
              <Col cw={6}>
                {serviceDetailsConfig?.map(({ title, data, component }) => (
                  <Row noGutters mb={3} key={title}>
                    <Col mb={1} cw={12} h="left">
                      <Text variant="overline" type="secondary">
                        {t(title)}:
                      </Text>
                    </Col>
                    <Col cw={12}>{component || <Text>{data}</Text>}</Col>
                  </Row>
                ))}
              </Col>
            </Row>
          </DetailsCard>
        </Col>
      </Row>
    </Container>
  )
}

EditorAdvancedView.propTypes = {
  editor: PropTypes.shape({
    fullName: PropTypes.string,
    role: PropTypes.string,
    status: PropTypes.string,
    phoneNumber: PropTypes.string,
    email: PropTypes.string,
    totalTips: PropTypes.number
  }),
  newspaper: PropTypes.shape({ name: PropTypes.string, _id: PropTypes.string })
}

export default EditorAdvancedView
