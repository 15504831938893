import styled from 'styled-components'

const styles = {
  image: {
    width: '200px',
    height: '200px',
    objectFit: 'cover',
    cursor: 'pointer',
    borderRadius: 'var(--card-radius)'
  }
}
const UploadButton = styled.div`
  border-radius: var(--card-radius);
  width: 200px;
  height: 200px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  border: 1px solid var(--ql-border-color-default);
  transition: all var(--ql-animation-duration-slow);

  path {
    fill: var(--ql-typography-text-color-secondary);
  }
  &:hover {
    border-color: var(--input-hover-border-color);
  }
`

export { UploadButton, styles }
