import { WidgetWrapper, StyledButton } from './WidgetSimpleView.styles'
import { Text } from '@qonsoll/react-design'
import PropTypes from 'prop-types'

const WidgetSimpleView = (props) => {
  const { widgetConfig } = props

  const buttonProps = widgetConfig?.button
  const additionalText = widgetConfig?.additionalText

  const handleClick = () => {
    const redirectTo = buttonProps?.redirectTo

    // There is no way to open url without protocol, so we need to add one
    const formattedRedirectTo = redirectTo?.startsWith('http')
      ? redirectTo
      : 'https://' + redirectTo

    window?.open(formattedRedirectTo)
  }

  return (
    <WidgetWrapper position={widgetConfig?.position}>
      <StyledButton onClick={handleClick} {...buttonProps}>
        {buttonProps?.text}
      </StyledButton>
      {additionalText && <Text>{additionalText}</Text>}
    </WidgetWrapper>
  )
}
WidgetSimpleView.propTypes = {
  widgetConfig: PropTypes.object
}
export default WidgetSimpleView
