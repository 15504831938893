import PATHS from 'pages/paths'
import { useEvent } from 'hooks'
import { useHistory } from 'react-router-dom'
import { useMemo } from 'react'

const useProfileBaseActions = () => {
  /* Getting history instance */
  const history = useHistory()

  /* Function to handle back click */
  const handleBack = useEvent(() => history.goBack())

  /* Handling opening of the show page */
  const handleOpen = useEvent(() => {
    history.push(PATHS.AUTHENTICATED.PROFILE_SHOW)
  })

  /* Handling opening of the edit page or change view to edit mode */
  const handleEdit = useEvent(() => {
    history.push(PATHS.AUTHENTICATED.PROFILE_EDIT)
  })
  /* Handling open change password */
  const handleChangePassword = useEvent(() => {
    history.push(PATHS.AUTHENTICATED.RESET_PASSWORD)
  })

  return useMemo(
    () => ({
      onBack: handleBack,
      onOpen: handleOpen,
      onEdit: handleEdit,
      onChangePassword: handleChangePassword
    }),
    [handleChangePassword, handleBack, handleEdit, handleOpen]
  )
}

export default useProfileBaseActions
