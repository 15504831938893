import {
  useGetRewardInitialValues,
  useRewardBaseActions
} from 'domains/Reward/hooks'

import { Link } from '@qonsoll/react-design'
import PropTypes from 'prop-types'

const RewardLink = ({ rewardId, text }) => {
  const { onOpen } = useRewardBaseActions({ rewardId })

  /* Get reward's document */
  const [rewardInitialValues, loading] = useGetRewardInitialValues(
    !text && rewardId
  )

  /* Get name from reward's document */
  const rewardName = text || rewardInitialValues?.reward?.name

  return !loading && <Link onClick={onOpen}>{rewardName}</Link>
}
RewardLink.propTypes = {
  rewardId: PropTypes.string,
  text: PropTypes.string
}

export default RewardLink
