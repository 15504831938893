/**
 * It takes in an object with two properties, `enLabel` and `noLabel`, and returns an array of objects
 * with the same shape as the `LANGUAGES` constant, but with the `value` property set to the value of
 * the `enLabel` or `noLabel` property, depending on the language
 * @returns An array of objects with the following shape:
 * {
 *   shortCode: 'en',
 *   label: 'English',
 *   value: 'English'
 * },
 * {
 *   shortCode: 'nn',
 *   label: 'Norwegian',
 *   value: 'Norwegian'
 * }
 */
const assembleTranslationLanguages = ({
  textInApp = '',
  translateToLabel = '',
  languages,
  translateToShortCode
}) => {
  const languageShortCodeToStateMap = {}

  languages.forEach((lang) => {
    if (lang?.shortCode === translateToShortCode) {
      languageShortCodeToStateMap[lang?.shortCode] = translateToLabel
    } else {
      languageShortCodeToStateMap[lang?.shortCode] = textInApp
    }
  })

  return languages?.map((lang) => ({
    ...lang,
    value: languageShortCodeToStateMap[lang?.shortCode]
  }))
}

export default assembleTranslationLanguages
