import { Button, Title } from '@qonsoll/react-design'
import {
  useActionsNewspaperAdvancedSettingForm,
  useInitializeNewspaperAdvancedSettingForm
} from './hooks'

import { Form } from 'antd'
import { KEYS } from '__constants__'
import { NewspaperExpirationPointForm } from 'domains/Newspaper/components'
import PropTypes from 'prop-types'
import { useCheckChangeFormValue } from 'hooks'
import { useTranslations } from 'contexts/Translation'
import { useGetNewspaperExpirationLanguageConfig } from 'domains/Newspaper/hooks'

const NewspaperAdvancedSettingForm = (props) => {
  const { initialData, showTitle } = props

  /* Getting translations instance */
  const { t } = useTranslations()

  const languageConfig = useGetNewspaperExpirationLanguageConfig(
    initialData?.newspaper
  )

  const { activeLanguage } = languageConfig

  /* Getting form instance from hook */
  const { form } = useInitializeNewspaperAdvancedSettingForm(
    initialData,
    activeLanguage
  )

  /* Handling enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      form.submit()
    }
  }

  /* Getting loading state and actions from hook */
  const { loading, onFinish, onReset } = useActionsNewspaperAdvancedSettingForm(
    {
      initialData,
      form
    }
  )

  const { triggerValueChange, isEqualForms } = useCheckChangeFormValue(form)

  return (
    <Form
      onKeyPress={handleKeyPress}
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
      onValuesChange={triggerValueChange}
      form={form}>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Advanced settings')}
        </Title>
      )}
      <NewspaperExpirationPointForm
        initialValues={initialData}
        group={['expirationPoints']}
        {...languageConfig}
      />
      <Button mr={2} htmlType="button" onClick={onReset}>
        {t('Cancel')}
      </Button>
      <Button
        type="primary"
        loading={loading}
        disabled={isEqualForms}
        onClick={() => form.submit()}>
        {t('Save')}
      </Button>
    </Form>
  )
}

NewspaperAdvancedSettingForm.propTypes = {
  initialData: PropTypes.object,
  group: PropTypes.array,
  showTitle: PropTypes.bool
}

export default NewspaperAdvancedSettingForm
