import { Avatar, Text } from '@qonsoll/react-design'

import PropTypes from 'prop-types'
import { avatarLetterFromNameOrEmail } from 'helpers'
import { useTranslations } from 'contexts/Translation'

const AvatarWithLetter = ({ user, size, additionalStyles }) => {
  /* Getting translations instance */
  const { t } = useTranslations()

  const avatarFirstLetter = avatarLetterFromNameOrEmail(
    user?.firstName,
    user?.email
  )
  return (
    <Avatar
      shape="square"
      size={size || 200}
      alt={t('User avatar')}
      style={additionalStyles}
      src={user?.avatarUrl}>
      <Text
        color="var(--ql-typography-text-color-secondary)"
        fontWeight="var(--ql-typography-heading-font-weight)"
        fontSize="var(--ql-font-size-avatar)">
        {avatarFirstLetter}
      </Text>
    </Avatar>
  )
}
AvatarWithLetter.propTypes = {
  user: PropTypes.object,
  size: PropTypes.number
}
export default AvatarWithLetter
