import { Box, Button, Text } from '@qonsoll/react-design'
import {
  DeleteOutlined,
  EditOutlined,
  HistoryOutlined,
  SendOutlined,
  StopOutlined
} from '@ant-design/icons'
import { Popconfirm, Space, Tooltip } from 'antd'

import { MOMENT_FORMATS } from '__constants__'
import PropTypes from 'prop-types'
import { Spinner } from 'components'
import { capitalize } from 'helpers'
import moment from 'moment'
import { useTranslations } from 'contexts/Translation'

const TableActions = (props) => {
  const {
    actions,
    isBlocked,
    name,
    isResend,
    isSent,
    _sentAt,
    isRewardOverview,
    isInvitedUser
  } = props

  const { t } = useTranslations()

  const blockedUserTooltip = isBlocked
    ? t(`Enable ${name}`)
    : t(`Disable ${name}`)

  const invitedUserTooltip =
    isInvitedUser &&
    t(`${capitalize(name)} cannot be disabled when he is invited`)

  const computedTooltip = isInvitedUser
    ? invitedUserTooltip
    : blockedUserTooltip

  const dateSent = (
    <Text>
      {!_sentAt?.seconds ? (
        <Spinner flex={1} />
      ) : (
        moment
          ?.unix(_sentAt?.seconds)
          ?.format(MOMENT_FORMATS.MONTH_DAYS_COMMA_YEAR)
      )}
    </Text>
  )

  return (
    <Space>
      {actions?.onBlock && (
        <Tooltip title={computedTooltip}>
          <Box>
            <Button
              type="text"
              onClick={actions.onBlock}
              disabled={isInvitedUser}
              icon={isBlocked ? <HistoryOutlined /> : <StopOutlined />}
            />
          </Box>
        </Tooltip>
      )}
      {actions?.onSend && !isResend && (
        <Tooltip title={t('Send notification')}>
          <Button
            type="text"
            icon={<SendOutlined />}
            onClick={actions?.onSend}
          />
        </Tooltip>
      )}

      {actions?.onDelete && (
        <Popconfirm
          okButtonProps={{ danger: true }}
          title={`${t(`Are you sure to remove ${name}`)}?`}
          onConfirm={actions?.onDelete}
          okText={t('Yes')}
          cancelText={t('No')}>
          <Button
            loading={actions?.deleteLoading}
            type="text"
            onSubmit={actions.onDelete}
            icon={<DeleteOutlined />}
            itemName={t(`${name}`)}
          />
        </Popconfirm>
      )}
      {actions?.onEdit && (
        <Tooltip title={t(`Edit ${name}`)}>
          <Button
            type="text"
            onClick={actions.onEdit}
            icon={<EditOutlined />}
          />
        </Tooltip>
      )}
      {isRewardOverview &&
        (isSent ? (
          dateSent
        ) : (
          <Tooltip title={t('Mark reward as sent')}>
            <Button type="primary" onClick={actions.onSent}>
              {t('Mark as sent')}
            </Button>
          </Tooltip>
        ))}
    </Space>
  )
}

TableActions.propTypes = {
  actions: PropTypes.shape({
    onBlock: PropTypes.func,
    onSent: PropTypes.func,
    onDelete: PropTypes.func,
    onEdit: PropTypes.func,
    onSend: PropTypes.func,
    deleteLoading: PropTypes.bool
  }),
  isBlocked: PropTypes.bool,
  isResend: PropTypes.bool,
  name: PropTypes.string,
  isSent: PropTypes.bool,
  isRewardOverview: PropTypes.bool,
  _sentAt: PropTypes.object,
  isInvitedUser: PropTypes.bool,
  deleteLoading: PropTypes.bool
}

export default TableActions
