import { Spinner, StyledTable } from 'components'
import { useEffect, useState } from 'react'

import { Box } from '@qonsoll/react-design'
import PropTypes from 'prop-types'
import { useGetRewardOverviewTableColumns } from 'domains/RewardOverview/hooks/get'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

const RewardOverviewTable = (props) => {
  const { lastActiveTab, dataSource, loading } = props

  /* Pagination params for table */
  const [tableParams, setTableParams] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showQuickJumper: true,
    showSizeChanger: true
  })

  const { t } = useTranslations()

  /* Getting table columns */
  const columns = useGetRewardOverviewTableColumns(lastActiveTab)

  const filteredDataSource = useMemo(
    () => dataSource?.filter((item) => item?.rewardType === lastActiveTab),
    [dataSource, lastActiveTab]
  )

  /* Handle changes for table */
  const handleTableChange = (pagination) => setTableParams(pagination)

  /* Update total value for tableParams */
  useEffect(() => {
    setTableParams((prev) => ({
      ...prev,
      total: filteredDataSource?.length
    }))
  }, [filteredDataSource])

  return loading ? (
    <Box height="35vh">
      <Spinner flex={1} text={t('Reward overviews loading')} />
    </Box>
  ) : (
    <StyledTable
      tableOuterHeight={490}
      dataSource={filteredDataSource}
      columns={columns}
      rowKey="_id"
      pagination={tableParams}
      onChange={handleTableChange}
    />
  )
}

RewardOverviewTable.propTypes = {
  dataSource: PropTypes.array,
  lastActiveTab: PropTypes.string,
  loading: PropTypes.bool
}

export default RewardOverviewTable
