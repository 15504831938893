import { COLLECTIONS, STATUSES } from '__constants__'

import { useCollection } from 'services/api/firebase'
import { useGetQuery } from 'hooks'

/**
 * It returns an array of values that are used to render the subadmins
 * @param props - An object that contains the query parameters.
 * @returns An array of values.
 */
const useGetSubadmins = (props, isSuperAdmin) => {
  const collectionNameComputed = isSuperAdmin
    ? COLLECTIONS.SUPER_ADMINS
    : COLLECTIONS.SUBADMINS

  /* Query for getting collection reference */
  const query = useGetQuery({
    collectionName: collectionNameComputed,
    where: props?.where,
    orderBy: props?.orderBy,
    statuses: STATUSES.USER_STATUSES
  })

  /* Getting collection data */

  return useCollection(query)
}

export default useGetSubadmins
