import { COLLECTIONS, STORAGE_PATH } from '__constants__'
import { compareObjects, deleteReplacedFile, uploadImage } from 'helpers'
import { generatePath, useHistory } from 'react-router-dom'
import { useEvent, useGetSubadminData } from 'hooks'

import PATHS from 'pages/paths'
import { convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import { message } from 'antd'
import { updateDocument } from 'services/api/firebase'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

/* Path's */
const editPath = PATHS.AUTHENTICATED.WEB_TIPPS_CONFIGURATION_EDIT

const useWebTippsConfigurationBaseActions = ({
  actions,
  isWebTippsEnabled,
  webTippsConfigurationId,
  handleSubmitLoading,
  initialValues
} = {}) => {
  /* Getting translations instance */
  const { t, languages } = useTranslations()
  /* Getting history instance */
  const history = useHistory()

  // Check if user is sub-admin
  const { isSubadmin } = useGetSubadminData()

  /* Function to handle back click */
  const handleBack = useEvent(() => history.goBack())

  /* Handling opening of the edit page or change view to edit mode */
  const handleOpen = useEvent(() => {
    const pathParams = { webTippsConfigurationId }
    const path = generatePath(editPath, pathParams)
    history.push(path)
  })

  /* Handling enable/disable web tipps */
  const handleBlock = useEvent(async () => {
    try {
      await updateDocument(
        COLLECTIONS.WEB_TIPPS_CONFIGURATIONS,
        webTippsConfigurationId,
        {
          isWebTippsEnabled: !isWebTippsEnabled
        }
      )
      message.success(t('Web tipps was updated successfully'))
    } catch (error) {
      message.error(t(`Web tipps was updated with error`))
    }
  })

  const prepareValues = async (values = {}) => {
    const wasLogoUpdated = Object.keys(values?.app || {})?.includes('logoUrl')

    /* Preparing newspaper values */
    let preparedValues = {
      ...values
    }

    // Check if values has property defaultLanguage in app
    // and check if values are different from initial values
    if (
      Object.keys(values?.app?.defaultLanguage || {})?.length &&
      Object.keys(
        compareObjects(
          initialValues?.app?.defaultLanguage || {},
          values?.app?.defaultLanguage || {}
        )
      )?.length
    ) {
      preparedValues.app.defaultLanguage = languages?.find(
        ({ value }) => value === values?.app?.defaultLanguage?.value
      )
    }

    // Fields that have nesting
    const specialFields = [
      'app',
      'footer',
      'header',
      'menu',
      'widgetConfig',
      'publicWizardConfiguration'
    ]

    /* Code below is needed to prevent fields overwriting */
    specialFields.forEach((value) => {
      preparedValues[value] = {
        ...initialValues?.[value],
        ...values?.[value]
      }
    })

    if (values?.footer) {
      preparedValues.footer = {
        ...values?.footer,
        mainText: draftToHtml(
          convertToRaw(values?.footer?.mainText?.getCurrentContent())
        )
      }
    }

    if (values?.publicWizardConfiguration) {
      preparedValues.publicWizardConfiguration = {
        ...values?.publicWizardConfiguration,
        publicWizardText: draftToHtml(
          convertToRaw(
            values?.publicWizardConfiguration?.publicWizardText?.getCurrentContent()
          )
        )
      }
    }

    if (wasLogoUpdated) {
      preparedValues.app.logoUrl =
        (await uploadImage(values?.app?.logoUrl, STORAGE_PATH.NEWSPAPER)) ??
        null
    }
    return preparedValues
  }
  // Third parameter isThrowError is needed to throw error above
  const handleUpdate = async (formData, initialData, isThrowError = false) => {
    try {
      handleSubmitLoading?.(true)

      const preparedValues = await prepareValues(formData)

      if (Object.keys(formData).includes('logoUrl')) {
        try {
          await deleteReplacedFile(initialData?.logoUrl, formData?.logoUrl)
        } catch (error) {
          message.error(t('Error deleting image'), error)
        }
      }

      await updateDocument(
        COLLECTIONS.WEB_TIPPS_CONFIGURATIONS,
        webTippsConfigurationId,
        preparedValues
      )
      message.success(t('Web tipps was successfully updated'))
    } catch (error) {
      if (isThrowError) throw Error(error)
      else message.error(t('Error during updating web tipps'))
    } finally {
      handleSubmitLoading?.(false)
    }
  }

  return useMemo(
    () => ({
      onBack: handleBack,
      onOpen: handleOpen,
      onBlock: !isSubadmin && handleBlock, // Enable/disable web tipps
      onEdit: handleOpen,
      onUpdate: handleUpdate,
      ...(actions || {})
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      actions?.onBack,
      actions?.onOpen,
      actions?.handleBlock,
      handleBack,
      handleOpen,
      handleBlock,
      initialValues
    ]
  )
}

export default useWebTippsConfigurationBaseActions
