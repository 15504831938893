import { COLLECTIONS, STATUSES } from '__constants__'
import { deleteDocument, updateDocument } from 'services/api/firebase'
import { generatePath, useHistory } from 'react-router-dom'

import PATHS from 'pages/paths'
import { deleteStorageFile } from 'helpers'
import { message } from 'antd'
import { useEvent } from 'hooks'
import { useMemo } from 'react'
import { useTranslations } from 'contexts/Translation'

/* Path's */
const createPath =
  PATHS.AUTHENTICATED.STORE_CREATE || PATHS.AUTHENTICATED.STORE_WIZARD
const editPath = PATHS.AUTHENTICATED.STORE_EDIT
const showPath = PATHS.AUTHENTICATED.STORE_SHOW

const useStoreBaseActions = ({ storeId, isBlocked, logoUrl, actions } = {}) => {
  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting history instance */
  const history = useHistory()

  const { STORE_STATUSES } = STATUSES

  /* Function to handle back click */
  const handleBack = useEvent(() => history.goBack())

  /* Handling opening of the create page */
  const handleCreate = useEvent(() => {
    history.push(createPath)
  })

  /* Handling opening of the show page */
  const handleOpen = useEvent(() => {
    const pathParams = { storeId }
    const path = generatePath(showPath, pathParams)
    history.push(path)
  })

  /* Handling opening of the edit page or change view to edit mode */
  const handleEdit = useEvent(() => {
    const pathParams = { storeId }
    const path = generatePath(editPath, pathParams)
    history.push(path)
  })

  const handleBlock = useEvent(async () => {
    const computedStatus = isBlocked ? 'unblocked' : 'blocked'
    try {
      await updateDocument(COLLECTIONS.STORES, storeId, {
        isBlocked: !isBlocked,
        status: !isBlocked ? STORE_STATUSES.BLOCKED : STORE_STATUSES.ACTIVE
      })
      message.success(t(`Store successfully ${computedStatus}`))
    } catch (error) {
      console.error(t(`Error ${computedStatus} status`, error.message))
      message.error(t(`Error ${computedStatus} status`, error.message))
    }
  })

  const handleDelete = useEvent(async () => {
    try {
      await deleteDocument(COLLECTIONS.STORES, storeId)

      logoUrl && (await deleteStorageFile(logoUrl))

      message.success(t('Store successfully deleted'))
    } catch (error) {
      message.error(error.message)
    }
  })

  return useMemo(
    () => ({
      onOpen: handleOpen,
      onBack: handleBack,
      onCreate: handleCreate,
      onEdit: handleEdit,
      onDelete: handleDelete,
      onBlock: handleBlock,
      ...(actions || {}),
      createPath,
      editPath,
      showPath
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      actions?.onOpen,
      actions?.onBack,
      actions?.onCreate,
      actions?.onDelete,
      actions?.onEdit,
      handleOpen,
      handleBack,
      handleCreate,
      handleDelete,
      handleEdit,
      handleBlock
    ]
  )
}

export default useStoreBaseActions
