import { Button, Col, Row, TextArea, Title } from '@qonsoll/react-design'
import { EMOJI_REGEX, KEYS, MAX_LENGTH_DESCRIPTION } from '__constants__'
import { Form, Input } from 'antd'
import {
  useActionsRewardAdvancedForm,
  useInitializeRewardAdvancedForm
} from './hooks'

import { NewspaperSelect } from 'domains/Newspaper/components'
import PropTypes from 'prop-types'
import { REWARD_OVERVIEW_TYPES } from 'domains/RewardOverview/constants'
import { RewardSimpleForm } from 'domains/Reward/components'
import { RewardTypeSelect } from 'domains/RewardType/components'
import { StoreSelect } from 'domains/Store/components'
import { onTextAreaEnterPress } from 'helpers'
import { useCheckChangeFormValue } from 'hooks'
import { useState } from 'react'
import { useTranslations } from 'contexts/Translation'

const RewardAdvancedForm = (props) => {
  const { initialData, initialCreateData, group, showTitle } = props

  /* Getting translations instance */
  const { t } = useTranslations()
  /* Getting form instance from hook */
  const { form } = useInitializeRewardAdvancedForm(
    initialData || initialCreateData
  )

  /* Handling enter key press */
  const handleKeyPress = (e) => {
    if (e.key === KEYS.ENTER) {
      e.preventDefault()
      form.submit()
    }
  }

  /* Getting loading state and actions from hook */
  const { loading, onFinish, onReset } = useActionsRewardAdvancedForm({
    initialData,
    form
  })

  // Get reward type to check if emoji choosing is available for current one
  const [rewardType, setRewardType] = useState()

  const { triggerValueChange, isEqualForms } =
    useCheckChangeFormValue(initialData)

  // Check if emoji choosing is available for current reward
  const isEmojiAvailable = !rewardType
    ? initialData?.reward?.type === REWARD_OVERVIEW_TYPES.STORE
    : rewardType === REWARD_OVERVIEW_TYPES.STORE

  return group ? (
    <>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Reward')}
        </Title>
      )}
      <RewardSimpleForm showTitle={false} group={[...group, 'reward']} />
      <Form.Item
        rules={[{ required: true, message: t('Please select newspaper') }]}
        name={[group, 'newspaper']}>
        <NewspaperSelect />
      </Form.Item>
      <Form.Item
        name={[group, 'type']}
        rules={[{ required: true, message: t('Please select reward type') }]}>
        <RewardTypeSelect />
      </Form.Item>
    </>
  ) : (
    <Form
      onKeyPress={handleKeyPress}
      onFinish={onFinish}
      layout="vertical"
      autoComplete="off"
      onValuesChange={triggerValueChange}
      form={form}>
      {showTitle && (
        <Title level={4} mb={2}>
          {t('Reward')}
        </Title>
      )}
      <RewardSimpleForm group={['reward']} />

      <Row noOuterGutters>
        <Col>
          <Form.Item
            name={['newspaper']}
            rules={[
              { required: true, message: t('Please select newspaper!') }
            ]}>
            <NewspaperSelect />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            name={['store']}
            rules={[{ required: true, message: t('Please select store!') }]}>
            <StoreSelect />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        name={['reward', 'type']}
        rules={[{ required: true, message: t('Please select reward type!') }]}>
        <RewardTypeSelect setRewardType={setRewardType} />
      </Form.Item>

      {isEmojiAvailable && (
        <Form.Item
          label={['Emoji']}
          name={['reward', 'emoji']}
          rules={[
            {
              required: true,
              message: t('Please enter a valid emoji')
            },
            {
              pattern: EMOJI_REGEX,
              message: t('Please enter a valid emoji')
            },
            {
              min: 1,
              max: 1,
              message: t('Please input no more then 1 emoji')
            }
          ]}>
          <Input placeholder={t('Please input reward emoji')} />
        </Form.Item>
      )}

      <Form.Item
        label={t('Enter the description')}
        name={['reward', 'description']}
        rules={[
          {
            max: MAX_LENGTH_DESCRIPTION,
            message: t('Description is too long!')
          }
        ]}>
        <TextArea
          onPressEnter={onTextAreaEnterPress}
          placeholder={t('Description')}
        />
      </Form.Item>
      <Form.Item
        label={t('Enter the direction of use')}
        name={['reward', 'direction']}
        rules={[
          {
            max: MAX_LENGTH_DESCRIPTION,
            message: t('Direction of use is too long!')
          }
        ]}>
        <TextArea
          onPressEnter={onTextAreaEnterPress}
          placeholder={t('Direction of use')}
        />
      </Form.Item>

      <Button mr={2} htmlType="button" onClick={onReset}>
        {t('Cancel')}
      </Button>
      <Button
        type="primary"
        loading={loading}
        disabled={isEqualForms}
        onClick={() => form.submit()}>
        {t('Save')}
      </Button>
    </Form>
  )
}

RewardAdvancedForm.propTypes = {
  initialData: PropTypes.object,
  initialCreateData: PropTypes.object,
  group: PropTypes.array,
  showTitle: PropTypes.bool
}

export default RewardAdvancedForm
