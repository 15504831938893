import PropTypes from 'prop-types'
import { STATUSES } from '__constants__'
import { Text } from '@qonsoll/react-design'
import { useSubfilterActions } from 'hooks'
import { useTranslations } from 'contexts/Translation'
import { Checkbox } from 'antd'

const TipFilter = ({ filterData, setFilterData }) => {
  const { t } = useTranslations()

  const { onToggleDetails, onChange, checkIsEnabled } = useSubfilterActions({
    filterData,
    setFilterData,
    fieldName: 'status',
    operand: '==',
    type: 'rewardOverviewStatus'
  })

  const { REWARD_OVERVIEW_STATUSES } = STATUSES

  const rewardOverviewStatuses = Object.values(REWARD_OVERVIEW_STATUSES)?.map(
    (status) => ({ value: status, title: status })
  )

  return (
    <details
      open={
        localStorage.getItem(`rewardOverviewStatus-details-visibility`) ===
        'opened'
      }>
      <summary onClick={onToggleDetails}>{t('Reward overview status')}</summary>
      {rewardOverviewStatuses?.map((tip, index) => (
        <Checkbox
          key={`status-${index}`}
          checked={checkIsEnabled(tip)}
          onChange={() => onChange(tip)}>
          <Text wordBreak="break-all">{tip?.title || t('Unnamed filter')}</Text>
        </Checkbox>
      ))}
    </details>
  )
}

TipFilter.propTypes = {
  filterData: PropTypes.object,
  setFilterData: PropTypes.func
}

export default TipFilter
