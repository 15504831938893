import moment from 'moment'
import MOMENT_FORMATS from '__constants__/momentFormats'
/**
 * Function is used to get formatted date and time in format
 * @param   {number} timestamp
 * @returns {string} formatted date and time
 */
const getFormattedDateAndTime = (timestamp) =>
  timestamp &&
  moment
    .unix(timestamp)
    .format(
      `${MOMENT_FORMATS.MONTH_DAYS_COMMA_YEAR} ${MOMENT_FORMATS.HOURS_MINUTES}`
    )

export default getFormattedDateAndTime
